import styled, { css } from "styled-components";

import { animated } from "@react-spring/web";

export const PopupMenu = styled(animated.div)`
  cursor: default;
  position: absolute;
  width: 570px;
  top: 75%;
  // left: ${(p) => (p.renderRight ? "calc(50% -10px)" : "calc(50% - 360px")};
  background-color: white;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  z-index: 99;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border: ${(p) => p.theme.borders.standard};
  border: 1px solid #cecece;

  border-radius: 5px;

  ${(p) =>
    p.leftAligned &&
    css`
      left: calc(50% - 300px);
    `}

  ${(p) =>
    p.topAligned &&
    css`
      top: calc(50% - 350px);
    `}
`;

export const PopupButton = styled.div`
  cursor: pointer;
  color: ${(p) => p.theme.colors.primaryText};
  background-color: ${(p) => p.theme.colors.gray1};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:hover {
    background-color: ${(p) => p.theme.colors.gray2};
    color: ${(p) => p.theme.colors.white};
  }

  ${(p) =>
    p.hidden &&
    css`
      display: none;
    `}

  ${(p) =>
    p.active &&
    css`
      background-color: ${(p) => p.theme.colors.primaryBlue};
      color: white;

      &:hover {
        background-color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}

    ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}

    ${(p) =>
    p.isDelete &&
    css`
      color: ${p.theme.colors.red};

      &:hover {
        background-color: ${(p) => p.theme.colors.redLight};
        color: ${(p) => p.theme.colors.red};
      }
    `}
`;

export const PopupText = styled.div`
  max-width: 192px;
  white-space: normal;
  line-height: 140%;
  word-break: break-word;
  text-align: center;
`;
