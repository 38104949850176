import * as React from "react";

import constants from "../store/constants";
import {
  useFilteredObjects,
  useObject,
} from "../../base";
import { useDispatch, useSelector } from "react-redux";
// import { useWidgetPlacement } from "../../idgetPlacements/hooks/retrieve";
import { getValueForWidget } from "../store/actions";
import { getSingle, performFilter } from "../store/actions";
import {
  getInfoForWidget,
  kpiDataMapping,
} from "../../../components/Insights/Widgets/WidgetInfo";
import { removeFromProgress } from "src/store/base/store/reducer";

export function useWidgetPlacement(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useWidgetPlacements(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };
  return useFilteredObjects(params);
}

export const useInsightsWidgetData = (
  insightsPage={},
  _widgetPlacement,
  jsonWidgetPlacement,
  realestateIds,
  needRealEstateIds,
) => {
 const dispatch = useDispatch() 
  const all = useSelector((state) => state[constants.STORE_NAME].all || {})
  const resultData = useSelector((state) => state[constants.STORE_NAME].resultData || {})

  const deleteQueue = useSelector((state) => state[constants.STORE_NAME].deleteQueue)

  const [data, setData] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true)
  const [isRequesting, setIsRequesting] = React.useState(false)

  let widgetPlacement = _widgetPlacement || jsonWidgetPlacement
  const widgetPlacementId = widgetPlacement?.id

  React.useEffect(() => {
   if (isRequesting) {
    return
   }

   if (!all.hasOwnProperty(widgetPlacementId)) {
    return
   }

    if (resultData.hasOwnProperty(widgetPlacementId)) {
      setData(resultData[widgetPlacementId])
      setIsLoading(false)
      return
    }

   if (deleteQueue && deleteQueue.hasOwnProperty(widgetPlacementId)) {
    setIsLoading(false)
    return
   }

   if (needRealEstateIds) {
    if (!realestateIds || realestateIds.length === 0) {
      return
    }
   }
    setIsRequesting(true)
   setData({})
   setIsLoading(true)
   doRequest((res) => {
    setData(res) 
    setIsRequesting(false)
    setIsLoading(false)
   })
  
  }, [all, realestateIds, deleteQueue, resultData[_widgetPlacement?.id], isRequesting])

  const doRequest = (cb) => {
    const postObj = {
    month_granularity: widgetPlacement.month_granularity,
    day_granularity: widgetPlacement.day_granularity,
    aggregates: widgetPlacement.aggregates,
    interval_type: widgetPlacement.interval_type,
    body_parameters: widgetPlacement.body_parameters || {},
    double_intervals: getInfoForWidget(widgetPlacement.widget).double_intervals,
  };
  if (kpiDataMapping[widgetPlacement?.kpi]?.percentage_value) {
    postObj["body_parameters"]["show_percentage"] = true;
  }
  let queryParams = null;
  if (needRealEstateIds && jsonWidgetPlacement && realestateIds?.length > 0) {
    queryParams = { realestate_ids: realestateIds };
  } else if (insightsPage?.query_parameters?.realestate_ids?.length) {
    queryParams = { ...insightsPage?.query_parameters };
  } else {
    queryParams = {};
  }
  if (widgetPlacement.start_date) postObj["start"] = widgetPlacement.start_date;
  if (widgetPlacement.end_date) postObj["end"] = widgetPlacement.end_date;
  dispatch(
    getValueForWidget({
      widgetPlacement: widgetPlacement,
      kpi: widgetPlacement.kpi,
      postObj: postObj,
      queryParams: queryParams,
      responseCallback: cb,
      errorCallback: () => {
        setIsLoading(false)
        setIsRequesting(false)
        removeFromProgress(dispatch, constants, `value-${widgetPlacement.id}`)

      }
    })
  );
  }









  return [data, isLoading];
};
