import * as React from "react";

export default () => {
  return (
    <div>
      Ladda upp det dokument du fått från utföraren.
      <br />
      <br />
    </div>
  );
};
