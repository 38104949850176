import * as React from "react";

import LocalCheckManyField from "../Forms/Base/Fields/LocalCheckManyField";
import LocalCheckField from "../Forms/Base/Fields/LocalCheckField";
import LocalTableSelectField from "../Forms/Base/Fields/LocalTableSelectField";
import LocalDateSelect from "../Forms/Base/Fields/LocalDateSelect";

import realEstateListDefs from "../Tables/RealEstate/listDefs";
import {
  constants as RealEstateConstants,
  useRealEstatePagination,
} from "../../store/realEstates";

import buildingListDefs from "../Tables/Buildings/listDefs";
import {
  constants as BuildingConstants,
  useBuildingPagination,
} from "../../store/buildings";

import companyListDefs from "../Tables/Companies/listDefs";
import {
  constants as CompanyConstants,
  useCompanyPagination,
} from "../../store/companies";

import { constants } from "../../store/newExcelReports";
import { KWARG_MAP } from "../../views/NewReports/utils";
import { buildQueryString } from "../../store/base";

import { getInstancesByIds } from "../../store/newExcelReports";

import { cloneDeep, get } from "lodash";
import { useSelector } from "react-redux";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import LocalSelectField from "../Forms/Base/Fields/LocalSelectField";

import { INFO_TEXTS_FOR_FILTERS } from "../../views/NewReports/utils";

import InfoModal from "./InfoModal.js";
import RealEstateTable from "../Tables/RealEstate/FullTable";
import BuildingTable from "../Tables/Buildings/FullTable";
import CompanyTable from "../Tables/Companies/FullTable";

export const useFilterInputs = ({
  filterInputStates,
  setFilterInputStates,
  currentReportData,
  currentReportStructure,
  selectedReport,
  hasCalculatedAllData,
  onAllDataCalculatedFirstTime,
  orderOfColumns,
  DEFAULTS,
  setIsFullPageLoading,
  aggregations,
}) => {
  const formErrors = useSelector(
    (state) => state[constants.STORE_NAME].formErrors
  );

  const [groupableColumnStates, setGroupableColumnStates] = React.useState(
    DEFAULTS?.grouping
      ? DEFAULTS.grouping
      : currentReportData.group_by
      ? currentReportData.group_by
      : []
  );
  const [prevGroupableColumnStates, setPrevGroupableColumnStates] =
    React.useState(groupableColumnStates);

  const [hasFetchedData, setHasFetchedData] = React.useState(false);

  const [filterInputs, setFilterInputs] = React.useState([]);
  const [filterInputsLast, setFilterInputsLast] = React.useState([]);
  const filterInputStatesRef = React.useRef();

  React.useEffect(() => {
    filterInputStatesRef.current = filterInputStates;
  }, [filterInputStates]);

  const [choicesMapping, setChoicesMapping] = React.useState({});
  const [shouldChoicesViewDone, setShouldChoicesViewDone] = React.useState({});
  const choicesMappingRef = React.useRef();

  React.useEffect(() => {
    choicesMappingRef.current = choicesMapping;
  }, [choicesMapping]);

  const [createParametersMap, setCreateParametersMap] = React.useState(
    new Map()
  );

  const [createParametersLast, setCreateParametersLast] = React.useState([]);

  const [fetchedTableData, setFetchedTableData] = React.useState({});

  const [
    currentChangeStateForTableSelects,
    setCurrentChangeStateForTableSelects,
  ] = React.useState({});

  React.useEffect(() => {
    const doFetch = async () => {
      let newTableData = {};
      if (!selectedReport) return newTableData;

      for (let param in selectedReport.options.filters) {
        const kwargInfo = KWARG_MAP[param];

        if (kwargInfo.type !== "tableSelect") continue;

        let [_, consts] = getBuildArgumentsForTableSelectField(kwargInfo);
        if (!consts) continue;
        if (Object.keys(consts).length === 0) continue;

        let currentParamValue = currentReportData?.filters?.[param];

        if (!currentParamValue) {
          newTableData[param] = [];
          continue;
        }

        let instances = await getInstancesByIds({
          constants: consts,
          ids: currentParamValue,
        });

        newTableData[param] = instances;
      }

      setFetchedTableData(newTableData);
      setTimeout(() => {
        setIsFullPageLoading(false);
        setHasFetchedData(true);
      }, 250);
    };
    doFetch();
  }, [currentReportData, currentReportStructure, selectedReport]);

  //ensure always same order on createParameters.
  React.useEffect(() => {
    if (!selectedReport) return;

    let map = new Map();
    let stateForTableSelects = {};

    let paramsLast = [];

    for (let param in currentReportStructure.createParameters) {
      let kwargInfo = KWARG_MAP[param];

      if (!kwargInfo) {
        console.log(
          `${param} is not implemented in KWARG_MAP (creating FIRST parameters map)`
        );
        map.set(param, undefined);
        continue;
      }

      if (kwargInfo.last === true) {
        paramsLast.push(param);
        continue;
      }

      if (currentReportData?.additional_kwargs?.hasOwnProperty(param)) {
        map.set(param, currentReportData.additional_kwargs[param]);
      } else if (currentReportData.hasOwnProperty(param)) {
        map.set(param, currentReportData[param]);
      } else {
        map.set(param, currentReportStructure.createParameters[param]);
      }
    }

    let newChoicesMapping = {};

    for (let param in selectedReport.options.filters) {
      let val = selectedReport.options.filters[param];

      if (val.hasOwnProperty("choices")) {
        //is it open or closed.
        newChoicesMapping[param] = false;
      }

      if (!KWARG_MAP.hasOwnProperty(param)) {
        console.log(
          `${param} is not implemented in KWARG_MAP (creating parameters map)`
        );
        map.set(param, undefined);
        continue;
      }

      const kwargInfo = KWARG_MAP[param];

      let currentParamValue = currentReportData?.filters?.[param];

      if (currentParamValue === undefined || currentParamValue === null) {
        //Doesnt have value
        let newParamValue = undefined;

        if (kwargInfo.type === "checkbox") {
          if (currentParamValue !== undefined && currentParamValue !== null) {
            newParamValue = currentParamValue;
          } else {
            newParamValue = kwargInfo.default ? kwargInfo.default : true;
          }
        }

        if (DEFAULTS && DEFAULTS?.filters.hasOwnProperty(param)) {
          newParamValue = DEFAULTS.filters[param];
        }

        if (kwargInfo.type === "tableSelect") {
          stateForTableSelects[kwargInfo.typeName] = newParamValue;
          //ensure filters for tables is at least an empty array
          //is used for detecting updates to show
          //the "Uppdatera" button
          if (newParamValue === undefined) newParamValue = [];
        }

        if (kwargInfo.arrayContentType === "boolean") {
          newParamValue = "true"; //booleans defaults to true
        }

        map.set(param, newParamValue);
      } else {
        //Have param value
        if (kwargInfo.type === "tableSelect") {
          currentParamValue = fetchedTableData?.[param];

          //See above comment
          if (currentParamValue === undefined) currentParamValue = [];

          stateForTableSelects[kwargInfo.typeName] = currentParamValue;
        }

        if (kwargInfo.arrayContentType === "boolean") {
          currentParamValue = currentParamValue.toString();
        }

        //Convert an array of ids in string format to integers
        //selectMany dropdowns need it.
        if (
          kwargInfo.type === "selectMany" &&
          kwargInfo.isIdArray === true &&
          Array.isArray(currentParamValue) &&
          currentParamValue?.length > 0 &&
          !isNaN(currentParamValue[0])
        ) {
          currentParamValue = currentParamValue.map((str) => {
            try {
              return parseInt(str);
            } catch (err) {
              console.log(
                "Couldn't parse integer (param:",
                param,
                "value:",
                currentParamValue,
                "index_value:",
                str + ")"
              );
              return -1;
            }
          });
        }
        map.set(param, currentParamValue);
      }
    }

    let createParametersLastClone = [...paramsLast];

    //only exclude_main_data will run here at the moment
    for (let param of paramsLast) {
      if (currentReportData.hasOwnProperty(param)) {
        map.set(param, currentReportData[param]);
      } else {
        map.set(param, currentReportStructure.createParameters[param]);
      }
    }

    setCurrentChangeStateForTableSelects(stateForTableSelects);
    setChoicesMapping(newChoicesMapping);
    setCreateParametersLast(createParametersLastClone);
    setCreateParametersMap(map);
  }, [fetchedTableData]);

  React.useEffect(() => {
    if (!createParametersMap || createParametersMap.size === 0) return;
    let arr = [];
    for (let value of createParametersMap.values()) {
      arr.push(value);
    }

    setFilterInputStates(arr);
  }, [createParametersMap]);

  const toggleChoiceMapping = (key, forceValue) => {
    let newChoicesMapping = Object.assign({}, choicesMappingRef.current);
    newChoicesMapping[key] =
      forceValue === undefined || forceValue === null
        ? !newChoicesMapping[key]
        : forceValue;

    setChoicesMapping(newChoicesMapping);
  };

  const changeFilterState = (value, index) => {
    let newArr = filterInputStates.slice();

    newArr[index] = value;

    setFilterInputStates(newArr);
  };

  const getValueFromInputState = (index) => {
    return filterInputStatesRef.current[index];
  };

  const getBuildArgumentsForTableSelectField = (kwargInfo) => {
    switch (kwargInfo.typeName) {
      case "realestates":
        return [RealEstateTable, RealEstateConstants];
      case "buildings":
        return [BuildingTable, BuildingConstants];
      case "companies":
        return [CompanyTable, CompanyConstants];
      default:
        console.log(
          "This kwarg type is not implemented (getBuildArgumentsForTableSelectField):",
          kwargInfo.typeName
        );
        return ["empty", () => {}, () => {}, {}];
    }
  };

  const buildSelectManyForRendering = ({
    onClick,
    onDone,
    onClose,
    values,
    choices,
    enabled,
    choicesAmt,
    forceHasUpdated = false,
    onChange,
    title,
    error,
    getValues,
    callDoneOnClose,
    removeUpdateOnlyOnDone,
  }) => {
    return (
      <div className="relative">
        <div className="font-medium text-gray-900 text-sm flex items-center">
          {error && (
            <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
          )}
          <p className="">{title}</p>
        </div>
        {error && (
          <div className="text-xs font-normal text-red-600 mb-1">{error}</div>
        )}
        <button
          onClick={(evt) => {
            onClick();
          }}
          className="inline-flex w-full bg-white items-center border border-solid border-slate-300 text-gray-900 rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-sky-100  focus:outline-none text-sm p-2.5 text-center"
        >
          {choicesAmt !== 0 ? (
            <>{values.length} objekt valda</>
          ) : (
            <>Välj ett eller flera objekt...</>
          )}
        </button>
        <LocalCheckManyField
          values={values}
          onDone={onDone}
          choices={choices}
          enabled={enabled}
          forceHasUpdated={forceHasUpdated}
          onChange={onChange}
          onClose={onClose}
          getValues={getValues}
          callDoneOnClose={callDoneOnClose}
          removeUpdateOnlyOnDone={removeUpdateOnlyOnDone}
        />
      </div>
    );
  };

  const buildFiltersForTableSelect = (kwargInfo) => {
    let res = {};

    if (!kwargInfo.filterBy || kwargInfo.filterBy.length === 0) return;

    for (let filterKey of kwargInfo.filterBy) {
      let indexOfFilter = Array.from(createParametersMap.keys()).indexOf(
        filterKey
      );

      if (indexOfFilter === -1) continue;

      const currentFilterValue = filterInputStates[indexOfFilter];

      if (!currentFilterValue || currentFilterValue.length === 0) {
        continue;
      }

      res[filterKey] = currentFilterValue.map((obj) => {
        return obj.id;
      });
    }

    return res;
  };

  const handleDependencies = (newVal, kwargInfo) => {
    //No need to update dependencies state if no value is accepted
    if (newVal.length === 0) return;

    if (
      !kwargInfo.dependencies ||
      Object.keys(kwargInfo.dependencies).length === 0
    )
      return;

    for (let dep of Object.keys(kwargInfo.dependencies)) {
      let indexOfDep = Array.from(createParametersMap.keys()).indexOf(dep);

      if (indexOfDep === -1) continue;

      const currentDepValue = filterInputStates[indexOfDep];

      if (!currentDepValue || currentDepValue.length === 0) continue;

      let instructions = kwargInfo.dependencies[dep];

      let acceptedComparator = [];

      for (let val of newVal) {
        acceptedComparator.push(get(val, instructions.internalComparator));
      }

      let newDepValue = currentDepValue.filter((obj) => {
        return acceptedComparator.includes(
          get(obj, instructions.externalComparator)
        );
      });

      if (newDepValue.length === currentDepValue.length) continue;

      changeFilterStateForTableSelect(newDepValue, KWARG_MAP[dep]);
      changeFilterState(newDepValue, indexOfDep);
    }
  };

  const changeFilterStateForTableSelect = (val, kwargInfo) => {
    let clone = cloneDeep(currentChangeStateForTableSelects);
    clone[kwargInfo.typeName] = val;
    setCurrentChangeStateForTableSelects(clone);
  };

  const getValueFromStaticInput = (inputName) => {
    let value;

    switch (inputName) {
      case "aggregations":
        value = aggregations;
        break;
      case "groupings":
        value = groupableColumnStates;
        break;
      default:
        value = undefined;
        break;
    }

    return value;
  };

  const getInputElementForType = (kwargInfo, index, key) => {
    if (!selectedReport) return;

    if (kwargInfo.hasOwnProperty("showOnStaticInputsHasValue")) {
      let noValue = 0;

      for (let name of kwargInfo.showOnStaticInputsHasValue) {
        const value = getValueFromStaticInput(name);

        if (value === undefined || value === null || value?.length === 0) {
          noValue++;
        }
      }

      if (noValue === kwargInfo.showOnStaticInputsHasValue.length) {
        if (kwargInfo.type === "checkbox") {
          if (filterInputStates[index] !== false) {
            changeFilterState(false, index);
          }
        }

        return undefined;
      }
    }

    switch (kwargInfo.type) {
      case "tableSelect":
        const [TableComponent] =
          getBuildArgumentsForTableSelectField(kwargInfo);

        let filters = buildFiltersForTableSelect(kwargInfo);

        return (
          <LocalTableSelectField
            value={currentChangeStateForTableSelects[kwargInfo.typeName]}
            onChange={(val) => {
              changeFilterStateForTableSelect(val, kwargInfo);
            }}
            onDone={() => {
              handleDependencies(
                currentChangeStateForTableSelects[kwargInfo.typeName],
                kwargInfo
              );
              changeFilterState(
                currentChangeStateForTableSelects[kwargInfo.typeName],
                index
              );
            }}
            onClose={() => {
              changeFilterStateForTableSelect(
                filterInputStatesRef.current[index],
                kwargInfo
              );
            }}
            onClearAll={() => {
              let newVal = [];
              handleDependencies(newVal, kwargInfo);
              changeFilterState(newVal, index);
            }}
            TableComponent={TableComponent}
            placeholder={kwargInfo.placeholder}
            isMany={kwargInfo.many ? true : false}
            title={kwargInfo.name}
            error={get(formErrors, kwargInfo.errorPath)}
            persistantQuery={filters || ""}
          />
        );

      case "checkbox":
        let title = kwargInfo.text ? kwargInfo.text : kwargInfo.name;

        let titleHtml = undefined;

        if (kwargInfo.hasOwnProperty("infoText")) {
          titleHtml = <InfoModal text={kwargInfo.infoText} />;
        }

        return (
          <div>
            <p className="font-medium text-gray-900 text-sm flex items-center">
              {kwargInfo.name}
              {titleHtml && titleHtml}
            </p>
            <div
              onClick={() =>
                changeFilterState(!filterInputStates[index], index)
              }
              className="inline-flex cursor-pointer w-full bg-white items-center border border-solid border-slate-300 text-gray-900  rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-sky-100 focus:outline-none text-sm p-2.5 text-center"
            >
              <LocalCheckField
                className="pointer-events-none select-none child-div-div-label:font-normal"
                title={title}
                value={filterInputStates[index]}
                onChange={() => {}}
                error={get(formErrors, kwargInfo.errorPath)}
              />
            </div>
          </div>
        );

      case "selectMany":
        //get choices from filters
        const choices = selectedReport.options.filters[key].choices.map(
          (arr) => {
            return {
              value: arr[0],
              name: arr[1],
            };
          }
        );

        const choicesAmt =
          filterInputStates[index] && filterInputStates[index].length !== 0
            ? filterInputStates[index].length
            : 0;

        const doSave = (val, key, index) => {
          console.log("doSave", val);
          if (val !== null) {
            console.log(key, index, 573);
            toggleChoiceMapping(key, false);
            changeFilterState(val, index);
          } else {
            toggleChoiceMapping(key, false);
          }
        };

        return buildSelectManyForRendering({
          onClick: () => toggleChoiceMapping(key),
          values: filterInputStates[index],
          onDone: (val) => {
            toggleChoiceMapping(key, false);
          },
          choices,
          enabled: choicesMapping[key],
          choicesAmt,
          title: kwargInfo.name,
          error: get(formErrors, kwargInfo.errorPath),
          onClose: (val) => {},
          callDoneOnClose: true,
          removeUpdateOnlyOnDone: true,
          onChange: (val) => {
            changeFilterState(val, index);
          },
          getValues: () => getValueFromInputState(index),
        });

      case "select":
        let value = filterInputStates[index];

        if (value === undefined) value = true;

        return (
          <LocalSelectField
            choices={kwargInfo.choices}
            removePlaceholder={true}
            title={kwargInfo.name}
            value={filterInputStates[index]}
            onChange={(val) => changeFilterState(val, index)}
            error={get(formErrors, kwargInfo.errorPath)}
          />
        );

      case "date":
        return (
          <LocalDateSelect
            title={kwargInfo.name}
            value={filterInputStates[index]}
            onChange={(val) => changeFilterState(val, index)}
            error={get(formErrors, kwargInfo.errorPath)}
          />
        );
      default:
        console.log(
          "This kwarg type is not implemented (getInputElementForType):",
          kwargInfo.type
        );
        return "";
    }
  };

  React.useEffect(() => {
    if (filterInputStates.length === 0) return;
    if (!selectedReport) return;
    if (!hasFetchedData) return;

    let arr = [];
    let lastArr = [];

    let index = 0;

    const buildFilter = (key, isOnLastArray = false) => {
      const kwargInfo = KWARG_MAP[key];
      if (!kwargInfo) {
        return console.log("This kwarg type is not supported:", key);
      }

      const inputElement = getInputElementForType(kwargInfo, index, key);

      if (inputElement !== undefined) {
        if (isOnLastArray) {
          lastArr.push(
            <div key={key} className="min-w-[14rem] mt-2 mr-2">
              {inputElement}
            </div>
          );
        } else {
          arr.push(
            <div key={key} className="min-w-[14rem] mt-2 mr-2">
              {inputElement}
            </div>
          );
        }
      }

      index++;
    };

    for (let key of createParametersMap.keys()) {
      if (createParametersLast.includes(key)) continue;
      buildFilter(key);
    }

    for (let key of createParametersLast) {
      buildFilter(key, true);
    }

    setFilterInputs(arr);
    setFilterInputsLast(lastArr);

    if (!hasCalculatedAllData) onAllDataCalculatedFirstTime();
  }, [
    filterInputStates,
    choicesMapping,
    formErrors,
    currentChangeStateForTableSelects,
    hasFetchedData,
    aggregations,
    groupableColumnStates,
  ]);

  //Handle all states for groupable(/groupings) element seperately
  const [groupableElement, setGroupableElement] = React.useState(undefined);
  const [isGroupableElementOpen, setIsGroupableElementOpen] =
    React.useState(false);
  const [prevTrackingColumns, setPrevTrackingColumns] = React.useState([]);
  const [
    currentGroupableStateBeforeSubmit,
    setCurrentGroupableStateBeforeSubmit,
  ] = React.useState(groupableColumnStates);
  const [groupableColumns, setGroupableColumns] = React.useState([]);

  React.useEffect(() => {
    const groupable = [];
    const groupableColsFullData = [];

    for (let obj of orderOfColumns) {
      if (obj.groupable) {
        groupable.push({
          value: obj.identifier,
          name: obj.name,
        });
        groupableColsFullData.push(obj);
      }
    }

    setGroupableColumns(groupableColsFullData);

    if (groupable.length === 0) {
      if (groupableElement === undefined) return;
      setGroupableColumnStates([]);
      setCurrentGroupableStateBeforeSubmit([]);
      setPrevGroupableColumnStates([]);
      setPrevTrackingColumns([]);
      setGroupableElement(undefined);
      setIsGroupableElementOpen(false);
      return;
    }

    let groupableColumnsRemoved = [];

    let trackingData = [];

    for (let col of groupableColsFullData) {
      trackingData.push(col.identifier);
    }

    for (let columnId of prevTrackingColumns) {
      if (!trackingData.includes(columnId))
        groupableColumnsRemoved.push(columnId);
    }

    let newColStates = groupableColumnStates;

    if (groupableColumnsRemoved.length !== 0) {
      for (let i = groupableColumnsRemoved.length - 1; i >= 0; i--) {
        let removedId = groupableColumnsRemoved[i];
        const idIndex = newColStates.indexOf(removedId);
        if (idIndex !== -1) {
          newColStates.splice(idIndex, 1);
        }
      }
      setGroupableColumnStates(newColStates);
    }

    let shouldForceUpdate = false;

    if (
      JSON.stringify(currentGroupableStateBeforeSubmit) !==
      JSON.stringify(prevGroupableColumnStates)
    ) {
      shouldForceUpdate = true;
    }

    setPrevTrackingColumns(trackingData);

    setGroupableElement(
      <div key={"groupable"} className="min-w-[14rem] mt-2 mr-2">
        {buildSelectManyForRendering({
          onClick: () => setIsGroupableElementOpen(!isGroupableElementOpen),
          values: newColStates,
          onDone: (val) => {
            setIsGroupableElementOpen(false);
            setGroupableColumnStates(val);
            setPrevGroupableColumnStates(val);
            setCurrentGroupableStateBeforeSubmit(val);
          },
          onClose: (val) => {},
          callDoneOnClose: true,
          choices: groupable,
          enabled: isGroupableElementOpen,
          choicesAmt: newColStates.length,
          forceHasUpdated: shouldForceUpdate,
          onChange: (val, _new) => {
            setCurrentGroupableStateBeforeSubmit(val);
            setGroupableColumnStates(val);
          },
          title: (
            <div className="flex">
              <p>Gruppera</p>
              <InfoModal text={INFO_TEXTS_FOR_FILTERS.groupings} />
            </div>
          ),
          error: get(formErrors, "group_by"),
        })}
      </div>
    );
  }, [
    orderOfColumns,
    isGroupableElementOpen,
    groupableColumnStates,
    currentGroupableStateBeforeSubmit,
  ]);

  return {
    filterInputs,
    filterInputsLast,
    createParametersMap,
    choicesMapping,
    groupableElement,
    groupableColumns,
    groupingState: groupableColumnStates,
  };
};
