import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useApartment } from "../../store/apartments";
import { buildQueryString } from "../../store/base";
import { useFilteredErrandComponents } from "../../store/errandComponents";
import {
  useFilteredInspectionErrands,
  detailUrl as inspectionDetailUrl,
} from "../../store/inspectionErrands";
import {
  detailUrl as errandDetailUrl,
  useFilteredReportErrands,
} from "../../store/reportErrands";
import PageSlider from "../../views/Layouts/PageSlider/PageSlider";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../Details/OverviewInfo/styles";
import { PrimaryButton, TextButton } from "../Forms/Base/Buttons";
import OverlaySpinner from "../Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../sharedStyles";
import RoomOverview from "./Component/RoomOverview";
import ReportErrandBox from "./ErrandComponents/ReportErrandBox";
import {
  create as createRoom,
  update as updateRoom,
  useRoomForm,
  constants as roomConstants,
  useFilteredRooms,
} from "../../store/rooms";
import StandardModal from "../Modals/StandardModal";
import NonConnectedTextInput from "../Forms/Base/Old/NonConnected/NonConnectedTextInput";
import { useDispatch, useSelector } from "react-redux";
import NonConnectedSelect from "../Forms/Base/Old/NonConnected/NonConnectedSelect";
import { useParkingSpot } from "../../store/parkingSpots";
import { getComponentsByRooms } from "./utils";
import { useCommonArea } from "../../store/commonAreas";
import { useIndustrialPremises } from "../../store/industrialPremises";
import { useBrfPremises } from "../../store/brfPremises";
import ReportErrandTable from "../Tables/ReportErrands/FullTableNewVersion";
import InspectionErrandTable from "../Tables/InspectionErrands/FullTable";
import InspectionErrandModalForm from "../Forms/InspectionErrand/ChapterForm/ModalForm";
import moment from "moment";

const PREMISES_TYPES = {
  APARTMENT: 1,
  INDP: 2,
  COMMON: 3,
  BRF: 4,
  PARKING: 5,
};

const getTypeFromParams = ({
  apartmentId,
  parkingSpotId,
  commonAreaId,
  industrialPremisesId,
  brfPremisesId,
}) => {
  if (apartmentId) return PREMISES_TYPES.APARTMENT;
  if (parkingSpotId) return PREMISES_TYPES.PARKING;
  if (commonAreaId) return PREMISES_TYPES.COMMON;
  if (industrialPremisesId) return PREMISES_TYPES.INDP;
  if (brfPremisesId) return PREMISES_TYPES.BRF;
};
const getIdFromParams = ({
  apartmentId,
  parkingSpotId,
  commonAreaId,
  industrialPremisesId,
  brfPremisesId,
}) => {
  if (apartmentId) return apartmentId;
  if (parkingSpotId) return parkingSpotId;
  if (commonAreaId) return commonAreaId;
  if (industrialPremisesId) return industrialPremisesId;
  if (brfPremisesId) return brfPremisesId;
};

const getPremisesHookFromType = (type) => {
  if (type === PREMISES_TYPES.APARTMENT) return useApartment;
  if (type === PREMISES_TYPES.PARKING) return useParkingSpot;
  if (type === PREMISES_TYPES.COMMON) return useCommonArea;
  if (type === PREMISES_TYPES.INDP) return useIndustrialPremises;
  if (type === PREMISES_TYPES.BRF) return useBrfPremises;
};
const getComponentQueryKeyFromType = (type) => {
  if (type === PREMISES_TYPES.APARTMENT) return "apartments";
  if (type === PREMISES_TYPES.PARKING) return "parking_spot";
  if (type === PREMISES_TYPES.COMMON) return "common_areas";
  if (type === PREMISES_TYPES.INDP) return "industrial_premises_list";
  if (type === PREMISES_TYPES.BRF) return "brf_premises";
};
const getContentKeyFromType = (type) => {
  if (type === PREMISES_TYPES.APARTMENT) return "apartment";
  if (type === PREMISES_TYPES.PARKING) return "parking_spot";
  if (type === PREMISES_TYPES.COMMON) return "common_area";
  if (type === PREMISES_TYPES.INDP) return "industrial_premises";
  if (type === PREMISES_TYPES.BRF) return "brf_premis";
};

const TABS = {
  REPORT_ERRANDS: "Felanmälningar",
  INSPECTION_ERRANDS: "Besiktningar",
};

export default function TFPremisesBase() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const {
    apartmentId,
    parkingSpotId,
    commonAreaId,
    industrialPremisesId,
    brfPremisesId,
  } = useParams();
  const [selectedTab, setSelectedTab] = React.useState("REPORT_ERRANDS");
  const [addRoomOpen, setAddRoomOpen] = React.useState(false);
  const [editRoomOpen, setEditRoomOpen] = React.useState(false);
  const [addRoomLoading, setAddRoomLoading] = React.useState(false);
  const [roomName, setRoomName] = React.useState("");
  const [roomKind, setRoomKind] = React.useState(null);
  const [createInspectionOpen, setCreateInspectionOpen] = React.useState(false);
  useRoomForm("POST");

  const isParkingSpot = !!parkingSpotId;

  const kindAlternatives = useSelector(
    (state) => state[roomConstants.STORE_NAME].forms["POST"]?.kind?._choices
  );

  const premisesType = getTypeFromParams({
    apartmentId,
    parkingSpotId,
    commonAreaId,
    industrialPremisesId,
    brfPremisesId,
  });
  const premisesId = getIdFromParams({
    apartmentId,
    parkingSpotId,
    commonAreaId,
    industrialPremisesId,
    brfPremisesId,
  });
  const componentQueryKey = getComponentQueryKeyFromType(premisesType);
  const contentKey = getContentKeyFromType(premisesType);
  const usePremisesHook = getPremisesHookFromType(premisesType);
  const [premises, premisesLoading] = usePremisesHook(premisesId);
  const userId = useSelector((state) => state.app.userId);

  const componentQ = buildQueryString({
    [componentQueryKey || "id__in"]: premisesId || [],
  });

  const [components, componentsLoading] =
    useFilteredErrandComponents(componentQ);

  // ugly hack to not fetch all rooms when parking spot
  const roomQ = buildQueryString({
    [isParkingSpot ? "apartment" : contentKey]: isParkingSpot ? -1 : premisesId,
  });

  const [premisesRooms, roomsLoading] = useFilteredRooms(roomQ);

  const activeInspectionsQ = React.useMemo(() => {
    return {
      [contentKey]: premisesId,
      status__in: [0, 1, 2],
    };
  }, [premisesId, contentKey]);

  const nonActiveInspectionsQ = React.useMemo(() => {
    return {
      [contentKey]: premisesId,
      status__in: [3, 4],
    };
  }, [premisesId, contentKey]);

  const [activeInspectionErrands] = useFilteredInspectionErrands(
    buildQueryString(activeInspectionsQ)
  );

  const activeReportErrandQ = React.useMemo(() => {
    return {
      component_placement__in: components?.map((c) => c.id),
      status__in: [0, 1, 2],
    };
  }, [components]);

  const nonActiveReportErrandQ = React.useMemo(() => {
    return {
      component_placement__in: components?.map((c) => c.id),
      status__in: [3, 4],
    };
  }, [components]);

  const [activeReportErrands] = useFilteredReportErrands(
    buildQueryString(activeReportErrandQ)
  );

  const componentsByRoom = React.useMemo(() => {
    return getComponentsByRooms({ components, premisesRooms });
  }, [components, premisesRooms]);

  const addRoom = () => {
    setAddRoomLoading(true);
    dispatch(
      createRoom({
        forceData: {
          room_id: roomName,
          kind: roomKind,
          [contentKey]: {
            id: premisesId,
          },
          building: {
            id: premises?.building?.id,
          },
        },
        successCallback: () => {
          setAddRoomLoading(false);
          setRoomKind(null);
          setRoomName("");
          setAddRoomOpen(false);
        },
        errorCallback: () => {
          setAddRoomLoading(false);
        },
      })
    );
  };
  const editRoom = () => {
    setAddRoomLoading(true);
    dispatch(
      updateRoom({
        id: editRoomOpen.id,
        forceData: {
          id: editRoomOpen.id,
          room_id: roomName,
          kind: roomKind,
        },
        successCallback: () => {
          setAddRoomLoading(false);
          setRoomKind(null);
          setRoomName("");
          setEditRoomOpen(false);
        },
        errorCallback: () => {
          setAddRoomLoading(false);
        },
      })
    );
  };

  const handleEditRoom = (room) => {
    setRoomName(room.room_id);
    setRoomKind(room.kind);
    setEditRoomOpen(room);
  };

  return (
    <>
      <InspectionErrandModalForm
        method="POST"
        isOpen={createInspectionOpen}
        onCheckout={() => setCreateInspectionOpen(false)}
        instance={{
          [contentKey]: {
            id: premisesId,
          },
          reported_by: {
            id: userId,
          },
          created_at: moment().format("YYYY-MM-DD HH:mm"),
          _dontClear: true,
        }}
      />

      <StandardModal
        canAccept={!!roomName}
        title="Skapa rum"
        isOpen={addRoomOpen}
        closeFunction={() => setAddRoomOpen(false)}
        withActionBar
        saveFunction={addRoom}
      >
        <NonConnectedTextInput
          value={roomName}
          onChange={(val) => setRoomName(val)}
          label="Namn på rum"
        />
        <NonConnectedSelect
          extraStyles={{}}
          allowNull={false}
          onUpdate={(val) => setRoomKind(val)}
          choices={kindAlternatives}
          value={roomKind}
          id="roomkind"
          label="Typ av rum"
          getOptionLabel={(o) => o.d}
          getOptionValue={(o) => o.v}
          required
        />
      </StandardModal>

      <StandardModal
        canAccept={!!roomName}
        title="Redigera rum"
        isOpen={editRoomOpen}
        closeFunction={() => setEditRoomOpen(false)}
        withActionBar
        saveFunction={editRoom}
      >
        <NonConnectedTextInput
          value={roomName}
          onChange={(val) => setRoomName(val)}
          label="Namn på rum"
        />
        <NonConnectedSelect
          extraStyles={{}}
          allowNull={false}
          onUpdate={(val) => setRoomKind(val)}
          choices={kindAlternatives}
          value={roomKind}
          id="roomkind"
          label="Typ av rum"
          getOptionLabel={(o) => o.d}
          getOptionValue={(o) => o.v}
          required
        />
      </StandardModal>

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{ flex: 2, maxWidth: "66%", alignSelf: "flex-start" }}
          >
            <OverviewTitleWrapper>
              <OverviewTitle small>
                {isParkingSpot ? "Komponenter" : "Rum & Komponenter"}
              </OverviewTitle>

              {!isParkingSpot && (
                <PrimaryButton
                  title="Lägg till rum"
                  clicked={() => setAddRoomOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            {(componentsLoading || addRoomLoading) && <OverlaySpinner />}

            {(Object.keys(componentsByRoom)?.length > 0 || isParkingSpot) && (
              <RoomOverview
                isParkingSpot={isParkingSpot}
                parkingSpotId={parkingSpotId}
                rooms={componentsByRoom}
                handleEditRoom={handleEditRoom}
              />
            )}

            {!componentsLoading &&
              Object.keys(componentsByRoom)?.length === 0 &&
              !isParkingSpot && (
                <InnerBox
                  style={{
                    minHeight: 120,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {isParkingSpot
                    ? "Detta objekt saknar komponenter"
                    : "Detta objekt saknar rum & komponenter"}
                </InnerBox>
              )}
          </DetailPageBox>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "33%",
              alignSelf: "flex-start",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>Skapa ärende</OverviewTitle>
                  <OverviewSubtitle>
                    För att skapa en felanmälan, gå in på den aktuella
                    komponenten
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              <InnerBox>
                <TextButton
                  title="Skapa besiktning"
                  iconType="add"
                  iconPlacement="right"
                  clicked={() => setCreateInspectionOpen(true)}
                />
              </InnerBox>
            </DetailPageBox>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle small>Aktiva ärenden</OverviewTitle>
              </OverviewTitleWrapper>

              {activeReportErrands?.map((errand) => {
                return (
                  <ReportErrandBox
                    key={errand?.id}
                    onClick={() => push(errandDetailUrl({ id: errand.id }))}
                    errand={errand}
                  />
                );
              })}

              {activeInspectionErrands?.map((errand) => {
                return (
                  <ReportErrandBox
                    key={errand.id}
                    onClick={() => push(inspectionDetailUrl({ id: errand.id }))}
                    errand={errand}
                  />
                );
              })}

              {activeInspectionErrands?.length === 0 &&
                activeReportErrands?.length === 0 && (
                  <InnerBox
                    style={{
                      minHeight: 120,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Detta objekt har inga aktiva ärenden
                  </InnerBox>
                )}
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle small>Historik</OverviewTitle>

            <PageSlider
              {...{
                TABS,
                selectedTab,
                onTabSelected: (tab) => setSelectedTab(tab),
              }}
            />
          </OverviewTitleWrapper>

          {selectedTab === "REPORT_ERRANDS" && (
            <ReportErrandTable persistantQuery={nonActiveReportErrandQ} />
          )}
          {selectedTab === "INSPECTION_ERRANDS" && (
            <InspectionErrandTable persistantQuery={nonActiveInspectionsQ} />
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
