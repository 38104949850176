import React from "react";
import { buildQueryString, useFormInstanceField } from "../../../../store/base";
import { TextInput } from "../../Base/Fields";
import { constants } from "../../../../store/roundingErrandSettings";
import { useFilteredRealEstates } from "../../../../store/realEstates";
import useActiveFilters from "../../../../hooks/useActiveFilters";
import TableSelectField from "../../Base/Fields/TableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

export default function AreaForm({ id, method }) {
  const storeName = constants.STORE_NAME;
  const { filteredRealEstates } = useActiveFilters();
  const realEstateQuery = {
    id__in: filteredRealEstates,
  };

  const [realEstates] = useFilteredRealEstates(
    buildQueryString(realEstateQuery)
  );

  const areas = useFormInstanceField({ storeName, fieldKey: "areas" });
  const currentAreaIndex = areas?.findIndex((a) => (a.id || a._reduxId) === id);

  return (
    <>
      <TextInput
        storeName={storeName}
        method={method}
        fieldKey={`areas[${currentAreaIndex}].title`}
        instructionsKey={"areas.title"}
        title="Titel"
      />
      <TableSelectField
        storeName={storeName}
        persistantQuery={realEstateQuery}
        placeholder="Välj fastigheter..."
        title="Fastigheter*"
        method={"POST"}
        fieldKey={`areas[${currentAreaIndex}].realestates`}
        instructionsKey={"areas.realestates"}
        TableComponent={RealEstateTable}
      />
    </>
  );
}
