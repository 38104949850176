import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { LinkedObject } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";

import PaymentGroupTable from "../../../components/Tables/PaymentGroup/FullTable";
import LoanTable from "../../../components/Tables/Loan/FullTable";

import {
  createUrl as paymentGroupCreateUrl,
  useFilteredPaymentGroups,
} from "../../../store/paymentGroup";
import {
  createUrl as loanCreateUrl,
  useFilteredLoans,
} from "../../../store/loan";
import { TextButton } from "../../../components/Forms/Base/Buttons";
import moment from "moment";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { toMoneyString } from "../../../components/utils/stringUtils";

export default function BrfCompanyLoans() {
  const { brfCompanyId } = useParams();
  const { push } = useHistory();

  const [paymentGroups, paymentGroupsLoading] = useFilteredPaymentGroups(
    buildQueryString({ brf_company: brfCompanyId })
  );
  const [standaloneLoans, standaloneLoansLoading] = useFilteredLoans(
    buildQueryString({ brf_company: brfCompanyId, payment_group__isnull: true })
  );

  const canAdd = useAllPermissionCheck(["add_can_brf"]);

  const getInfoObj = () => {
    const startDateLimit = moment();
    startDateLimit.set({ day: 1, month: 0 });

    const endDateLimit = moment();
    endDateLimit.set({ day: 31, month: 11 });

    let loan_capital = 0;
    let loan_interest_fee = 0;
    let loan_firm_interest_fee = 0;
    let loan_non_capital = 0;
    let non_loan = 0;
    let total = 0;

    let out_loan_amort = 0;
    let out_loan_interest = 0;
    let out_loan_firm_interest_fee = 0;
    let out_loan_total = 0;

    (paymentGroups ?? []).forEach((g) => {
      (g.fee_previews ?? []).forEach((p) => {
        const _start = moment(p.period_start);
        const _end = moment(p.period_end);

        if (_start > endDateLimit || _end < startDateLimit) {
          return;
        }

        const _months =
          (_end.year() - _start.year()) * 12 +
          (_end.month() - _start.month()) +
          1;
        let _actualMonths = _months;
        if (startDateLimit > _start) {
          _actualMonths -=
            (startDateLimit.year() - _start.year()) * 12 +
            (startDateLimit.month() - _start.month());
        }
        if (endDateLimit < _end) {
          _actualMonths -=
            (_end.year() - endDateLimit.year()) * 12 +
            (_end.month() - endDateLimit.month());
        }

        if (_actualMonths <= 0) {
          return;
        }
        const quota = _actualMonths / _months;

        loan_capital += p.loan_capital_contribution * quota;
        loan_interest_fee += p.loan_interest_fee * quota;
        loan_firm_interest_fee += p.loan_firm_interest_fee * quota;
        loan_non_capital += p.loan_non_capital_contribution * quota;
        non_loan += p.non_loan * quota;
        total += p.total * quota;

        out_loan_amort += p.out_loan_amortization * quota;
        out_loan_interest += p.out_loan_interest * quota;
        out_loan_firm_interest_fee += p.out_loan_firm_interest_fee * quota;
        out_loan_total += p.out_loan_total * quota;
      });
    });

    (standaloneLoans ?? []).forEach((g) => {
      (g.amortization_previews ?? []).forEach((p) => {
        const _start = moment(p.period_start);
        const _end = moment(p.period_end);

        if (_start > endDateLimit || _end < startDateLimit) {
          return;
        }

        const _months =
          (_end.year() - _start.year()) * 12 +
          (_end.month() - _start.month()) +
          1;
        let _actualMonths = _months;
        if (startDateLimit > _start) {
          _actualMonths -=
            (startDateLimit.year() - _start.year()) * 12 +
            (startDateLimit.month() - _start.month());
        }
        if (endDateLimit < _end) {
          _actualMonths -=
            (_end.year() - endDateLimit.year()) * 12 +
            (_end.month() - endDateLimit.month());
        }

        if (_actualMonths <= 0) {
          return;
        }

        const quota = _actualMonths / _months;

        out_loan_amort += p.amortization_value * quota;
        out_loan_interest += p.interest_fee_to_pay * quota;
        out_loan_firm_interest_fee += p.firm_interest_fee_to_pay * quota;
        out_loan_total += p.total * quota;
      });
    });

    return {
      [`Summering ${startDateLimit.year()}`]: [
        {
          title: <b>Inbet. / Utbet.</b>,
          value: (
            <b>
              {out_loan_total
                ? `${((total * 100) / out_loan_total).toFixed(2)} %`
                : "-"}
            </b>
          ),
        },
        {
          title: <b>Inbet. Totalt</b>,
          value: <b>{toMoneyString(total, true, 2)}</b>,
        },
        {
          title: <b>Utbet. Lån - Totalt</b>,
          value: <b>{toMoneyString(out_loan_total, true, 2)}</b>,
        },
        {
          title: <b>Avgiftsintäkter efter Lånekostnader</b>,
          value: <b>{toMoneyString(total - out_loan_total, true, 2)}</b>,
        },
        {
          title: "Inbet. Lån - Kapitaltillskott",
          value: toMoneyString(loan_capital, true, 2),
        },
        {
          title: "Inbet. Lån - Ej kapitaltillskott",
          value: toMoneyString(loan_non_capital, true, 2),
        },
        {
          title: "Inbet. Lån - Ränteavgift",
          value: toMoneyString(loan_interest_fee, true, 2),
        },
        {
          title: "Inbet. Lån - Låst ränta, avgift",
          value: toMoneyString(loan_firm_interest_fee, true, 2),
        },
        {
          title: "Inbet. Ej lån",
          value: toMoneyString(non_loan, true, 2),
        },
        {
          title: "Utbet. Lån - Amortering",
          value: toMoneyString(out_loan_amort, true, 2),
        },
        {
          title: "Utbet. Lån - Ränteavgift",
          value: toMoneyString(out_loan_interest, true, 2),
        },
        {
          title: "Utbet. Lån - Låst ränta, avgift",
          value: toMoneyString(out_loan_firm_interest_fee, true, 2),
        },
      ],
    };
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              width: "60%",
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Avgiftsgrupper</OverviewTitle>
                  <OverviewSubtitle style={{ maxWidth: "70%" }}>
                    Varje avgiftsgrupp motsvarar en avgift som de lägenheter som
                    är delaktiga i avgiftsgruppen behöver betala för, och som
                    dem automatiskt blir debiterade för. Detta innefattar
                    exempelvis huvudavgiften, ofta benämnd "Medlemsavgift" eller
                    "Månadsavgift" i debiteringssammanhang.
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
                {canAdd && (
                  <div>
                    <TextButton
                      extraStyle={{ minWidth: 200 }}
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() => push(paymentGroupCreateUrl(brfCompanyId))}
                    />
                  </div>
                )}
              </OverviewTitleWrapper>

              <PaymentGroupTable
                persistantQuery={{
                  brf_company: brfCompanyId,
                }}
                initialOrderBy={["-is_main_fee"]}
                isBare
                ignoreLocalStorage
              />
            </DetailPageBox>
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle>Lån utan avgiftsgrupper</OverviewTitle>
                  <OverviewSubtitle>
                    Detta är lån som föreningengen har men som inte har
                    markerats upp till att betalas av med hjälp av någon av dem
                    ovanstående avgiftsgrupperna.
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
                {canAdd && (
                  <div>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() => push(loanCreateUrl(brfCompanyId, null))}
                    />
                  </div>
                )}
              </OverviewTitleWrapper>

              <LoanTable
                persistantQuery={{
                  brf_company: brfCompanyId,
                  payment_group__isnull: true,
                }}
                isBare
                ignoreLocalStorage
              />
            </DetailPageBox>
          </div>

          <div
            style={{
              width: "40%",
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DetailPageBox>
              <DetailInfo infoObj={getInfoObj()} />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
