import * as React from "react";
import { useSelector } from "react-redux";
import { buildQueryString } from "../../../store/base";
import { useFilteredErrandRoleUsers } from "../../../store/errandRoleUsers";
import theme from "../../../theme";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import { PrimaryButton } from "../../Forms/Base/Buttons";
import OverlaySpinner from "../../Loaders/OverlaySpinner";
import { DetailPageBox } from "../../sharedStyles";

export default function AdminTakeOverErrandStatusBar({
  errand,
  onTakeOver,
  loading,
}) {
  const userId = useSelector((state) => state.app.userId);

  const errandRoleUserQ = buildQueryString({
    user: userId,
  });
  const [errandRoleUsers, errandRoleUsersLoading] =
    useFilteredErrandRoleUsers(errandRoleUserQ);

  const adminCanTakeOver =
    errandRoleUsers?.[0] && errand?.performer?.user?.id != userId;

  return (
    <>
      <DetailPageBox style={{ backgroundColor: theme.colors.primaryBlue }}>
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle style={{ color: "#fff" }} small>
              Detta ärende är{" "}
              {errand?.requested_performer
                ? "delegerat till en annan användare"
                : "ej delegerat"}
            </OverviewTitle>
            {!errandRoleUsersLoading && (
              <>
                <OverviewSubtitle style={{ color: "#fff" }}>
                  {adminCanTakeOver
                    ? 'Tryck på "Ta över ärende" för att sätta din användare som ansvarig utförare istället'
                    : "Du kan inte ta över detta ärende då du ej har en utförarroll på denna typ av ärende. Åtgärda detta genom att sätta din användare på någon av rollerna för utförande under"}
                </OverviewSubtitle>
                {!adminCanTakeOver && (
                  <OverviewSubtitle style={{ color: "#fff" }}>
                    Teknisk Förvaltning -> Inställningar -> Utföranderoller
                  </OverviewSubtitle>
                )}
              </>
            )}
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div style={{ display: "flex" }}>
          <PrimaryButton
            extraStyle={{
              backgroundColor: theme.colors.red,
            }}
            title="Ta över ärende"
            clicked={() => onTakeOver(errandRoleUsers?.[0])}
          />
        </div>
      </DetailPageBox>
    </>
  );
}
