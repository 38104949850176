import { cloneDeep, isEqual } from "lodash";
import React from "react";

import { useDispatch } from "react-redux";
import {
  setActiveFormInstance,
  useAllPermissionCheck,
  useFormField,
} from "../../../../../store/base";
import {
  useWidgetPlacementsForm,
  destroyPatchForm,
  constants,
  update,
  clearSpecificValues,
  getGranularityOptions,
} from "../../../../../store/widgetPlacements";
import { OverviewSubtitle } from "../../../../Details/OverviewInfo/styles";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import { PrimaryButton, TextButton } from "../../../Base/Buttons";
import * as SC from "../../../Base/Chapters/styles";
import ContainerSpinner from "../../../../Loaders/ContainerSpinner";
import LocalDateSelect from "../../../Base/Fields/LocalDateSelect";
import LocalSelectField from "../../../Base/Fields/LocalSelectField";
import { removeObject } from "src/store/base/store/actions";

const inputsReducer = (state, action) => {
  if (action?.type === "all")
    return {
      ...action.value,
    };
  return {
    ...state,
    [action.key]: action.value,
  };
};

export default ({
  untouchedInstance,
  nextUrl,
  openModalForm,
  closeFunction,
  handleDeleteModal,
}) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const [granularityError, setGranularityError] = React.useState(false);
  const [granularityOptions, setGranularityOptions] = React.useState([]);
  const method = "PATCH";

  const { _choices: intervalTypeFormChoices } = {
    ...useFormField({
      storeName,
      fieldKey: "interval_type",
      method,
    }),
  };

  const formLoaded = Boolean(
    useWidgetPlacementsForm(method, untouchedInstance?.id)
  );

  const [inputsState, inputsStateDispatch] = React.useReducer(
    inputsReducer,
    untouchedInstance || {}
  );

  const topTenantSelected = Boolean(inputsState?.kpi === "top_tenant");
  const dayGranularity = Boolean(untouchedInstance?.day_granularity);

  React.useEffect(() => {
  if ((inputsState?.interval_type) || inputsState?.start_date || inputsState?.end_date) {
    const fetchData = async () => {
      const _options = await getGranularityOptions(inputsState?.interval_type, inputsState?.start_date, inputsState?.end_date)
    setGranularityOptions(_options)
    };
    fetchData().catch(console.error);
  } 
}, [inputsState?.interval_type, inputsState?.start_date, inputsState?.end_date])
  
  React.useEffect(() => {
    if (!untouchedInstance) return;

    inputsStateDispatch({
      type: "all",
      value: untouchedInstance,
    });
  }, [untouchedInstance]);

  const hasChanged = React.useMemo(() => {
    return !isEqual(untouchedInstance, inputsState);
  }, [inputsState]);

  const dateDisabled = React.useMemo(() => {
    let isDateDisabled = Boolean(inputsState?.interval_type);

    if (isDateDisabled && (inputsState.start_date || inputsState.end_date)) {
      inputsStateDispatch({
        key: "end_date",
        value: undefined,
      });
      inputsStateDispatch({
        key: "start_date",
        value: undefined,
      });
    }

    return isDateDisabled;
  }, [inputsState]);

  const checkout = (success) => {
    setLoading(false);
    setGranularityError(false);
    if (method == "PATCH") {
      dispatch(destroyPatchForm(success));
      closeFunction(true);
    }
  };

  const customErrorCallback = (data, returnedData, error) => {
    setLoading(false);
    if (!returnedData) return;
    if (returnedData?.month_granularity) {
      setGranularityError(returnedData.month_granularity);
    }
  };

  const customPreProcess = (data) => {
    const newData = cloneDeep(data);
    if (newData.interval_type === "") {
      newData.interval_type = null;
    }
    if (
      newData.interval_type &&
      (untouchedInstance?.start_date || untouchedInstance?.end_date)
    ) {
      newData.start_date = null;
      newData.end_date = null;
    }
    return newData;
  };

  const onSuccess = (_, returned) => {
    dispatch(
      removeObject(
        {constants, objectId: untouchedInstance?.id}
      )
    )
    dispatch(clearSpecificValues([untouchedInstance?.id]))
        

    checkout(true)
  }

  const onSubmit = () => {
    setLoading(true);
    customErrorCallback(undefined, undefined, undefined);
    setLoading(false);
    if (method === "PATCH") {
      dispatch(
        update({
          id: untouchedInstance?.id,
          successCallback: () => onSuccess(),
          errorCallback: customErrorCallback,
          forceData: inputsState,
          preProcess: customPreProcess,
        })
      );
    }
  };

  const handleFullPatchFormOpen = () => {
    closeFunction(true);

    dispatch(
      setActiveFormInstance({
        storeName,
        data: inputsState,
      })
    );

    openModalForm(untouchedInstance);
  };
  const hasDeletePermission = useAllPermissionCheck([
    "allow_insights",
    "delete_can_insights",
  ]);

  if (!formLoaded) {
    return <ContainerSpinner />;
  }
  return (
    <SC.FormArea
      insights={true}
      onClick={(e) => e.nativeEvent.stopPropagation()}
    >
      {loading && <OverlaySpinner />}

      <SC.FormAreaTitle>Interval</SC.FormAreaTitle>

      <div className="grid grid-cols-1 gap-6 mb-6">
        <LocalSelectField
          title="Typ"
          value={inputsState.interval_type}
          choices={intervalTypeFormChoices || []}
          onChange={(value) => {
            inputsStateDispatch({
              key: "interval_type",
              value,
            });
          }}
          canClear
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalDateSelect
          title="Startdatum"
          value={inputsState.start_date}
          onChange={(value) =>
            inputsStateDispatch({
              key: "start_date",
              value,
            })
          }
          disabled={dateDisabled}
          inline
        />
        <LocalDateSelect
          title="Slutdatum"
          fieldKey={`end_date`}
          value={inputsState.end_date}
          onChange={(value) =>
            inputsStateDispatch({
              key: "end_date",
              value,
            })
          }
          disabled={dateDisabled}
          inline
        />
      </div>

      {!topTenantSelected && !dayGranularity && (
        <div className="grid grid-cols-1 gap-6 mb-6">
          <LocalSelectField
            title="Visa data per (månader)"
            value={inputsState.month_granularity}
            choices={granularityOptions || []}
            onChange={(value) => {
              inputsStateDispatch({
                key: "month_granularity",
                value,
              });
            }}
            canClear
            error={granularityError ? granularityError : false}
          />
        </div>
      )}
      {dayGranularity && (
        <div className="grid grid-cols-1 gap-6 mb-6">
          <p className="text-gray-900">Visar med daglig uträkning</p>
        </div>
      )}

      <PrimaryButton
        newDesign
        clicked={onSubmit}
        title="Uppdatera"
        disabled={!hasChanged}
      />
      <OverviewSubtitle>
        <TextButton
          title="Övriga inställningar"
          iconType="edit"
          iconPlacement="right"
          clicked={() => handleFullPatchFormOpen()}
        />
      </OverviewSubtitle>
      {hasDeletePermission && (
        <OverviewSubtitle>
          <TextButton
            title="Radera"
            red={true}
            iconType="delete"
            iconPlacement="right"
            clicked={() => handleDeleteModal(untouchedInstance)}
          />
        </OverviewSubtitle>
      )}
    </SC.FormArea>
  );
};
