import * as React from "react";
import * as SC from "./styles";
import * as FieldStyles from "../Forms/Base/Fields/styles";

import WidgetPlacementQuickEditForm from "../Forms/Insights/WidgetPlacement/WidgetPlacementQuickEdit/Form";
import { useWidgetPlacement } from "../../store/widgetPlacements";

export default ({
  open,
  closeFunction,
  widgetPlacement,
  openModalForm,
  handleDeleteModal,
  popupPlacement,
}) => {
  const ref = React.useRef();

  let leftAligned = false;
  let topAligned = false;
  if (popupPlacement[0] === "left") {
    leftAligned = true;
  }
  if (popupPlacement[1] === "up") {
    topAligned = true;
  }
  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (!ref.current) return;

    if (ref.current.contains(e.target)) {
      // inside click
      return;
    }
    // pikaday click
    if (
      e.target.classList.contains("pika-button") ||
      e.target.classList.contains("pika-prev") ||
      e.target.classList.contains("pika-title") ||
      e.target.classList.contains("pika-next")
    ) {
      return;
    }

    // outside click
    closeFunction();
  };

  return (
    <>
      {open && (
        <SC.PopupMenu
          ref={ref}
          style={{ opacity: 1 }}
          onClick={(e) => e.preventDefault()}
          {...{ leftAligned, topAligned }}
        >
          <FieldStyles.InputSpacing
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <WidgetPlacementQuickEditForm
              untouchedInstance={widgetPlacement}
              openModalForm={openModalForm}
              closeFunction={closeFunction}
              handleDeleteModal={handleDeleteModal}
            />
          </FieldStyles.InputSpacing>
        </SC.PopupMenu>
      )}
    </>
  );
};
