import * as React from "react";

// style, design
import * as SharedStyles from "../../../components/sharedStyles";

// store, state

import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useCompany } from "../../../store/companies";
import { getFortnoxSettings } from "../../../store/billectaIntegrations";
import { uid } from "uid";
import { InfoBox } from "../../../components/Displays";
import { useFortnoxForm } from "../../../store/billectaIntegrations/hooks/form";
import { constants } from "../../../store/billectaIntegrations";
import { DatePicker, TextInput } from "../../../components/Forms/Base/Fields";
import moment from "moment";
import { bulkGetIntegrationSettings } from "../../../store/billectaIntegrations/store/actions";

export default function CompanyActivateFortnox() {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const returnUrl = `https://${window.location.hostname}/configcenter/integrations/activate-fortnox-complete/company/${companyId}/`;

  const [company, companyLoading] = useCompany(companyId);

  const fortnoxSettings = useSelector(
    (state) => state.billectaIntegrations?.fortnoxSettings?.[company?.id]
  );

  const instance = useSelector(
    (state) => state[constants.STORE_NAME].formInstance
  );

  useFortnoxForm("PUT", true);

  React.useEffect(() => {
    if (company != null && !fortnoxSettings?.[company?.id]) {
      dispatch(
        bulkGetIntegrationSettings({
          companies: [company],
          integrationSetting: "fortnoxSettings",
        })
      );
    }
  }, [companyLoading, company]);

  const goToActivationPage = () => {
    const state = uid();
    const voucherSeries = instance?.VoucherSeries;
    const startDate = instance?.StartDate;

    localStorage.setItem("fnx_state", state);
    localStorage.setItem("fnx_voucher_series", voucherSeries);
    localStorage.setItem("fnx_start_date", startDate);

    window.location.href = `https://apps.fortnox.se/oauth-v1/auth?client_id=32hsGLkR78Av&scope=settings%20project%20price%20bookkeeping%20costcenter&state=${state}&access_type=offline&response_type=code&redirect_uri=${returnUrl}`;
  };

  return (
    <SharedStyles.DetailInnerWrapper>
      <SharedStyles.DetailPageBox>
        <OverviewTitleWrapper>
          <OverviewTitle>Aktivera Fortnox-integration</OverviewTitle>
        </OverviewTitleWrapper>

        <SharedStyles.InnerBox>
          {!!fortnoxSettings?.APICode && (
            <InfoBox
              boxTheme="warning"
              title="Redan aktiverad"
              text="Denna integration är redan aktiverad för detta bolag"
            />
          )}

          <SharedStyles.BodyText style={{ marginBottom: 12 }}>
            Aktivering att integrationen Fortnox sker genom att autentisera mot
            Fortnox via deras inloggningssida. Tryck på knappen för att
            autentisera och aktivera integrationen. När du autentiserat kommer
            du att skickas tillbaka till Pigello.
          </SharedStyles.BodyText>

          <DatePicker
            title="Startdatum för verifikationsserie"
            storeName={constants.STORE_NAME}
            fieldKey={"StartDate"}
            forceRequired
            method={"PUT"}
            minDate={moment().set({ year: "2010", month: 0, day: 1 }).toDate()}
          />

          <TextInput
            storeName={constants.STORE_NAME}
            fieldKey={"VoucherSeries"}
            method={"PUT"}
          />

          <div style={{ marginTop: 12 }}>
            <PrimaryButton
              title="Autentisera och aktivera Fortnox-integration"
              clicked={goToActivationPage}
              disabled={
                !!fortnoxSettings?.APICode ||
                !instance?.VoucherSeries ||
                !instance?.StartDate
              }
            />
          </div>
        </SharedStyles.InnerBox>
      </SharedStyles.DetailPageBox>
    </SharedStyles.DetailInnerWrapper>
  );
}
