import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";

import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { usePermissionCheck } from "../../store/base";
import RealEstateModal from "../../components/Forms/RealEstate/ChapterForm/ModalForm";

import { overviewUrl, constants } from "../../store/realEstates";
import { setActiveFilteredRealEstates } from "../../store/overview/store/actions";
import { useRealEstate } from "../../store/realEstates";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import RealEstateOverview from "./detail/Overview";
import { useCompany } from "../../store/companies";

export default function RealEstateDetail() {
  const dispatch = useDispatch();
  const { realEstateId } = useParams();
  const { url, path } = useRouteMatch();

  const [realEstate] = useRealEstate(realEstateId);
  const [company] = useCompany(realEstate?.company?.id);
  usePermissionRedirect(["view_can_realestate"]);

  const canDelete = usePermissionCheck("delete_can_realestate");
  const canEdit = usePermissionCheck("change_can_realestate");
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);

  const { push, replace } = useHistory();

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Fastighet",
      component: RealEstateOverview,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderLogo = () => {
    if (company?.image?.get) {
      return (
        <div
          style={{
            height: 50,
            width: 70,
            marginRight: 8,
            boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
            borderRadius: 8,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${company.image.get})`,
          }}
        ></div>
      );
    }

    return null;
  };

  const renderActions = () => {
    return (
      <PrimaryButton
        title="Filtrera systemet på denna fastighet"
        clicked={() => {
          dispatch(
            setActiveFilteredRealEstates({
              realEstateIds: [parseInt(realEstateId)],
            })
          );
          push("/");
        }}
      />
    );
  };

  const handleActions = [];

  if (canEdit) {
    handleActions.push({ name: "Redigera", onClick: () => setEditOpen(true) });
  }
  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteOpen(true),
      isDelete: true,
    });
  }

  return (
    <>
      {editOpen && (
        <RealEstateModal
          method="PATCH"
          id={realEstateId}
          instance={realEstate}
          onCheckout={() => setEditOpen(false)}
        />
      )}

      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={realEstate}
        constants={constants}
        deletedCallback={() => replace(overviewUrl())}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Fastighet ${realEstate?.str_representation || "laddar..."}`}
          renderLogo={renderLogo}
          breadCrumbs={[
            { url: "/realestates", label: "Fastigheter" },
            { label: realEstate?.str_representation || "Laddar..." },
          ]}
          eventContentType="standard.realestate"
          eventObjectId={realEstateId}
          eventIdQueryStr="realestate"
          {...{
            subPages: subPageLinks,
            handleActions,
            renderActions,
          }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
