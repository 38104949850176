import * as React from "react";
import { buildQueryString } from "../../../../store/base";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import ApartmentErrandConfigFullTable from "../../../Tables/Apartments/ErrandConfigFullTable";
import IndpErrandConfigFullTable from "../../../Tables/IndustrialPremises/ErrandConfigFullTable";
import BrfErrandConfigFullTable from "../../../Tables/BrfPremises/ErrandConfigFullTable";
import CommonAreaErrandConfigFullTable from "src/components/Tables/CommonArea/ErrandConfigFullTable";
import ParkingErrandConfigFullTable from "../../../Tables/Parking/ParkingSpots/ErrandConfigFullTable";
import { DetailInnerWrapper, DetailPageBox } from "../../../sharedStyles";
import useQuery from "../../../utils/useQuery";
import { PrimaryButton, TextButton } from "../../Base/Buttons";
import { MASS_CONFIG_OBJECT_TYPES_KEYS } from "../FlowForm/Chapters/Type";
import { useHistory } from "react-router-dom";
import { constants } from "../../../../store/massConfigPremisesComponents";

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export default function MassConfigPremisesComponentsPreviewForm() {
  const [exclusions, setExclusions] = React.useState([]);
  const q = useQuery();
  const query = q.get("query");
  const type = q.get("type");
  const { push, goBack } = useHistory();

  // array of ids to exclude
  const excludeQ = {
    "id__in!": exclusions?.length ? exclusions : undefined,
  };

  const queryInfo = new URLSearchParams(decodeURIComponent(query));

  const entries = queryInfo.entries();
  let queryObject = paramsToObject(entries);

  if (exclusions?.length) {
    queryObject = {
      ...queryObject,
      ...excludeQ,
    };
  }

  const fullQ = `${query}${exclusions?.length ? `&${excludeQ}` : ""}`;

  const onSubmit = () => {
    push(
      `${constants.CONFIG_PATH}?query=${encodeURIComponent(fullQ)}&type=${type}`
    );
  };

  const handleExclude = (row) => {
    const id = row.original.id;

    const newExl = [...exclusions, id];

    setExclusions(newExl);
  };

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        <TextButton
          title="Tillbaka"
          iconType="arrow-back"
          extraStyle={{ marginBottom: 24 }}
          clicked={() => goBack()}
        />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Objekt som påverkas</OverviewTitle>
            <OverviewSubtitle style={{ marginRight: 40 }}>
              Kontrollera att dessa objekt ska påverkas av uppdateringen. Objekt
              kan exkluderas genom att klicka på "Exkludera" till vänster i
              tabellen
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          <PrimaryButton title="Fortsätt" clicked={onSubmit} />
        </OverviewTitleWrapper>

        {type === MASS_CONFIG_OBJECT_TYPES_KEYS.APARTMENT && (
          <ApartmentErrandConfigFullTable
            hideTitle
            persistantQuery={queryObject}
            onExcludeClicked={(row) => handleExclude(row)}
            isBare
            ignoreLocalStorage
          />
        )}

        {type === MASS_CONFIG_OBJECT_TYPES_KEYS.INDP && (
          <IndpErrandConfigFullTable
            hideTitle
            persistantQuery={queryObject}
            onExcludeClicked={(row) => handleExclude(row)}
            isBare
            ignoreLocalStorage
          />
        )}
        {type === MASS_CONFIG_OBJECT_TYPES_KEYS.BRF && (
          <BrfErrandConfigFullTable
            hideTitle
            persistantQuery={queryObject}
            onExcludeClicked={(row) => handleExclude(row)}
            isBare
            ignoreLocalStorage
          />
        )}
        {type === MASS_CONFIG_OBJECT_TYPES_KEYS.COMMON && (
          <CommonAreaErrandConfigFullTable
            hideTitle
            persistantQuery={queryObject}
            onExcludeClicked={(row) => handleExclude(row)}
            isBare
            ignoreLocalStorage
          />
        )}
        {type === MASS_CONFIG_OBJECT_TYPES_KEYS.PARKING && (
          <ParkingErrandConfigFullTable
            hideTitle
            persistantQuery={queryObject}
            onExcludeClicked={(row) => handleExclude(row)}
            isBare
            ignoreLocalStorage
          />
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
