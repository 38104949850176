import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//store, state
import { buildQueryString, usePermissionCheck } from "../../../store/base";
import {
  useReportErrand,
  constants,
  acceptReportErrand,
  declineReportErrand,
  update,
} from "../../../store/reportErrands";
import { useReportErrandSetting } from "../../../store/reportErrandSettings";
import {
  useRealEstate,
  detailUrl as realEstateDetailUrl,
} from "../../../store/realEstates";
import {
  useBuilding,
  detailUrl as buildingDetailUrl,
} from "../../../store/buildings";
import {
  useApartment,
  detailUrl as apartmentDetailUrl,
} from "../../../store/apartments";
import {
  useIndustrialPremises,
  detailUrl as indpDetailUrl,
} from "../../../store/industrialPremises";
import { useFilteredCalendarEvents } from "../../../store/calendarEvents";
import { detailUrl as userDetailUrl, useUser } from "../../../store/users";
import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { useFilteredNotes } from "../../../store/notes";
import { useCalendar } from "../../../store/calendar";
import { useErrandComponent } from "../../../store/errandComponents";

//components & styles
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, LinkedObject } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  ErrandStatusLabel,
  StatusLabel,
} from "../../../components/Lists/Base/CompleteList/styles";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import {
  getChosenPerformer,
  getErrandStatus,
} from "../../../components/utils/errandUtils";

import ErrandFault from "../../../components/Details/ReportErrand/Fault";
import FaultLocation from "../../../components/Details/ReportErrand/FaultLocation";
import { getComponentsByRooms } from "../../../components/TechnicalManagement/utils";
import RoomOverview from "../../../components/TechnicalManagement/Component/RoomOverview";
import {
  useParkingSpot,
  detailUrl as parkingSpotDetailUrl,
} from "../../../store/parkingSpots";
import PerformerStatusBar from "../../../components/TechnicalManagement/ErrandComponents/PerformerStatusBar";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import ExecuteReportErrandModalForm from "../../../components/Forms/ReportErrand/ExecuteChapterForm/ModalForm";
import ReportErrandPlanForm from "../../../components/Forms/ReportErrand/PlanForm/ModalForm";
import useDurationString from "../../../hooks/useDurationString";
import FinishedErrandStatusBar from "../../../components/TechnicalManagement/ErrandComponents/FinishedErrandStatusBar";
import AdminTakeOverErrandStatusBar from "../../../components/TechnicalManagement/ErrandComponents/AdminTakeOverErrandStatusBar";
import {
  useCommonArea,
  detailUrl as commonAreaDetailUrl,
} from "../../../store/commonAreas";
import {
  useBrfPremises,
  detailUrl as brfPremisDetailUrl,
} from "../../../store/brfPremises";

function ReportErrandOverview() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const userId = useSelector((state) => state.app.userId);
  const { reportErrandId } = useParams();
  const currentUser = useSelector((state) => state.app.user);
  const [reportErrand, reportErrandLoading] = useReportErrand(reportErrandId);
  const [currentComponent] = useErrandComponent(
    reportErrand?.component_placement?.id
  );

  const canEditErrand = usePermissionCheck("change_can_reporterrand");

  const [loadingAction, setLoadingAction] = React.useState(false);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [reactivateErrandLoading, setReactivateErrandLoading] =
    React.useState(false);
  const [confirmExecuteOpen, setConfirmExecuteOpen] = React.useState(false);
  const [executeErrandOpen, setExecuteErrandOpen] = React.useState(false);
  const [planErrandOpen, setPlanErrandOpen] = React.useState(false);
  const room = currentComponent?.room;
  const parkingSpotId = currentComponent?.parking_spot?.id;
  const isParkingSpot = !!parkingSpotId;
  const durationStr = useDurationString(reportErrand?.expected_duration);
  const executeDurationStr = useDurationString(reportErrand?.execute_duration);

  const componentsByRoom = React.useMemo(() => {
    const components = [];
    if (currentComponent) {
      components.push(currentComponent);
    }
    return getComponentsByRooms({ components });
  }, [currentComponent]);

  const eventsQuery = buildQueryString({ reporterrand: reportErrandId });
  const settingId = reportErrand?.setting?.id;

  const [setting] = useReportErrandSetting(settingId);

  const calendarId = setting?.meeting_calendar?.id;
  const [calendar] = useCalendar(calendarId);
  const [event, eventLoading] = useFilteredCalendarEvents(eventsQuery);
  const [building] = useBuilding(room?.building?.id);
  const [realEstate] = useRealEstate(building?.realestate?.id);

  const [apartment] = useApartment(room?.apartment?.id);
  const [indp] = useIndustrialPremises(room?.industrial_premises?.id);
  const [parkingSpot] = useParkingSpot(parkingSpotId);
  const [commonArea] = useCommonArea(room?.common_area?.id);
  const [brfPremis] = useBrfPremises(room?.brf_premis?.id);
  const [reportedBy] = useUser(reportErrand?.reported_by?.id);

  const reportCommentsQuery = buildQueryString({
    id__in: reportErrand?.report_comments?.map((n) => n.id) || [],
  });
  const [reportComments] = useFilteredNotes(reportCommentsQuery);

  const notesQ = buildQueryString({
    id__in: reportErrand?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const { status, display } = getErrandStatus({
    status: reportErrand?.status,
    plannedStart: reportErrand?.planned_start,
    plannedEnd: reportErrand?.planned_end,
    actualStart: reportErrand?.execute_start,
    actualEnd: reportErrand?.execute_end,
  });

  const displayedPremises = apartment
    ? apartment
    : indp
    ? indp
    : parkingSpot
    ? parkingSpot
    : brfPremis
    ? brfPremis
    : commonArea;

  const premisesDetailUrl = apartment
    ? apartmentDetailUrl
    : indp
    ? indpDetailUrl
    : parkingSpot
    ? parkingSpotDetailUrl
    : brfPremis
    ? brfPremisDetailUrl
    : commonAreaDetailUrl;

  const infoObjProps = {
    reportedBy,
    status,
    display,
  };

  const handleAcceptErrand = () => {
    setLoadingAction(true);
    dispatch(
      acceptReportErrand({
        reportErrandId: reportErrand?.id,
        errorCallback: () => {
          setLoadingAction(false);
        },
        successCallback: () => {
          setLoadingAction(false);
        },
      })
    );
  };

  const onDeclineSuccess = () => {
    setLoadingAction(false);
    push("/report-errands");
  };

  const handleDeclineErrand = () => {
    setLoadingAction(true);
    dispatch(
      declineReportErrand({
        reportErrandId: reportErrand?.id,
        successCallback: onDeclineSuccess,
        errorCallback: () => {
          setLoadingAction(false);
        },
      })
    );
  };

  const pauseErrand = () => {
    setExecuteErrandOpen(false);
    setLoadingUpdate(true);
    dispatch(
      update({
        id: reportErrandId,
        forceData: {
          status: 2,
        },
        successCallback: () => {
          setLoadingUpdate(false);

          setExecuteErrandOpen(false);
        },
        errorCallback: () => setLoadingUpdate(false),
      })
    );
  };

  const executeErrand = () => {
    if (reportErrand?.status !== 1) {
      setLoadingUpdate(true);
      dispatch(
        update({
          id: reportErrandId,
          forceData: {
            status: 1,
          },
          successCallback: () => {
            setLoadingUpdate(false);

            setExecuteErrandOpen(true);
          },
          errorCallback: () => setLoadingUpdate(false),
        })
      );
    } else {
      setExecuteErrandOpen(true);
    }
  };

  const reactivateErrand = () => {
    setReactivateErrandLoading(true);

    dispatch(
      update({
        id: reportErrandId,
        forceData: {
          status: 1,
        },
        successCallback: () => {
          setReactivateErrandLoading(false);
        },
        errorCallback: () => setReactivateErrandLoading(false),
      })
    );
  };

  const takeOverErrand = (newPerformer) => {
    setReactivateErrandLoading(true);
    dispatch(
      update({
        id: reportErrandId,
        forceData: {
          performer: newPerformer,
        },
        successCallback: () => {
          setReactivateErrandLoading(false);
        },
        errorCallback: () => setReactivateErrandLoading(false),
      })
    );
  };

  return (
    <>
      {canEditErrand && (
        <ExecuteReportErrandModalForm
          method="PATCH"
          id={reportErrandId}
          isOpen={executeErrandOpen}
          onCheckout={() => setExecuteErrandOpen(false)}
          canPause
          instance={reportErrand}
        />
      )}

      {canEditErrand && (
        <ReportErrandPlanForm
          id={reportErrandId}
          isOpen={planErrandOpen}
          instance={reportErrand}
          onCheckout={() => setPlanErrandOpen(false)}
        />
      )}

      <ConfirmationModal
        isOpen={confirmExecuteOpen}
        title="Påbörja utförande"
        acceptTitle="Starta"
        closeFunction={() => setConfirmExecuteOpen(false)}
        renderContent={() => {
          return (
            <div>
              Är du säker på att du vill påbörja utförandet? Klicka på "Starta"
              för att starta igång utförandet.
            </div>
          );
        }}
        acceptCallback={executeErrand}
      />

      <DetailInnerWrapper>
        {reportErrand && currentUser && (
          <PerformerStatusBar
            errand={reportErrand}
            currentUser={currentUser}
            onAccept={handleAcceptErrand}
            onDecline={handleDeclineErrand}
            onPerform={
              reportErrand?.status === 1
                ? () => setExecuteErrandOpen(true)
                : () => setConfirmExecuteOpen(true)
            }
            onPlan={() => setPlanErrandOpen(true)}
            onPause={pauseErrand}
            loading={loadingAction}
            canPause={reportErrand?.status === 1}
          />
        )}

        {[3, 4].includes(reportErrand?.status) && canEditErrand && (
          <FinishedErrandStatusBar
            onReactivate={reactivateErrand}
            loading={reactivateErrandLoading}
          />
        )}

        {[0, 1, 2].includes(reportErrand?.status) &&
          canEditErrand &&
          !reportErrand?.performer &&
          !reportErrand?.requested_performer?.user?.id === userId && (
            <AdminTakeOverErrandStatusBar
              errand={reportErrand}
              onTakeOver={takeOverErrand}
              loading={reactivateErrandLoading}
            />
          )}

        <DetailPageBox>
          {(loadingUpdate || reportErrandLoading) && <OverlaySpinner />}

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1, marginRight: 24 }}>
              <InnerBox style={{ marginBottom: 24 }}>
                <OverviewTitleWrapper>
                  <OverviewTitleWithSubtitleWrapper>
                    <OverviewTitle small>Komponent</OverviewTitle>
                  </OverviewTitleWithSubtitleWrapper>
                </OverviewTitleWrapper>

                <RoomOverview
                  isParkingSpot={isParkingSpot}
                  rooms={componentsByRoom}
                  selectOnly
                  allOpen
                  showArchived
                />
              </InnerBox>
              <ErrandFault
                reportErrand={reportErrand}
                reportComments={reportComments}
                bookingInfo={event?.[0]}
                setting={setting}
                calendar={calendar}
              />
            </div>
            <div style={{ flex: 1, flexDirection: "column" }}>
              <FaultLocation
                reportErrand={reportErrand}
                realEstate={realEstate}
                building={building}
                displayedPremises={displayedPremises}
                premisesDetailUrl={premisesDetailUrl}
                reportedBy={reportedBy}
                realEstateDetailUrl={realEstateDetailUrl}
                buildingDetailUrl={buildingDetailUrl}
                tenantDetailUrl={tenantDetailUrl}
                userDetailUrl={userDetailUrl}
              />
              <DetailInfo
                infoObj={getInfoObj({
                  reportErrand,
                  infoObjProps,
                  durationStr,
                  executeDurationStr,
                })}
              />
              <InnerBox>
                <Notes
                  notes={notes}
                  contentType={"errands.reporterrand"}
                  objectId={reportErrand?.id}
                  title="Interna anteckningar"
                />
              </InnerBox>
            </div>
          </div>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const getTime = (timeString) => {
  return timeString ? moment(timeString).format("YYYY-MM-DD HH:mm:ss") : "-";
};

const isDelayed = (timeString, forText) => {
  const planned = moment(timeString);
  const today = moment();
  const delayed = planned.isBefore(today);
  if (delayed) {
    if (forText) {
      return true;
    }
    return 3;
  } else {
    if (forText) {
      return false;
    }
    return 1;
  }
};

const executeEndDelayed = (execute_end, planned_end, forText) => {
  const actualEnd = moment(execute_end);
  const planned = moment(planned_end);
  const delayed = planned.isBefore(actualEnd);
  if (delayed) {
    if (forText) {
      return true;
    }
    return 3;
  } else {
    if (forText) {
      return false;
    }
    return 1;
  }
};

function getInfoObj({
  reportErrand,
  infoObjProps,
  durationStr,
  executeDurationStr,
}) {
  const getReportedBy = () => {
    const user = infoObjProps?.reportedBy;

    if (user?.tenant) {
      const obj = {
        id: user.tenant?.id,
        str_representation: user?.str_representation,
      };
      return <LinkedObject obj={obj} urlMethod={tenantDetailUrl} />;
    }
    return <LinkedObject obj={user} urlMethod={userDetailUrl} />;
  };

  const getPerformer = (errand) => {
    const foundPerformer = getChosenPerformer(errand);
    if (foundPerformer) {
      return (
        <LinkedObject
          obj={{ ...foundPerformer, id: foundPerformer.userId }}
          urlMethod={userDetailUrl}
        />
      );
    }
    return <div> Ingen utförare delegerad </div>;
  };

  let data = [
    {
      title: "Skapat",
      value: <DateCell date={reportErrand?.created_at} />,
    },
    {
      title: "Status",
      value: (
        <ErrandStatusLabel state={infoObjProps?.status}>
          {infoObjProps?.display}
        </ErrandStatusLabel>
      ),
    },
    {
      title: `${
        isDelayed(reportErrand?.planned_start, true)
          ? "Planerad start(Försenad)"
          : "Planerad start"
      }`,
      value: (
        <StatusLabel
          state={
            reportErrand?.planned_start
              ? isDelayed(reportErrand?.planned_start)
              : 10
          }
        >
          {getTime(reportErrand?.planned_start)}
        </StatusLabel>
      ),
    },
    {
      title: `${
        isDelayed(reportErrand?.planned_end, true)
          ? "Planerat slut(Försenad)"
          : "Planerat slut"
      }`,
      value: (
        <StatusLabel
          state={
            reportErrand?.planned_end
              ? isDelayed(reportErrand?.planned_end)
              : 10
          }
        >
          {getTime(reportErrand?.planned_end)}
        </StatusLabel>
      ),
    },
    {
      title: "Förväntad arbetstid",
      value: durationStr,
    },
    {
      title: "Faktiskt arbetstid",
      value: executeDurationStr,
      hidden: ![3, 4].includes(reportErrand?.status),
    },
    {
      title: "Typ av fel",
      value: reportErrand?.setting?.str_representation,
    },

    {
      title: "Får använda huvudnyckel",
      value: (
        <StatusLabel state={reportErrand?.can_use_key ? 0 : 3}>
          {reportErrand?.can_use_key ? "Ja" : "Nej"}
        </StatusLabel>
      ),
    },
    {
      title: "Utförare",
      value: getPerformer(reportErrand),
    },
    {
      title: "Rapporterat av",
      value: getReportedBy(),
    },
  ];

  if (reportErrand?.status === 3 || reportErrand?.status === 4) {
    const executeEnd = {
      title: `${
        executeEndDelayed(
          reportErrand?.execute_end,
          reportErrand?.planned_end,
          true
        )
          ? "Avklarad(Försenad)"
          : "Avklarad"
      }`,
      value: (
        <StatusLabel
          state={
            reportErrand?.execute_end
              ? executeEndDelayed(
                  reportErrand?.execute_end,
                  reportErrand?.planned_end
                )
              : 10
          }
        >
          {getTime(reportErrand?.execute_end)}
        </StatusLabel>
      ),
    };
    data.splice(2, 1, executeEnd);

    {
      /*Visa om det blev löst eller inte*/
    }
    if (reportErrand?.active_rating) {
      const componentIsOk = {
        title: "Fel avhjälpt",
        value: (
          <StatusLabel state={reportErrand?.active_rating?.is_ok ? 1 : 6}>
            {reportErrand?.active_rating?.is_ok ? "Ja" : "Nej"}
          </StatusLabel>
        ),
      };
      data.splice(3, 1, componentIsOk);
    }
  }

  const infoObj = {
    Ärendeinformation: data,
  };

  return infoObj;
}

export default ReportErrandOverview;
