import React from "react";

import {
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import InsightsPage from "./InsightsPage";
import DefaultInsightsPage from "./DefaultInsightsPage";

export default function InsightsMain() {
  const { path, url } = useRouteMatch();

  // keeping this disabled until we run into memory issues, currently tracking memory usage of insights is at worst case 3MB
  // React.useEffect(() => {
  //   return () => {
  //     dispatch(clearAllValues());
  //   };
  // }, []);

  return (
    <>
      <Switch>
        <Route path={`${path}/page/:id`} component={InsightsPage} />
        <Route exact path={`${path}/`} component={DefaultInsightsPage} />
      </Switch>
    </>
  );
}
