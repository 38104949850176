import * as React from "react";
import LocalSelectField from "../Base/Fields/LocalSelectField";
import Modal from "../Base/Modals/Modal";

export default function InvoiceSendForm({
  closeFunction,
  sendInvoice,
  value,
  setValue,
}) {
  return (
    <Modal
      closeFunction={closeFunction}
      title="Skicka faktura"
      onAccept={sendInvoice}
      acceptTitle="Skicka faktura"
      denyTitle="Avbryt"
    >
      <LocalSelectField
        id="_send_invoice_method"
        value={value}
        choices={[
          { v: "Email", d: "Email (0 kr/st)" },
          { v: "Kivra", d: "Kivra (4 kr/st)" },
          {
            v: "EInvoice",
            d: "E-faktura (2.50 kr/st exkl. bankkostnader)",
          },
          { v: "Mail", d: "Post (6.50 kr/st)" },
        ]}
        onChange={(val) => setValue(val)}
        title="Leveransmetod"
      />
    </Modal>
  );
}
