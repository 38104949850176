import React, { useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import { buildQueryString, usePermissionCheck } from "../../store/base";
import {
  useInsightsPage,
  constants as insightsPageConstants,
} from "../../store/insightsPage/index";
import {
  useWidgetPlacements,
  constants as widgetPlacementConstants,
  setDeleteQueueWidgetPlacement,
} from "../../store/widgetPlacements";
import * as SC from "../../components/Layout/Insights/styles";
import PageTopBar from "../../components/Layout/Insights/PageTopBar";
import WidgetPlacement from "./WidgetPlacement";

import addWidgetPlacementIcon from "../../assets/svg/add_widget_placement.svg";
import WidgetPlacementForm from "../../components/Forms/Insights/WidgetPlacement/WidgetPlacementForm/WidgetPlacementForm";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import InsightsPageForm from "../../components/Forms/Insights/InsightsPage/InsightsPageForm";
import InsightsGoalOverview from "../../components/Forms/Insights/InsightsGoal/InsightsGoalOverview";
import { useDispatch, useSelector } from "react-redux";
import OverlaySpinnerLoadingBar from "src/components/Loaders/OverlaySpinnerLoadingBar";
import { axiosInstance } from "src/store/base/store/axios";
import { addToast, TOAST_TYPES } from "src/store/toasts";

export default function InsightsPage() {
  let { id } = useParams();
  const pageId = id;
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [insightsPage, insightsPageLoading] = useInsightsPage(pageId);
  const [currentPageDimensions, setCurrentPageDimensions] = React.useState([
    9, 5,
  ]);

  const [insightsPageFormOpen, setInsightsPageFormOpen] = React.useState(false);
  const [insightsPageFormMethod, setInsightsPageFormMethod] =
    React.useState(null);
  const [insightsPageFormInstance, setInsightsPageFormInstance] =
    React.useState(null);

  const [goalOverviewOpen, setGoalOverviewOpen] = React.useState(false);

  const [emptyGridCells, setEmptyGridCells] = React.useState([]);

  const [widgetPlacementFormOpen, setWidgetPlacementFormOpen] =
    React.useState(false);
  const [quickEditPopupOpen, setQuickEditPopupOpen] = React.useState(false);
  const [selectedElementData, setSelectedElementData] = React.useState(null);
  const [selectedInsightsPage, setSelectedInsightsPage] = React.useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(null);
  const [deleteInsightsPageModalOpen, setDeleteInsightsPageModalOpen] =
    React.useState(null);
  const [formMethod, setFormMethod] = React.useState(null);
  const [gridCellClicked, setGridCellClicked] = React.useState(null);
  const [availableGridAreas, setAvailableGridAreas] = React.useState([]);

  const [bottomRightAreas, setBottomRightAreas] = React.useState([]);
  const [bottomLeftAreas, setBottomLeftAreas] = React.useState([]);
  const [topLeftAreas, setTopLeftAreas] = React.useState([]);
  const [topRightAreas, setTopRightAreas] = React.useState([]);

  const [kpiChoices, setKpiChoices] = React.useState([]);

  const [gridHeight, setGridHeight] = React.useState(null);

  const gridRef = useRef();

  const widgetPlacementsQuerystring = buildQueryString({
    page: pageId,
  });

  const [widgetPlacements, widgetPlacementsLoading] = useWidgetPlacements(
    widgetPlacementsQuerystring
  );

  const widgetsInProgress = useSelector((state) => {
    return state[widgetPlacementConstants.STORE_NAME].inProgress;
  });

  const hasEditPermission = usePermissionCheck("change_can_insights");

  React.useEffect(() => {
    getKpiChoices();
  }, []);

  const getKpiChoices = (timesTried = 0) => {
    if (timesTried === 5) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde inte hämta Nyckeltal",
          description: "Ladda om sidan och försök igen",
        })
      );
      return;
    }
    axiosInstance
      .get("/insights/permcheckedkpioptions")
      .then((response) => {
        setKpiChoices(response.data);
      })
      .catch((err) => {
        getKpiChoices(timesTried + 1);
      });
  };

  React.useEffect(() => {
    if (!insightsPage) return;
    setCurrentPageDimensions([insightsPage.width, insightsPage.height]);
  }, [insightsPage]);

  React.useEffect(() => {
    generateEmptyGridCells();
  }, [currentPageDimensions, widgetPlacements]);

  React.useLayoutEffect(() => {
      if (gridRef?.current?.offsetWidth && insightsPage?.width) {
        setGridHeight(gridRef?.current?.offsetWidth / insightsPage?.width * insightsPage?.height * 0.8)
      }
  },  [gridRef?.current?.offsetWidth, insightsPage?.width])

  // item can either be a widgetPlacement object or an array of grid column and row where user clicked
  const handleOpenWidgetPlacementForm = (item) => {
    if (widgetPlacementFormOpen) return;

    // if (Array.isArray(cell)) return
    if (!Array.isArray(item)) {
      setSelectedElementData(item);
      setFormMethod("PATCH");
      setSelectedElementData(item);
      getAvailableGridAreas(item.grid_column, item.grid_row, item);
    } else {
      getAvailableGridAreas(item[0], item[1]);
      setGridCellClicked(item);
      setFormMethod("POST");
      setSelectedElementData(item);
    }
    setWidgetPlacementFormOpen(true);
  };

  const handleDeleteWidgetPlacementModal = (item) => {
    if (deleteModalOpen) return;
    setSelectedElementData(item);
    setDeleteModalOpen(true);
  };

  const getAvailableGridAreas = (col, row, widgetPlacement) => {
    const maxWidth = 8;
    const maxHeight = 20;
    const minWidth = 1;
    const minHeight = 1;

    let placementPositions = {};

    widgetPlacements.map((place) => {
      if (place.id !== widgetPlacement?.id) {
        for (let i = place.grid_row; i < place.grid_row_end; i++) {
          if (placementPositions[place.grid_column]) {
            placementPositions[place.grid_column].push(i);
          } else {
            placementPositions[place.grid_column] = [i];
          }
        }
        for (let j = place.grid_column + 1; j < place.grid_column_end; j++) {
          for (let k = place.grid_row; k < place.grid_row_end; k++) {
            if (placementPositions[j]) {
              placementPositions[j].push(k);
            } else {
              placementPositions[j] = [k];
            }
          }
        }
      }
    });

    let bottomRightAreas = [];
    let bottomLeftAreas = [];
    let topLeftAreas = [];
    let topRightAreas = [];
    let bottomRightDimensions = [];
    let bottomLeftDimensions = [];
    let topLeftDimensions = [];
    let topRightDimensions = [];

    let bottomRightDimensionsDict = {};
    let bottomLeftDimensionsDict = {};
    let topLeftDimensionsDict = {};
    let topRightDimensionsDict = {};

    let areas = [];
    let hashmap = {};

    let maxRow = maxHeight;
    // Step 1: start going right
    for (let i = col; i < maxWidth + 1; i++) {
      // Step 2: go down
      for (let j = row; j <= maxRow; j++) {
        if (
          (placementPositions[i] && placementPositions[i].includes(j + 1)) ||
          j + 1 > maxRow
        ) {
          maxRow = j;
          areas.push([i, j]);
          bottomRightAreas.push([i, j]);
          const width = i - col + 1;
          const height = j - row + 1;
          bottomRightDimensions.push([width, height]);
          if (bottomRightDimensionsDict[width]) {
            bottomRightDimensionsDict[width][height] = [i, j];
          } else {
            bottomRightDimensionsDict[width] = { [height]: [i, j] };
          }

          if (j - row > (hashmap[i] ?? Number.NEGATIVE_INFINITY) - row) {
            hashmap[i] = j;
          }

          break;
        }
      }

      // check if cell to right is occupied
      if (
        (placementPositions[i + 1] &&
          placementPositions[i + 1].includes(row)) ||
        i === maxWidth
      ) {
        break;
      }
    }
    let minCol = minWidth;
    // Step 1: Start going down
    for (let k = row; k < maxHeight + 1; k++) {
      // Step 2: Go left
      for (let l = col; l >= minCol; l--) {
        if (
          (placementPositions[l - 1] &&
            placementPositions[l - 1].includes(k)) ||
          l - 1 < minCol
        ) {
          minCol = l;
          areas.push([l, k]);
          bottomLeftAreas.push([l, k]);
          const width = col - l + 1;
          const height = k - row + 1;
          bottomLeftDimensions.push([width, height]);
          if (bottomLeftDimensionsDict[width]) {
            bottomLeftDimensionsDict[width][height] = [l, k];
          } else {
            bottomLeftDimensionsDict[width] = { [height]: [l, k] };
          }
          if (k - row > (hashmap[l] ?? Number.NEGATIVE_INFINITY) - row) {
            hashmap[l] = k;
          }

          break;
        }
      }

      // check if cell below is occupied
      if (
        (placementPositions[col] && placementPositions[col].includes(k + 1)) ||
        k === maxHeight
      ) {
        break;
      }
    }

    let minRow = minHeight;
    // Step 1: start going left
    for (let m = col; m > minWidth - 1; m--) {
      // Step 2: go up
      for (let n = row; n >= minRow; n--) {
        if (
          (placementPositions[m] && placementPositions[m].includes(n - 1)) ||
          n - 1 < minRow
        ) {
          minRow = n;
          areas.push([m, n]);
          topLeftAreas.push([m, n]);
          const width = col - m + 1;
          const height = row - n + 1;
          topLeftDimensions.push([width, height]);
          if (topLeftDimensionsDict[width]) {
            topLeftDimensionsDict[width][height] = [m, n];
          } else {
            topLeftDimensionsDict[width] = { [height]: [m, n] };
          }

          if (col - m < (hashmap[m] ?? Number.NEGATIVE_INFINITY) - col) {
            hashmap[m] = n;
          }

          break;
        }
      }

      // check if cell to left is occupied
      if (
        (placementPositions[m - 1] &&
          placementPositions[m - 1].includes(row)) ||
        m === minWidth
      ) {
        break;
      }
    }

    let maxCol = maxWidth;
    // Step 1: start going up
    for (let o = row; o > minHeight - 1; o--) {
      // Step 2: Go right
      for (let p = col; p < maxCol + 1; p++) {
        if (
          (placementPositions[p + 1] &&
            placementPositions[p + 1].includes(o)) ||
          p + 1 > maxCol
        ) {
          maxCol = p;
          areas.push([p, o]);
          topRightAreas.push([p, o]);
          const width = p - col + 1;
          const height = row - o + 1;
          topRightDimensions.push([width, height]);
          if (topRightDimensionsDict[width]) {
            topRightDimensionsDict[width][height] = [p, o];
          } else {
            topRightDimensionsDict[width] = { [height]: [p, o] };
          }
          break;
        }
      }

      // check if cell above is occupied
      if (
        (placementPositions[col] && placementPositions[col].includes(o - 1)) ||
        o === minHeight
      ) {
        break;
      }
    }

    let availableGridAreasArray = [];

    bottomRightDimensions.forEach((dimension) => {
      availableGridAreasArray.push(dimension[0]);
      availableGridAreasArray.push(dimension[1]);
    });
    bottomLeftDimensions.forEach((dimension) => {
      availableGridAreasArray.push(dimension[0]);
      availableGridAreasArray.push(dimension[1]);
    });
    topLeftDimensions.forEach((dimension) => {
      availableGridAreasArray.push(dimension[0]);
      availableGridAreasArray.push(dimension[1]);
    });
    topRightDimensions.forEach((dimension) => {
      availableGridAreasArray.push(dimension[0]);
      availableGridAreasArray.push(dimension[1]);
    });

    setAvailableGridAreas(availableGridAreasArray);

    setBottomRightAreas(bottomRightDimensionsDict);
    setBottomLeftAreas(bottomLeftDimensionsDict);
    setTopLeftAreas(topLeftDimensionsDict);
    setTopRightAreas(topRightDimensionsDict);
  };

  const generateEmptyGridCells = () => {
    let emptyGridCells = [];
    for (let col = 1; col <= currentPageDimensions[0]; col++) {
      for (let row = 1; row <= currentPageDimensions[1]; row++) {
        const found = widgetPlacements.find((place) => {
          if (
            place.grid_column <= col &&
            col < place.grid_column_end &&
            place.grid_row <= row &&
            row < place.grid_row_end
          ) {
            return true;
          }
        });
        if (!found) {
          emptyGridCells.push([col, row]);
        }
      }
    }

    setEmptyGridCells(emptyGridCells);
  };

  const handleOpenInsightsForm = (method) => {
    setInsightsPageFormMethod(method);
    setInsightsPageFormOpen(true);
    if (method === "PATCH") {
      setInsightsPageFormInstance(insightsPage);
    } else {
      setInsightsPageFormInstance(null);
    }
  };
  const handleDeleteInsightsPageModal = (instance) => {
    setInsightsPageFormOpen(false);
    setDeleteInsightsPageModalOpen(true);
    setSelectedInsightsPage(instance);
  };
  const handleDeleteInsightsPageModalClose = () => {
    setDeleteInsightsPageModalOpen(false);
    setSelectedInsightsPage(null);
    push("/insights");
  };

  const handleDeleteWidgetPlacementModalClose = (success) => {
    setDeleteModalOpen(false);
    setWidgetPlacementFormOpen(false);
    setFormMethod(null);
    setSelectedElementData(null);
  };
  const widgetPlacementPreDelete = (id, successCallback) => {
    dispatch(setDeleteQueueWidgetPlacement(id));
    successCallback();
  };

  return (
    <SC.InsightsPageContainer>
      {insightsPageLoading ||
        emptyGridCells === [] ||
        (widgetsInProgress.length != 0 &&
          widgetsInProgress[0] != "formPATCH" && (
            <>
              {widgetPlacements.length > 0 ? (
                <OverlaySpinnerLoadingBar
                  currentCount={Math.max(
                    widgetPlacements.length - widgetsInProgress.length,
                    0
                  )}
                  totalCount={widgetPlacements.length}
                />
              ) : (
                <OverlaySpinner />
              )}
            </>
          ))}

      {widgetPlacementFormOpen && formMethod && (
        <WidgetPlacementForm
          method={formMethod}
          pageId={pageId}
          widgetPlacement={selectedElementData}
          open={widgetPlacementFormOpen}
          onCheckout={() => setWidgetPlacementFormOpen(false)}
          _gridCellClicked={gridCellClicked}
          availableGridAreas={availableGridAreas}
          bottomRightAreas={bottomRightAreas}
          bottomLeftAreas={bottomLeftAreas}
          topLeftAreas={topLeftAreas}
          topRightAreas={topRightAreas}
          handleDeleteModal={handleDeleteWidgetPlacementModal}
          kpiChoices={kpiChoices}
        />
      )}
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={handleDeleteWidgetPlacementModalClose}
        constants={widgetPlacementConstants}
        instance={selectedElementData}
        preDelete={widgetPlacementPreDelete}
      />
      <DeleteModal
        isOpen={deleteInsightsPageModalOpen}
        closeFunction={() => handleDeleteInsightsPageModalClose()}
        constants={insightsPageConstants}
        instance={selectedInsightsPage}
      />
      {insightsPageFormOpen && (
        <InsightsPageForm
          method={insightsPageFormMethod}
          insightsPage={insightsPageFormInstance}
          open={insightsPageFormOpen}
          onCheckout={() => setInsightsPageFormOpen(false)}
          handleDeleteModal={(instance) =>
            handleDeleteInsightsPageModal(instance)
          }
          widgetPlacements={widgetPlacements}
        />
      )}
      <InsightsGoalOverview
        insightsPage={insightsPage}
        open={goalOverviewOpen}
        checkout={() => setGoalOverviewOpen(false)}
        kpiChoices={kpiChoices}
      />
      <PageTopBar
        currentPage={insightsPage}
        openModal={handleOpenInsightsForm}
        setGoalOverviewOpen={setGoalOverviewOpen}
      />
      <div style={{ overflowX: "auto", marginRight: "20px" }}>
        <SC.WidgetPlacementsGrid
          id="widgetPlacementsGrid"
          width={currentPageDimensions[0]}
          height={currentPageDimensions[1]}
          gridHeight={gridHeight}
          ref={gridRef}
        >
          {kpiChoices &&
            kpiChoices.length !== 0 &&
            widgetPlacements?.map((place, idx) => (
              <SC.WidgetPlacementContainer
                columnStart={place.grid_column}
                columnEnd={place.grid_column_end}
                rowStart={place.grid_row}
                rowEnd={place.grid_row_end}
                key={place.id}
              >
                <WidgetPlacement
                  _widgetPlacement={place}
                  _insightsPage={insightsPage}
                  gridRef={gridRef}
                  setFormOpen={handleOpenWidgetPlacementForm}
                  handleDeleteModal={handleDeleteWidgetPlacementModal}
                  setQuickEditPopupOpen={setQuickEditPopupOpen}
                  hasEditPermission={hasEditPermission}
                />
              </SC.WidgetPlacementContainer>
            ))}
          {!quickEditPopupOpen &&
            emptyGridCells.map((cell, idx) => (
              <SC.EmptyGridCell
                columnStart={cell[0]}
                columnEnd={cell[0] + 1}
                rowStart={cell[1]}
                rowEnd={cell[1] + 1}
                icon={addWidgetPlacementIcon}
                onClick={() => handleOpenWidgetPlacementForm(cell)}
                key={JSON.stringify(cell) + idx}
              ></SC.EmptyGridCell>
            ))}
        </SC.WidgetPlacementsGrid>
      </div>
    </SC.InsightsPageContainer>
  );
}
