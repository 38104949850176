import * as React from "react";
import { buildQueryString } from "../../../../../store/base";

import { constants } from "../../../../../store/roundingErrands";
import { useFilteredRoundingErrandSettings } from "../../../../../store/roundingErrandSettings";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { NonNestedSelect } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const govErrandQuery = buildQueryString({ kind__isnull: false });

  return (
    <>
      <FormAreaTitle>Typ av myndighetskrav</FormAreaTitle>

      <FormAreaDescription>
        Välj vilken typ av myndighetskrav{" "}
        {method === "POST" ? "du vill skapa" : "ärendet gäller"}
      </FormAreaDescription>

      <NonNestedSelect
        title="Typ av myndighetskrav"
        storeName={storeName}
        method={method}
        fieldKey={"setting"}
        placeholder="Välj typ av myndighetskrav..."
        fetchMethod={useFilteredRoundingErrandSettings}
        fetchQueryString={govErrandQuery}
      />
    </>
  );
};
