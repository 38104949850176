import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/CompleteList/Table";
import columnDefs from "./listDefs";
import { performFilter, constants } from "../../../../../store/roundingAreas";
import { useRoundingAreasPagination } from "../../../../../store/roundingAreas";

export default ({
  toggleFormCallback,
  persistantQueryString,
  persistantFilterMethod,
  hideTitle,
  hideSearch,
  hideExport,
  hideFilters,
  hideColumns,
  openAreaModal,
  realestates,
}) => {
  const storeName = constants.STORE_NAME;

  const filterInstructions = {
    title: { operator: "icontains" },
  };

  const columns = React.useMemo(
    () => columnDefs({ realestates }),
    [realestates]
  );
  const fetchAllTreshold = 25;

  return (
    <>
      <BaseTable
        storeName={storeName}
        columns={columns}
        persistantQueryString={persistantQueryString}
        persistantFilterMethod={persistantFilterMethod}
        paginationMethod={useRoundingAreasPagination}
        filterInstructions={filterInstructions}
        fetchAllTreshold={fetchAllTreshold}
        filterAction={performFilter}
        toggleFormCallback={toggleFormCallback}
        title={hideTitle ? undefined : "Ronderingstyper"}
        {...{ hideSearch, hideFilters, hideExport, hideColumns }}
        onRowClicked={(row) => {
          openAreaModal(row);
        }}
      />
    </>
  );
};
