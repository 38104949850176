import * as React from "react";

import { useRouteMatch, Redirect, Route, Switch } from "react-router-dom";

import GDPRCleaning from "../../../../../views/ConfigCenter/General/GDPRCleaning";
import EditGDPRCleaning from "../../../../../views/ConfigCenter/General/EditGDPRCleaning";
import ESigning from "../../../../../views/ConfigCenter/General/ESigning";
import EditESigning from "../../../../../views/ConfigCenter/General/EditESigning";
import AutoInvitation from "../../../../../views/ConfigCenter/General/AutoInvitation";
import EditAutoInvitation from "../../../../../views/ConfigCenter/General/EditAutoInvitation";
import Insights from "../../../../../views/ConfigCenter/General/Insights";
import EditInsights from "../../../../../views/ConfigCenter/General/EditInsights";
import ManagementGroups from "../../../../../views/Account/ManagementGroups";
import TermsGdpr from "../../../../../views/Account/TermsGdpr";

import * as SC from "../../styles";
import { usePermissionCheck } from "../../../../../store/base";
import SidebarSettings from "src/views/ConfigCenter/General/SidebarSettings";
import EditSidebarSettings from "src/views/ConfigCenter/General/EditSidebarSettings";

export default function GeneralDetailBox() {
  const { path, url } = useRouteMatch();

  const getIsActive = (match, exact) => {
    if (exact && window.location.pathname === match) {
      return true;
    } else if (window.location.pathname.includes(match)) {
      return true;
    }
    return false;
  };

  const SUB_PAGES = [
    {
      path: "/gdpr-cleaning/edit",
      component: EditGDPRCleaning,
    },
    {
      path: "/gdpr-cleaning",
      component: GDPRCleaning,
    },
    {
      path: "/sidebar/edit",
      component: EditSidebarSettings,
    },
    {
      path: "/sidebar",
      component: SidebarSettings,
    },
    {
      path: "/e-signing/edit",
      component: EditESigning,
    },
    {
      path: "/e-signing",
      component: ESigning,
    },
    {
      path: "/autoinvitation/edit",
      component: EditAutoInvitation,
    },
    {
      path: "/autoinvitation",
      component: AutoInvitation,
    },
    {
      path: "/user-terms",
      component: TermsGdpr,
    },
    {
      path: "/insights/edit",
      component: EditInsights,
    },
    {
      path: "/insights",
      component: Insights,
    },
    {
      path: "/management-groups",
      component: ManagementGroups,
    },
  ];

  const canViewConfigCenter = usePermissionCheck(`view_can_configcenter`);
  const canViewInsights = usePermissionCheck(`view_can_insights`);

  const subPageIndex = canViewConfigCenter ? 1 : 9;
  return (
    <SC.ConfigCenterDetailWrapper>
      <SC.ConfigCenterSideBarDetailWrapper>
        <SC.DetailPageBox>
          {canViewConfigCenter && (
            <SC.DetailPageItem
              active={getIsActive("/configcenter/general/gdpr-cleaning", false)}
              to={"/configcenter/general/gdpr-cleaning"}
            >
              GDPR-Rensning
            </SC.DetailPageItem>
          )}
          {canViewConfigCenter && (
            <SC.DetailPageItem
              active={getIsActive("/configcenter/general/sidebar", false)}
              to={"/configcenter/general/sidebar"}
            >
              Sidomeny
            </SC.DetailPageItem>
          )}
          {canViewConfigCenter && (
            <SC.DetailPageItem
              active={getIsActive("/configcenter/general/e-signing", false)}
              to={"/configcenter/general/e-signing"}
            >
              E-Signering
            </SC.DetailPageItem>
          )}
          {canViewConfigCenter && (
            <SC.DetailPageItem
              active={getIsActive("/configcenter/general/autoinvitation", false)}
              to={"/configcenter/general/autoinvitation"}
            >
             Automatisk Inbjudan
            </SC.DetailPageItem>
          )}

          {canViewConfigCenter && canViewInsights && (
            <SC.DetailPageItem
              active={getIsActive("/configcenter/general/insights", false)}
              to={"/configcenter/general/insights"}
            >
             Insights
            </SC.DetailPageItem>
          )}

          <SC.DetailPageItem
            active={getIsActive(
              "/configcenter/general/management-groups",
              false
            )}
            to={"/configcenter/general/management-groups"}
          >
            Förvaltning
          </SC.DetailPageItem>
          <SC.DetailPageItem separateMode={true}
            active={getIsActive("/configcenter/general/user-terms", false)}
            to={"/configcenter/general/user-terms"}
          >
            Användarvillkor
          </SC.DetailPageItem>
        </SC.DetailPageBox>
      </SC.ConfigCenterSideBarDetailWrapper>

      <SC.ConfigCenterDetailElement>
        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              exact={sp.exact}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect
            exact
            to={`${url}${SUB_PAGES[subPageIndex].path}`}
            path={`${path}`}
          />
        </Switch>
      </SC.ConfigCenterDetailElement>
    </SC.ConfigCenterDetailWrapper>
  );
}
