export default () => [
  {
    title: "Typ av fel",
    key: "TYPE",
    visited: true,
    hasError: false,
    fieldKeys: ["setting"],
  },
  {
    title: "Komponent",
    key: "COMPONENT",
    visited: true,
    hasError: false,
    fieldKeys: ["component_placement"],
  },
  {
    title: "Beskrivning",
    key: "DESCRIPTION",
    visited: false,
    hasError: false,
    fieldKeys: ["report_comments", "title", "report_files"],
  },
  {
    title: "Detaljer",
    key: "DETAILS",
    visited: false,
    hasError: false,
    fieldKeys: [
      "priority",
      "created_at",
      "reported_by",
      "video_url",
      "performer",
      "can_use_key",
      "remind_executor_duration",
      "expected_duration",
      "planned_start",
      "planned_end",
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
