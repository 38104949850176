import { combineReducers } from "redux";
import appReducer from "./app/reducer";

import insightsPageReducer from "./insightsPage/store/reducer";
import insightsPageConstants from "./insightsPage/store/constants";

import insightsGoalReducer from "./insightsGoal/store/reducer";
import insightsGoalConstants from "./insightsGoal/store/constants";

import widgetPlacementsReducer from "./widgetPlacements/store/reducer";
import widgetPlacementsConstants from "./widgetPlacements/store/constants";

import {
  reducer as sftpReducer,
  constants as sftpConstants,
} from "./SFTPCatalogue";

import {
  reducer as unit4ERPReducer,
  constants as unit4ERPConstants,
} from "./Unit4ERP";
import {
  reducer as xLedgerReducer,
  constants as xLedgerConstants,
} from "./xLedger";

import {
  reducer as yourBlockReducer,
  constants as yourBlockConstants,
} from "./yourBlock";

import {
  reducer as insightsScenarioReducer,
  constants as insightsScenarioConstants,
} from "./insightsScenarios";
import {
  reducer as invoicingSummaryReducer,
  constants as invoicingSummaryConstants,
} from "./invoicingSummary";
import {
  reducer as premisesStatusReducer,
  constants as premisesStatusConstants,
} from "./premisesStatuses";

import {
  reducer as reportsReducer,
  constants as reportsConstants,
} from "./excelReports";

import {
  reducer as newReportsReducer,
  constants as newReportsConstants,
} from "./newExcelReports";

import {
  reducer as pptReportsReducer,
  constants as pptReportsConstants,
} from "./powerPointReports";

import {
  reducer as globalSearchReducer,
  constants as globalSearchConstants,
} from "./globalSearch";

import { constants as notesConstants, reducer as notesReducer } from "./notes";
import { constants as costsConstants, reducer as costsReducer } from "./costs";

import realestateReducer from "./realEstates/store/reducer";
import realEstateConstants from "./realEstates/store/constants";

import realestatePortfolioReducer from "./realEstatePortfolios/store/reducer";
import realestatePortfolioConstants from "./realEstatePortfolios/store/constants";

import realestateTaxRecordReducer from "./realEstateTaxRecord/store/reducer";
import realEstateTaxRecordConstants from "./realEstateTaxRecord/store/constants";

import companyReducer from "./companies/store/reducer";
import companyConstants from "./companies/store/constants";

import {
  reducer as brfCompanyReducer,
  constants as brfCompanyConstants,
} from "./brfCompanies";

import {
  reducer as mutationReducer,
  constants as mutationConstants,
} from "./mutations";

import {
  reducer as brfPlannedRaisesReducer,
  constants as brfPlannedRaisesConstants,
} from "./brfPlannedRaises";
import {
  reducer as brfPledgesReducer,
  constants as brfPledgesConstants,
} from "./brfPledges";

import {
  constants as brfContractConstants,
  reducer as brfContractReducer,
} from "./brfContract";

import {
  constants as brfQuotaHandlingConstants,
  reducer as brfQuotaHandlingReducer,
} from "./brfQuotaHandling";

import {
  constants as invoicingDebtorConstants,
  reducer as invoicingDebtorReducer,
} from "./invoicingDebtor";

import {
  constants as invoicingCompanyConstants,
  reducer as invoicingCompanyReducer,
} from "./invoicingCompany";

import {
  constants as invoicingSettingConstants,
  reducer as invoicingSettingReducer,
} from "./invoicingSettings";

import {
  constants as invoicingErrorConstants,
  reducer as invoicingErrorReducer,
} from "./invoicingErrors";
import {
  constants as invoicingRecordConstants,
  reducer as invoicingRecordReducer,
} from "./invoicingRecords";
import {
  constants as invoicingVacancyRecordConstants,
  reducer as invoicingVacancyRecordReducer,
} from "./invoicingVacancyRecords";

import {
  constants as invoicingDebtSettingConstants,
  reducer as invoicingDebtSettingReducer,
} from "./invoicingDebtSettings";

import {
  constants as invoicingIMDSettingConstants,
  reducer as invoicingIMDSettingReducer,
} from "./invoicingIMDSettings";

import {
  constants as reportErrandInvoiceConstants,
  reducer as reportErrandInvoiceReducer,
} from "./reportErrandInvoice";

import {
  constants as invoicingLeaseConstants,
  reducer as invoicingLeaseReducer,
} from "./invoicingLease";
import {
  constants as invoicingParkingConstants,
  reducer as invoicingParkingReducer,
} from "./invoicingParking";
import {
  constants as invoicingOtherConstants,
  reducer as invoicingOtherReducer,
} from "./invoicingOther";

import {
  constants as invoicingServiceConstants,
  reducer as invoicingServiceReducer,
} from "./invoicingService";
import {
  constants as invoicingBrfConstants,
  reducer as invoicingBrfReducer,
} from "./invoicingBrf";

import {
  constants as invoicingProductConstants,
  reducer as invoicingProductReducer,
} from "./invoicingProducts";

import {
  constants as invoicingCostCenterConstants,
  reducer as invoicingCostCenterReducer,
} from "./invoicingCostCenters";

import {
  constants as invoicingProjectConstants,
  reducer as invoicingProjectReducer,
} from "./invoicingProjects";

import {
  constants as invoicingCustomDimensionConstants,
  reducer as invoicingCustomDimensionReducer,
} from "./invoicingCustomDimensions";

import {
  constants as invoicingCustomDimensionEntryConstants,
  reducer as invoicingCustomDimensionEntryReducer,
} from "./invoicingCustomDimensionEntries";

import leaseContractReducer from "./leaseContracts/store/reducer";
import leaseContractConstants from "./leaseContracts/store/constants";
import {
  constants as leaseContractWithCostsConstants,
  reducer as leaseContractWithCostsReducer,
} from "./leaseContractsWithCosts";

import userReducer from "./users/store/reducer";
import userConstants from "./users/store/constants";

import userGroupReducer from "./userGroups/store/reducer";
import userGroupConstants from "./userGroups/store/constants";

import tenantReducer from "./tenants/store/reducer";
import tenantConstants from "./tenants/store/constants";

import tenantPortalSettingsReducer from "./tenantPortalSettings/store/reducer";
import tenantPortalSettingsConstants from "./tenantPortalSettings/store/constants";

import tenantPortalSettingTermsReducer from "./tenantPortalSettingTerms/store/reducer";
import tenantPortalSettingTermsConstants from "./tenantPortalSettingTerms/store/constants";

import {
  constants as brfOwnerConstants,
  reducer as brfOwnerReducer,
} from "./brfOwner";

import buildingReducer from "./buildings/store/reducer";
import buildingConstants from "./buildings/store/constants";

import overviewReducer from "./overview/store/reducer";
import overviewConstants from "./overview/store/constants";

import industrialPremisesReducer from "./industrialPremises/store/reducer";
import industrialPremisesConstants from "./industrialPremises/store/constants";

import apartmentReducer from "./apartments/store/reducer";
import apartmentConstants from "./apartments/store/constants";

import {
  reducer as brfPremisesReducer,
  constants as brfPremisesConstants,
} from "./brfPremises";

import otherContractReducer from "./otherContracts/store/reducer";
import otherContractConstants from "./otherContracts/store/constants";

import basicDocReducer from "./basicDocs/store/reducer";
import basicDocConstants from "./basicDocs/store/constants";

import addressReducer from "./addresses/store/reducer";
import addressConstants from "./addresses/store/constants";

import notificationReducer from "./notifications/store/reducer";
import notificationConstants from "./notifications/store/constants";

import billectaInvoiceReducer from "./billectaInvoicing/store/reducer";
import billectaInvoiceConstants from "./billectaInvoicing/store/constants";

import invoiceReportsReducer from "./billectaReports/store/reducer";
import invoiceReportsConstants from "./billectaReports/store/constants";

import invoiceSearchReducer from "./billectaSearch/store/reducer";
import invoiceSearchConstants from "./billectaSearch/store/constants";

import commonAreaReducer from "./commonAreas/store/reducer";
import commonAreaConstants from "./commonAreas/store/constants";

import blueprintReducer from "./blueprints/store/reducer";
import blueprintConstants from "./blueprints/store/constants";

import roomReducer from "./rooms/store/reducer";
import roomConstants from "./rooms/store/constants";

import editableDocReducer from "./editabledocs/store/reducer";
import editableDocConstants from "./editabledocs/store/constants";

import notificationsDisplayReducer from "./notificationsDisplay/store/reducer";
import notificationsDisplayConstants from "./notificationsDisplay/store/constants";

import infoTraderReducer from "./infoTrader/reducer";

import bookKeepingReducer from "./billectaBookKeeping/store/reducer";
import bookKeepingConstants from "./billectaBookKeeping/store/constants";

import paymentMeansReducer from "./billectaPaymentMeans/store/reducer";
import paymentMeansConstants from "./billectaPaymentMeans/store/constants";

import accountingReportsReducer from "./billectaAccountingReports/store/reducer";
import accountingReportsConstants from "./billectaAccountingReports/store/constants";

import billectaIntegrationsReducer from "./billectaIntegrations/store/reducer";
import billectaIntegrationsConstants from "./billectaIntegrations/store/constants";

import billectaEventsReducer from "./billectaEvents/store/reducer";
import billectaEventsConstants from "./billectaEvents/store/constants";

import {
  constants as billectaAccountsBalanceConstants,
  reducer as billectaAccountsBalanceReducer,
} from "./billectaAccountBalance";

import billectaKycReducer from "./billectaKyc/store/reducer";
import billectaKycConstants from "./billectaKyc/store/constants";

import billectaAccountsReceivableReducer from "./billectaAccountsReceivable/store/reducer";
import billectaAccountsReceivableConstants from "./billectaAccountsReceivable/store/constants";

import serviceContractReducer from "./serviceContracts/store/reducer";
import serviceContractConstants from "./serviceContracts/store/constants";

import serviceCategoryReducer from "./serviceCategories/store/reducer";
import serviceCategoryConstants from "./serviceCategories/store/constants";

import servicePartnerReducer from "./servicePartners/store/reducer";
import servicePartnerConstants from "./servicePartners/store/constants";

import reportErrandSettingsReducer from "./reportErrandSettings/store/reducer";
import reportErrandSettingsConstants from "./reportErrandSettings/store/constants";

import reportErrandsReducer from "./reportErrands/store/reducer";
import reportErrandsConstants from "./reportErrands/store/constants";

import errandComponentsReducer from "./errandComponents/store/reducer";
import errandComponentsConstants from "./errandComponents/store/constants";

import {
  constants as componentTypeConstants,
  reducer as componentTypeReducer,
} from "./componentTypes";
import {
  constants as componentRatingsConstants,
  reducer as componentRatingsReducer,
} from "./componentRatings";

import {
  constants as productTypeConstants,
  reducer as productTypeReducer,
} from "./productTypes";

import {
  constants as checklistConstants,
  reducer as checklistReducer,
} from "./checklists";

import {
  constants as checklistRowConstants,
  reducer as checklistRowReducer,
} from "./checklistRows";

import errandRatingReducer from "./errandRatings/store/reducer";
import errandRatingConstants from "./errandRatings/store/constants";

import parkingLotsReducer from "./parkingLots/store/reducer";
import parkingLotsConstants from "./parkingLots/store/constants";

import parkingSpotsReducer from "./parkingSpots/store/reducer";
import parkingSpotsConstants from "./parkingSpots/store/constants";

import parkingContractsReducer from "./parkingContracts/store/reducer";
import parkingContractsConstants from "./parkingContracts/store/constants";
import {
  constants as parkingContractsWithCostsConstants,
  reducer as parkingContractsWithCostsReducer,
} from "./parkingContractsWithCosts";

import parkingZonesReducer from "./parkingZones/store/reducer";
import parkingZonesConstants from "./parkingZones/store/constants";

import parkingSpecificationsReducer from "./parkingSpecifications/store/reducer";
import parkingSpecificationsConstants from "./parkingSpecifications/store/constants";

import errandArticleReducer from "./errandArticles/store/reducer";
import errandArticleConstants from "./errandArticles/store/constants";

import {
  constants as errandCostConstants,
  reducer as errandCostReducer,
} from "./errandCosts";

import tagReducer from "./tags/store/reducer";
import tagConstants from "./tags/store/constants";

import { reducer as toastReducer, constants as toastConstants } from "./toasts";

import { reducer as pipeReducer, constants as pipeConstants } from "./pipes";
import {
  reducer as stageReducer,
  constants as stageConstants,
} from "./pipeStages";
import { reducer as leadsReducer, constants as leadConstants } from "./leads";
import {
  reducer as marketingRequirementsReducer,
  constants as marketingRequirementsConstants,
} from "./marketRequirements";
import {
  reducer as marketApartmentCommoditiesReducer,
  constants as marketApartmentCommoditiesConstants,
} from "./marketApartmentCommodities";
import {
  reducer as marketIndustrialPremisesCommoditiesReducer,
  constants as marketIndustrialPremisesCommoditiesConstants,
} from "./marketIndustrialPremisesCommodities";
import {
  reducer as marketingApartmentAdsReducer,
  constants as marketingApartmentAdsConstants,
} from "./marketApartmentAds";
import {
  reducer as marketIndpAdsReducer,
  constants as marketIndpAdsConstants,
} from "./marketIndpAds";
import {
  reducer as marketParkingAdsReducer,
  constants as marketParkingAdsConstants,
} from "./marketParkingAds";

import { reducer as homeQReducer, constants as homeQConstants } from "./homeQ";
import {
  reducer as sbfReducer,
  constants as sbfConstants,
} from "./stockholmsBF";

import {
  reducer as milestoneReducer,
  constants as milestoneConstants,
} from "./leadMilestones";
import {
  reducer as pipeAttributesReducer,
  constants as pipeAttributesConstants,
} from "./pipeAttributes";

import {
  constants as tenantGroupConstants,
  reducer as tenantGroupReducer,
} from "./tenantGroup";

import {
  constants as rentIncreaseConstants,
  reducer as rentIncreaseReducer,
} from "./rentIncrease";

import {
  constants as whitelistPermsConstants,
  reducer as whitelistPermsReducer,
} from "./whitelistPerms";

import inspectionReducer from "./inspectionErrands/store/reducer";
import inspectionConstants from "./inspectionErrands/store/constants";
import inspectionSettingsReducer from "./inspectionErrandSettings/store/reducer";
import inspectionSettingsConstants from "./inspectionErrandSettings/store/constants";

import roundingReducer from "./roundingErrands/store/reducer";
import roundingConstants from "./roundingErrands/store/constants";
import roundingSettingsReducer from "./roundingErrandSettings/store/reducer";
import roundingSettingsConstants from "./roundingErrandSettings/store/constants";
import roundingAreasReducer from "./roundingAreas/store/reducer";
import roundingAreasConstants from "./roundingAreas/store/constants";
import roundingComponentsReducer from "./roundingComponents/store/reducer";
import roundingComponentsConstants from "./roundingComponents/store/constants";
import roundingFaultsReducer from "./roundingErrandFaults/store/reducer";
import roundingFaultsConstants from "./roundingErrandFaults/store/constants";

import sensorReducer from "./IMDSensor/store/reducer";
import sensorConstants from "./IMDSensor/store/constants";

import measureTypeReducer from "./IMDMeasureType/store/reducer";
import measureTypeConstants from "./IMDMeasureType/store/constants";

import priceValueReducer from "./IMDPriceValue/store/reducer";
import priceValueConstants from "./IMDPriceValue/store/constants";

import measureValueReducer from "./IMDMeasureValue/store/reducer";
import measureValueConstants from "./IMDMeasureValue/store/constants";

import fortnoxAccountReducer from "./fortnoxAccount/store/reducer";
import fortnoxAccountConstants from "./fortnoxAccount/store/constants";

import fortnoxConfigReducer from "./fortnoxConfig/store/reducer";
import fortnoxConfigConstants from "./fortnoxConfig/store/constants";

import elvacoReducer from "./elvaco/store/reducer";
import elvacoConstants from "./elvaco/store/constants";

import fortnoxSupplierInvoiceReducer from "./fortnoxSupplierInvoice/store/reducer";
import fortnoxSupplierInvoiceConstants from "./fortnoxSupplierInvoice/store/constants";

import fortnoxTransactionReducer from "./fortnoxTransaction/store/reducer";
import fortnoxTransactionConstants from "./fortnoxTransaction/store/constants";

import fortnoxCredentialReducer from "./fortnoxCredential/store/reducer";
import fortnoxCredentialConstants from "./fortnoxCredential/store/constants";

import errandRoleReducer from "./errandRoles/store/reducer";
import errandRoleConstants from "./errandRoles/store/constants";

import errandRoleUserReducer from "./errandRoleUsers/store/reducer";
import errandRoleUserConstants from "./errandRoleUsers/store/constants";

import apartmentCancellationsReducer from "./apartmentCancellations/store/reducer";
import apartmentCancellationsConstants from "./apartmentCancellations/store/constants";

import industrialPremisesCancellationsReducer from "./industrialPremisesCancellations/store/reducer";
import industrialPremisesCancellationsConstants from "./industrialPremisesCancellations/store/constants";

import parkingSpotCancellationsReducer from "./parkingSpotCancellations/store/reducer";
import parkingSpotCancellationsConstants from "./parkingSpotCancellations/store/constants";

import calendarReducer from "./calendar/store/reducer";
import calendarConstants from "./calendar/store/constants";

import calendarEventReducer from "./calendarEvents/store/reducer";
import calendarEventConstants from "./calendarEvents/store/constants";

import recipientGroupReducer from "./recipientGroup/store/reducer";
import recipientGroupConstants from "./recipientGroup/store/constants";

import sendoutReducer from "./sendout/store/reducer";
import sendoutConstants from "./sendout/store/constants";

import outlookEventReducer from "./outlookCalendarEvents/store/reducer";
import outlookEventConstants from "./outlookCalendarEvents/store/constants";

import outlookCalendarReducer from "./outlookCalendar/store/reducer";
import outlookCalendaronstants from "./outlookCalendar/store/constants";

import keyReducer from "./key/store/reducer";
import keyConstants from "./key/store/constants";

import keypermissionReducer from "./keypermission/store/reducer";
import keypermissionConstants from "./keypermission/store/constants";

import parakeyReducer from "./parakey/store/reducer";
import parakeyConstants from "./parakey/store/constants";

import {
  reducer as vacancyBookingReducer,
  constants as vacancyBookingConstants,
} from "./vacancyBookingSettings";

import {
  constants as externalSyncErrorConstants,
  reducer as externalSyncErrorReducer,
} from "./externalSyncErrors";

import {
  constants as massConfigPremisesComponentsConstants,
  reducer as massConfigPremisesComponentsReducer,
} from "./massConfigPremisesComponents";
import {
  constants as indexSettingConstants,
  reducer as indexSettingReducer,
} from "./indexsetting";
import {
  constants as indexTableConstants,
  reducer as indexTableReducer,
} from "./indexTable";
import {
  constants as indexTableRowConstants,
  reducer as indexTableRowReducer,
} from "./indexTableRow";

import {
  constants as invoiceConstants,
  reducer as invoiceReducer,
} from "./invoice";
import {
  constants as reminderInvoiceConstants,
  reducer as reminderInvoiceReducer,
} from "./reminderInvoice";
import {
  constants as debtInvoiceConstants,
  reducer as debtInvoiceReducer,
} from "./debtInvoice";
import {
  constants as invoicePaymntConstants,
  reducer as invoicePaymntReducer,
} from "./invoicePayment";

import keyDeviceConstants from "./keydevice/store/constants";
import keyDeviceReducer from "./keydevice/store/reducer";

import configCenterConstants from "./configcenter/store/constants";
import configCenterReducer from "./configcenter/store/reducer";

import paymentGroupConstants from "./paymentGroup/store/constants";
import paymentGroupReducer from "./paymentGroup/store/reducer";

import loanConstants from "./loan/store/constants";
import loanReducer from "./loan/store/reducer";

import brfPaymentGroupQuotaConstants from "./brfPaymentGroupQuota/store/constants";
import brfPaymentGroupQuotaReducer from "./brfPaymentGroupQuota/store/reducer";

import paymentContributionConstants from "./paymentContribution/store/constants";
import paymentContributionReducer from "./paymentContribution/store/reducer";

const createRootReducer = () =>
  combineReducers({
    app: appReducer,
    [accountingReportsConstants.STORE_NAME]: accountingReportsReducer,
    [addressConstants.STORE_NAME]: addressReducer,
    [apartmentCancellationsConstants.STORE_NAME]: apartmentCancellationsReducer,
    [apartmentConstants.STORE_NAME]: apartmentReducer,
    [basicDocConstants.STORE_NAME]: basicDocReducer,
    [billectaAccountsBalanceConstants.STORE_NAME]:
      billectaAccountsBalanceReducer,
    [billectaAccountsReceivableConstants.STORE_NAME]:
      billectaAccountsReceivableReducer,
    [billectaEventsConstants.STORE_NAME]: billectaEventsReducer,
    [billectaIntegrationsConstants.STORE_NAME]: billectaIntegrationsReducer,
    [billectaInvoiceConstants.STORE_NAME]: billectaInvoiceReducer,
    [billectaKycConstants.STORE_NAME]: billectaKycReducer,
    [blueprintConstants.STORE_NAME]: blueprintReducer,
    [bookKeepingConstants.STORE_NAME]: bookKeepingReducer,
    [brfCompanyConstants.STORE_NAME]: brfCompanyReducer,
    [brfContractConstants.STORE_NAME]: brfContractReducer,
    [brfOwnerConstants.STORE_NAME]: brfOwnerReducer,
    [brfPaymentGroupQuotaConstants.STORE_NAME]: brfPaymentGroupQuotaReducer,
    [brfPlannedRaisesConstants.STORE_NAME]: brfPlannedRaisesReducer,
    [brfPledgesConstants.STORE_NAME]: brfPledgesReducer,
    [brfPremisesConstants.STORE_NAME]: brfPremisesReducer,
    [brfQuotaHandlingConstants.STORE_NAME]: brfQuotaHandlingReducer,
    [buildingConstants.STORE_NAME]: buildingReducer,
    [calendarConstants.STORE_NAME]: calendarReducer,
    [calendarEventConstants.STORE_NAME]: calendarEventReducer,
    [checklistConstants.STORE_NAME]: checklistReducer,
    [checklistRowConstants.STORE_NAME]: checklistRowReducer,
    [commonAreaConstants.STORE_NAME]: commonAreaReducer,
    [companyConstants.STORE_NAME]: companyReducer,
    [componentRatingsConstants.STORE_NAME]: componentRatingsReducer,
    [componentTypeConstants.STORE_NAME]: componentTypeReducer,
    [configCenterConstants.STORE_NAME]: configCenterReducer,
    [costsConstants.STORE_NAME]: costsReducer,
    [debtInvoiceConstants.STORE_NAME]: debtInvoiceReducer,
    [editableDocConstants.STORE_NAME]: editableDocReducer,
    [elvacoConstants.STORE_NAME]: elvacoReducer,
    [errandArticleConstants.STORE_NAME]: errandArticleReducer,
    [errandComponentsConstants.STORE_NAME]: errandComponentsReducer,
    [errandCostConstants.STORE_NAME]: errandCostReducer,
    [errandRatingConstants.STORE_NAME]: errandRatingReducer,
    [errandRoleConstants.STORE_NAME]: errandRoleReducer,
    [errandRoleUserConstants.STORE_NAME]: errandRoleUserReducer,
    [externalSyncErrorConstants.STORE_NAME]: externalSyncErrorReducer,
    [fortnoxAccountConstants.STORE_NAME]: fortnoxAccountReducer,
    [fortnoxConfigConstants.STORE_NAME]: fortnoxConfigReducer,
    [fortnoxCredentialConstants.STORE_NAME]: fortnoxCredentialReducer,
    [fortnoxSupplierInvoiceConstants.STORE_NAME]: fortnoxSupplierInvoiceReducer,
    [fortnoxTransactionConstants.STORE_NAME]: fortnoxTransactionReducer,
    [globalSearchConstants.STORE_NAME]: globalSearchReducer,
    [homeQConstants.STORE_NAME]: homeQReducer,
    [indexSettingConstants.STORE_NAME]: indexSettingReducer,
    [indexTableConstants.STORE_NAME]: indexTableReducer,
    [indexTableRowConstants.STORE_NAME]: indexTableRowReducer,
    [industrialPremisesCancellationsConstants.STORE_NAME]:
      industrialPremisesCancellationsReducer,
    [industrialPremisesConstants.STORE_NAME]: industrialPremisesReducer,
    [insightsGoalConstants.STORE_NAME]: insightsGoalReducer,
    [insightsPageConstants.STORE_NAME]: insightsPageReducer,
    [insightsScenarioConstants.STORE_NAME]: insightsScenarioReducer,
    [inspectionConstants.STORE_NAME]: inspectionReducer,
    [inspectionSettingsConstants.STORE_NAME]: inspectionSettingsReducer,
    [invoiceConstants.STORE_NAME]: invoiceReducer,
    [invoicePaymntConstants.STORE_NAME]: invoicePaymntReducer,
    [invoiceReportsConstants.STORE_NAME]: invoiceReportsReducer,
    [invoiceSearchConstants.STORE_NAME]: invoiceSearchReducer,
    [invoicingBrfConstants.STORE_NAME]: invoicingBrfReducer,
    [invoicingCompanyConstants.STORE_NAME]: invoicingCompanyReducer,
    [invoicingCostCenterConstants.STORE_NAME]: invoicingCostCenterReducer,
    [invoicingCustomDimensionConstants.STORE_NAME]:
      invoicingCustomDimensionReducer,
    [invoicingCustomDimensionEntryConstants.STORE_NAME]:
      invoicingCustomDimensionEntryReducer,
    [invoicingDebtorConstants.STORE_NAME]: invoicingDebtorReducer,
    [invoicingDebtSettingConstants.STORE_NAME]: invoicingDebtSettingReducer,
    [invoicingErrorConstants.STORE_NAME]: invoicingErrorReducer,
    [invoicingIMDSettingConstants.STORE_NAME]: invoicingIMDSettingReducer,
    [invoicingLeaseConstants.STORE_NAME]: invoicingLeaseReducer,
    [invoicingOtherConstants.STORE_NAME]: invoicingOtherReducer,
    [invoicingParkingConstants.STORE_NAME]: invoicingParkingReducer,
    [invoicingProductConstants.STORE_NAME]: invoicingProductReducer,
    [invoicingProjectConstants.STORE_NAME]: invoicingProjectReducer,
    [invoicingRecordConstants.STORE_NAME]: invoicingRecordReducer,
    [invoicingServiceConstants.STORE_NAME]: invoicingServiceReducer,
    [invoicingSettingConstants.STORE_NAME]: invoicingSettingReducer,
    [invoicingSummaryConstants.STORE_NAME]: invoicingSummaryReducer,
    [invoicingVacancyRecordConstants.STORE_NAME]: invoicingVacancyRecordReducer,
    [keyConstants.STORE_NAME]: keyReducer,
    [keyDeviceConstants.STORE_NAME]: keyDeviceReducer,
    [keypermissionConstants.STORE_NAME]: keypermissionReducer,
    [leadConstants.STORE_NAME]: leadsReducer,
    [leaseContractConstants.STORE_NAME]: leaseContractReducer,
    [leaseContractWithCostsConstants.STORE_NAME]: leaseContractWithCostsReducer,
    [loanConstants.STORE_NAME]: loanReducer,
    [marketApartmentCommoditiesConstants.STORE_NAME]:
      marketApartmentCommoditiesReducer,
    [marketIndpAdsConstants.STORE_NAME]: marketIndpAdsReducer,
    [marketIndustrialPremisesCommoditiesConstants.STORE_NAME]:
      marketIndustrialPremisesCommoditiesReducer,
    [marketingApartmentAdsConstants.STORE_NAME]: marketingApartmentAdsReducer,
    [marketingRequirementsConstants.STORE_NAME]: marketingRequirementsReducer,
    [marketParkingAdsConstants.STORE_NAME]: marketParkingAdsReducer,
    [massConfigPremisesComponentsConstants.STORE_NAME]:
      massConfigPremisesComponentsReducer,
    [measureTypeConstants.STORE_NAME]: measureTypeReducer,
    [measureValueConstants.STORE_NAME]: measureValueReducer,
    [milestoneConstants.STORE_NAME]: milestoneReducer,
    [mutationConstants.STORE_NAME]: mutationReducer,
    [newReportsConstants.STORE_NAME]: newReportsReducer,
    [notesConstants.STORE_NAME]: notesReducer,
    [notificationConstants.STORE_NAME]: notificationReducer,
    [notificationsDisplayConstants.STORE_NAME]: notificationsDisplayReducer,
    [otherContractConstants.STORE_NAME]: otherContractReducer,
    [outlookCalendaronstants.STORE_NAME]: outlookCalendarReducer,
    [outlookEventConstants.STORE_NAME]: outlookEventReducer,
    [overviewConstants.STORE_NAME]: overviewReducer,
    [parakeyConstants.STORE_NAME]: parakeyReducer,
    [parkingContractsConstants.STORE_NAME]: parkingContractsReducer,
    [parkingContractsWithCostsConstants.STORE_NAME]:
      parkingContractsWithCostsReducer,
    [parkingLotsConstants.STORE_NAME]: parkingLotsReducer,
    [parkingSpecificationsConstants.STORE_NAME]: parkingSpecificationsReducer,
    [parkingSpotCancellationsConstants.STORE_NAME]:
      parkingSpotCancellationsReducer,
    [parkingSpotsConstants.STORE_NAME]: parkingSpotsReducer,
    [parkingZonesConstants.STORE_NAME]: parkingZonesReducer,
    [paymentContributionConstants.STORE_NAME]: paymentContributionReducer,
    [paymentGroupConstants.STORE_NAME]: paymentGroupReducer,
    [paymentMeansConstants.STORE_NAME]: paymentMeansReducer,
    [pipeAttributesConstants.STORE_NAME]: pipeAttributesReducer,
    [pipeConstants.STORE_NAME]: pipeReducer,
    [pptReportsConstants.STORE_NAME]: pptReportsReducer,
    [premisesStatusConstants.STORE_NAME]: premisesStatusReducer,
    [priceValueConstants.STORE_NAME]: priceValueReducer,
    [productTypeConstants.STORE_NAME]: productTypeReducer,
    [realEstateConstants.STORE_NAME]: realestateReducer,
    [realestatePortfolioConstants.STORE_NAME]: realestatePortfolioReducer,
    [realEstateTaxRecordConstants.STORE_NAME]: realestateTaxRecordReducer,
    [recipientGroupConstants.STORE_NAME]: recipientGroupReducer,
    [reminderInvoiceConstants.STORE_NAME]: reminderInvoiceReducer,
    [rentIncreaseConstants.STORE_NAME]: rentIncreaseReducer,
    [reportErrandInvoiceConstants.STORE_NAME]: reportErrandInvoiceReducer,
    [reportErrandsConstants.STORE_NAME]: reportErrandsReducer,
    [reportErrandSettingsConstants.STORE_NAME]: reportErrandSettingsReducer,
    [reportsConstants.STORE_NAME]: reportsReducer,
    [roomConstants.STORE_NAME]: roomReducer,
    [roundingAreasConstants.STORE_NAME]: roundingAreasReducer,
    [roundingComponentsConstants.STORE_NAME]: roundingComponentsReducer,
    [roundingConstants.STORE_NAME]: roundingReducer,
    [roundingFaultsConstants.STORE_NAME]: roundingFaultsReducer,
    [roundingSettingsConstants.STORE_NAME]: roundingSettingsReducer,
    [sbfConstants.STORE_NAME]: sbfReducer,
    [sendoutConstants.STORE_NAME]: sendoutReducer,
    [sensorConstants.STORE_NAME]: sensorReducer,
    [serviceCategoryConstants.STORE_NAME]: serviceCategoryReducer,
    [serviceContractConstants.STORE_NAME]: serviceContractReducer,
    [servicePartnerConstants.STORE_NAME]: servicePartnerReducer,
    [sftpConstants.STORE_NAME]: sftpReducer,
    [stageConstants.STORE_NAME]: stageReducer,
    [tagConstants.STORE_NAME]: tagReducer,
    [tenantConstants.STORE_NAME]: tenantReducer,
    [tenantGroupConstants.STORE_NAME]: tenantGroupReducer,
    [tenantPortalSettingsConstants.STORE_NAME]: tenantPortalSettingsReducer,
    [tenantPortalSettingTermsConstants.STORE_NAME]:
      tenantPortalSettingTermsReducer,
    [toastConstants.STORE_NAME]: toastReducer,
    [unit4ERPConstants.STORE_NAME]: unit4ERPReducer,
    [userConstants.STORE_NAME]: userReducer,
    [userGroupConstants.STORE_NAME]: userGroupReducer,
    [vacancyBookingConstants.STORE_NAME]: vacancyBookingReducer,
    [whitelistPermsConstants.STORE_NAME]: whitelistPermsReducer,
    [widgetPlacementsConstants.STORE_NAME]: widgetPlacementsReducer,
    [xLedgerConstants.STORE_NAME]: xLedgerReducer,
    [yourBlockConstants.STORE_NAME]: yourBlockReducer,
    infoTrader: infoTraderReducer,
  });

export default createRootReducer;
