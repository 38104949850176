import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import EditableDocContractContainer from "../../components/Forms/EditableDoc/ContractForm/Container";
import FullPageSpinner from "../../components/Loaders/FullPageSpinner";
import { useFilteredApartments } from "../../store/apartments";
import {
  buildQueryString,
  setActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import { useCompany } from "../../store/companies";
import { useConfigCenter } from "../../store/configcenter";
import {
  EDITABLE_DOC_CONTRACT_TYPES,
  update,
  constants as editableDocConstants,
  matchContractAttributesToPartyFields,
  useEditabledoc,
  performDocAndFastDocValidation,
  cleanSignTypeBasedOnContract,
} from "../../store/editabledocs";
import {
  handleDocError,
  performPageFieldsValidation,
  setInitialParties,
} from "../../store/editabledocs/utils";
import { useFilteredIndustrialPremises } from "../../store/industrialPremises";
import { useCompanyInvoicing } from "../../store/invoicingCompany";
import { useLeaseInvoicing } from "../../store/invoicingLease";
import { useOtherInvoicing } from "../../store/invoicingOther";
import { useParkingInvoicing } from "../../store/invoicingParking";
import {
  useLeaseContract,
  detailUrl as leaseContractDetailUrl,
} from "../../store/leaseContracts";
import {
  useOtherContract,
  detailUrl as otherContractDetailUrl,
} from "../../store/otherContracts";
import {
  useParkingContract,
  detailUrl as parkingContractDetailUrl,
} from "../../store/parkingContracts";
import { useFilteredParkingSpots } from "../../store/parkingSpots";
import { useFilteredTenants } from "../../store/tenants";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import { useUser } from "../../store/users";

const getInvoicingObjHook = (contractType) => {
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT)
    return useLeaseInvoicing;
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.OTHER_CONTRACT)
    return useOtherInvoicing;
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.PARKING_CONTRACT)
    return useParkingInvoicing;

  return null;
};

export const getDetailUrl = (contractType) => {
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT)
    return leaseContractDetailUrl;
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.OTHER_CONTRACT)
    return otherContractDetailUrl;
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.PARKING_CONTRACT)
    return parkingContractDetailUrl;

  return null;
};

const getContractHook = (contractType) => {
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT)
    return useLeaseContract;
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.OTHER_CONTRACT)
    return useOtherContract;
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.PARKING_CONTRACT)
    return useParkingContract;

  return null;
};

const getAttrName = (contractType) => {
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT)
    return "lease_invoicing";
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.OTHER_CONTRACT)
    return "other_invoicing";
  if (contractType === EDITABLE_DOC_CONTRACT_TYPES.PARKING_CONTRACT)
    return "parking_invoicing";

  return null;
};

export default function CancelledDocHandleContract() {
  const dispatch = useDispatch();
  const { contractType, id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [hasSetInitialDoc, setHasSetInitialDoc] = React.useState(false);
  const [hasSetInitialPartyData, setHasSetInitialPartyData] =
    React.useState(false);

  const { push } = useHistory();

  const contractTypeHook = getContractHook(contractType);
  const attrName = getAttrName(contractType);
  const invoicingTypeHook = getInvoicingObjHook(contractType);
  const detailUrl = getDetailUrl(contractType);

  const [contract, contractLoading] = contractTypeHook(id);
  const [cancelledDoc] = useEditabledoc(contract?.cancelled_doc?.id);

  const [configcenter] = useConfigCenter();

  // may be undefined if configcenter has not specified a user
  const [defaultSigningUser] = useUser(
    configcenter?.default_signatory_user?.id
  );

  const userId = useSelector((state) => state.app.user?.id);
  const [user] = useUser(userId);

  const apartmentIds = contract?.apartments?.map((a) => a.id) || [];
  const indpIds = contract?.industrial_premises_list?.map((i) => i.id) || [];
  const parkingIds = contract?.parking_spots?.map((i) => i.id) || [];

  const apartmentQuery = buildQueryString({
    id__in: apartmentIds,
  });
  const indpQuery = buildQueryString({
    id__in: indpIds,
  });
  const parkingQuery = buildQueryString({
    id__in: parkingIds,
  });

  const [apartments, apartmentsLoading] = useFilteredApartments(apartmentQuery);
  const [industrialPremises, industrialPremisesLoading] =
    useFilteredIndustrialPremises(indpQuery);
  const [parkingSpots, parkingSpotsLoading] =
    useFilteredParkingSpots(parkingQuery);

  let tenantIds = [];
  if (contract?.tenant?.id) {
    tenantIds.push(contract.tenant.id);
  }
  if (contract?.bi_tenants?.length) {
    tenantIds = [
      ...tenantIds,
      ...(contract.bi_tenants?.map((t) => t.id) || []),
    ];
  }
  const tenantQuery = buildQueryString({
    id__in: tenantIds,
  });
  const [tenants, tenantsLoading] = useFilteredTenants(tenantQuery);
  const [landlordCompany, landlordLoading] = useCompany(contract?.landlord?.id);
  const [billingSettingLandlordCompany, billingSettingLandlordCompanyLoading] =
    useCompanyInvoicing(landlordCompany?.invoicing_details?.id);
  const [landlordUser, landlordUserLoading] = useUser(
    landlordCompany?.owner?.id
  );

  const getDynamicLandlordUser = () => {
    switch (configcenter?.default_signatory_party) {
      case "user":
        return defaultSigningUser;

      case "landlord":
        return landlordUser;

      case "logged_in":
        return user;
    }
  };

  const dynamicLandlordUser = getDynamicLandlordUser();

  const [invoicingObj, invoicingObjLoading] = invoicingTypeHook(
    contract?.[attrName]?.id
  );

  const cancelledDocInstance = useFormInstanceField({
    storeName: editableDocConstants.STORE_NAME,
    fieldKey: "",
  });

  // for templates, match field
  const matchAttributesToPartyFields = () => {
    const cancelledDocClone = cloneDeep(cancelledDocInstance);

    const matched = matchContractAttributesToPartyFields({
      editableDoc: cancelledDocClone,
      contract,
      apartments,
      parkingSpots,
      industrialPremises,
      invoicingObj,
      costs: contract?.costs,
      tenants,
      landlordCompany,
      landlordUser,
      billingSettingLandlordCompany,
      mainTenantId: contract?.tenant?.id,
    });

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Data från avtalet matchades automatiskt mot mallen",
        description: `De fält som ingen match hittades för ligger kvar.`,
      })
    );

    dispatch(
      setActiveFormInstance({
        storeName: editableDocConstants.STORE_NAME,
        data: { ...matched, _page: 1 },
      })
    );
  };

  // for custom documents, no matching of fields, only default party data from contract
  const setInitialPartyData = () => {
    if (tenants?.length || landlordCompany || dynamicLandlordUser) {
      const cancelledDocClone = setInitialParties({
        editableDoc: cloneDeep(cancelledDocInstance),
        tenants,
        mainTenantId: contract.tenant?.id,
        landlordUser: dynamicLandlordUser,
        landlordCompany,
      });

      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Parter lades automatiskt till från avtalet",
          description: `Du hittar de tillagda parterna under "Hantera parter"`,
        })
      );

      dispatch(
        setActiveFormInstance({
          storeName: editableDocConstants.STORE_NAME,
          data: { ...cancelledDocClone, _page: 1 },
        })
      );
    }
  };

  const onDocumentUpdated = () => {
    if (!cancelledDocInstance || hasSetInitialPartyData) return;

    if (cancelledDocInstance?._chosenTemplate != null) {
      if (cancelledDocInstance.parties?.length) {
        setHasSetInitialPartyData(true);

        matchAttributesToPartyFields();
      } else {
      }
    } else if (
      cancelledDocInstance?.doc &&
      !cancelledDocInstance?.parties?.length
    ) {
      setHasSetInitialPartyData(true);
      setInitialPartyData();
    } else {
    }
  };

  React.useEffect(() => {
    if (cancelledDoc && !hasSetInitialDoc) {
      dispatch(
        setActiveFormInstance({
          storeName: editableDocConstants.STORE_NAME,
          data: cancelledDoc,
        })
      );

      setHasSetInitialDoc(true);
    }
  }, [cancelledDoc]);

  React.useEffect(() => {
    if (cancelledDocInstance) {
      onDocumentUpdated();
    }
  }, [cancelledDocInstance]);

  const successCallback = (_, returned) => {
    setLoading(false);
    push(detailUrl({ id }));
  };

  const errorCallback = (data, returnedData) => {
    setLoading(false);
    handleDocError(data, returnedData);
  };

  const preProcess = (data) => {
    let dataClone = performPageFieldsValidation(
      performDocAndFastDocValidation(cloneDeep(data))
    );

    dataClone = cleanSignTypeBasedOnContract({
      editableDocData: dataClone,
      contract,
      isCancellation: true,
    });

    console.log({ dataClone });

    // go through fields, no null values allowed

    if (dataClone?.parties?.length > 0) {
      dataClone.parties.forEach((party) => {
        if (party.fields?.length > 0) {
          party.fields.forEach((field) => {
            if (!field.value) {
              field.value = "";
            }
          });
        }
      });
    }

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        preProcess,
        id: contract?.cancelled_doc?.id,
        successCallback,
        errorCallback,
        preventDefaultToast: true,
      })
    );
  };

  return (
    <>
      {loading && <FullPageSpinner />}

      {hasSetInitialDoc && (
        <EditableDocContractContainer
          isCancelledDoc
          {...{
            contract,
            onSubmit,
            detailUrl,
            method: "PATCH",
            onClearDocumentCallback: () => setHasSetInitialPartyData(false),
          }}
        />
      )}
    </>
  );
}
