import * as React from "react";
import { useDispatch } from "react-redux";
import {
  buildQueryString,
  clearFetched,
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import {
  constants,
  handleUrl,
  useSensorPagination,
} from "../../../store/IMDSensor";
import Table from "../../Billecta/Table/BasicTable";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../Details/OverviewInfo/styles";
import StandardModal from "../../Modals/StandardModal";
import { DetailInnerWrapper, DetailPageBox } from "../../sharedStyles";
import { PrimaryButton, TextButton } from "../Base/Buttons";
import * as SC from "./styles";

import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { cloneDeep } from "lodash";
import {
  clearSensorsFromFormInstance,
  performConnection,
} from "../../../store/IMDSensor/store/actions";
import FullPageSpinner from "../../Loaders/FullPageSpinner";
import { useHistory } from "react-router";
import TableSelectField from "../Base/Fields/TableSelectField";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import CommonAreaTable from "src/components/Tables/CommonArea/FullTable";
import ParkingSpotsTable from "src/components/Tables/Parking/ParkingSpots/FullTable";
import BrfPremisesTable from "src/components/Tables/BrfPremises/FullTable";

export default function IMDSensorConnectForm() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { replace } = useHistory();

  const queryString = buildQueryString({
    apartment__isnull: true,
    industrial_premises__isnull: true,
    parking_spot__isnull: true,
    brf_premis__isnull: true,
    common_area__isnull: true,
    _page_size: 50,
    _page: 1,
  });

  const [realEstates] = useFilteredRealEstates("");

  const [paginatedSensors] = useSensorPagination(queryString);
  const sensors = paginatedSensors?.results;

  const [connectModalId, setConnectModalId] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const formSensors = useFormInstanceField({
    storeName,
    fieldKey: "sensors",
  });

  const connectedSensorsCount = formSensors?.reduce((acc, cur) => {
    if (
      cur.apartment ||
      cur.industrial_premises ||
      cur.common_area ||
      cur.parking_spot ||
      cur.brf_premis
    ) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);

  const currentConnectionIndex = formSensors?.findIndex(
    (fs) => fs.id === connectModalId
  );

  const currentConnectionSensor = formSensors?.[currentConnectionIndex];

  React.useEffect(() => {
    if (sensors?.length && !formSensors?.length) {
      dispatch(
        setActiveFormInstance({
          storeName,
          data: {
            sensors,
          },
        })
      );
    }
  }, [sensors]);

  const tableSensors = React.useMemo(() => {
    return formSensors || [];
  }, [formSensors]);

  const tableColumns = React.useMemo(() => {
    const cols = [
      {
        Header: " ",
        id: "connected",
        Cell: ({ row }) => {
          const { original } = row;

          if (
            original.apartment ||
            original.industrial_premises ||
            original.common_area ||
            original.parking_spot ||
            original.brf_premis
          ) {
            return <SC.CheckCircle />;
          } else {
            return <div></div>;
          }
        },
      },
      {
        Header: "Sensor-ID",
        accessor: "sensor_id",
        Cell: ({ value }) => {
          return <div style={{ fontWeight: 600 }}>{value}</div>;
        },
      },
      {
        Header: "Mätartyp",
        accessor: "measure_type.str_representation",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Koppling",
        id: "connection",
        Cell: ({ row }) => {
          if (row.original.apartment)
            return (
              <TextButton
                title={`Hyreslägenhet ${row.original.apartment.str_representation}`}
                iconType="edit"
                iconPlacement="right"
                clicked={() => setConnectModalId(row.original.id)}
              />
            );

          if (row.original.industrial_premises)
            return (
              <TextButton
                title={`Lokal ${row.original.industrial_premises.str_representation}`}
                iconType="edit"
                iconPlacement="right"
                clicked={() => setConnectModalId(row.original.id)}
              />
            );

          if (row.original.common_area)
            return (
              <TextButton
                title={`Gemensam yta ${row.original.common_area.str_representation}`}
                iconType="edit"
                iconPlacement="right"
                clicked={() => setConnectModalId(row.original.id)}
              />
            );

          if (row.original.parking_spot)
            return (
              <TextButton
                title={`Fordonsplats ${row.original.parking_spot.str_representation}`}
                iconType="edit"
                iconPlacement="right"
                clicked={() => setConnectModalId(row.original.id)}
              />
            );

          if (row.original.brf_premis)
            return (
              <TextButton
                title={`Bostadsrätt ${row.original.brf_premis.str_representation}`}
                iconType="edit"
                iconPlacement="right"
                clicked={() => setConnectModalId(row.original.id)}
              />
            );

          return (
            <TextButton
              title="Koppla till objekt"
              iconType="add"
              iconPlacement="right"
              clicked={() => setConnectModalId(row.original.id)}
            />
          );
        },
      },
      {
        Header: "Övrig information",
        accessor: "meta_data",
        Cell: ({ value }) => {
          let metaStr = "-";
          if (
            value &&
            typeof value === "object" &&
            Object.keys(value).length > 0
          ) {
            const metaData = Object.keys(value)
              .filter((key) => typeof value[key] === "string")
              .map((key) => {
                return `${key}: ${value[key]}`;
              });

            if (metaData?.length) {
              metaStr = metaData.join(", ");
            }
          }
          return <div>{metaStr}</div>;
        },
      },
    ];

    return cols;
  }, [formSensors]);

  const successCallback = ({ updateLength }) => {
    setLoading(false);
    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: "Kopplingarna genomfördes",
        description: `${updateLength} sensorer kopplades till objekt`,
      })
    );
    replace(
      `${handleUrl()}?postconnect=true&possible=${
        sensors.length
      }&connected=${updateLength}`
    );
    dispatch(clearSensorsFromFormInstance());
    dispatch(clearFetched(constants, true));
  };

  const errorCallback = () => {
    setLoading(false);
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Något gick fel",
        description:
          "Någon eller alla av sensorerna misslyckades med att kopplas till objekt",
      })
    );
  };

  const handleConnect = () => {
    setLoading(true);
    const sensorsCopy = cloneDeep(formSensors).filter((s) => {
      return (
        s.apartment ||
        s.industrial_premises ||
        s.common_area ||
        s.parking_spot ||
        s.brf_premis
      );
    });

    const convertedData = {};

    sensorsCopy.forEach((sc) => {
      convertedData[sc.id] = sc;
    });

    const updateLength = sensorsCopy.length;

    if (updateLength) {
      dispatch(
        performConnection({
          data: convertedData,
          successCallback: () => successCallback({ updateLength }),
          errorCallback,
        })
      );
    }
  };

  const renderConnectionModal = () => (
    <StandardModal
      isOpen={connectModalId}
      closeFunction={() => setConnectModalId(null)}
      title="Välj typ av objekt"
      withActionBar
    >
      <SC.PickerWrapper>
        <SC.PickerContainer>
          <TableSelectField
            storeName={storeName}
            fieldKey={`sensors[${currentConnectionIndex}].apartment`}
            forceRender
            placeholder="Välj hyreslägenhet..."
            title={"Koppla till hyreslägenhet"}
            description={
              "Välj att koppla denna sensor till ett objekt av typen hyreslägenhet"
            }
            TableComponent={ApartmentTable}
            closeCallback={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    [`sensors[${currentConnectionIndex}].industrial_premises`]:
                      null,
                    [`sensors[${currentConnectionIndex}].common_area`]: null,
                    [`sensors[${currentConnectionIndex}].parking_spot`]: null,
                    [`sensors[${currentConnectionIndex}].brf_premis`]: null,
                  },
                })
              );
              setConnectModalId(null);
              dispatch(
                addToast({
                  type: TOAST_TYPES.INFO,
                  title: "Sensorn kopplades mot en hyreslägenhet",
                  description: "OBS:Kopplingen är ej sparad än.",
                })
              );
            }}
          />
        </SC.PickerContainer>

        <SC.PickerContainer>
          <TableSelectField
            forceRender
            storeName={storeName}
            fieldKey={`sensors[${currentConnectionIndex}].industrial_premises`}
            instructionsKey={"sensors.industrial_premises"}
            title="Koppla till lokal"
            placeholder={"Välj lokal..."}
            description={
              "Välj att koppla denna sensor till ett objekt av typen lokal"
            }
            closeCallback={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    [`sensors[${currentConnectionIndex}].apartment`]: null,
                    [`sensors[${currentConnectionIndex}].common_area`]: null,
                    [`sensors[${currentConnectionIndex}].parking_spot`]: null,
                    [`sensors[${currentConnectionIndex}].brf_premis`]: null,
                  },
                })
              );
              setConnectModalId(null);
              dispatch(
                addToast({
                  type: TOAST_TYPES.INFO,
                  title: "Sensorn kopplades mot en lokal",
                  description: "OBS:Kopplingen är ej sparad än.",
                })
              );
            }}
          />
        </SC.PickerContainer>
      </SC.PickerWrapper>

      <SC.PickerWrapper>
        <SC.PickerContainer>
          <TableSelectField
            forceRender
            storeName={storeName}
            fieldKey={`sensors[${currentConnectionIndex}].common_area`}
            title="Koppla till gemensam yta"
            placeholder={"Välj gemensam yta..."}
            description={
              "Välj att koppla denna sensor till ett objekt av typen gemensam yta"
            }
            TableComponent={CommonAreaTable}
            closeCallback={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    [`sensors[${currentConnectionIndex}].apartment`]: null,
                    [`sensors[${currentConnectionIndex}].industrial_premises`]:
                      null,
                    [`sensors[${currentConnectionIndex}].parking_spot`]: null,
                    [`sensors[${currentConnectionIndex}].brf_premis`]: null,
                  },
                })
              );
              setConnectModalId(null);
              dispatch(
                addToast({
                  type: TOAST_TYPES.INFO,
                  title: "Sensorn kopplades mot en gemensam yta",
                  description: "OBS:Kopplingen är ej sparad än.",
                })
              );
            }}
          />
        </SC.PickerContainer>
        <SC.PickerContainer>
          <TableSelectField
            forceRender
            storeName={storeName}
            fieldKey={`sensors[${currentConnectionIndex}].parking_spot`}
            title="Koppla till fordonsplats"
            placeholder={"Välj fordonsplats..."}
            description={
              "Välj att koppla denna sensor till ett objekt av typen fordonsplats"
            }
            TableComponent={ParkingSpotsTable}
            closeCallback={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    [`sensors[${currentConnectionIndex}].apartment`]: null,
                    [`sensors[${currentConnectionIndex}].industrial_premises`]:
                      null,
                    [`sensors[${currentConnectionIndex}].common_area`]: null,
                    [`sensors[${currentConnectionIndex}].brf_premis`]: null,
                  },
                })
              );
              setConnectModalId(null);
              dispatch(
                addToast({
                  type: TOAST_TYPES.INFO,
                  title: "Sensorn kopplades mot en fordonsplats",
                  description: "OBS:Kopplingen är ej sparad än.",
                })
              );
            }}
          />
        </SC.PickerContainer>
      </SC.PickerWrapper>

      <SC.PickerWrapper>
        <SC.PickerContainer>
          <TableSelectField
            forceRender
            storeName={storeName}
            fieldKey={`sensors[${currentConnectionIndex}].brf_premis`}
            title="Koppla till bostadsrätt"
            placeholder={"Välj bostadsrätt..."}
            description={
              "Välj att koppla denna sensor till ett objekt av typen bostadsrätt"
            }
            TableComponent={BrfPremisesTable}
            closeCallback={() => {
              dispatch(
                updateActiveFormInstance({
                  storeName,
                  data: {
                    [`sensors[${currentConnectionIndex}].apartment`]: null,
                    [`sensors[${currentConnectionIndex}].industrial_premises`]:
                      null,
                    [`sensors[${currentConnectionIndex}].common_area`]: null,
                    [`sensors[${currentConnectionIndex}].parking_spot`]: null,
                  },
                })
              );
              setConnectModalId(null);
              dispatch(
                addToast({
                  type: TOAST_TYPES.INFO,
                  title: "Sensorn kopplades mot en bostadsrätt",
                  description: "OBS:Kopplingen är ej sparad än.",
                })
              );
            }}
          />
        </SC.PickerContainer>
        <SC.PickerContainer></SC.PickerContainer>
      </SC.PickerWrapper>
    </StandardModal>
  );

  return (
    <>
      {renderConnectionModal()}

      <DetailInnerWrapper>
        {loading && <FullPageSpinner />}
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Koppla IMD-sensorer till objekt</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>

            <PrimaryButton
              disabled={connectedSensorsCount === 0}
              title={`Spara ${
                connectedSensorsCount || 0
              } kopplingar och gå vidare`}
              clicked={handleConnect}
            />
          </OverviewTitleWrapper>

          <Table
            onRowClicked={() => {}}
            columns={tableColumns}
            data={tableSensors}
            forceInitialPageSize={50}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
