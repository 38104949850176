import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject, MultiEntryLinks } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import StateFilter from "./Filters/StateFilter";
import CategoryFilter from "./Filters/CategoryFilter";
import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";

import { detailUrl as tenantDetailUrl } from "../../../store/tenants";

import { StatusLabel } from "src/components/Lists/Base/CompleteList/styles";
import { renderFloors } from "../../Forms/Base/FieldComponents/FloorSelector";
import { detailUrl as realEstateDetailUrl } from "../../../store/realEstates";
import { toMoneyPerSqmString, toMoneyString } from "../../utils/stringUtils";
import { getAdStateAndLabel } from "../../../store/marketApartmentAds/utils";
import { lightOrDark } from "../../DigitalDoc/utils";

import { getObjectCostColumns } from "src/components/Lists/utils/costCalculations";

export default ({
  startDate,
  endDate,
  canViewSensitiveInfoTemp,
  ads,
  withCosts,
  onlyWithCosts,
  useSqm,
  showMonthlyCosts,
} = {}) => {
  const cols = [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ value, row }) => {
        return (
          <StatusLabel state={row.original.state} contentType="premises">
            {row.original.state_display}
            {row.original.subletted ? " (andrahand)" : ""}
          </StatusLabel>
        );
      },
      Filter: StateFilter,
      filter: "textExact",
    },

    {
      Header: "Lägenhetsnummer",
      accessor: "apartment_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Objektsnummer",
      accessor: "premises_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kategori",
      accessor: "category",
      Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
      Filter: CategoryFilter,
      filter: "text",
    },
    {
      Header: "Adress",
      accessor: "address.str_representation",
      Cell: ({ row }) => {
        const value = row.original.address?.str_representation;
        return <ToolTipCell text={value} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fastighet",
      accessor: "realestate.str_representation",
      Cell: ({ row }) => {
        // (row) => row.realestate?.str_representation
        const value = row.original.realestate;
        return <LinkedObject obj={value} urlMethod={realEstateDetailUrl} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Byggnad",
      accessor: "building.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Våning",
      accessor: "dynamic_floors",
      // for convenience, backend allow filtering on summed_floors
      id: "dynamicFloors",
      Cell: ({ value }) => <ToolTipCell text={renderFloors(value)} />,
      // Filter: SimpleTextFilter,
      // filter: "text",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Area (m2)",
      accessor: "area",
      Cell: ({ value }) => (
        <ToolTipCell text={(value || 0).toLocaleString("sv")} />
      ),
      Filter: NumberRangeFilter,
      filter: "between",
    },
    {
      Header: "Referenshyra (SEK/månad)",
      accessor: "base_rent",
      Cell: ({ row, value }) => (
        <ToolTipCell
          text={
            row.original.base_rent_is_m2
              ? toMoneyPerSqmString(value)
              : toMoneyString(value)
          }
        />
      ),
      // Filter: NumberRangeFilter,
      // filter: "between",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Referenshyra (SEK/år)",
      accessor: "base_rent_year",
      Cell: ({ row }) => {
        const value = row.original.base_rent * 12;

        return (
          <ToolTipCell
            text={
              row.original.base_rent_is_m2
                ? toMoneyPerSqmString(value)
                : toMoneyString(value)
            }
          />
        );
      },
      // Filter: NumberRangeFilter,
      // filter: "between",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Hyresgäst",
      accessor: "tenant.str_representation",
      Cell: ({ row }) => (
        <LinkedObject obj={row.original.tenant} urlMethod={tenantDetailUrl} />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Övriga hyresgäster",
      id: "bi_tenant_name",
      Cell: ({ row }) => {
        let mapping = [];
        let restLength = 0;

        const biTenants = row.original.bi_tenants ?? [];
        biTenants.forEach((b) => {
          mapping.push({ obj: b, urlMethod: tenantDetailUrl });
        });

        if (mapping.length > 2) {
          restLength = mapping.length - 2;
          mapping = mapping.slice(0, 2);
        }

        return <MultiEntryLinks mappings={mapping} restLength={restLength} />;
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      // Filter: SimpleTextFilter,
      // filter: "text",
    },
    {
      Header: "Hyresavtal",
      accessor: "leasecontract_amount",
      Cell: ({ row }) => {
        const value = row.original.leasecontract_amount
          ? row.original.leasecontract_amount
          : 0;
        return <ToolTipCell text={`${value} st`} />;
      },
      // Filter: NumberRangeFilter,
      // filter: "between",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Anpassade statusar",
      accessor: "customer_statuses",
      Cell: ({ value }) => {
        if (!value?.length) return <div>-</div>;

        return value.slice(0, 2).map((status) => {
          return (
            <StatusLabel
              style={{
                backgroundColor: status.status.color_code || "#f1f1f1",
                color:
                  lightOrDark(status.status.color_code || "#f1f1f1") === "light"
                    ? "#000"
                    : "#fff",
              }}
            >
              {status?.str_representation}
            </StatusLabel>
          );
        });
      },
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
  ];

  /// TEMP
  if (!canViewSensitiveInfoTemp) {
    cols.splice(9, 1);
  }
  if (!canViewSensitiveInfoTemp) {
    cols.splice(9, 1);
  }

  if (withCosts) {
    return cols.concat(
      getObjectCostColumns(
        startDate,
        endDate,
        [
          "CATEGORY_MEMBER_FEE",
          "CATEGORY_PARKING",
          "CATEGORY_IMD",
          "CATEGORY_DEPOSIT",
        ],
        useSqm,
        showMonthlyCosts
      )
    );
  }

  // special for ads table
  if (ads) {
    cols.splice(0, 1);
    cols.splice(10, 1);
    cols.splice(10, 1);
    cols.splice(0, 0, {
      Header: "Publiceringsstatus",
      id: "_adstate",
      Cell: ({ row }) => {
        const matchingAd = ads?.find(
          (ad) => ad.apartment?.id === row.original.id
        );

        const { stateDisplay, state } = matchingAd
          ? getAdStateAndLabel(matchingAd)
          : { stateDisplay: "Ej publicerad", state: 3 };

        return <StatusLabel state={state}>{stateDisplay}</StatusLabel>;
      },
      disableSortBy: true,
      disableFilters: true,
    });
  }

  return cols;
};

//Exporting columDefs as funciton to play nicely with detailPerms logic (components/Details/Account/UserGroups/WhiteListTable.js)

export const apartmentColumnDefs = (realEstates, buildings) => {
  return [
    {
      Header: "Status",
      accessor: "state",
      Cell: ({ value, row }) => {
        return (
          <StatusLabel state={row.original.state} contentType="premises">
            {row.original.state_display}
            {row.original.subletted ? " (andrahand)" : ""}
          </StatusLabel>
        );
      },
      Filter: StateFilter,
      filter: "textExact",
    },
    {
      Header: "Lägenhetsnummer",
      accessor: "apartment_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Objektsnummer",
      accessor: "premises_id",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Kategori",
      accessor: "category",
      Cell: ({ row }) => <ToolTipCell text={row.original.category_display} />,
      Filter: CategoryFilter,
      filter: "text",
    },
    {
      Header: "Adress",
      accessor: "address.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Fastighet",
      accessor: "realestate",
      Cell: ({ value }) => {
        return <ToolTipCell text={value?.str_representation || "-"} />;
      },
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Byggnad",
      accessor: (row, _) => {
        const buildingId = row.building;
        const building = buildings?.find((b) => b.id === buildingId);
        return building?.str_representation || null;
      },
      id: "building.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Våning",
      accessor: (row, _) => {
        return (row?.original?.dynamic_floors ?? []).join(", ");
      },
      // for convenience, backend allow filtering on summed_floors
      id: "floors",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Area (m2)",
      accessor: "area",
      Cell: ({ value }) => (
        <ToolTipCell text={(value || 0).toLocaleString("sv")} />
      ),
      Filter: NumberRangeFilter,
      filter: "between",
    },
    {
      Header: "Referenshyra (SEK/månad)",
      accessor: "base_rent",
      Cell: ({ row, value }) => (
        <ToolTipCell
          text={
            value
              ? row.original.base_rent_is_m2
                ? ((value || 0) * 12 * (row.original.area || 0)).toLocaleString(
                    "sv"
                  )
                : value.toLocaleString("sv")
              : "-"
          }
        />
      ),
      // Filter: NumberRangeFilter,
      // filter: "between",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Hyresgäst",
      accessor: "tenant.str_representation",
      Cell: ({ row }) => (
        <LinkedObject obj={row.original.tenant} urlMethod={tenantDetailUrl} />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Övriga hyresgäster",
      id: "bi_tenant_name",
      Cell: ({ row }) => {
        let mapping = [];
        let restLength = 0;

        const biTenants = row.original.bi_tenants ?? [];
        biTenants.forEach((b) => {
          mapping.push({ obj: b, urlMethod: tenantDetailUrl });
        });

        if (mapping.length > 2) {
          restLength = mapping.length - 2;
          mapping = mapping.slice(0, 2);
        }

        return <MultiEntryLinks mappings={mapping} restLength={restLength} />;
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      // Filter: SimpleTextFilter,
      // filter: "text",
    },
    {
      Header: "Hyresavtal",
      accessor: "leasecontract_amount",
      id: "leasecontracts",
      Cell: ({ row }) => {
        const value = row.original?.leasecontract_amount
          ? row.original.leasecontract_amount
          : 0;
        return <ToolTipCell text={`${value} st`} />;
      },
      // Filter: NumberRangeFilter,
      // filter: "between",
      disableSortBy: true,
      disableFilters: true,
      disableGlobalFilter: true,
    },
    {
      Header: "Hyresvärd",
      accessor: "landlord.str_representation",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
  ];
};
