import * as React from "react";
import { useParams } from "react-router";
import { DetailInnerWrapper } from "../../../components/sharedStyles";

import { useRoundingErrand } from "../../../store/roundingErrands";
import Document from "../../../components/Details/RoundingErrand/Document";

function ErrandReport() {
  const { roundingErrandId } = useParams();
  const [roundingErrand, roundingErrandLoading] =
    useRoundingErrand(roundingErrandId);

  return (
    <DetailInnerWrapper>
      <Document roundingErrand={roundingErrand} />
    </DetailInnerWrapper>
  );
}

export default ErrandReport;
