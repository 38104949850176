import * as React from "react";
import StandardModal from "../../../Modals/StandardModal";
import {
  MultiChapterRow,
  SingleChapterRow,
  baseToggleInfo,
  InfoModalContent,
} from "./ExtraPermsComps";
import * as SC from "./styles";

export default ({ group, disabled }) => {
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);

  const handleInfoModal = (permObject) => {
    setInfoModalOpen(permObject);
  };

  const mapCurrentPerms = () => {
    if (!group) return;

    return (
      <>
        <MultiChapterRow
          title="Teknisk förvaltning"
          perms={baseToggleInfo.TF_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Fastigheter"
          perms={baseToggleInfo.REALESTATE_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="BRF"
          perms={baseToggleInfo.BRF_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Företag"
          perms={baseToggleInfo.COMPANY_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Objektshantering"
          perms={baseToggleInfo.BASEOBJECT_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Parkering"
          perms={baseToggleInfo.PARKRING_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Hyresgäster"
          perms={baseToggleInfo.TENANTS_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Avtal"
          perms={baseToggleInfo.CONTRACTS_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Mina sidor - admin"
          perms={baseToggleInfo.TENANTPORTAL_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Leads"
          perms={baseToggleInfo.LEADS_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Marknad"
          perms={baseToggleInfo.MARKET_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="HomeQ"
          perms={baseToggleInfo.HOMEQ_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Bostadsförm. Stockholm"
          perms={baseToggleInfo.SBF_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Användarhantering"
          perms={baseToggleInfo.USER_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Behörighetshantering"
          perms={baseToggleInfo.PERMISSION_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Fakturering/Avisering"
          perms={baseToggleInfo.BILLING_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="E-signering"
          perms={baseToggleInfo.E_SIGNING_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Fastdok"
          perms={baseToggleInfo.FASTDOC_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Avy/Tmpl"
          perms={baseToggleInfo.AVYTMPL_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Driftnetto (Fortnox)"
          perms={baseToggleInfo.FORTNOX_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="IMD"
          perms={baseToggleInfo.IMD_PERMS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Anonymisering"
          perms={baseToggleInfo.ANONYMIZATION}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Konfigurationer"
          perms={baseToggleInfo.CONFIG_CENTER}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Nyckelhantering"
          perms={baseToggleInfo.KEYS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
        <SingleChapterRow
          title="Insights"
          perms={baseToggleInfo.INSIGHTS}
          showPermsInfo={handleInfoModal}
          group={group}
          disabled={disabled}
        />
      </>
    );
  };

  if (!group) return null;

  return (
    <>
      <StandardModal
        isOpen={infoModalOpen}
        closeFunction={() => setInfoModalOpen(false)}
        title={`Behörighetsinformation om ${infoModalOpen?.title}`}
        withActionBar
        actionBarCancelTitle="Stäng"
      >
        <InfoModalContent infoObj={infoModalOpen} />
      </StandardModal>

      <SC.PermPickerWrapper key={group?.id}>
        <SC.HeaderRow>
          <SC.HeaderTitle
            style={{ flex: 2, paddingLeft: "20px" }}
          ></SC.HeaderTitle>
          <SC.HeaderTitle style={{ flex: 1 }}>Se</SC.HeaderTitle>
          <SC.HeaderTitle style={{ flex: 1 }}>Skapa</SC.HeaderTitle>
          <SC.HeaderTitle style={{ flex: 1 }}>Redigera</SC.HeaderTitle>
          <SC.HeaderTitle style={{ flex: 1 }}>Ta bort</SC.HeaderTitle>
          <SC.TableFiller />
        </SC.HeaderRow>
        {mapCurrentPerms()}
      </SC.PermPickerWrapper>
    </>
  );
};
