import * as React from "react";
import Pdf from "../../Pdf/Pdf";

import * as SharedStyles from "../../sharedStyles";

export default ({ roundingErrand }) => {
  const file = roundingErrand?.completion_files[0]?.file?.get;
  return (
    <SharedStyles.DetailPageBox>
      <Pdf url={file} />
    </SharedStyles.DetailPageBox>
  );
};
