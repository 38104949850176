import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import AreaList from "../../../RoundingErrandSetting/AreaList/AreaList";

export default ({ method, id }) => {
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Områden</OverviewTitle>
          <OverviewSubtitle>
            Ange vilka områden som inkluderas i myndighetskravet
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <AreaList method={method} id={id} />
    </>
  );
};
