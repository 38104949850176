import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import { TextButton } from "../Buttons";
import * as SC from "./styles";

const BYTES_PER_MEGABYTE = 1000000;
const MAX_FILE_SIZE = 100;
const ALLOWED_FORMATS = [".png", ".jpg", ".pdf", ".mp4"];

export default function ManyMediaField({
  title,
  storeName,
  fieldKey,
  instructionsKey,
  method,
  description,
  allowedFormats,
  fileKey = "file",
  options = {},
}) {
  const dispatch = useDispatch();
  const {
    minImageWidth,
    maxImageWidth,
    minImageHeight,
    maxImageHeight,
    resizeImage,
    imageQuality,
  } = options;
  const [loading, setLoading] = React.useState(false);
  const [highlighted, setHighlighted] = React.useState(false);
  const formats = allowedFormats || ALLOWED_FORMATS;
  const [files, setFileList] = React.useState([]);
  const [errors, setError] = React.useState({});
  const [hasSetListeners, setHasSetListeners] = React.useState(false);

  const dragAreaRef = React.useRef();
  const fileInputRef = React.useRef(null);

  const value = useFormInstanceField({
    storeName,
    fieldKey,
  });
  const instructions = useFormField({
    storeName,
    method,
    fieldKey: instructionsKey || fieldKey,
  });
  const error = useFormError({ storeName, fieldKey });

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const highlight = () => {
    setHighlighted(true);
  };
  const unhighlight = () => {
    setHighlighted(false);
  };

  const handleDrop = (e) => {
    let dt = e.dataTransfer;
    let files = dt.files;

    onChange(files);
  };

  React.useLayoutEffect(() => {
    if (!dragAreaRef.current || hasSetListeners) return;
    setHasSetListeners(true);

    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dragAreaRef.current.addEventListener(eventName, preventDefaults, false);
    });

    ["dragenter", "dragover"].forEach((eventName) => {
      dragAreaRef.current.addEventListener(eventName, highlight, false);
    });
    ["dragleave", "drop"].forEach((eventName) => {
      dragAreaRef.current.addEventListener(eventName, unhighlight, false);
    });

    dragAreaRef.current.addEventListener("drop", handleDrop, false);
  });

  const removeFile = (idx) => {
    const valueClone = cloneDeep(value);

    valueClone.splice(idx, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: valueClone,
        },
      })
    );
  };

  const onClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const onChange = async (fileList) => {
    if (!fileList || !fileList.length) return;

    // Scrub previous data from state
    setError(() => ({}));
    setFileList([]);

    // Convert native file list to iterable. Much easier to work with.
    let iterableFileList = Array.from(fileList);

    const anyHasWrongFormat = iterableFileList.some((f) => {
      const nameParts = f.name.split(".");
      return !formats.includes(
        `.${nameParts[nameParts?.length - 1]?.toLowerCase()}`
      );
    });

    if (anyHasWrongFormat) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "En eller flera filer är av ett icke godkänt format",
          description: `De godkända formaten är ${formats?.join(", ")}`,
        })
      );

      return;
    }

    if (MAX_FILE_SIZE) {
      // convert maxSize from megabytes to bytes
      const maxBytes = MAX_FILE_SIZE * BYTES_PER_MEGABYTE;
      const tooBig = iterableFileList.some((file) => file.size > maxBytes);
      setError((prevErrors) => ({
        ...prevErrors,
        hasInvalidFileSize: tooBig,
      }));

      if (tooBig) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "En eller flera filer är för stora",
            description: `Max godkänd storlek på fil är ${MAX_FILE_SIZE}MB`,
          })
        );
        return;
      }
    }

    const dims = {
      minImageWidth,
      maxImageWidth,
      minImageHeight,
      maxImageHeight,
    };
    // Is there at least one dim to care about?
    if (Object.values(dims).some(Boolean)) {
      try {
        const dataUrls = await Promise.all(iterableFileList.map(loadFile));
        const images = await Promise.all(dataUrls.map(loadImage));
        const hasImageWithInvalidDims = images.some(
          (image) => !areImageDimsValid(image, dims)
        );
        // Is there an image in the collection with invalid dims or...
        // ...does user want to change the quality of the images?
        if (
          (hasImageWithInvalidDims && resizeImage) ||
          (!hasImageWithInvalidDims && imageQuality)
        ) {
          const resizedImageBlobs = await Promise.all(
            images.map((image, index) => {
              // Either we resize based on a max width/height provided by the user...
              // Or we use the image itself and just change the quality (without scaling size).
              const maxSize =
                Math.max(maxImageWidth || 0, maxImageHeight || 0) ||
                Math.max(image.width, image.height);
              const imageType = iterableFileList[index].type;
              return resizeImage(image, maxSize, imageType, imageQuality);
            })
          );
          iterableFileList = resizedImageBlobs.map((blob, index) => {
            const fileName = iterableFileList[index].name;
            return new File([blob], fileName, {
              lastModified: Date.now(),
            });
          });
          setError((prevErrors) => ({
            ...prevErrors,
            hasInvalidImage: false,
          }));
        } else if (hasImageWithInvalidDims) {
          setError((prevErrors) => ({
            ...prevErrors,
            hasInvalidImage: true,
          }));
        } else {
          setError((prevErrors) => ({
            ...prevErrors,
            hasInvalidImage: false,
          }));
        }
      } catch (err) {
        setError((prevErrors) => ({
          ...prevErrors,
          hasInvalidImage: true,
        }));
      }
    }

    setFileList(iterableFileList);
    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChange = async () => {
    if (files.length === 0) {
      return;
    } else {
      setLoading(true);

      let newFiles = [];
      if (value?.length) {
        newFiles = [...value];
      }

      for (let i = 0; i < files.length; i++) {
        const restFile = await toBase64(files[i], fileKey);
        newFiles.push(restFile);
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: newFiles },
        })
      );

      setFileList([]);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleChange();
  }, [files]);

  if (!fieldKey || !storeName || !instructions || instructions?._readOnly) {
    return null;
  }

  return (
    <>
      <SC.InputSpacing>
        <SC.InputFieldTitle>{title}</SC.InputFieldTitle>
        <SC.InputFieldDescription>{description}</SC.InputFieldDescription>
        <SC.InputFieldDescription style={{ fontSize: 12 }}>
          Tillåtna filformat: {formats?.join(", ")}
        </SC.InputFieldDescription>

        <SC.FileDropArea {...{ highlighted }} ref={dragAreaRef}>
          {loading && <OverlaySpinner />}
          {(value || [])?.map((file, idx) => (
            <SC.FileDisplay
              key={file.id || file._referenceId}
              id={file.id || file._referenceId}
              name={file.str_representation || file._tempData?.file_name}
              src={file.id ? file[fileKey]?.get : file?._tempData?.data}
            >
              <SC.RemoveFileDisplayButton onClick={() => removeFile(idx)} />

              <SC.FileDisplayName>
                {file._tempData?.file_name || file.str_representation}
              </SC.FileDisplayName>
            </SC.FileDisplay>
          ))}
          <SC.EmptyFile>
            Släpp filer här <br />
            eller
            <TextButton
              title="Lägg till fil"
              iconType="add"
              clicked={onClick}
              iconPlacement="right"
              extraStyle={{ marginRight: "auto", marginLeft: "auto" }}
            />
          </SC.EmptyFile>

          <input
            type="file"
            ref={fileInputRef}
            multiple={true}
            accept={formats}
            style={{ display: "none" }}
            onChange={(evt) => {
              const target = evt.target;
              onChange(target.files);
            }}
          />
        </SC.FileDropArea>
      </SC.InputSpacing>
    </>
  );
}

export const loadFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        if (typeof reader.result === "string") {
          resolve(reader.result);
        }
      },
      false
    );

    reader.addEventListener(
      "error",
      () => {
        reject(new Error("There was an error uploading the file"));
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  });
};

/**
 * Wraps native image loader API in a promise.
 */
export const loadImage = (dataUrl) => {
  return new Promise((resolve, reject) => {
    // create a new html image element
    const img = new Image();
    // set the image src attribute to our dataUrl
    img.src = dataUrl;
    // listen for onload event
    img.addEventListener("load", () => resolve(img), false);
    img.addEventListener("error", (ev) => {
      reject(`Error loading image: ${ev}`);
    });
  });
};

export const areImageDimsValid = (image, dims) => {
  if (dims) {
    if (dims.minImageHeight && image.height < dims.minImageHeight) {
      return false;
    }

    if (dims.minImageWidth && image.width < dims.minImageWidth) {
      return false;
    }

    if (dims.maxImageHeight && image.height > dims.maxImageHeight) {
      return false;
    }

    if (dims.maxImageWidth && image.width > dims.maxImageWidth) {
      return false;
    }
  }

  return true;
};

export const resizeImage = (img, maxSize, mime, quality = 0.92) => {
  return new Promise((resolve, reject) => {
    let { width, height } = img;
    const maxDimension = Math.max(width, height);
    if (maxDimension > maxSize) {
      const scale = maxSize / maxDimension;
      width = scale * img.width;
      height = scale * img.height;
    }
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    const blobCallback = (blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject("Could not resize. Blob not available.");
      }
    };
    if (ctx) {
      ctx.drawImage(img, 0, 0, width, height);
      ctx.canvas.toBlob(blobCallback, mime, quality);
    } else {
      reject("Could not reize. Canvas context not available.");
    }
  });
};

export const toBase64 = (file, fileKey) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);

    reader.onload = () => {
      const fileData = {
        [fileKey]: file.name,
        _tempData: {
          file_name: file.name,
          file_size: file.size,
          data: reader.result.toString(),
        },
        _referenceId: `${file.name}&${file.size}&${Math.floor(
          Math.random() * 100
        )}`,
      };

      resolve(fileData);
    };
  });
};
