import * as React from "react";

import columnDefs from "./columnDefs";

import {
  useReportTemplatePagination,
  constants,
  getAvailableReports,
  updateReportInAll,
} from "../../../store/newExcelReports";

import BaseTable from "./BaseTable";

import { buildQueryString } from "../../../store/base";
import { useDispatch, useSelector } from "react-redux";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import { useReportMethods } from "../Helpers/useReportMethods";

import { STRUCTURES, templates } from "../templates";

import ConfirmModal from "../../../components/Forms/Base/Modals/ConfirmModal";
import ModalSpinner from "../../../components/Loaders/ModalSpinner";

import { useShare } from "../../../components/ExcelReports";

import { retrieve } from "../../../store/base/store/services";
import { categoriesToStructures } from "../ReportsOverviewTable";
import { getCreatedReportsUrl } from "../../../store/newExcelReports/utils";
import { setBadgeFilterCounts } from "../../../store/newExcelReports";
import { cloneDeep } from "lodash";

export default ({
  persistantQueryString,
  persistantFilterMethod,
  isOnSharedPage,
  choosenPath,
  runEmptyActionMethod,
  setCategoriesRemoved,
}) => {
  //TODO: sometimes badgefilters fails when navigating from a report
  //back to list view.

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(true);

  const [didDelete, setDidDelete] = React.useState(false);

  const [localQuery, setLocalQuery] = React.useState("");

  const updateLocalQuery = (queryObject) => {
    setLocalQuery(cloneDeep(queryObject));
  };

  const reportMethods = useReportMethods({ setDidDelete });

  const [isSharing, setIsSharing] = React.useState(false);
  const [currentSharingReport, setCurrentSharingReport] = React.useState();

  const onRowClicked = (row) => {
    if (row.original.is_template) reportMethods.goToReportTable(row.original);
    else reportMethods.downloadAgain(row.original);
  };

  const openShareSettings = (evt, report) => {
    evt.stopPropagation();

    setCurrentSharingReport(report);

    setIsSharing(true);
  };

  const onShareSettingsUpdate = (newReportData) => {
    dispatch(updateReportInAll(newReportData));
  };

  const { ShareElement } = useShare({
    isSharing,
    setIsSharing,
    currentSharingReport,
    setCurrentSharingReport,
    setIsLoading,
    onShareSettingsUpdate,
  });

  const columns = React.useMemo(
    () =>
      columnDefs({
        isOnSharedPage,
        goToReportTable: reportMethods.goToReportTable,
        removeReport: reportMethods.removeReport,
        downloadAgain: reportMethods.downloadAgain,
        openShareSettings,
        query: localQuery,
      }),
    [
      persistantFilterMethod,
      persistantQueryString,
      isOnSharedPage,
      reportMethods,
    ]
  );

  const allAvailableReports = useSelector(
    (state) => state[constants.STORE_NAME].allAvailableReports
  );

  React.useEffect(() => {
    dispatch(getAvailableReports());
  }, []);

  React.useEffect(() => {
    if (Object.keys(allAvailableReports).length === 0) return;

    let allReportNames = Object.keys(allAvailableReports);
    let categoriesToRemove = [];

    //Check if all reports in categoriesToStructures exists.
    for (let category in categoriesToStructures) {
      let structures = categoriesToStructures[category];

      let found = 0;

      for (let struct of structures) {
        if (allReportNames.includes(struct)) {
          found++;
        }
      }

      if (found === 0) categoriesToRemove.push(category);
    }

    setCategoriesRemoved(categoriesToRemove);

    setIsLoading(false);
  }, [allAvailableReports]);

  const badgeFilterCounts = useSelector(
    (state) => state[constants.STORE_NAME].badgeFilterCounts
  );
  const templatePathing = useSelector(
    (state) => state[constants.STORE_NAME].templatePathing
  );

  const templatePathingRef = React.useRef(templatePathing);
  const badgeFilterCountsRef = React.useRef(badgeFilterCounts);

  React.useEffect(() => {
    templatePathingRef.current = templatePathing;
  }, [templatePathing]);

  React.useEffect(() => {
    badgeFilterCountsRef.current = badgeFilterCounts;
  }, [badgeFilterCounts]);

  const [fetchingBadgeFilterCountsQuery, setFetchingBadgeFilterCountsQuery] =
    React.useState("");

  const getBadgeFilterCountsCallback = React.useCallback(
    (query, cb) => {
      return getBadgeFilterCounts(query, cb);
    },
    [choosenPath.pathId, templatePathing, fetchingBadgeFilterCountsQuery]
  );

  const [lastBadgeFilterCountsCallback, setLastBadgeFilterCountsCallback] =
    React.useState({
      callback: null,
      querystring: null,
    });

  const [badgeFilterCountsCallbackQueue, setBadgeFilterCountsCallbackQueue] =
    React.useState([]);

  const doBadgeFilterCountReturn = ({ queryString, data, callback }) => {
    console.log({ queryString, data, callback });
    if (Object.keys(data).length === 0) {
      let clone = badgeFilterCountsCallbackQueue.slice();
      clone.push({ queryString, data, callback });
      return;
    }

    setLastBadgeFilterCountsCallback({
      callback: data,
      querystring: queryString,
    });

    if (badgeFilterCountsCallbackQueue.length !== 0) {
      //if pathId matches current, dont update?????

      // console.log(queryString, 190)

      for (let obj of badgeFilterCountsCallbackQueue) {
        console.log(obj, 193);
      }

      return;
    }

    return callback(data);
  };

  const getBadgeFilterCounts = async (query, callback) => {
    if (choosenPath.canChooseBadgeFilter === false) return callback({});
    //remove everything that badge filter counts doesnt care about
    //if any other keys update, new badge filters will be counted.
    delete query._page;

    let templatesQuery = buildQueryString(query);

    delete query.title__icontains;
    delete query._page_size;
    delete query.report__in;

    let queryString = buildQueryString(query);

    let isCached =
      badgeFilterCounts.hasOwnProperty(queryString) ||
      badgeFilterCountsRef.current.hasOwnProperty(queryString);

    //Might need to subsricbe to some callback with the identical fetch badge filter
    //query, so when one finished both passed callbacks will be run
    //running getBadgeFilterCounts() twice per page reload
    //don't think this is necessary, leaving the comment for now
    if (fetchingBadgeFilterCountsQuery === queryString && !isCached) {
      console.log(queryString, 196);

      console.log(
        "empty badge filterscount callback",
        lastBadgeFilterCountsCallback
      );

      if (queryString === lastBadgeFilterCountsCallback.querystring) {
        console.log("same query as last time!");
      }

      return doBadgeFilterCountReturn({ queryString, data: {}, callback });
    }
    setFetchingBadgeFilterCountsQuery(queryString);

    if (isCached) {
      setFetchingBadgeFilterCountsQuery("");
      if (badgeFilterCounts.hasOwnProperty(queryString)) {
        // setLastBadgeFilterCountsCallback({
        //   callback: badgeFilterCounts[queryString],
        //   querystring: queryString,
        // });
        // return callback(badgeFilterCounts[queryString]);
        return doBadgeFilterCountReturn({
          queryString,
          data: badgeFilterCounts[queryString],
          callback,
        });
      }
      // setLastBadgeFilterCountsCallback({
      //   callback: badgeFilterCountsRef.current[queryString],
      //   querystring: queryString,
      // });
      // return callback(badgeFilterCountsRef.current[queryString]);
      return doBadgeFilterCountReturn({
        queryString,
        data: badgeFilterCountsRef.current[queryString],
        callback,
      });
    }

    let toReturn = {};

    if (choosenPath.pathId !== query.path_id____) {
      console.log("wrong pathId", 229);
      // setLastBadgeFilterCountsCallback({
      //   callback: toReturn,
      //   querystring: queryString,
      // });
      // return callback(toReturn);
      return doBadgeFilterCountReturn({
        queryString,
        data: toReturn,
        callback,
      });
    }

    delete query.path_id____;

    query._page = 1;
    query._page_size = 0;

    const baseURL = getCreatedReportsUrl();

    let handledCats = [];

    for (let cat in categoriesToStructures) {
      if (categoriesToStructures[cat].length === 0) continue;

      //probably an unnecessary check
      if (handledCats.includes(cat)) continue;
      handledCats.push(cat);

      await new Promise((resolve) => {
        retrieve({
          url: `${baseURL}?${buildQueryString({
            ...query,
            report__in: categoriesToStructures[cat],
          })}`,
        })
          .then((res) => {
            toReturn[cat] = res.data.count !== 0 ? true : false;
            resolve();
          })
          .catch((err) => {
            toReturn[cat] = true;
            resolve();
          });
      });
    }

    const saveToRedux = () => {
      let clone = cloneDeep(badgeFilterCounts);

      clone[queryString] = toReturn;

      dispatch(setBadgeFilterCounts(clone));
    };

    //handle templates

    if (!templatePathingRef.current.hasOwnProperty(templatesQuery)) {
      saveToRedux();
      // callback(toReturn);
      // setLastBadgeFilterCountsCallback({
      //   callback: toReturn,
      //   querystring: queryString,
      // });
      // return;
      return doBadgeFilterCountReturn({
        queryString,
        data: toReturn,
        callback,
      });
    }

    let templatesToCheck = [];

    for (let key in templatePathingRef.current[templatesQuery]) {
      if (isNaN(key)) continue;
      templatesToCheck = [
        ...templatesToCheck,
        ...templatePathingRef.current[templatesQuery][key],
      ];
    }

    for (let tIndex of templatesToCheck) {
      let structure = STRUCTURES[templates.pigello[tIndex].report];

      if (toReturn[structure.category] === false) {
        toReturn[structure.category] = true;
      }
    }

    saveToRedux();

    // setLastBadgeFilterCountsCallback({
    //   callback: toReturn,
    //   querystring: queryString,
    // });

    // callback(toReturn);
    return doBadgeFilterCountReturn({
      queryString,
      data: toReturn,
      callback,
    });
  };

  return (
    <>
      {isLoading ? (
        <OverlaySpinner />
      ) : (
        <>
          <BaseTable
            constants={constants}
            columns={columns}
            paginationMethod={useReportTemplatePagination}
            rowClassName="py-3 px-2"
            hideHeaders={true}
            persistantQueryString={persistantQueryString}
            title={choosenPath?.title}
            emptyText={choosenPath.emptyText}
            emptyAction={choosenPath.emptyAction}
            runEmptyActionMethod={runEmptyActionMethod}
            getBadgeFilterCounts={getBadgeFilterCountsCallback}
            onRowClicked={onRowClicked}
            key={choosenPath.pathId}
            updateLocalQuery={updateLocalQuery}
            didDelete={didDelete}
            setDidDelete={setDidDelete}
          />

          {reportMethods.deleteReportInfo.show && (
            <ConfirmModal
              overrideConfirm={true}
              closeFunction={() => reportMethods.resetDeleteReportInfo()}
              onAccept={() => reportMethods.doRemoveReport()}
            >
              <p>
                Är du säker på att du vill radera rapporten,{" "}
                {reportMethods.deleteReportInfo.report.title ||
                  reportMethods.deleteReportInfo.report.str_representation}
                ?
              </p>
            </ConfirmModal>
          )}
          {reportMethods.deleteReportInfo.loading && (
            <ModalSpinner
              title="Raderar..."
              text={`Raderar rapport: ${
                reportMethods.deleteReportInfo.report.title ||
                reportMethods.deleteReportInfo.report.str_representation
              }...`}
            />
          )}
          {isSharing && ShareElement}
        </>
      )}
    </>
  );
};
