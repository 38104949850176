import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";

export default ({ method, isDraft }) => {
  return (
    <>
      <FormAreaTitle>Granska och slutför</FormAreaTitle>
      <FormAreaDescription>
        Kontrollera att uppgifterna stämmer. Tryck på "
        {method === "POST" || isDraft ? "Skapa" : "Uppdatera"} myndighetskravet"
        för att slutföra.
      </FormAreaDescription>
    </>
  );
};
