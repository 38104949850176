import * as React from "react";

import * as SharedStyles from "../../sharedStyles";
import FullAreaTable from "../../Tables/RoundingErrandSettings/Detail/Areas/FullTable";
import { buildQueryString } from "../../../store/base";
import { OverviewTitle } from "../OverviewInfo/styles";

export default ({ realestates, roundingSetting, openAreaModal }) => {
  const areaQuery = buildQueryString({
    roundingerrandsetting: roundingSetting?.id,
  });

  return (
    <SharedStyles.InnerBox>
      <OverviewTitle>Områden</OverviewTitle>

      <FullAreaTable
        hideTitle
        hideExport
        hideFilters
        hideColumns
        hideSearch
        persistantQueryString={areaQuery}
        persistantFilterMethod={(area) => {
          return roundingSetting?.areas?.find((a) => a.id === area.id);
        }}
        openAreaModal={openAreaModal}
        realestates={realestates}
      />
    </SharedStyles.InnerBox>
  );
};
