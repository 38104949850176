import moment from "moment";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { constants } from "../../../../../store/roundingErrands";
import TimeSuggestion from "../../../../Details/BaseErrand/TimeSuggestion";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { TimePicker } from "../../../Base/Fields";
import DurationField from "../../../Base/Fields/DurationField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const planned_start = useFormInstanceField({
    storeName,
    fieldKey: "planned_start",
  });
  const expected_duration = useFormInstanceField({
    storeName,
    fieldKey: "expected_duration",
  });

  const setEndDate = (planned_start, expected_duration) => {
    const momentStart = moment(planned_start);

    const durationInMinutes =
      moment.duration(expected_duration).asMilliseconds() / 1000 / 60;

    const endDate = momentStart
      .add(durationInMinutes, "minutes")
      .format("YYYY-MM-DD HH:mm");

    dispatch(
      updateActiveFormInstance({
        storeName: storeName,
        data: {
          planned_end: endDate,
        },
      })
    );
  };

  React.useEffect(() => {
    if (planned_start && expected_duration) {
      setEndDate(planned_start, expected_duration);
    }
  }, [planned_start, expected_duration]);

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Planering av utförande</OverviewTitle>
          <OverviewSubtitle>
            Inställningar för tidsplanering gällande ärendet
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TimeSuggestion storeName={storeName} method={method} />
      <br />
      <DurationField
        title="Förväntad arbetstid"
        storeName={storeName}
        fieldKey={"expected_duration"}
        method={method}
        extraStyle={{ maginTop: "12px" }}
      />
      <TimePicker
        title="Planerat slut"
        storeName={storeName}
        fieldKey={"planned_end"}
        method={method}
      />
    </>
  );
};
