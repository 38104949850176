import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";

// store, state
import { constants } from "../../../../../store/roundingErrandSettings";
import { DatePicker } from "../../../Base/Fields";
import { useFormInstanceField } from "../../../../../store/base";
import Interval, {
  INTERVAL_MAP_GOV,
} from "../../../Base/Fields/CustomFields/Interval";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const mode = useFormInstanceField({
    storeName,
    fieldKey: "interval.mode",
  });

  return (
    <>
      <FormAreaTitle>Schemaläggning och intervall</FormAreaTitle>
      <FormAreaDescription>
        Schemaläggning av förknippat praktiskt utförande
      </FormAreaDescription>

      <Interval
        mode={mode}
        storeName={storeName}
        method={method}
        config={INTERVAL_MAP_GOV}
      />

      <DatePicker
        storeName={storeName}
        method={method}
        fieldKey={"interval_start"}
        title="Välj vilket datum systemet ska börja generera ärenden"
      />
    </>
  );
};
