import * as React from "react";

export default ({ realestates }) => [
  {
    Header: "Område",
    accessor: "title",
    Cell: ({ value }) => {
      return <div>{value}</div>;
    },
  },
  {
    Header: "Antal komponenter",
    accessor: "components",
    Cell: ({ value }) => {
      return <div>{value?.length}</div>;
    },
  },
  {
    Header: "Fastigheter",
    accessor: "realestates",
    Cell: ({ value }) => {
      const ids = value?.map((r) => r.id);

      const realestateNames = realestates
        ?.filter((r) => ids.includes(r.id))
        .map((estate) => estate.str_representation)
        .join(", ");
      return <div>{realestateNames || "-"}</div>;
    },
  },
];
