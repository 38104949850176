import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import { DetailInnerWrapper, DetailPageBox } from "../../../sharedStyles";
import RoomsAndComponents from "../../../TechnicalManagement/Component/RoomsAndComponentsMassForm";
import { PrimaryButton, TextButton } from "../../Base/Buttons";
import {
  constants,
  updateData,
  create,
} from "../../../../store/massConfigPremisesComponents";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import useQuery from "../../../utils/useQuery";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import { MASS_CONFIG_OBJECT_TYPES_KEYS } from "../FlowForm/Chapters/Type";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";

export default function RoomComponentForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const data = useSelector((state) => state[constants.STORE_NAME].data);
  const instance = useSelector((state) => state[constants.STORE_NAME].instance);
  const restrictions = useSelector(
    (state) => state[constants.STORE_NAME].exclusionRules
  );
  const q = useQuery();
  const query = q.get("query");
  const type = q.get("type");
  const { goBack, push } = useHistory();

  const updateRooms = (newData) => {
    dispatch(updateData({ newData }));
  };

  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const onCreate = () => {
    setLoading(true);
    dispatch(
      create({
        type,
        query,
        data,
        restrictions,
        successCallback: (data) => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Komponenterna lades till på de valda objekten",
            })
          );

          push(
            `${constants.SUMMARY_PATH}?rooms=${data?.rooms}&components=${data?.components}`
          );
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Något gick fel",
              description: "Komponenterna kunde ej läggas till",
            })
          );
        },
      })
    );
  };

  return (
    <>
      {loading && <OverlaySpinner />}
      <ConfirmationModal
        isOpen={confirmOpen}
        title="Bekräfta skapande"
        renderContent={() => (
          <div>
            Rummen och komponenterna kommer att läggas till i alla valda
            objekten, exklusive de som filtreras bort med hjälp av eventuella
            exkluderingsregler.
          </div>
        )}
        closeFunction={() => setConfirmOpen(false)}
        acceptCallback={() => onCreate()}
      />

      <DetailInnerWrapper>
        <DetailPageBox>
          <TextButton
            title="Tillbaka"
            iconType="arrow-back"
            extraStyle={{ marginBottom: 24 }}
            clicked={() => goBack()}
          />
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Rum & Komponenter</OverviewTitle>
              <OverviewSubtitle style={{ marginRight: 24 }}>
                Bygg upp den strukturen av rum & komponenter som ska appliceras
                på de valda objekten. Observerera att exkluderingsreglerna kan
                leda till att rum och/eller objekt ignoreras för vissa objekt.
                Klicka på "Spara" för att lägga till rum/komponent-modellen på
                de valda objekten.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
            <PrimaryButton
              disabled={!data?.length}
              title="Spara"
              clicked={() => setConfirmOpen(true)}
            />
          </OverviewTitleWrapper>

          <RoomsAndComponents
            rooms={data}
            isParking={instance.type === MASS_CONFIG_OBJECT_TYPES_KEYS.PARKING}
            updateFunction={updateRooms}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
