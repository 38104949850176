import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

import {
  buildQueryString,
  updateActiveFormInstance,
} from "../../../store/base";
import {
  useRoundingErrandSetting,
  constants,
  update,
  editUrl,
} from "../../../store/roundingErrandSettings";
import { detailUrl as userDetailUrl } from "../../../store/users";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { useFilteredNotes } from "../../../store/notes";

import NextErrandInfo from "../../../components/Details/RoundingErrandSetting/NextErrandInfo";
import Areas from "../../../components/Details/RoundingErrandSetting/Areas";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";

//styling
import { LinkedObject } from "../../../components/Displays";
import BasicTable from "../../../components/Billecta/Table/BasicTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import StandardModal from "../../../components/Modals/StandardModal";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useFilteredBuildings } from "../../../store/buildings";
import { getIntervalStr } from "../../../components/Displays/InvoicingHelpers";

function GovErrandSettingOverview() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { settingId } = useParams();

  const [govErrandSetting, govErrandSettingLoading] =
    useRoundingErrandSetting(settingId);
  const [realEstates, realEstatesLoading] = useFilteredRealEstates();
  const [buildings, buildingsLoading] = useFilteredBuildings();

  const [autoGenOn, setAutoGenOn] = React.useState(false);
  const [pausedLoading, setPausedLoading] = React.useState(false);
  const [areaModalOpen, setAreaModalOpen] = React.useState(false);
  const [activeAreaInfo, setActiveAreaInfo] = React.useState(null);

  const notesQ = buildQueryString({
    id__in: govErrandSetting?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const getBuildingName = (buildingId) => {
    const building = buildings?.find((b) => b.id === buildingId);
    return building?.str_representation || "Ingen byggnad hittades";
  };

  const getRealEstateName = (realEstateId) => {
    const realEstate = realEstates?.find((r) => r.id === realEstateId);
    return realEstate?.str_representation || "Ingen fastighet hittades";
  };

  const data = React.useMemo(() => {
    return activeAreaInfo?.realestates;
  }, [activeAreaInfo, realEstates]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Namn",
        accessor: "id",
        Cell: ({ value }) => {
          const match = realEstates?.find((r) => r.id === value);
          return <div>{match?.str_representation || "-"}</div>;
        },
      },
      {
        Header: "Adress",
        accessor: "_id",
        Cell: ({ row }) => {
          const id = row?.original?.id;
          const match = realEstates?.find((r) => r.id === id);
          return <div>{match?.addresses[0]?.base || "-"}</div>;
        },
      },
    ],
    [realEstates]
  );

  const componentData = React.useMemo(() => {
    return activeAreaInfo?.components;
  }, [activeAreaInfo, realEstates, buildings]);

  const componentColumns = React.useMemo(
    () => [
      {
        Header: "Namn",
        accessor: "str_representation",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Beskrivning",
        accessor: "description",
        Cell: ({ value }) => {
          const shortnedText = value?.substring(0, 12);
          if (shortnedText) {
            return <div>{shortnedText}...</div>;
          } else {
            return <div> - </div>;
          }
        },
      },
      {
        Header: "Byggnad",
        accessor: "building",
        Cell: ({ value }) => {
          return <div>{value?.str_representation || "-"}</div>;
        },
      },
      {
        Header: "Fastighet",
        accessor: "realestates",
        Cell: ({ value }) => {
          const matches = realEstates
            ?.filter((r) => value.includes(r.id))
            ?.map((rr) => rr.str_representation)
            ?.join(", ");
          return <div>{matches || "-"}</div>;
        },
      },
    ],
    [realEstates]
  );

  const renderComponents = () => {
    const items = activeAreaInfo?.components?.map((c) => {
      return (
        <>
          <div
            style={{
              border: "thin solid black",
              padding: "12px",
              margin: "0 auto",
              marginBottom: "12px",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ flexDirection: "column" }}>
              <BodyText style={{ marginRight: "12px" }}>
                Komponent: {c.title}
              </BodyText>
              <BodyText style={{ marginRight: "12px", paddingRight: "12px" }}>
                Beskrivning: {c.description || "Ingen beskrivning finns"}
              </BodyText>
              <BodyText style={{ marginRight: "12px" }}>
                Byggnad: {getBuildingName(c.building?.id)}
              </BodyText>
              {c.building && (
                <BodyText>
                  Fastighet: {getRealEstateName(c.realestates[0]?.id)}
                </BodyText>
              )}
            </div>
          </div>
        </>
      );
    });
    return items;
  };

  const preProcess = () => {
    const clone = cloneDeep(govErrandSetting);
    if (clone.paused) {
      clone.paused = false;
    } else {
      clone.paused = true;
    }
    return clone;
  };

  const onSuccess = (data, returnedData) => {
    setPausedLoading(false);
    setAutoGenOn(returnedData?.paused);
  };

  const handleTogglePaused = () => {
    setPausedLoading(true);
    dispatch(
      update({
        id: govErrandSetting?.id,
        preProcess: preProcess,
        successCallback: onSuccess,
        errorCallback: () => setPausedLoading(false),
      })
    );
  };

  const handleOpenAreaModal = (row) => {
    setActiveAreaInfo(row.original);
    setAreaModalOpen(true);
  };

  React.useEffect(() => {
    dispatch(updateActiveFormInstance({ storeName, data: govErrandSetting }));
    if (govErrandSetting) {
      setAutoGenOn(govErrandSetting?.paused);
    }
  }, [govErrandSetting]);

  return (
    <>
      {pausedLoading && <OverlaySpinner />}

      <StandardModal
        isOpen={areaModalOpen}
        closeFunction={() => setAreaModalOpen(false)}
        withActionBar
        title="Områdesöversikt"
        actionBarCancelTitle="Stäng"
      >
        <OverviewTitleWrapper
          style={{
            marginBottom: "40px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <OverviewTitle small>
            Det ingår {activeAreaInfo?.realestates?.length} fastigheter i
            området
          </OverviewTitle>
          <BasicTable
            data={data}
            columns={columns}
            onRowClicked={() => {}}
            tableId="arearealestatetable"
            hideSearch
          />
        </OverviewTitleWrapper>
        <OverviewTitle small>
          Det ingår {activeAreaInfo?.components?.length} komponenter i området
        </OverviewTitle>

        <BasicTable
          data={componentData}
          columns={componentColumns}
          onRowClicked={() => {}}
          tableId="comptable"
          hideSearch
        />
      </StandardModal>

      <DetailInnerWrapper>
        <DetailPageBox style={{ flex: 1 }}>
          <OverviewTitleWrapper>
            <OverviewTitle>Myndighetskrav</OverviewTitle>
          </OverviewTitleWrapper>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1, marginRight: 24 }}>
              <DetailInfo
                infoObj={getInfoObj(govErrandSetting)}
                extraChapterStyles={{ marginRight: 0 }}
              />
              <InnerBox>
                <Notes
                  notes={notes}
                  contentType={"errands.roundingerrandsetting"}
                  objectId={govErrandSetting?.id}
                  title="Anteckningar"
                />
              </InnerBox>
            </div>
            <div style={{ flex: 1, flexDirection: "column" }}>
              <NextErrandInfo
                setting={govErrandSetting}
                autoGenOn={autoGenOn}
                onToggle={handleTogglePaused}
              />
              <Areas
                roundingSetting={govErrandSetting}
                openAreaModal={handleOpenAreaModal}
                realestates={realEstates}
              />
            </div>
          </div>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const getResponsibleTrustee = (trustee) => {
  if (trustee) {
    return <LinkedObject obj={trustee} urlMethod={userDetailUrl} />;
  }
  return <div>Ingen ansvarig hittades</div>;
};

function getInfoObj(govErrandSetting, infoObjProps) {
  const getContactInfo = () => {
    const user = infoObjProps?.reportedBy;

    return {
      phone: user?.phone || "-",
      email: user?.email || "-",
    };
  };

  let data = [
    {
      title: "Typ",
      value: govErrandSetting?.kind_display,
    },
    {
      title: "Titel",
      value: govErrandSetting?.title || govErrandSetting?.str_representation,
    },
    {
      title: "Typ av interval",
      value: govErrandSetting?.interval?.mode_display,
    },
    {
      title: "Interval",
      value: getIntervalStr(govErrandSetting?.interval),
    },
    {
      title: "Interval påbörjades",
      value: govErrandSetting?.interval_start,
    },
    {
      title: "Ansvarig",
      value: getResponsibleTrustee(govErrandSetting?.responsible_trustee),
    },
    {
      title: "Prioriterad roll",
      value: govErrandSetting?.performers?.[0]?.str_representation || "-",
    },
  ];

  const infoObj = {
    Information: data,
  };

  return infoObj;
}

export default GovErrandSettingOverview;
