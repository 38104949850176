import * as React from "react";

import CreatableSelect from "react-select/creatable";

export default ({
  value,
  options,
  getOptionLabel,
  getOptionValue,
  onChange,
  addOption,
  nextId,
  isValidNewOption,
  menuPlacement,
}) => {
  const [isLoading, setLoading] = React.useState(false);
  //   const [options, setOptions] = React.useState(defaultOptions);

  const setValue = (val) => {
    onChange(val);
  };

  const handleChange = (newValue, actionMeta) => {
    setValue(newValue);
  };

  const handleCreate = (inputValue) => {
    setLoading(true);

    setTimeout(() => {
      const newOption = {
        title: inputValue,
        _internalId: nextId,
      };
      setLoading(false);
      addOption(newOption);
      setValue(newOption);
    }, 1000);
  };

  const displayedPlaceholder = isLoading ? "" : "Välj eller skapa ny";

  return (
    <CreatableSelect
      menuPlacement={menuPlacement || "top"}
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={handleChange}
      onCreateOption={handleCreate}
      getNewOptionData={(inputValue, optionLabel) => {
        return {
          title: optionLabel,
          id: nextId,
          __isNew__: true,
        };
      }}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      options={options}
      value={value}
      loadingMessage="Laddar..."
      noOptionsMessage={() => "Skriv för att skapa"}
      placeholder={displayedPlaceholder}
      formatCreateLabel={(input) => `Skapa ${input}`}
      isValidNewOption={isValidNewOption}
    />
  );
};
