//#permisisonchoices

export const TF_PERMS = {
  reporterrand: [
    "view_can_reporterrand",
    "add_can_reporterrand",
    "change_can_reporterrand",
    "delete_can_reporterrand",
  ],
  inspectionerrand: [
    "view_can_inspectionerrand",
    "add_can_inspectionerrand",
    "change_can_inspectionerrand",
    "delete_can_inspectionerrand",
  ],
  roundingerrand: [
    "view_can_roundingerrand",
    "add_can_roundingerrand",
    "change_can_roundingerrand",
    "delete_can_roundingerrand",
  ],
  goverrand: [
    "view_can_goverrand",
    "add_can_goverrand",
    "change_can_goverrand",
    "delete_can_goverrand",
  ],
};

export const REALESTATE_PERMS = [
  "view_can_realestate",
  "add_can_realestate",
  "change_can_realestate",
  "delete_can_realestate",
];

export const BRF_PERMS = [
  "view_can_brf",
  "add_can_brf",
  "change_can_brf",
  "delete_can_brf",
];

export const COMPANY_PERMS = [
  "view_can_company",
  "add_can_company",
  "change_can_company",
  "delete_can_company",
];

export const BASEOBJECT_PERMS = [
  "view_can_baseobject",
  "add_can_baseobject",
  "change_can_baseobject",
  "delete_can_baseobject",
];

export const PARKRING_PERMS = [
  "view_can_parking",
  "add_can_parking",
  "change_can_parking",
  "delete_can_parking",
];

export const TENANTS_PERMS = [
  "view_can_tenant",
  "add_can_tenant",
  "change_can_tenant",
  "delete_can_tenant",
];

export const CONTRACTS_PERMS = [
  "view_can_contract",
  "add_can_contract",
  "change_can_contract",
  "delete_can_contract",
];

export const TENANTPORTAL_PERMS = [
  "view_can_tenantportal",
  "add_can_tenantportal",
  "change_can_tenantportal",
  "delete_can_tenantportal",
];

export const LEADS_PERMS = [
  "view_can_lead",
  "add_can_lead",
  "change_can_lead",
  "delete_can_lead",
];

export const MARKET_PERMS = [
  "view_can_market",
  "add_can_market",
  "change_can_market",
  "delete_can_market",
];

export const USER_PERMS = [
  "view_can_user",
  "add_can_user",
  "change_can_user",
  "delete_can_user",
];

export const PERMISSION_PERMS = [
  "view_can_permissions",
  "add_can_permissions",
  "change_can_permissions",
  "delete_can_permissions",
];

export const BILLING_PERMS = [
  "view_can_billing",
  "add_can_billing",
  "change_can_billing",
  "delete_can_billing",
];

export const E_SIGNING_PERMS = [
  "view_can_e_signing",
  "add_can_e_signing",
  "change_can_e_signing",
  "delete_can_e_signing",
];

export const FASTDOC_PERMS = [
  "view_can_fastdoc",
  "add_can_fastdoc",
  "change_can_fastdoc",
  "delete_can_fastdoc",
];

export const HOMEQ_PERMS = [
  "view_can_homeq",
  "add_can_homeq",
  "change_can_homeq",
  "delete_can_homeq",
];

export const AVYTMPL_PERMS = [
  "view_can_avytmpl",
  "add_can_avytmpl",
  "change_can_avytmpl",
  "delete_can_avytmpl",
];

export const SBF_PERMS = [
  "view_can_sbf",
  "add_can_sbf",
  "change_can_sbf",
  "delete_can_sbf",
];

export const FORTNOX_PERMS = [
  "view_can_fortnox",
  "add_can_fortnox",
  "change_can_fortnox",
  "delete_can_fortnox",
];

export const IMD_PERMS = [
  "view_can_imd",
  "add_can_imd",
  "change_can_imd",
  "delete_can_imd",
];

export const ANONYMIZATION = [
  "view_can_anonymization",
  "add_can_anonymization",
  "change_can_anonymization",
  "delete_can_anonymization",
];

export const CONFIG_CENTER = [
  "view_can_configcenter",
  "",
  "change_can_configcenter",
  ""
];

export const KEYS = [
  "view_can_keys",
  "add_can_keys",
  "change_can_keys",
  "delete_can_keys",
];

export const INSIGHTS = [
  "view_can_insights",
  "add_can_insights",
  "change_can_insights",
  "delete_can_insights",
];