import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { useAllPermissionCheck } from "../../../store/base";
import SettingModalForm from "../../../components/Forms/RoundingErrandSetting/ChapterForm/ModalForm";

import SettingsTable from "../../../components/Tables/RoundingErrandSettings/FullTable";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

export default function Settings() {
  const { filteredRealEstates } = useActiveFilters();

  const [createSettingOpen, setCreateSettingOpen] = React.useState(false);

  const canAdd = useAllPermissionCheck([
    "add_can_roundingerrand",
    "allow_errand_roundings",
  ]);

  const persistantQuerySettings = {
    realestate_ids: filteredRealEstates,
    kind__isnull: true,
  };

  return (
    <>
      {canAdd && (
        <SettingModalForm
          method="POST"
          isOpen={createSettingOpen}
          onCheckout={() => setCreateSettingOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Ronderingstyper</OverviewTitle>
            {canAdd && (
              <PrimaryButton
                title="Skapa ronderingstyp"
                clicked={() => setCreateSettingOpen(true)}
              />
            )}
          </OverviewTitleWrapper>
          <SettingsTable persistantQuery={persistantQuerySettings} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
