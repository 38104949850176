import * as React from "react";

import { Confirm, RoundingType, Details, Planning } from "./Chapters";

export default ({ key, method, apartmentId, indpId, settingId }) => {
  switch (key) {
    case "TYPE":
      return <RoundingType {...{ method, settingId }} />;

    case "DESCRIPTION":
      return <Details {...{ method, apartmentId, indpId }} />;

    case "PLANNING":
      return <Planning {...{ method }} />;
    default:
      return <Confirm />;
  }
};
