import * as React from "react";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import BrfCompanyModal from "../../../components/Forms/BrfCompany/ChapterForm/ModalForm";
import { useParams, useHistory } from "react-router";
import {
  buildQueryString,
  updateActiveFormInstance,
  useAllPermissionCheck,
} from "../../../store/base";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { useFilteredNotes } from "../../../store/notes";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { useRealEstatePaginationCount } from "../../../store/realEstates";
import { useBrfCompany, ku55Url } from "../../../store/brfCompanies";
import PlannedRaisesForm from "../../../components/Forms/BrfPlannedRaises/BrfPlannedRaisesForm";

import { useDispatch } from "react-redux";
import {
  useFilteredBrfPlannedRaises,
  constants as brfCompanyPlannedFeeRaisesConstants,
} from "../../../store/brfPlannedRaises";
import PremisesTable from "../../../components/PremisesTable/PremisesTable";
import { toMoneyString } from "../../../components/utils/stringUtils";
import { detailUrl as userDetailUrl } from "../../../store/users";
import { LinkedObject } from "../../../components/Displays";
import RealEstateTable from "../../../components/Tables/RealEstate/FullTable";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";

export default function BrfCompanyOverview() {
  const dispatch = useDispatch();
  const { brfCompanyId } = useParams();
  const [editOpen, setEditOpen] = React.useState(false);
  const [plannedFeeRaisesFormOpen, setPlannedFeeRaisesFormOpen] =
    React.useState(false);
  const [plannedFeeRaisesEditFormOpen, setPlannedFeeRaisesEditFormOpen] =
    React.useState(false);
  const [deletePlannedFeeRaisesOpen, setDeletePlannedFeeRaisesOpen] =
    React.useState(false);
  const [brfCompany] = useBrfCompany(brfCompanyId);

  const { push } = useHistory();

  const plannedFeeRaisesQuery = buildQueryString({
    brf_company__in: brfCompanyId,
  });
  const [plannedFeeRaises] = useFilteredBrfPlannedRaises(plannedFeeRaisesQuery);

  const canEdit = useAllPermissionCheck([
    "change_can_company",
    "change_can_brf",
  ]);

  const notesQ = buildQueryString({
    id__in: brfCompany?.company?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const [realEstateCount] = useRealEstatePaginationCount({
    filters: { company: brfCompany?.company?.id },
  });

  const companyId = brfCompany?.company?.id;
  const realEstateQuery = {
    company: companyId,
  };

  const onEdit = () => {
    setEditOpen(true);
  };

  const handleAddFeeRaise = () => {
    dispatch(
      updateActiveFormInstance({
        storeName: brfCompanyPlannedFeeRaisesConstants.STORE_NAME,
        data: {
          brf_company: {
            id: brfCompanyId,
          },
        },
      })
    );

    setPlannedFeeRaisesFormOpen(true);
  };

  const handlePlannedFeeRowClicked = (id) => {
    const plannedFeeRaise = plannedFeeRaises.find((pf) => pf.id === id);

    dispatch(
      updateActiveFormInstance({
        storeName: brfCompanyPlannedFeeRaisesConstants.STORE_NAME,
        data: plannedFeeRaise,
      })
    );

    setPlannedFeeRaisesEditFormOpen(id);
  };

  return (
    <>
      <DeleteModal
        isOpen={!!deletePlannedFeeRaisesOpen}
        instance={deletePlannedFeeRaisesOpen}
        constants={brfCompanyPlannedFeeRaisesConstants}
        closeFunction={() => setDeletePlannedFeeRaisesOpen(false)}
      />

      <PlannedRaisesForm
        isOpen={plannedFeeRaisesFormOpen}
        onCheckout={() => setPlannedFeeRaisesFormOpen(false)}
        method={"POST"}
      />

      <PlannedRaisesForm
        isOpen={plannedFeeRaisesEditFormOpen}
        onCheckout={() => setPlannedFeeRaisesEditFormOpen(false)}
        method={"PATCH"}
        id={plannedFeeRaisesEditFormOpen}
      />

      {editOpen && (
        <BrfCompanyModal
          method="PATCH"
          onCheckout={() => setEditOpen(false)}
          id={brfCompanyId}
          instance={brfCompany}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              flex: 1,
              maxWidth: "70%",
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              marginRight: 12,
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Översikt</OverviewTitle>

                {canEdit && <PrimaryButton title="Redigera" clicked={onEdit} />}
              </OverviewTitleWrapper>

              <DetailInfo infoObj={getInfoObj(brfCompany, realEstateCount)} />
            </DetailPageBox>

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Fastigheter under föreningen</OverviewTitle>
              </OverviewTitleWrapper>

              <RealEstateTable persistantQuery={realEstateQuery} />
            </DetailPageBox>
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              maxWidth: "29%",
              flexDirection: "column",
              alignSelf: "flex-start",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>
                    Planerade avgiftshöjningar
                  </OverviewTitle>
                  <OverviewSubtitle>
                    OBS: Planerade avgiftshöjningar är enbart till för att visas
                    på mäklarbilden och tar ingen effekt i systemet i praktiken.
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>

              {plannedFeeRaises?.length ? (
                <PremisesTable
                  onRowClicked={handlePlannedFeeRowClicked}
                  headers={["Datum", "Höjning i SEK/månad", "Ta bort"]}
                  rows={plannedFeeRaises.map((pf) => {
                    return [
                      pf.date,
                      pf.increase.toLocaleString("sv"),
                      <TextButton
                        red
                        title="Ta bort"
                        clicked={() => setDeletePlannedFeeRaisesOpen(pf)}
                        iconType="close"
                        iconPlacement="right"
                      />,
                    ];
                  })}
                  onClickRowMapping={plannedFeeRaises.map((pf) => pf.id)}
                />
              ) : (
                <BodyText style={{ marginBottom: 12 }}>
                  Inga avgiftshöjningar planerade
                </BodyText>
              )}

              <PrimaryButton title="Lägg till" clicked={handleAddFeeRaise} />
            </DetailPageBox>

            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitleWithSubtitleWrapper>
                  <OverviewTitle small>KU55</OverviewTitle>
                  <OverviewSubtitle>
                    Generera KU55 underlag för föreningen
                  </OverviewSubtitle>
                </OverviewTitleWithSubtitleWrapper>
              </OverviewTitleWrapper>
              <PrimaryButton
                title="Starta flödet"
                clicked={() => push(ku55Url({ id: brfCompanyId }))}
              />
              <OverviewSubtitle>
                Testa/Verifiera dina KU55 filer via{" "}
                <a
                  href="https://www1.test.skatteverket.se/aiwa23/ai_etjanst/pages/upload/upload.xhtml?method=test"
                  target="_blank"
                >
                  Skatteverkets testtjänst
                </a>
              </OverviewSubtitle>
            </DetailPageBox>

            <DetailPageBox>
              <Notes
                notes={notes}
                contentType="standard.company"
                objectId={brfCompany?.company?.id}
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj(brfCompany, realEstateCount) {
  const infoObj = {
    Föreningsinfo: [
      {
        title: "Namn",
        value: brfCompany?.str_representation,
      },
      {
        title: "Kontaktperson",
        value: brfCompany?.company?.contact_person ? (
          <LinkedObject
            obj={brfCompany?.company?.contact_person}
            urlMethod={userDetailUrl}
          />
        ) : (
          "Ej angiven"
        ),
      },
      {
        title: "Orgnr",
        value: brfCompany?.company?.orgnr || "-",
      },
      {
        title: "Föreningens adress",
        value: brfCompany?.company?.address?.base?.split(",")?.[0] || "-",
      },
      {
        title: "Pantsättningsavgift (SEK)",
        value: toMoneyString(brfCompany?.pledge_fee),
      },
      {
        title: "Pantsättningsavgift Betalas av",
        value: brfCompany?.pledge_fee_paid_by_display ?? "-",
      },
      {
        title: "Pantsättningsavgift Produkt",
        value: brfCompany?.pledge_fee_product?.str_representation ?? "-",
      },
      {
        title: "Överlåtelseavgift (SEK)",
        value: toMoneyString(brfCompany?.transfer_fee),
      },
      {
        title: "Överlåtelseavgift Betalas av",
        value: brfCompany?.transfer_fee_paid_by_display ?? "-",
      },
      {
        title: "Överlåtelseavgift Produkt",
        value: brfCompany?.transfer_fee_product?.str_representation ?? "-",
      },
      {
        title: "Upplåtelseavgift Betalas av",
        value: brfCompany?.placement_fee_paid_by_display ?? "-",
      },
    ],
    Övrigt: [
      {
        title: "Antal fastigheter",
        value: realEstateCount,
      },
    ],
  };

  return infoObj;
}
