import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import {
  updateActiveFormInstance,
  setActiveFormInstance,
  usePermissionCheck,
} from "../../store/base";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import {
  useRoundingErrand,
  constants,
  overviewUrl,
} from "../../store/roundingErrands";
import { getErrandStatus } from "../../components/utils/errandUtils";

//components & styles
import ErrandOverview from "./detail/Overview";
import ErrandProtocol from "./detail/Protocol";
import ModalForm from "../../components/Forms/GovErrand/ChapterForm/ModalForm";
import DelegateModalForm from "../../components/Forms/GovErrand/DelegateErrandChapterForm/ModalForm";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { ErrandStatusLabel } from "../../components/Lists/Base/CompleteList/styles";

function GovErrandDetail() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { govErrandId } = useParams();
  const { url, path } = useRouteMatch();
  const { replace, push } = useHistory();

  const { id, user_type } = useSelector((state) => state.app?.user);

  const [govErrand, govErrandLoading] = useRoundingErrand(govErrandId);

  const [editErrandOpen, setEditErrandOpen] = React.useState(false);
  const [delegateErrandOpen, setDelegateErrandOpen] = React.useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  usePermissionRedirect(["view_can_goverrand", "allow_errand_gov"]);

  const canEdit = usePermissionCheck("change_can_goverrand");
  const canDelete = usePermissionCheck("delete_can_goverrand");

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Ärende",
      component: ErrandOverview,
    },
  ];

  if (govErrand?.status === 3 || govErrand?.status === 4) {
    SUB_PAGES.push({
      path: "/protocol",
      label: "Protokoll",
      component: ErrandProtocol,
    });
  }

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
  }));

  const onEdit = () => {
    const errandClone = cloneDeep(govErrand);
    dispatch(updateActiveFormInstance({ storeName, data: errandClone }));

    setEditErrandOpen(true);
  };

  const onExecute = () => {
    push(`/gov-errands/execute/${govErrand?.id}`);
  };

  const canUserExecuteGovErrand = () => {
    let currentPerformer = false;
    if (govErrand?.performer) {
      currentPerformer = govErrand?.performer?.user?.id;
    }

    //Errand already done
    if (govErrand?.status === 3 || govErrand?.status === 4) {
      return false;
    }

    if (!canEdit) {
      return false;
    }
    if (user_type === 0 || user_type === 1) {
      return canEdit;
    }
    if (currentPerformer === id) {
      return canEdit;
    }
  };

  const canDelegateErrand = () => {
    //errand already done
    if (govErrand?.status === 3 || govErrand?.status === 4) {
      return false;
    }

    //is admin or standarduser with correct permissions
    if (user_type === 0 || user_type === 1) {
      return canEdit;
    }
  };

  const onDelegate = () => {
    const errandClone = cloneDeep(govErrand);
    dispatch(updateActiveFormInstance({ storeName, data: errandClone }));
    setDelegateErrandOpen(true);
  };

  const handleActions = [];

  if (canEdit) {
    handleActions.push({
      name: "Registrera besiktningprotokoll",
      onClick: onExecute,
      hidden: !canUserExecuteGovErrand(),
    });
  }

  if (canEdit) {
    handleActions.push(
      {
        name: "Delegera ärende",
        onClick: onDelegate,
        hidden: !canDelegateErrand(),
      },
      {
        name: "Redigera",
        onClick: onEdit,
      }
    );
  }

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteModalOpen(true),
      isDelete: true,
    });
  }

  const { status, display } = getErrandStatus({
    status: govErrand?.status,
    plannedStart: govErrand?.planned_start,
    plannedEnd: govErrand?.planned_end,
    actualStart: govErrand?.execute_start,
    actualEnd: govErrand?.execute_end,
  });

  const renderInfoPills = () => {
    return (
      <>
        <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>
      </>
    );
  };

  React.useEffect(() => {
    return () => {
      dispatch(setActiveFormInstance({ storeName, data: {} }));
    };
  }, []);

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        closeFunction={() => setDeleteModalOpen(false)}
        instance={govErrand}
        constants={constants}
        deletedCallback={() => replace(overviewUrl({ isGov: true }))}
      />

      {canEdit && (
        <ModalForm
          method="PATCH"
          id={govErrand?.id}
          isOpen={editErrandOpen}
          onCheckout={() => setEditErrandOpen(false)}
        />
      )}

      {delegateErrandOpen && (
        <DelegateModalForm
          method="PATCH"
          id={govErrand?.id}
          isOpen={delegateErrandOpen}
          onCheckout={() => setDelegateErrandOpen(false)}
        />
      )}

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Ärende ${govErrand?.str_representation || "laddar..."}`}
          breadCrumbs={[
            { url: "/gov-errands", label: "Myndighetskrav" },
            { label: govErrand?.str_representation || "Laddar..." },
          ]}
          {...{ subPages: subPageLinks, handleActions, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}

export default GovErrandDetail;
