import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import { useMeasureType } from "../../store/IMDMeasureType";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import IMDDetailOverview from "./measureTypeDetail/Overview";
import MeasureTypeSensors from "./measureTypeDetail/Sensors";

export default function IMDDetail() {
  const { path, url } = useRouteMatch();
  const { id } = useParams();

  const [measureType, loading] = useMeasureType(id);

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Översikt",
      component: IMDDetailOverview,
    },
    {
      path: "/senors",
      label: "Sensorer under mätartyp",
      component: MeasureTypeSensors,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  return (
    <DetailLayoutWrapper>
      <DetailPageHeaderMenu
        title={`Mätartyp${
          measureType?.str_representation
            ? ` - ${measureType.str_representation}`
            : ""
        }`}
        breadCrumbs={[
          {
            label: "IMD",
            url: "/imd",
          },
          {
            label: "Detaljsida",
          },
        ]}
        eventContentType="imd.measuretype"
        eventObjectId={id}
        eventIdQueryStr="measuretype"
        {...{ subPages: subPageLinks }}
      />

      <Switch>
        {SUB_PAGES.map((sp) => (
          <Route
            key={sp.path}
            path={`${path}${sp.path}`}
            component={sp.component}
          />
        ))}

        {/* Default sub page  */}
        <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
      </Switch>
    </DetailLayoutWrapper>
  );
}
