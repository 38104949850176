import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import { usePermissionCheck } from "../../store/base";
import { constants, useBuilding } from "../../store/buildings";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";
import BuildingBlueprints from "./detail/Blueprints";
import BuildingInfo from "./detail/Building";

export default function BuildingDetail() {
  const { buildingId } = useParams();
  const { url, path } = useRouteMatch();
  const { push } = useHistory();
  const [building] = useBuilding(buildingId);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  usePermissionRedirect(["view_can_baseobject"]);

  const canDelete = usePermissionCheck("delete_can_baseobject");

  const SUB_PAGES = [
    {
      path: "/overview",
      label: "Byggnad",
      component: BuildingInfo,
    },
    {
      path: "/blueprints",
      label: "Planritningar",
      component: BuildingBlueprints,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderLogo = () => {
    if (building?.image?.get) {
      return (
        <div
          style={{
            height: 50,
            width: 70,
            marginRight: 8,
            boxShadow: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
            borderRadius: 8,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${building.image.get})`,
          }}
        ></div>
      );
    }

    return null;
  };

  const handleActions = [];

  if (canDelete) {
    handleActions.push({
      name: "Radera",
      onClick: () => setDeleteOpen(true),
      iseDelete: true,
    });
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteOpen}
        closeFunction={() => setDeleteOpen(false)}
        instance={building}
        constants={constants}
        deletedCallback={() => push("/buildings")}
      />

      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Byggnad ${building?.str_representation || "laddar..."}`}
          renderLogo={renderLogo}
          breadCrumbs={[
            { url: "/buildings", label: "Byggnader" },
            { label: building?.str_representation || "Laddar..." },
          ]}
          eventContentType="standard.building"
          eventObjectId={buildingId}
          eventIdQueryStr="building"
          {...{ subPages: subPageLinks, handleActions }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
