import { isEqual } from "lodash";
import { updateActiveFormInstance } from "../../store/base";

import { PDFDocument, rgb } from "pdf-lib";
import fileDownload from "js-file-download";
import { toString } from "lodash";
import { getTemplate, getInstance } from "../../store/editabledocs";

export const FIELD_TYPES = {
  CHECKBOX: "checkbox",
  TEXT: "text",
  RADIO: "radiogroup",
  SIGNATURE: "signature",
};
export const CATEGORY_MAP = {
  tenant: "Hyresgäst",
  bi_tenant: "Övrig hyresgäst",
  landlord: "Hyresvärd",
  other: "Övrig",
};

export const SIGN_ROLE_MAP = {
  approver: "Godkänner dokumentet",
  signing_party: "Signerar dokumentet",
  viewer: "Visar dokumentet",
};

export const getFieldType = (kind) => {
  if (kind === "signature") return FIELD_TYPES.SIGNATURE;

  if (["checkbox", "subletted", "has_furniture"].includes(kind)) {
    return FIELD_TYPES.CHECKBOX;
  }
  if (["radiogroup"].includes(kind)) {
    return FIELD_TYPES.RADIO;
  }

  return FIELD_TYPES.TEXT;
};

export const SIGN_TYPES = {
  DIGITAL: "digital-sign",
  MANUAL: "manual-sign",
};

export const FIELD_KIND_CHOICES = [
  {
    d: "Text",
    v: "text",
  },
  {
    d: "Radiogrupp",
    v: "radiogroup",
  },
  {
    d: "Checkbox",
    v: "checkbox",
  },
];
export const lightOrDark = (color) => {
  if (!color) return "light";
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
};

export const exportEditableDoc = async ({
  editableDoc,
  forShow,
  download = false,
}) => {
  try {
    // prepare some data
    const url = editableDoc?.doc?.get;

    const b64Prefix = "data:application/pdf;base64,";
    let b64 = editableDoc?.doc?._tempData?.data;
    if (b64 && !b64.includes(b64Prefix)) {
      b64 = b64Prefix + b64;
    }

    const fastdocId = editableDoc?.fastdoc_id;
    //const fastdocCaseId = editableDoc?.fastdoc_case_id;

    const data = b64 || url;
    const hasData = Boolean(data) || !!fastdocId;

    if (!hasData && forShow) {
      return null;
    }

    const loadForPrint = async () => {
      if (!hasData) {
        throw Error("Don't call this function without any pdf data dude");
      }

      if (url) {
        const arrBuff = await fetch(url).then((res) => res.arrayBuffer());
        return await PDFDocument.load(arrBuff);
      } else if (b64) {
        const dataUri = "data:application/pdf;base64," + b64;
        return await PDFDocument.load(dataUri);
      } else {
        if (editableDoc?.is_template) {
          // collect the template
          const { data, unauthed } = await getTemplate(fastdocId);
          if (data == null) {
            return null;
          }

          const dataUri = "data:application/pdf;base64," + data;
          return await PDFDocument.load(dataUri);
        } else {
          // collect the actual pdf insetad of template pdf
          const { data, unauthed } = await getInstance(
            editableDoc.id,
            fastdocId
          );
          if (data == null) {
            return null;
          }

          const dataUri = "data:application/pdf;base64," + data;
          return await PDFDocument.load(dataUri);
        }
      }
    };

    const prepareFields = (pdf) => {
      // flattens out the party->fields->placements structure into fields with placements
      let fields = [];
      (editableDoc?.parties || []).forEach((p) => {
        (p?.fields || []).forEach((f) => {
          (f?.placements || []).forEach((plc) => {
            const pageNumber = plc?.page;
            const xrel = plc?.xrel;
            const yrel = plc?.yrel;
            const wrel = plc?.wrel;
            const hrel = plc?.hrel;
            const fsrel = plc?.fsrel;

            const plcValue = plc?.value;

            if (!pageNumber || !xrel || !yrel || !wrel || !hrel) {
              return;
            }

            if (!plcValue && getFieldType(f.kind) === FIELD_TYPES.RADIO) {
              return;
            }

            const page = pdf.getPage(pageNumber - 1);
            if (!page) {
              return;
            }

            const { width, height } = page.getSize();
            const w = wrel * width;
            let x = xrel * width;

            const h = hrel * height;
            // coordinate system for pdf is conjugate of normal
            let y = height - yrel * height - h;

            const fs = fsrel * width;

            // console.log("math", f);

            switch (f.kind) {
              case FIELD_TYPES.CHECKBOX:
                x += (w - 2) / 2;
                y += (h - 2) / 2.5;
                break;
              case FIELD_TYPES.RADIO:
                // x += 0;
                y += (h - 2) / 2.5;
                break;
              default:
                //text fields
                // x += 0;
                y += h / 5;
            }

            // console.log("final position", { x, y, w, h });

            const flattendField = {
              ...f,
              placements: undefined,
              page,
              x,
              y,
              w,
              h,
              fs,
              plcValue,
            };
            fields.push(flattendField);
          });
        });
      });

      return fields;
    };

    const decoratePdf = (pdf) => {
      pdf.setTitle(editableDoc?.title || "Avtal");
      pdf.setSubject(editableDoc?.title || "Avtal");
      pdf.setKeywords([
        "Pigello",
        "Pigello Solutions",
        "Pigello Property Solutions",
        "Pigello Fastighetssystem",
        "Avtal",
        "Contract",
      ]);
      pdf.setProducer("Pigello Property Systems");
      pdf.setCreator("Pigello Property Systems");
    };

    const printDocument = async () => {
      const pdf = await loadForPrint();
      if (pdf == null) {
        return null;
      }

      const form = pdf.getForm();
      const fields = prepareFields(pdf);

      // return;

      fields.forEach((field, index) => {
        const fieldType = getFieldType(field.kind);
        let component;
        const name = `${JSON.stringify({
          ...field,
          page: undefined,
        })}_${index}`;
        switch (fieldType) {
          case FIELD_TYPES.CHECKBOX:
            try {
              component = form.createCheckBox(name);
            } catch (error) {
              component = form.createCheckBox(`${name}_${name}`);
            }
            if (field.is_obligatory) {
              component.enableRequired();
            }

            if (forShow) {
              component.enableReadOnly();
            }

            // may need adustments if scrive adjustments are added
            component.addToPage(field.page, {
              x: field.x - 5,
              y: field.y,
              width: 10,
              height: 10,
              backgroundColor: rgb(1, 1, 1),
              borderWidth: 0,
            });
            if (field.is_checked) {
              component.check();
            }

            break;

          case FIELD_TYPES.RADIO:
            try {
              component = form.getRadioGroup(field.name);
            } catch (error) {
              component = form.createRadioGroup(field.name);
            }

            if (field.is_obligatory) {
              component.enableRequired();
            }

            if (forShow) {
              component.enableReadOnly();
            }

            component.addOptionToPage(field.plcValue.toString(), field.page, {
              x: field.x,
              y: field.y,
              width: field.w,
              height: field.h,
              backgroundColor: rgb(1, 1, 1),
              borderWidth: 0,
            });
            if (field.value == field.plcValue) {
              component.select(field.plcValue.toString());
            }
            break;

          default:
            if (!forShow) {
              try {
                component = form.createTextField(name);
              } catch (error) {
                component = form.createTextField(`${name}_${name}`);
              }

              if (field.is_obligatory) {
                component.enableRequired();
              }
              if (field.value) {
                component.setText(toString(field.value || ""));
              }
              component.addToPage(field.page, {
                x: field.x,
                y: field.y,
                width: field.w,
                height: field.h,
                backgroundColor: rgb(1, 1, 1),
                borderWidth: 0,
              });
            } else {
              field.page.drawText(toString(field.value || ""), {
                x: field.x,
                y: field.y + (field.h - field.fs),
                size: field.fs,
                backgroundColor: rgb(1, 1, 1),
                borderWidth: 0,
              });
            }

            break;
        }
      });

      decoratePdf(pdf);

      const pdfBytes = await pdf.save();
      if (forShow && !download) {
        return pdfBytes;
      }

      fileDownload(pdfBytes, "download.pdf");
      return pdfBytes;
    };

    return await printDocument();
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getPageFromBoundsAndPosition = ({ pageBounds, y }) => {
  for (let key in pageBounds) {
    const { start, end } = pageBounds[key];

    if (start <= y && end >= y) {
      return { page: key, start, end };
    }
  }
};

export const toInternalCoordinates = ({ xRel, yRel, pageData }) => {
  const { width, height, start } = pageData;

  return { x: xRel * width, y: start + yRel * height };
};

export const toExternalCoordinates = ({ pageData, x, y }) => {
  const { width, height, start } = pageData;

  const pageRelativeY = y - start;

  return { x: x / width, y: pageRelativeY / height };
};

export const toExternalSize = ({ size, pageData }) => {
  const { width, height } = pageData;

  return {
    w: width === 0 ? 0 : (size.width || 0) / width,
    h: height === 0 ? 0 : (size.height || 0) / height,
  };
};

export const toInternalSize = ({ w, h, pageData }) => {
  const { width, height } = pageData;

  return { w: w * width, h: h * height };
};

export const toInternalFontSize = ({ f, pageData }) => {
  const { width } = pageData;

  const result = Math.round(f * width);

  return { f: result };
};

export const toExternalFontSize = ({ f, pageData }) => {
  const { width } = pageData;

  return { f: width ? f / width : 0 };
};

export const getInitialPlacement = ({
  fontSize,
  extraTopOffset = 1,
  isCheckbox,
  pageBounds,
  page,
}) => {
  const pageData = pageBounds[page];
  const size = toExternalSize({
    size: isCheckbox ? { width: 20, height: 20 } : { width: 64, height: 36 },
    pageData,
  });

  const fsrel = toExternalFontSize({ f: fontSize || 16, pageData });

  let yRel = extraTopOffset / pageData.height;

  if (yRel > 1) {
    yRel = 0.9;
  } else if (yRel < 0.05) {
    yRel += 0.07;
  }

  return {
    xrel: 0.4,
    yrel: yRel,
    wrel: size.w,
    hrel: size.h,
    fsrel: fsrel.f,
    page: page || 1,
  };
};

export const canCollapseFieldOnName = ({ relevantFields, name }) => {
  return relevantFields.findIndex((c) => c.name === name) >= 0;
};

export const collapseFieldOnName = ({
  dispatch,
  storeName,
  allFieldsKey,
  relevantFields,
  currentField,
  name,
}) => {
  const existingIndex = relevantFields.findIndex((c) => c.name === name);

  if (existingIndex >= 0) {
    // alright, we found one, now take our pos and ad it to existing pos
    let newFields = [];
    relevantFields.forEach((c, index) => {
      if (isEqual(c, currentField)) {
        return;
      }

      if (index === existingIndex) {
        newFields.push({
          ...c,
          placements: [
            ...(c.placements || []),
            ...(currentField.placements || []),
          ],
        });
      } else {
        newFields.push(c);
      }
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [allFieldsKey]: newFields },
      })
    );

    return true;
  }

  return false;
};
