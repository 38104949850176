import * as React from "react";
import BackendTable from "../../../components/Lists/Base/CompleteList/Table";
import StandardModal from "../../../components/Modals/StandardModal";
import { buildQueryString } from "../../../store/base";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import DocumentSelector, {
  EDITABLEDOC_DOC_SELECTOR_STEPS,
} from "../../../components/EditableDocBase/DocumentSelector/DocumentSelector";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import { DoubleFieldWrapper } from "../../../components/Forms/Base/Chapters/styles";
import TableFileM2m from "../../../components/Forms/Base/Fields/TableFileM2m";
import NonConnectedNumberInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedNumberInput";
import NonConnectedTextField from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextField";
import NonConnectedTextInput from "../../../components/Forms/Base/Old/NonConnected/NonConnectedTextInput";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import {
  approveDocumentsUrl,
  constants,
  insertIntoCreatedContracts,
  removeFromCreatedContracts,
  setDocumentApprovalIndex,
} from "../../../store/pipes";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import {
  constants as leaseContractConstants,
  performFilter as leaseContractPerformFilter,
  useLeaseContractPagination,
} from "../../../store/leaseContracts";
import leaseListDefs from "src/components/Tables/LeaseContracts/listDefs";

export default function PrepareDocForFieldMatching() {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();

  const { type } = useParams();
  const { goBack, push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [hasInformed, setHasInformed] = React.useState(false);
  const [selectorOpen, setSelectorOpen] = React.useState(false);
  const createdContracts = useSelector(
    (state) => state[storeName].createdContracts
  );

  const baseDoc = useFormInstanceField({
    storeName: constants.STORE_NAME,
    fieldKey: "base_doc",
  });

  React.useEffect(() => {
    if (baseDoc && !hasInformed) {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Mall för dokument vald",
          description: "Välj inställningar för signering",
        })
      );
      setHasInformed(true);
    }
  }, [baseDoc]);

  const onRowSelected = (row) => {
    if (checkRowHighlighted(row)) {
      dispatch(removeFromCreatedContracts({ contract: row.original }));
    } else {
      dispatch(insertIntoCreatedContracts({ contract: row.original }));
    }
  };
  const leaseColumns = React.useMemo(() => leaseListDefs({}), []);

  const leaseQuery = buildQueryString({
    editabledoc__isnull: true,
  });
  const leaseFilter = (obj) => !obj.editabledoc;

  const updateAttr = ({ attr, val }) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`base_doc.${attr}`]: val,
        },
      })
    );
  };
  const checkRowHighlighted = (row) => {
    const match = createdContracts?.find((cc) => cc.id == row?.original?.id);

    return !!match;
  };

  const goToApproval = () => {
    dispatch(setDocumentApprovalIndex({ index: 0 }));
    push(approveDocumentsUrl({ type }));
  };

  if (!createdContracts?.length || selectorOpen) {
    return (
      <>
        <StandardModal
          isOpen={selectorOpen}
          closeFunction={() => setSelectorOpen(false)}
          title="Välj avtal"
          withActionBar
          actionBarCancelTitle="Klar"
        >
          <BackendTable
            storeName={leaseContractConstants.STORE_NAME}
            columns={leaseColumns}
            persistantQueryString={leaseQuery}
            persistantFilterMethod={leaseFilter}
            paginationMethod={useLeaseContractPagination}
            filterAction={leaseContractPerformFilter}
            {...{
              hideExport: true,
              hideColumns: true,
              onRowClicked: onRowSelected,
              checkRowHighlighted,
            }}
          />
        </StandardModal>

        <DetailInnerWrapper>
          <DetailPageBox>
            {loading && <OverlaySpinner />}
            <TextButton
              extraStyle={{ marginBottom: 8 }}
              title="Gå tillbaka till projekt"
              clicked={() => goBack()}
              iconType="arrow-back"
            />

            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>
                  Välj avtal att sätta upp signeringsdokument för
                </OverviewTitle>
                <OverviewSubtitle>
                  Endast avtal som inte har dokument uppsatta sedan tidigare kan
                  väljas. Efter att avtalen har valts så kommer mall och
                  inställningar för signeringen att ställas in.
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <div>
              <PrimaryButton
                title="Välj avtal"
                clicked={() => setSelectorOpen(true)}
              />
            </div>
          </DetailPageBox>
        </DetailInnerWrapper>
      </>
    );
  }

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        {loading && <OverlaySpinner />}
        <TextButton
          extraStyle={{ marginBottom: 8 }}
          title="Gå tillbaka till projekt"
          clicked={() => goBack()}
          iconType="arrow-back"
        />

        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>Välj mall för signeringsdokument</OverviewTitle>
            <OverviewSubtitle>
              Välj vilken mall som ska användas för skapande av dokument för
              signering, och vilken typ av signering som gäller.
              <br />
              Efter att detta är valt kommer dokumenten att gås igenom var för
              sig och antingen <strong>godkännas och sparas</strong> eller{" "}
              <strong>hoppas över</strong>. De avtal som hoppas över kommer att
              sammanfattas efter projektet och kan exporteras för att hanteras
              senare.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        {!baseDoc && (
          <DocumentSelector
            storeName={storeName}
            method={"POST"}
            editableDocFieldKey="base_doc"
            editableDoc={baseDoc}
            forceStep={EDITABLEDOC_DOC_SELECTOR_STEPS.TEMPLATES}
            canChangeStep={false}
          />
        )}

        {baseDoc && (
          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Inställningar för signering</OverviewTitle>
                <OverviewSubtitle>
                  Ställ in hur signeringen ska gå till. Tryck "Gå vidare och
                  granska dokument" för att gå igenom dokumenten som ska skapas
                  upp
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <NonConnectedTextInput
              id="title"
              label="Namn på dokument"
              description="Detta namn kommer vara synligt för alla parter som får dokumentet"
              value={baseDoc.title}
              onUpdate={(val) => updateAttr({ attr: "title", val })}
            />

            <DoubleFieldWrapper>
              <NonConnectedNumberInput
                id="days_to_sign"
                label="Antal dagar att signera"
                description="Ange hur många dagar parterna har på sig att signera avtalet innan signeringen misslyckas"
                value={baseDoc.days_to_sign}
                onUpdate={(val) => updateAttr({ attr: "days_to_sign", val })}
                placeholder="90 dagar är standard om inget anges"
                extraStyle={{ minWidth: 310, marginRight: 12 }}
              />
              <NonConnectedNumberInput
                id="days_to_remind"
                label="Påminnelse skickas efter X dagar"
                value={baseDoc.days_to_remind}
                description="Om inte parten har signerat kommer en påminnelse automatiskt att skickas ut efter X antal dagar"
                placeholder="Ingen påminnelse skickas om inget anges"
                onUpdate={(val) => updateAttr({ attr: "days_to_remind", val })}
                extraStyle={{ minWidth: 310 }}
              />
            </DoubleFieldWrapper>

            <DoubleFieldWrapper>
              <NonConnectedTextField
                title="Inbjudningsmeddelande"
                description="Detta meddelande kommer att skickas i E-postmeddelandet när parten bjuds in till att signera om E-post är valt som leveranssätt för parten"
                handleChange={(val) =>
                  updateAttr({ attr: "invitation_message", val })
                }
                value={baseDoc.invitation_message}
                extraStyles={{
                  minWidth: 310,
                  maxWidth: 310,
                  marginRight: 12,
                }}
              />
              <NonConnectedTextField
                title="Bekräftelsemeddelande"
                description="Detta meddelande kommer att skickas i E-postmeddelandet när parten informeras om att signeringen är avslutad om E-post är valt som leveranssätt för parten"
                handleChange={(val) =>
                  updateAttr({ attr: "confirmation_message", val })
                }
                value={baseDoc.confirmation_message}
                extraStyles={{ minWidth: 310, maxWidth: 310 }}
              />
            </DoubleFieldWrapper>

            <TableFileM2m
              title="Bilagor"
              extraStyles={{ width: "100%", maxWidth: 600 }}
              description="Bilagor"
              storeName={constants.STORE_NAME}
              method={"POST"}
              overrideInstructions={{}}
              fieldKey={`attachments`}
            />

            <hr />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                title="Gå vidare och granska dokument"
                clicked={goToApproval}
              />
            </div>
          </InnerBox>
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
