import * as React from "react";

import { Confirm, Settings, Interval, Areas } from "./Chapters";

export default ({ key, method, id }) => {
  switch (key) {
    case "SETTINGS":
      return <Settings {...{ method, id }} />;
    case "INTERVAL":
      return <Interval {...{ method }} />;
    case "AREAS":
      return <Areas {...{ method, id }} />;
    default:
      return <Confirm method={method} />;
  }
};
