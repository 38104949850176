const defs = [
  {
    title: "Generellt",
    key: "SETTINGS",
    visited: true,
    hasError: false,
    fieldKeys: ["responsible_trustee", "performers", "title", "kind"],
  },
  {
    title: "Schemaläggning",
    key: "INTERVAL",
    visited: false,
    hasError: false,
    fieldKeys: [
      "interval",
      "interval.mode",
      "interval.day_in_month",
      "interval.on_month",
      "interval.interval",
      "interval_start",
    ],
  },
  {
    title: "Områden",
    key: "AREAS",
    visited: false,
    hasError: false,
    fieldKeys: [
      "areas",
      "areas.components",
      "areas.title",
      "areas.realestates",
    ],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];

export default () => {
  return defs;
};
