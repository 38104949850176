import * as React from "react";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  useAtlasTemplates,
  usePrivateTemplates,
} from "../../../../store/editabledocs";

import { updateActiveFormInstance } from "../../../../store/base";

import { cleanEditableDocTemplate } from "./utils";

import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import FastDocTemplates from "./FastDocTemplates";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";

import PrimaryBtn from "../../../Forms/Base/Buttons/PrimaryBtn";
import PrivateTemplates from "./PrivateTemplates";
import PrivateFastDocTemplates from "./PrivateFastDocTemplates";

export const fastDocAtlasTemplateName = "publicAtlasFastDoc";
export const fastDocTemplateName = "fastDocTemplates";
export const privateTemplateName = "private";
export const privateFastDocTemplateName = "privateFastDoc";
export const publicAtlasTemplateName = "publicAtlas";

const OPTIONS = {
  PRIVATE: "PRIVATE",
  FASTDOC: "FASTDOC",
  PRIVATE_FASTDOC: "PRIVATE_FASTDOC",
};

export default React.memo(({ storeName, editableDocPath, authed }) => {
  const dispatch = useDispatch();

  const [selectedTemplateType, setSelectedTemplateType] = React.useState(null);

  const [atlasTemplates, isLoadingAtlasTemplates] = useAtlasTemplates();
  const [privateTemplates, isLoadingPrivateTemplates] = usePrivateTemplates();

  const [loading, setLoading] = React.useState(false);

  const chosenTemplate = useSelector((state) => {
    const d = state[storeName].formInstance?._chosenTemplate;
    if (d) {
      return d?.id || "";
    }
    return "";
  });

  const setChosenTemplate = ({ id, pay = false, type }) => {
    const data = {
      _chosenTemplate: {
        name: type,
        id,
        pay,
      },
    };

    dispatch(updateActiveFormInstance({ storeName, data }));
  };

  const getTemplateOptions = ({ type, templates }) => {
    switch (type) {
      case publicAtlasTemplateName: {
        return templates.map((value) => {
          return {
            value: value.id,
            label: value.title,
          };
        });
      }

      case privateTemplateName: {
        return templates.map((value) => {
          return {
            value: value.id,
            label: value.title,
          };
        });
      }

      default:
        break;
    }

    if (!templates?.length) {
      return null;
    }

    if (type === fastDocTemplateName) {
      return templates.map((value) => {
        return {
          value: value._id,
          label: value.name,
        };
      });
    } else {
      // private fastdok template or pigello fastdok
      return templates.map((t) => ({
        value: t.fastdoc_id,
        object: t,
        label: t.title,
      }));
    }
  };

  const fetchInternalTemplate = ({ id, type }) => {
    let chosenOption = null;

    switch (type) {
      case publicAtlasTemplateName: {
        chosenOption = atlasTemplates.find((t) => t.id === id);
        break;
      }
      case privateTemplateName: {
        chosenOption = privateTemplates.find((t) => t.id === id);
        break;
      }

      default:
        break;
    }

    setLoading(false);

    if (!chosenOption) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta mall",
          description: "Vänligen försök igen",
        })
      );

      setChosenTemplate("");
      return;
    }

    cleanEditableDocTemplate(cloneDeep(chosenOption)).then((cleaned) => {
      const data = editableDocPath ? { [editableDocPath]: cleaned } : cleaned;

      dispatch(updateActiveFormInstance({ storeName, data }));
      setChosenTemplate({
        id,
        pay: true,
        type,
      });
    });
  };

  // object for private and public fastdoc based, ONLY FASTDOC!!!!!!
  const fetchTemplate = async ({ id, pay, type, object }) => {
    setLoading(false);

    if (object) {
      cleanEditableDocTemplate(cloneDeep(object)).then((cleaned) => {
        const editableDocData = editableDocPath
          ? { [editableDocPath]: { ...cleaned, fastdoc_id: id } }
          : { ...cleaned, fastdoc_id: id };
        dispatch(
          updateActiveFormInstance({ storeName, data: editableDocData })
        );

        setChosenTemplate({ id, type, pay });
      });
    } else {
      dispatch(
        updateActiveFormInstance({ storeName, data: { fastdoc_id: id } })
      );
      setChosenTemplate({ id, type, pay });
    }
  };

  const choseTemplate = ({ id, type, object }) => {
    if (id === chosenTemplate || !id) {
      return;
    }

    setLoading(true);

    setChosenTemplate({ id, type });

    switch (type) {
      case fastDocTemplateName: {
        fetchTemplate({ id, pay: false, type });
        break;
      }

      case publicAtlasTemplateName: {
        fetchInternalTemplate({ id, type });
        break;
      }

      case fastDocAtlasTemplateName: {
        fetchTemplate({ id, pay: false, type, object });
      }

      case privateTemplateName: {
        fetchInternalTemplate({ id, type });
        break;
      }

      case privateFastDocTemplateName: {
        fetchTemplate({ id, pay: false, type, object });
      }

      default:
        return null;
    }
  };

  return (
    <>
      {loading && <OverlaySpinner />}

      <div className="flex space-x-2 mb-6">
        <PrimaryBtn
          secondary={selectedTemplateType !== OPTIONS.PRIVATE}
          onClick={() => setSelectedTemplateType(OPTIONS.PRIVATE)}
        >
          Egna mallar
        </PrimaryBtn>
        {authed && (
          <>
            <PrimaryBtn
              secondary={selectedTemplateType !== OPTIONS.PRIVATE_FASTDOC}
              onClick={() => setSelectedTemplateType(OPTIONS.PRIVATE_FASTDOC)}
            >
              Egna baserade på Fastighetsägarna Dokument
            </PrimaryBtn>
            <PrimaryBtn
              secondary={selectedTemplateType !== OPTIONS.FASTDOC}
              onClick={() => setSelectedTemplateType(OPTIONS.FASTDOC)}
            >
              Fastighetsägarna Dokument
            </PrimaryBtn>{" "}
          </>
        )}
      </div>

      {selectedTemplateType === OPTIONS.FASTDOC && (
        <FastDocTemplates
          {...{
            fastDocTemplateName,
            chosenTemplate,
            getTemplateOptions,
            choseTemplate,
          }}
        />
      )}

      {selectedTemplateType === OPTIONS.PRIVATE && (
        <PrivateTemplates
          privateTemplateName={privateTemplateName}
          choseTemplate={choseTemplate}
        />
      )}

      {selectedTemplateType === OPTIONS.PRIVATE_FASTDOC && (
        <PrivateFastDocTemplates
          privateTemplateName={privateTemplateName}
          choseTemplate={choseTemplate}
        />
      )}
    </>
  );
});
