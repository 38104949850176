import { cloneDeep } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import { buildQueryString, useFormField } from "../../../../../store/base";
import { useFilteredComponentTypes } from "../../../../../store/componentTypes";

import {
  useRoomForm,
  constants as roomConstants,
} from "../../../../../store/rooms";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InfoBox } from "../../../../Displays";
import StandardModal from "../../../../Modals/StandardModal";
import PremisesTable from "../../../../PremisesTable/PremisesTable";
import { InnerBox } from "../../../../sharedStyles";
import { PrimaryButton, TextButton } from "../../../Base/Buttons";
import NonConnectedNumberInput from "../../../Base/Old/NonConnected/NonConnectedNumberInput";
import NonConnectedSelect from "../../../Base/Old/NonConnected/NonConnectedSelect";
import { MASS_CONFIG_OBJECT_TYPES_KEYS } from "./Type";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import ComponentTypesTable from "src/components/Tables/ErrandComponentTypes/FullTable";

export default function Exclusions({ updateExclusionsFunction, storeName }) {
  useRoomForm("POST");

  const [addRoomExclusionOpen, setAddRoomExclusionOpen] = React.useState(false);
  const [roomKind, setRoomKind] = React.useState(null);
  const [maxRooms, setMaxRooms] = React.useState(null);
  const [addComponentRestrictionOpen, setAddComponentRestrictionOpen] =
    React.useState(false);
  const [componentType, setComponentType] = React.useState(null);
  const [maxComponents, setMaxComponents] = React.useState(null);
  const instance = useSelector((state) => state[storeName].instance);
  const exclusionRules = useSelector(
    (state) => state[storeName].exclusionRules
  );

  const componentTypeIds = Object.keys(
    exclusionRules?.component_type_restrictions || {}
  );

  const compQ = buildQueryString({
    id__in: componentTypeIds || [],
  });

  const [componentTypes] = useFilteredComponentTypes(compQ);

  const formCategories = useFormField({
    storeName: roomConstants.STORE_NAME,
    fieldKey: "kind",
    method: "POST",
  });
  const roomKinds = formCategories?._choices;

  const addRoomExclusion = (newRoom) => {
    const exclusionRulesRoomClone = cloneDeep(
      exclusionRules?.room_restrictions || {}
    );

    exclusionRulesRoomClone[Object.keys(newRoom)?.[0]] = parseInt(
      Object.values(newRoom)?.[0]
    );

    updateExclusionsFunction({
      key: "room_restrictions",
      value: exclusionRulesRoomClone,
    });
  };

  const addComponentExclusion = (newComp) => {
    const exclusionRulesComponentClone = cloneDeep(
      exclusionRules?.component_type_restrictions || {}
    );

    exclusionRulesComponentClone[Object.keys(newComp)?.[0]] = parseInt(
      Object.values(newComp)?.[0]
    );

    updateExclusionsFunction({
      key: "component_type_restrictions",
      value: exclusionRulesComponentClone,
    });
  };

  const removeRoomRestriction = (key) => {
    let exclusionRulesRoomClone = cloneDeep(exclusionRules?.room_restrictions);

    delete exclusionRulesRoomClone[key];

    updateExclusionsFunction({
      key: "room_restrictions",
      value: exclusionRulesRoomClone,
    });
  };

  const removeComponentRestriction = (key) => {
    let exclusionRulesComponentClone = cloneDeep(
      exclusionRules?.component_type_restrictions
    );

    delete exclusionRulesComponentClone[key];

    updateExclusionsFunction({
      key: "component_type_restrictions",
      value: exclusionRulesComponentClone,
    });
  };

  return (
    <>
      {!instance?.type ? (
        <>
          <InfoBox
            title="Välj typ av objekt "
            text="Välj typ av objekt först för att kunna specificera exkluderingsregler"
          />
        </>
      ) : (
        <>
          <StandardModal
            title="Lägg till exkludering"
            isOpen={addRoomExclusionOpen}
            closeFunction={() => setAddRoomExclusionOpen(false)}
            withActionBar
            small
            saveFunction={() => {
              addRoomExclusion({ [roomKind]: maxRooms });
              setMaxRooms(null);
              setRoomKind(null);
              setAddRoomExclusionOpen(false);
            }}
            canAccept={roomKind != null && maxRooms > 0}
          >
            <NonConnectedSelect
              choices={roomKinds}
              allowNull={false}
              getOptionLabel={(o) => o.d}
              getOptionValue={(o) => o.v}
              label="Typ av rum"
              id="roomkind"
              value={roomKind}
              onUpdate={(v) => setRoomKind(v)}
            />

            <NonConnectedNumberInput
              id={`roommax`}
              label="Max antal av denna typ"
              value={maxRooms}
              onUpdate={(val) => {
                setMaxRooms(val);
              }}
            />
          </StandardModal>
          <StandardModal
            title="Lägg till exkludering"
            isOpen={addComponentRestrictionOpen}
            closeFunction={() => setAddComponentRestrictionOpen(false)}
            withActionBar
            saveFunction={() => {
              addComponentExclusion({ [componentType?.id]: maxComponents });
              setMaxComponents(null);
              setComponentType(null);
              setAddComponentRestrictionOpen(false);
            }}
            canAccept={!!componentType && maxComponents > 0}
          >
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                value={componentType}
                TableComponent={ComponentTypesTable}
                title="Komponenttyp"
                placeholder="Välj komponenttyp..."
                onChange={(val) => setComponentType(val)}
              />
            </div>

            <NonConnectedNumberInput
              id={`compMax`}
              label="Max antal av denna typ"
              value={maxComponents}
              onUpdate={(val) => {
                setMaxComponents(val);
              }}
            />
          </StandardModal>

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Exkludering av skapande</OverviewTitle>
              <OverviewSubtitle>
                Dessa regler kan användas för att undvika att skapa dubletter av
                rum och komponenter på objekt som sedan innan denna uppsättning
                redan har dessa. T.ex. kan det anges att enbart ett (1) rum av
                kategorin "Kök" får finnas på objekten, vilket kommer leda till
                att objekt som redan har ett "Kök" ignorerar skapandet av rummet
                som sätts upp. Samma sak gäller för komponenttyper, där det kan
                specificeras att rum av typen "Kök" som redan har en "Spis" inte
                kommer att få en till "Spis" om en sådan ingår i köket som sätts
                upp i detta flöde.
              </OverviewSubtitle>

              {instance.type === MASS_CONFIG_OBJECT_TYPES_KEYS.PARKING && (
                <OverviewSubtitle>
                  <strong>OBS:</strong> Fordonsplatser saknar rum och därmed kan
                  ej exkluderingar för rum ställas in. Istället hanteras hela
                  fordonsplatsen som ett och samma rum, d.v.s. exkluderingar för
                  komponettyper gäller för hela fordonsplatsen.
                </OverviewSubtitle>
              )}
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          {instance.type !== MASS_CONFIG_OBJECT_TYPES_KEYS.PARKING && (
            <InnerBox style={{ marginBottom: 24 }}>
              <OverviewTitleWrapper>
                <OverviewTitle small>Exkluderingsregler för Rum</OverviewTitle>
                <PrimaryButton
                  title="Lägg till"
                  clicked={() => setAddRoomExclusionOpen(true)}
                />
              </OverviewTitleWrapper>

              <PremisesTable
                headers={["Typ av rum", "Max antal", ""]}
                rows={Object.keys(exclusionRules?.room_restrictions || {})?.map(
                  (key) => {
                    const rr = {
                      [key]: exclusionRules.room_restrictions[key],
                    };

                    return [
                      roomKinds[Object.keys(rr)?.[0]]?.d,
                      Object.values(rr)?.[0],
                      <TextButton
                        red
                        title="Ta bort"
                        clicked={() =>
                          removeRoomRestriction(Object.keys(rr)?.[0])
                        }
                      />,
                    ];
                  }
                )}
              />
            </InnerBox>
          )}

          <InnerBox>
            <OverviewTitleWrapper>
              <OverviewTitle small>
                Exkluderingsregler för Komponenttyper
              </OverviewTitle>
              <PrimaryButton
                title="Lägg till"
                clicked={() => setAddComponentRestrictionOpen(true)}
              />
            </OverviewTitleWrapper>

            <PremisesTable
              headers={["Komponenttyp", "Max antal", ""]}
              rows={Object.keys(
                exclusionRules?.component_type_restrictions || {}
              )?.map((key) => {
                const rr = {
                  [key]: exclusionRules.component_type_restrictions[key],
                };

                return [
                  componentTypes?.find((c) => c.id == Object.keys(rr)?.[0])
                    ?.str_representation,
                  Object.values(rr)?.[0],
                  <TextButton
                    red
                    title="Ta bort"
                    clicked={() =>
                      removeComponentRestriction(Object.keys(rr)?.[0])
                    }
                  />,
                ];
              })}
            />
          </InnerBox>
        </>
      )}
    </>
  );
}
