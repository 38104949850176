import * as React from "react";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ErrandRoleTable from "src/components/Tables/ErrandRoles/FullTable";

import { constants } from "../../../../../store/roundingErrands";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import DurationField from "../../../Base/Fields/DurationField";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Delegering av ärende</FormAreaTitle>

      <FormAreaDescription>
        Delegera vem som ska utföra ärendet.
      </FormAreaDescription>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey="performer"
          title="Utförare"
          placeholder="Välj utförare..."
          TableComponent={ErrandRoleTable}
        />
      </div>

      <DurationField
        storeName={storeName}
        method={method}
        title="Påminn utförare så här lång tid innan planerad start"
        fieldKey="remind_executor_duration"
        menuPlacement="top"
      />
    </>
  );
};
