import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  useInProgress,
  useObject,
} from "../../base";
import {
  getCurrentConfigCenter,
  getSingle,
} from "../store/actions";

import constants from "../store/constants";

export const useConfigCenter = () => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [notFound, setNotFound] = React.useState(false);

  const currentConfigCenter = useSelector(
    (state) => state[storeName].currentConfigCenter
  );
  
  const inProgress = useInProgress({ storeName, name:"configCenter" });
  
  if (notFound) return [undefined, false, true];

  if (inProgress) return [undefined, true];

  if (!currentConfigCenter?.id) {
    dispatch(
      getCurrentConfigCenter({
        notFoundCallback: () => setNotFound(true),
      })
    );
    return [undefined, true];
  }

  return [currentConfigCenter, false];
};