import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
  addToProcess,
  checkTrailingUrlSlash,
} from "../../base";
import { retrieve } from "../../base/store/services";
import { getSingleFetchProcessName } from "../../base/utils";
import { getRoundingOverviewStatsProcessName } from "../utils";
import constants from "./constants";
import { createUploadHandler } from "./uploadHandler";
import { store } from "../../store";
import { cloneDeep } from "lodash";
import { axiosInstance } from "../../base/store/axios";
import { addToast, TOAST_TYPES } from "../../toasts";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  // completion_files
  (cloned?.completion_files || []).forEach((f) => {
    if (f) {
      // will never be sent to backend
      delete f.fileData_;

      if (f.id) {
        // file has not been edited, so we can just use the id
        delete f?.file;
      }
    }
  });

  // cost files
  (cloned.costs || []).forEach((cf) => {
    if (cf?.evidence && !cf.evidenceData_) {
      delete cf.evidence;
    } else if (cf.evidenceData_) {
      delete cf.evidenceData_;
    }
  });

  // we don't handle this object here
  delete cloned.performer_signature;

  return cloned;
};

const uploadCallback = async (responseData, dispatch) => {
  const state = store.getState();
  const formInstance = state[constants.STORE_NAME].formInstance;

  await createUploadHandler({ formInstance, responseData, dispatch });
  return;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return post({
    url: constants.POST_URL,
    constants,
    preProcess: fullPreProcess,
    processSuccess,
    processError,
    successCallback,
    uploadCallback: uploadCallback,
    errorCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return patch({
    url,
    constants,
    preProcess: fullPreProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    uploadCallback: uploadCallback,
  });
};

export const fetchOverviewStats = () => {
  const url = constants.OVERVIEW_STATS_UTL;
  const name = getRoundingOverviewStatsProcessName();

  return async (dispatch) => {
    addToProcess(dispatch, constants, name);

    const result = await retrieve({ url: checkTrailingUrlSlash(url) });
    let data = result.data;

    dispatch({
      type: constants.SET_OVERVIEW_STATS,
      payload: { result: data },
    });
  };
};

export const acceptRoundingErrand = ({ id }) => {
  return async (dispatch) => {
    try {
      const url = `errands/main/roundingerrand/accepting/${id}/`;
      await axiosInstance.patch(url, {
        accept: true,
      });

      dispatch({
        type: constants.POST_ACCEPT_DECLINE,
        payload: { id },
      });
    } catch (error) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej acceptera",
          description: "Vänligen försök igen",
        })
      );
    }
  };
};

export const declineRoundingErrand = ({ id, successCallback }) => {
  return async (dispatch) => {
    try {
      const url = `errands/main/reporterrand/accepting/${id}/`;
      await axiosInstance.patch(url, {
        accept: false,
      });

      dispatch({
        type: constants.POST_ACCEPT_DECLINE,
        payload: { id },
      });

      if (successCallback) {
        successCallback();
      }
    } catch (error) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej neka",
          description: "Vänligen försök igen",
        })
      );
    }
  };
};
