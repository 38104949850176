import * as React from "react";
import { useDispatch } from "react-redux";

import {
  destroyPatchForm,
  constants,
  update,
  useParkingContractForm,
} from "../../../../store/parkingContracts";
import { updateActiveFormInstance } from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import TableSelectFieldWithCreate from "../../Base/Fields/TableSelectFieldWithCreate";
import ParkingSpotsTable from "src/components/Tables/Parking/ParkingSpots/FullTable";
import ParkingSpotChildren from "../../ParkingSpot/NestedChildren";

export default function UpdateParkingContractParkingSpotsModalForm({
  id,
  onCheckout,
  instance = {},
}) {
  const method = "PATCH"; // always patch
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useParkingContractForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  const getParkingSpotChildren = (parentPath) => {
    const parentInstructionsPath = "parking_spots";

    return (
      <ParkingSpotChildren
        storeName={storeName}
        method={method}
        parentPath={parentPath}
        parentInstructionsPath={parentInstructionsPath}
      />
    );
  };

  return (
    <Modal
      title="Uppdatera avtal"
      closeFunction={onDone}
      onAccept={onSubmit}
      acceptTitle="Spara"
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey={"parking_spots"}
          method={method}
          title={"Fordonsplatser"}
          placeholder={"Välj fordonsplatser..."}
          TableComponent={ParkingSpotsTable}
          isMany
        >
          {(parentPath) => getParkingSpotChildren(parentPath)}
        </TableSelectFieldWithCreate>
      </div>
    </Modal>
  );
}
