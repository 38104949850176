import * as React from "react";

import { RadioGroup } from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/paymentGroup";
import { buildQueryString } from "../../../../../store/base";
import useActiveFilters from "../../../../../hooks/useActiveFilters";
import { useFilteredRealEstates } from "../../../../../store/realEstates";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";

export default ({ method }) => {
  const { filteredRealEstates } = useActiveFilters();

  const storeName = constants.STORE_NAME;

  const persistantQuery = {
    realestate_ids: filteredRealEstates,
  };

  const realEstateQuery = buildQueryString({
    id__in: filteredRealEstates,
  });

  const [realEstates] = useFilteredRealEstates(realEstateQuery);

  return (
    <>
      <RadioGroup
        title="Är aktiv"
        description="Om avgiften är inaktiv kommer ingen debitering att ske"
        storeName={storeName}
        method={method}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        fieldKey={"is_active"}
        defaultValue={true}
      />
      <RadioGroup
        title="Automatisk avstängning av debitering"
        description="Om en lägenhets totala avgift att betala över tid är inbetald, så stängs debitering av automatiskt"
        storeName={storeName}
        method={method}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        fieldKey={"close_for_fully_payed_premis"}
        defaultValue={true}
      />
      <RadioGroup
        title="Avgifter bidrar till ränte-avgifter"
        description="Ange huruvida inbetalningarna för avgifterna bidrar till föreningens betalningar av ränteavgifter"
        storeName={storeName}
        method={method}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        fieldKey={"fee_contributes_to_interest_fees"}
        defaultValue={true}
      />
      <RadioGroup
        title="Alla avgifter går till kapitaltillskotts grundande betalningar/skulder"
        description="Detta innebär att 100% av alla avgifter är kapitaltillskotts grundande betalningar/skulder"
        storeName={storeName}
        method={method}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        fieldKey={"all_payments_loan_capital_contribution"}
        defaultValue={false}
      />
      <RadioGroup
        title="Proportionell avgiftsdistribuering till låneavgifter"
        description="Ange om (Ja) inbetalningar proportionellt ska fördelas på underliggande låns amortering och räntor, eller om (Nej) det i första hand ska gå till amortering"
        storeName={storeName}
        method={method}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        fieldKey={"distribute_fee_on_loan_proportionally"}
        defaultValue={true}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={persistantQuery}
          placeholder="Välj produkt..."
          title="Produkt"
          method={method}
          fieldKey="product"
          TableComponent={InvoicingProductTable}
        />
      </div>
    </>
  );
};
