import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import useKeyPress from "../../../../hooks/useKeyPress";
import {
  updateActiveFormInstance,
  useFormError,
  useFormInstanceField,
} from "../../../../store/base";
import { ImageRadioGroup } from "../../../Forms/Base/Fields";
import DescriptionToolTip from "../../../Forms/Base/Layout/DescriptionToolTip";
import StandardModal from "../../../Modals/StandardModal";
import { TextButton } from "../../../Forms/Base/Buttons";

import { getFilteredWidgets, getInfoForWidget } from "../WidgetInfo";

import {
  ErrorMessage,
  InputFieldTitle,
  InputSpacing,
  M2mLabel,
  M2mLabelRemoveButton,
  SelectButton,
} from "../../../Forms/Base/Fields/styles";
import LocalImageRadioGroup from "src/components/Forms/Base/Fields/LocalImageRadioGroup";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";
import Modal from "src/components/Forms/Base/Modals/Modal";

export default ({
  storeName,
  method,
  fieldKey,
  filter,
  fieldTitle,
  title,
  description,
  onCreateClicked,
  onEditClicked,
  constructStrRep,
  extraStyles = {},
  renderSecondaryAction,
  value,
  onChange,

  disabled,
  infobox,

  // m2m field
  isMany,
  modalInModal,

  noMargin,
  noTitle,
  closeModalCallBack,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const enterPressed = useKeyPress("Enter");
  const escapePressed = useKeyPress("Escape");

  // close modal on enter
  React.useEffect(() => {
    if (enterPressed || escapePressed) {
      setModalOpen(false);
    }
  }, [enterPressed, escapePressed]);

  const selected = useFormInstanceField({
    storeName,
    fieldKey,
  });

  const currentError = useFormError({ storeName, fieldKey });

  let radioOptionsList = [];

  const instances = getFilteredWidgets(filter);
  instances.forEach((instance) => {
    radioOptionsList.push({
      id: instance.id,
      label: instance["title"],
      imageUrl: instance["image_url"],
      width: instance["width"],
      height: instance["height"],
    });
  });

  const onRemoveClicked = (item) => {
    if (isMany) {
      let selectedClone = cloneDeep(selected);

      selectedClone = selectedClone.filter((s) => {
        if (item._index) {
          return s._index !== item._index;
        } else {
          return s.id !== item.id;
        }
      });

      onChange(selectedClone);
    } else {
      onChange(undefined);
    }
  };

  const getTitle = () => {
    if (!value || isMany) return title;
    return getInfoForWidget(value)?.title;
  };

  return (
    <>
      <InputSpacing {...{ noMargin, ...extraStyles }}>
        {!noTitle && (
          <InputFieldTitle
            style={{
              marginBottom: "12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {fieldTitle}
            {description && <DescriptionToolTip description={description} />}
          </InputFieldTitle>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: "8px",
          }}
        >
          <PrimaryBtn
            hasError={!!currentError}
            disabled={disabled}
            onClick={disabled ? null : () => setModalOpen(true)}
          >
            {getTitle()}
          </PrimaryBtn>

          {onCreateClicked && (isMany || !selected) && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ margin: "0 6px", fontWeight: "500", fontSize: "14px" }}
              >
                eller
              </span>

              <TextButton
                extraStyle={{ margin: "6px 0" }}
                title="Skapa ny"
                iconType="add"
                iconPlacement="right"
                clicked={onCreateClicked}
              />
            </div>
          )}
        </div>

        {isMany && selected?.length > 0 && (
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {selected.map((item, index) => {
              let hasErr =
                currentError &&
                Array.isArray(currentError) &&
                currentError[item._index];
              if (hasErr) {
                const fieldErr = currentError[item._index];
                if (
                  fieldErr.constructor === Object &&
                  !Object.keys(fieldErr).length
                ) {
                  hasErr = false;
                }
              }

              return (
                <M2mLabel key={index}>
                  {item._index !== undefined ? (
                    <div
                      onClick={() => onEditClicked(item._index)}
                      style={hasErr ? { backgroundColor: "red" } : {}}
                    >
                      {item?.str_representation || constructStrRep(item)}
                    </div>
                  ) : (
                    item?.str_representation || constructStrRep(item)
                  )}
                  <M2mLabelRemoveButton
                    onClick={disabled ? () => {} : () => onRemoveClicked(item)}
                  />
                </M2mLabel>
              );
            })}
          </div>
        )}

        {!!currentError && !isMany && (
          <ErrorMessage>Detta fält innehåller fel</ErrorMessage>
        )}

        {renderSecondaryAction && renderSecondaryAction()}
      </InputSpacing>

      
      {modalOpen && (
      <Modal
        isOpen={modalOpen}
        closeFunction={() => {
          setModalOpen(false);
          closeModalCallBack && closeModalCallBack();
        }}
        onAccept={() => {
          setModalOpen(false);
          closeModalCallBack && closeModalCallBack();
        }}
        title={
          method === "PATCH"
            ? "Uppdatera widgetplacering"
            : "Skapa widgetplacering"
        }
        className="!mt-0"
      >
        <LocalImageRadioGroup
          extraStyles={{ flex: 1 }}
          options={radioOptionsList}
          infoBox={infobox}
          value={value}
          onChange={onChange}
        />
      </Modal>
          
      )}
    </>
  );
};
