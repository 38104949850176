import constants from "./constants";
import { store } from "../../store";
import * as services from "./services";
import { cloneDeep } from "lodash";

export const getKivraSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getKivraSettings(creditorId);

    dispatch({
      type: constants.SET_KIVRA_SETTINGS,
      payload: {
        kivraSettings: settings,
      },
    });

    // successCallback && successCallback();
  };
};

export const updateKivraSettings = ({
  companies,
  creditorId,
  organisationId,
  sendByMail,
  enable,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    const data = {
      CreditorPublicId: creditorId,
      OrganisationId: organisationId,
      SendByMailIfKivraIsNotAvailable: sendByMail,
      IsEnabled: enable,
    };

    try {
      if (enable) {
        await services.updateKivraSettings(data);
      } else {
        await services.deleteKivraSettings(data);
      }

      // update integration status
      dispatch(
        bulkGetIntegrationSettings({
          companies: companies,
          integrationSetting: "kivraSettings",
        })
      );

      successCallback && successCallback();
    } catch (error) {
      errorCallback && errorCallback();
    }
  };
};

export const getVismaSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getVismaSettings(creditorId);

    dispatch({
      type: constants.SET_VISMA_SETTINGS,
      payload: {
        vismaSettings: settings,
      },
    });

    // successCallback && successCallback();
  };
};

export const updateVismaSettings = ({
  creditorId,
  successCallback,
  errorCallback,
  forceData,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const data =
        forceData || cloneDeep(state.billectaIntegrations.formInstance);
      data.CreditorPublicId = creditorId;

      await services.updateVismaSettings(data);

      // update integration status
      dispatch(getVismaSettings(creditorId));

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e);
    }
  };
};

export const getPEAccountingSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getPEAccountingSettings(creditorId);

    dispatch({
      type: constants.SET_PE_SETTINGS,
      payload: {
        peSettings: settings,
      },
    });

    // successCallback && successCallback();
  };
};

export const bulkGetIntegrationSettings = ({
  companies,
  integrationSetting,
}) => {
  const getSettingFunction = (name) => {
    switch (name) {
      case "kivraSettings":
        return services.getKivraSettings;

      case "autogiroSettings":
        return services.getAutogiroSettings;

      case "fortnoxSettings":
        return services.getFortnoxSettings;

      case "PEAccountingSettings":
        return services.getPEAccountingSettings;

      case "vismaSettings":
        return services.getVismaSettings;
    }
  };

  return async (dispatch) => {
    let settingsPayload = {};
    //models[sync_to_avytmpl]

    const promises = [];

    const integrationService = getSettingFunction(integrationSetting);

    companies.forEach((c) => {
      promises.push(integrationService(c.billecta_id));
    });

    const result = await Promise.all(promises);
    companies.forEach((c, i) => {
      const companyResult = result[i];
      settingsPayload[c.id] = companyResult;
    });

    dispatch({
      type: constants.BULK_SET_INTEGRATION_SETTINGS,
      payload: {
        integrationName: integrationSetting,
        data: settingsPayload,
      },
    });
  };
};

export const updatePEAccountingSettings = ({ creditorId, successCallback }) => {
  return async (dispatch) => {
    const state = store.getState();

    const data = cloneDeep(state.billectaIntegrations.formInstance);
    data.CreditorPublicId = creditorId;

    await services.updatePEAccountingSettings(data);

    // update integration status
    dispatch(getPEAccountingSettings(creditorId));

    successCallback && successCallback();
  };
};

export const getFortnoxSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getFortnoxSettings(creditorId);

    dispatch({
      type: constants.SET_FORTNOX_SETTINGS,
      payload: {
        fortnoxSettings: settings,
      },
    });

    // successCallback && successCallback();
  };
};

export const updateFortnoxSettings = ({
  creditorId,
  successCallback,
  errorCallback,
  forceData,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const data =
        forceData || cloneDeep(state.billectaIntegrations.formInstance);
      data.CreditorPublicId = creditorId;

      await services.updateFortnoxSettings(data);

      // update integration status
      dispatch(getFortnoxSettings(creditorId));

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e);
    }
  };
};

export const getAutogiroSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getAutogiroSettings(creditorId);

    dispatch({
      type: constants.SET_AUTOGIRO_SETTINGS,
      payload: {
        autogiroSettings: settings,
      },
    });
  };
};

export const getEInvoiceSettings = (creditorId) => {
  return async (dispatch) => {
    const settings = await services.getEInvoiceSettings(creditorId);

    dispatch({
      type: constants.SET_EINVOICE_SETTINGS,
      payload: {
        eInvoiceSettings: settings,
      },
    });
  };
};

export const updateAutogiroSettings = ({
  companies,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      const data = cloneDeep(state.billectaIntegrations.formInstance);
      data.CreditorPublicId = creditorId;

      await services.updateAutogiroSettings(data);

      // update integration status
      dispatch(
        bulkGetIntegrationSettings({
          companies: companies,
          integrationSetting: "autogiroSettings",
        })
      );

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback();
    }
  };
};

export const clearIntegrationData = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_INTEGRATION_STATE,
    });
  };
};
