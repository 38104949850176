import * as React from "react";
import { useDispatch } from "react-redux";

import * as SC from "./styles";
import { constants } from "../../../../store/roundingErrandSettings";

import { TextButton } from "../../Base/Buttons";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import StandardModal from "../../../Modals/StandardModal";
import { uid } from "uid";
import { cloneDeep } from "lodash";
import AreaForm from "./AreaForm";
import CompForm from "./CompForm";
import {
  useFilteredRoundingAreas,
  useRoundingAreas,
} from "../../../../store/roundingAreas";
import UnconnectedSelect from "../../Base/Fields/UnconnectedSelect";
import { useFilteredRoundingComponents } from "../../../../store/roundingComponents";
import { useFilteredBuildings } from "../../../../store/buildings";
import { useFilteredRealEstates } from "../../../../store/realEstates";

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const areaFieldKey = "areas";

  const [activeAreaId, setActiveAreaId] = React.useState(null);
  const [editAreaOpen, setEditAreaOpen] = React.useState(false);
  const [editCompOpen, setEditCompOpen] = React.useState(false);
  const [selectExistingAreaOpen, setSelectExistingAreaOpen] =
    React.useState(false);
  const [componentOptions, setComponentOptions] = React.useState([]);

  const areas =
    useFormInstanceField({ storeName, fieldKey: areaFieldKey }) || [];
  const [allAreas, allAreasLoading] = useRoundingAreas();
  const [allComponents, allComponentsLoading] = useFilteredRoundingComponents();
  const [allBuildings, allBuildingsLoading] = useFilteredBuildings();
  const [allRealEstates, allRealEstatesLoading] = useFilteredRealEstates();
  const allAreasIds = allAreas.map((a) => a.id);
  const currentAreaIds = areas.map((a) => a.id);
  const qsIds = allAreasIds.filter((a) => !currentAreaIds.includes(a));
  const selectQueryString = buildQueryString({ id__in: qsIds });

  React.useEffect(() => {
    const areaIds = areas?.map((ar) => ar.id);
    const fullAreas = allAreas?.filter((a) => areaIds.includes(a.id));
    const newAreas = areas?.filter((a) => a._reduxId);
    const mergedAreas = Array.from(new Set([...fullAreas, ...newAreas]));
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          areas: mergedAreas,
        },
      })
    );
  }, []);
  const handleAddNewArea = () => {
    const areasClone = cloneDeep(areas);
    const newId = uid(8);
    areasClone.push({ title: "", components: [], _reduxId: newId });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { areas: areasClone },
      })
    );
    setEditAreaOpen(newId);
  };

  const handleEditArea = (e, id) => {
    e.stopPropagation();
    setEditAreaOpen(id);
  };

  const handleRemoveArea = (e, id) => {
    if (e) {
      e.stopPropagation();
    }
    const areasClone = cloneDeep(areas);
    const newAreas = areasClone.filter((a) => (a.id || a._reduxId) !== id);
    setActiveAreaId(null);
    dispatch(
      updateActiveFormInstance({ storeName, data: { areas: newAreas } })
    );
  };

  const handleCloseAreaForm = () => {
    const areaIndex = areas.findIndex(
      (c) => (c.id || c._reduxId) === editAreaOpen
    );
    const activeArea = areas[areaIndex];

    if (activeArea?.title === "") {
      const e = false;
      handleRemoveArea(e, activeArea.id || activeArea._reduxId);
    }
    setEditAreaOpen(false);
  };

  const handleSelect = (areaData) => {
    const areasClone = cloneDeep(areas);
    areasClone.push(areaData);

    dispatch(
      updateActiveFormInstance({ storeName, data: { areas: areasClone } })
    );
  };

  const handleAddNewComp = (areaId) => {
    const currentAreaIndex = areas?.findIndex(
      (a) => areaId === (a.id || a._reduxId)
    );
    const activeArea = areas.find((a) => (a.id || a._reduxId) === activeAreaId);

    const compsClone = cloneDeep(activeArea?.components);
    const newId = uid(8);
    compsClone.push({
      title: null,
      description: null,
      building: null,
      _reduxId: newId,
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [`areas[${currentAreaIndex}].components`]: compsClone },
      })
    );
    setEditCompOpen({ compId: newId, areaIndex: currentAreaIndex });
  };

  const handleEditComp = (e, id) => {
    e.stopPropagation();

    const currentAreaIndex = areas?.findIndex(
      (a) => activeAreaId === (a.id || a._reduxId)
    );
    setEditCompOpen({ compId: id, areaIndex: currentAreaIndex });
  };

  const handleRemoveComp = (id) => {
    const currentAreaIndex = areas?.findIndex(
      (a) => activeAreaId === (a.id || a._reduxId)
    );
    const activeArea = areas.find((a) => (a.id || a._reduxId) === activeAreaId);

    const compsClone = cloneDeep(activeArea?.components);
    const newComps = compsClone.filter((a) => (a.id || a._reduxId) !== id);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [`areas[${currentAreaIndex}].components`]: newComps },
      })
    );
  };

  const handleCloseCompForm = () => {
    const compIndex = areas[editCompOpen.areaIndex]?.components?.findIndex(
      (c) => (c.id || c._reduxId) === editCompOpen.compId
    );
    const activeComp = areas[editCompOpen.areaIndex]?.components[compIndex];

    if (activeComp?.title === null) {
      handleRemoveComp(activeComp.id || activeComp._reduxId);
    }

    setEditCompOpen(false);
  };

  const checkIfCompExist = () => {
    const compIndex = areas[editCompOpen.areaIndex]?.components?.findIndex(
      (c) => (c.id || c._reduxId) === editCompOpen.compId
    );
    const activeComp = areas[editCompOpen.areaIndex]?.components[compIndex];

    const activeArea = areas.find((a) => (a.id || a._reduxId) === activeAreaId);

    const existsInRedux = activeArea?.components?.find((c) => {
      const exists =
        activeComp.title === c.title && activeComp?.building === c.building;
      if (!exists) return false;
      const cIndex = areas[editCompOpen.areaIndex]?.components?.findIndex(
        (o) => (o.id || o._reduxId) === (c.id || c._reduxId)
      );

      return cIndex !== compIndex;
    });

    const exists = allComponents.find((c) => {
      return (
        activeComp.title === c.title && activeComp?.building === c.building
      );
    });

    if (exists) {
      if (existsInRedux) {
        handleRemoveComp(activeComp.id || activeComp._reduxId);
      } else {
        exists.description = activeComp.description;

        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              [`areas[${editCompOpen.areaIndex}].components[${compIndex}]`]:
                exists,
            },
          })
        );
      }
    } else {
      if (existsInRedux) {
        handleRemoveComp(activeComp.id || activeComp._reduxId);
      }
    }
    setEditCompOpen(false);
  };

  const getRealEstateNames = (realEstateIds) => {
    const matches = allRealEstates
      .filter((r) => realEstateIds.includes(r.id))
      .map((m) => m.str_representation);

    if (!matches?.length) return "-";

    return `${matches[0]} ${
      matches.length > 1 ? ` + ${matches.length - 1}` : ""
    }`;
  };

  const renderAreaList = () => {
    const areaList = areas?.map((area) => {
      return (
        <SC.ListItem
          active={(area.id || area._reduxId) === activeAreaId}
          onClick={() => setActiveAreaId(area.id || area._reduxId)}
        >
          {area?._reduxId && (
            <div style={{ marginRight: "20px" }}>
              {area.str_representation || area.title} -{" "}
              {area?.realestates?.length > 0
                ? area.realestates.map((r) => r.str_representation)
                : null}
            </div>
          )}
          {area?.id && (
            <div style={{ marginRight: "20px" }}>
              {area.str_representation || area.title} -{" "}
              {area?.realestates?.length > 0
                ? getRealEstateNames(area.realestates.map((r) => r.id))
                : null}
            </div>
          )}

          {area?._reduxId && (
            <SC.EditButton onClick={(e) => handleEditArea(e, area._reduxId)} />
          )}
          <SC.AbsoluteRemoveButton
            onClick={(e) => handleRemoveArea(e, area.id || area._reduxId)}
          />
        </SC.ListItem>
      );
    });

    return areaList?.length ? (
      areaList
    ) : (
      <SC.ListItem>
        Skapa nytt eller lägg till befintligt område via knapparna nedan
      </SC.ListItem>
    );
  };

  const renderCompList = () => {
    const activeArea = areas.find((a) => (a.id || a._reduxId) === activeAreaId);
    const compList = activeArea?.components?.map((comp) => {
      let actualBuilding = null;
      let actualRealEstate = null;
      if (comp?.building) {
        actualBuilding = allBuildings.find((b) => b.id === comp?.building?.id);
      }
      if (comp.realestates) {
        actualRealEstate = allRealEstates.find(
          (r) => r.id === (actualBuilding?.id || comp?.realestates[0]?.id)
        );
      }
      return (
        <SC.ListItem>
          {comp?.title}
          {actualBuilding ? ` i ${actualBuilding?.str_representation}` : ""}
          <br />
          {actualRealEstate
            ? `Fastighet: ${actualRealEstate?.str_representation}`
            : ""}
          <SC.EditButton
            onClick={(e) => handleEditComp(e, comp.id || comp._reduxId)}
          />
          <SC.AbsoluteRemoveButton
            onClick={() => handleRemoveComp(comp.id || comp._reduxId)}
          />
        </SC.ListItem>
      );
    });

    return compList?.length ? (
      compList
    ) : (
      <SC.ListItem>Lägg till ny komponent med knappen nedan</SC.ListItem>
    );
  };

  const currentEditArea = areas?.find(
    (a) => (a.id || a._reduxId) === editAreaOpen
  );
  const canSaveArea =
    currentEditArea?.title && currentEditArea?.realestates?.length > 0;

  const activeArea = areas?.find((a) => (a.id || a._reduxId) === activeAreaId);
  const currentEditComp = activeArea?.components?.find(
    (c) => (c.id || c._reduxId) === editCompOpen?.compId
  );

  const canSaveComp = currentEditComp?.title;
  return (
    <>
      <SC.ListWrapper style={{ width: "95%" }}>
        <SC.ListHeader>
          <SC.ComponentArea>Område</SC.ComponentArea>
          <SC.ComponentName>Komponent</SC.ComponentName>
        </SC.ListHeader>
        <div style={{ display: "flex" }}>
          <SC.ListContent
            style={{
              flex: 3,
              borderRight: "thin solid rgba(0, 0, 0, 0.2)",
            }}
          >
            {renderAreaList()}
          </SC.ListContent>
          <SC.ListContent style={{ flex: 2 }}>
            {activeAreaId ? (
              renderCompList()
            ) : (
              <SC.ListItem>
                Välj ett område för att se dess specifika komponenter
              </SC.ListItem>
            )}
          </SC.ListContent>
        </div>
        <SC.ActionsWrapper>
          <SC.AreaActionsWrapper>
            {!selectExistingAreaOpen ? (
              <>
                <TextButton
                  title="Välj bland befintliga"
                  clicked={() => setSelectExistingAreaOpen(true)}
                  extraStyle={{ marginRight: "14px" }}
                />
                eller
                <TextButton
                  title="Lägg till nytt område"
                  clicked={() => handleAddNewArea()}
                  extraStyle={{ marginLeft: "14px" }}
                  iconType="add"
                  iconPlacement="right"
                />
              </>
            ) : (
              <>
                <SC.SelectWrapper style={{ width: "90%", maxHeight: "40px" }}>
                  <UnconnectedSelect
                    fetchMethod={useFilteredRoundingAreas}
                    fetchQueryString={selectQueryString}
                    changeCallback={handleSelect}
                    noMargin
                  />
                </SC.SelectWrapper>
                <SC.RemoveButton
                  onClick={() => setSelectExistingAreaOpen(false)}
                />
              </>
            )}
            <StandardModal
              isOpen={editAreaOpen}
              title="Hantera område"
              withActionBar
              closeFunction={() => handleCloseAreaForm()}
              saveFunction={() => setEditAreaOpen(false)}
              canAccept={canSaveArea}
              newDesign
              modalInModal
            >
              <AreaForm method={method} id={editAreaOpen} />
            </StandardModal>
          </SC.AreaActionsWrapper>
          <SC.ComponentActionsWrapper>
            {activeAreaId != null && (
              <TextButton
                title="Lägg till komponent"
                clicked={() => handleAddNewComp(activeAreaId)}
                iconType="add"
                iconPlacement="right"
              />
            )}
            <StandardModal
              isOpen={editCompOpen}
              title="Lägg till komponent"
              withActionBar
              closeFunction={() => handleCloseCompForm()}
              saveFunction={() => checkIfCompExist()}
              canAccept={canSaveComp}
              modalInModal
            >
              <CompForm
                method={method}
                id={editCompOpen.compId}
                areaIndex={editCompOpen.areaIndex}
                componentOptions={componentOptions}
                setComponentOptions={setComponentOptions}
                open={editCompOpen}
              />
            </StandardModal>
          </SC.ComponentActionsWrapper>
        </SC.ActionsWrapper>
      </SC.ListWrapper>
    </>
  );
};
