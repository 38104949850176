import Faults from "./Faults";
import Confirm from "./Confirm";
import Document from "./Document";

// Map of chapterdef KEY: Component
export default {
  DOCUMENT: Document,
  FAULTS: Faults,
  CONFIRM: Confirm,
};
