import * as React from "react";
import LocalCheckField from "../Base/Fields/LocalCheckField";
import LocalSelectField from "../Base/Fields/LocalSelectField";
import LocalTextAreaField from "../Base/Fields/LocalTextAreaField";
import LocalTextInputField from "../Base/Fields/LocalTextInputField";
import Modal from "../Base/Modals/Modal";

export default function InvoiceSendToDebtCollection({
  closeFunction,
  handleSendToDebtCollection,
  debtCollectionDeliveryMethod,
  setDebtCollectionDeliveryMethod,
  debtCollectionEviction,
  setDebtCollectionEviction,
  debtCollectionReason,
  setDebtCollectionReason,
  debtCollectionReminderAmount,
  setDebtCollectionReminderAmount,
  startDebtCollectionLevel,
  setStartDebtCollectionLevel,
  endDebtCollectionLevel,
  setEndDebtCollectionLevel,
  debtCollectionPaymentTerms,
  setDebtCollectionPaymentTerms,
  interestTypeDebtCollection,
  setInterestTypeDebtCollection,
  interestStartInDaysAfterDueDate,
  setInterestStartInDaysAfterDueDate,
  reasonForHigherInterest,
  setReasonForHigherInterest,
  debtCollectionSocialWelfare,
  setDebtCollectionSocialWelfare,
  debtCollectionInterestPercentage,
  setDebtCollectionInterestPercentage,
}) {
  return (
    <Modal
      title="Inställningar för kravhantering"
      canAccept={
        debtCollectionDeliveryMethod != null && debtCollectionReason != null
      }
      acceptTitle="Skicka till kravhantering"
      closeFunction={closeFunction}
      denyTitle="Avbryt"
      onAccept={handleSendToDebtCollection}
    >
      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalSelectField
          id="_send_debt_method"
          value={debtCollectionDeliveryMethod}
          choices={[
            { v: "Email", d: "Email (0 kr/st)" },
            { v: "Kivra", d: "Kivra (4 kr/st)" },
            { v: "Mail", d: "Post (6.50 kr/st)" },
          ]}
          onChange={(val) => setDebtCollectionDeliveryMethod(val)}
          title="Leveransmetod"
        />

        <LocalTextInputField
          isNumber
          title="Antal påminnelser"
          id="_debt_collection_reminder_amountl"
          value={debtCollectionReminderAmount}
          onChange={(val) => setDebtCollectionReminderAmount(val)}
        />
      </div>

      <div className="mb-6">
        <LocalTextInputField
          title="Anledning för krav"
          id="_debt_collection_reason"
          value={debtCollectionReason}
          onChange={(val) => setDebtCollectionReason(val)}
          required={true}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalSelectField
          id="_debt_collection_start_level"
          value={startDebtCollectionLevel}
          choices={[
            { v: "LatePaymentFee", d: "Förseningsavgift" },
            { v: "Reminders", d: "Påminnelser" },
            { v: "DebtCollection", d: "Inkasso" },
            { v: "Bailiff", d: "Kronofogden" },
          ]}
          onChange={(val) => setStartDebtCollectionLevel(val)}
          title="Starta kravhantering med"
        />
        <LocalSelectField
          id="_debt_collection_end_level"
          value={endDebtCollectionLevel}
          choices={[
            { v: "LatePaymentFee", d: "Förseningsavgift" },
            { v: "Reminders", d: "Påminnelser" },
            { v: "DebtCollection", d: "Inkasso" },
            { v: "Bailiff", d: "Kronofogden" },
          ]}
          onChange={(val) => setEndDebtCollectionLevel(val)}
          title="Avsluta kravhantering med"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalTextInputField
          isNumber
          title="Betalningsvillkor i dagar"
          id="_debt_collection_payment_terms"
          value={debtCollectionPaymentTerms}
          onChange={(val) => setDebtCollectionPaymentTerms(val)}
          required={true}
          error={
            debtCollectionPaymentTerms == null ||
            debtCollectionPaymentTerms < 10
              ? "Skriv in ett värde större eller lika med 10"
              : undefined
          }
        />
        <LocalSelectField
          id="_debt_collection_interest_type"
          value={interestTypeDebtCollection}
          choices={[
            { d: "Fast ränta", v: "Fixed" },
            { d: "Över gällande referensränta", v: "AboveEffectiveReference" },
            { d: "Ingen ränta", v: "NoInterest" },
          ]}
          onChange={(val) => setInterestTypeDebtCollection(val)}
          title="Typ av dröjsmålsränta"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalTextInputField
          isNumber
          title="Dröjsmålsränta"
          id="_debt_collection_interest"
          value={debtCollectionInterestPercentage}
          onChange={(val) => setDebtCollectionInterestPercentage(val)}
        />
        <LocalTextInputField
          title="Antal dagar innan ränta gäller"
          id="_debt_collection_interest_days"
          value={interestStartInDaysAfterDueDate}
          onChange={(val) => setInterestStartInDaysAfterDueDate(val)}
        />
      </div>

      {debtCollectionInterestPercentage > 8 && (
        <div className="mb-6">
          <LocalTextAreaField
            title="Anledning till hög ränta"
            id="_debt_collection_high_interest_reason"
            value={reasonForHigherInterest}
            onChange={(val) => setReasonForHigherInterest(val)}
          />
        </div>
      )}

      <LocalCheckField
        title="Avhysning skall ske vid
          betalningsföreläggande"
        description="Kravet utgör bostadsavgifter där avhysning skall ske vid
          betalningsföreläggande"
        id="_debt_collection_eviction"
        value={debtCollectionEviction}
        onChange={(val) => setDebtCollectionEviction(val)}
      />
      <LocalCheckField
        title="Socialnämnden
          skall informeras vid betalningsföreläggande"
        description="Kravet utgör bostadsavgifter för privatperson där Socialnämnden
          skall informeras vid betalningsföreläggande"
        id="_debt_collection_social"
        value={debtCollectionSocialWelfare}
        onChange={(val) => setDebtCollectionSocialWelfare(val)}
      />
    </Modal>
  );
}
