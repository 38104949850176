import * as React from "react";
import { constants } from "../../../../../store/inspectionErrandSettings";
import { Select, TextInput } from "../../../Base/Fields";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Typ av besiktning</OverviewTitle>
          <OverviewSubtitle>
            Ange ett namn för besiktningstypen och vilken typ av ärende
            inställningen gäller.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        method={method}
        fieldKey={"title"}
        title="Typ av besiktning"
        description={`Beskriv vilken typ av besiktning som ingår i denna inställning.`}
        alwaysShowDescription
      />

      <Select
        menuPlacement="top"
        storeName={storeName}
        method={method}
        fieldKey={"category"}
        title="Kategori"
        description={`Koppla besiktningstypen till en specifik kategori, lämnas den tom gäller kategorin "Övrig". Det innebär att besiktningstypen kan gälla oavsett specifierad plats/objekt.`}
      />
    </>
  );
};
