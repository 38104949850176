import * as React from "react";
import { buildQueryString } from "../../../../../store/base";

import { constants } from "../../../../../store/roundingErrands";
import { useFilteredRoundingErrandSettings } from "../../../../../store/roundingErrandSettings";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { NonNestedSelect } from "../../../Base/Fields";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const roundingErrandQuery = buildQueryString({ kind__isnull: true });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Typ av rondering</OverviewTitle>
          <OverviewSubtitle>
            Välj vilken typ av rondering{" "}
            {method === "POST" ? "du vill skapa" : "ärendet gäller"}
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <NonNestedSelect
        title="Typ av rondering"
        storeName={storeName}
        method={method}
        fieldKey={"setting"}
        placeholder="Välj typ av rondering..."
        fetchMethod={useFilteredRoundingErrandSettings}
        fetchQueryString={roundingErrandQuery}
      />
    </>
  );
};
