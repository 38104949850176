import moment from "moment";
import { durationParse } from "../../components/Displays/utils";
import { toMoneyString } from "../../components/utils/stringUtils";
import { checkTrailingUrlSlash } from "../base";
import { retrieve, update, upload } from "../base/store/services";
import { PRODUCT_CATEGORIES } from "../invoicingProducts/utils";
import constants from "./store/constants";
import { store } from "../store";
import { addToast, TOAST_TYPES } from "../toasts";
import { BADGE_TYPES } from "../../components/Badge/Badge";
import { FIELD_TYPES } from "../../components/DigitalDoc/utils";
import { cloneDeep } from "lodash";

const CATEGORY_MAP = {
  tenant: "Hyresgäst",
  bi_tenant: "Övrig hyresgäst",
  landlord: "Hyresvärd",
  other: "Övrig",
};

export const cleanSignTypeBasedOnContract = ({
  editableDocData,
  isCancellation,
  contract,
}) => {
  const dataClone = cloneDeep(editableDocData);
  if (isCancellation && !contract.uses_cancellation_e_signing) {
    dataClone.parties = dataClone.parties.map((party) => ({
      ...party,
      authentication_method_to_sign: "standard",
      authentication_method_to_view: "standard",
      confirmation_delivery_method: "none",
      delivery_method: "pad",
    }));
  }

  if (!isCancellation && !contract.uses_e_signing) {
    dataClone.parties = dataClone.parties.map((party) => {
      const cleaned = {
        ...party,
        authentication_method_to_sign: "standard",
        authentication_method_to_view: "standard",
        confirmation_delivery_method: "none",
        delivery_method: "pad",
      };

      return cleaned;
    });
  }

  return dataClone;
};

export const performDocAndFastDocValidation = (data) => {
  if (!data) return data;

  // Clear doc if fastdoc. Needs to send null
  if (data.fastdoc_id) {
    data.doc = null;
  } else {
    data.fastdoc_id = null;
  }

  return data;
};

export const performPageFieldsValidation = (data) => {
  if (!data?.parties) return data;

  let foundMultipleSignFieldsOnSinglePage = false;

  for (let party of data.parties) {
    if (!party.fields) continue;
    if (foundMultipleSignFieldsOnSinglePage) break;

    const partySignField = party.fields.find(
      (f) => f.kind === FIELD_TYPES.SIGNATURE
    );

    if (!partySignField) continue;

    let pagesUsed = [];

    for (let placement of partySignField.placements) {
      if (foundMultipleSignFieldsOnSinglePage) break;

      if (pagesUsed.includes(placement.page)) {
        foundMultipleSignFieldsOnSinglePage = true;
        break;
      }

      pagesUsed.push(placement.page);
    }
  }

  if (foundMultipleSignFieldsOnSinglePage) {
    store.dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Uppdateringen misslyckades",
        description: "Du kan max ha ett signeringsfält per part per sida",
      })
    );
    throw new Error("Detected multiple signature fields on one page");
  }

  return data;
};

const getPartyName = (p, idx, isTemplate) => {
  if (isTemplate) return `Part ${idx + 1} (${CATEGORY_MAP[p.category]})`;

  const pFields = p.fields;

  const firstName = pFields.find((f) => f.kind === "first_name")?.value;
  const lastName = pFields.find((f) => f.kind === "last_name")?.value;
  const companyName = pFields.find((f) => f.kind === "company")?.value;

  let str = "";
  if (firstName) {
    str += `${firstName} `;
  }
  if (lastName) str += `${lastName} `;

  if (companyName) str += `${companyName} `;

  if (str) return str + `(${CATEGORY_MAP[p.category]})`;

  return `Part ${idx + 1} (${CATEGORY_MAP[p.category]})`;
};

export const handleDocError = (data, returnedData) => {
  const currentParties =
    store.getState()[constants.STORE_NAME].formInstance.parties;

  const isTemplate =
    store.getState()[constants.STORE_NAME].formInstance.is_template;

  try {
    let originalParties = data.parties;

    if (!originalParties) throw "err";

    let errorData = {
      party: undefined,
      message: undefined,
    };

    const parties = returnedData?.parties;

    for (let partyIndex in parties) {
      if (!parties[partyIndex]?.fields) continue;

      for (let field of parties[partyIndex].fields) {
        let keys = Object.keys(field);

        if (keys.length === 0) continue;

        for (let key of keys) {
          if (field?.[key] && field[key].length !== 0) {
            errorData = {
              party: parseInt(partyIndex),
              message:
                typeof field[key] === "string" ? field[key] : field[key][0],
            };
            break;
          }
        }
      }

      //if a error has been found, break to not waste performance
      if (errorData.party !== undefined) break;
    }

    let errorPartyTitle = getPartyName(
      currentParties[errorData.party],
      errorData.party,
      isTemplate
    );

    if (!errorPartyTitle) errorPartyTitle = "Ett fel har uppstått";
    else errorPartyTitle = `Fel under parten: ${errorPartyTitle}`;

    store.dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: errorPartyTitle,
        description: !errorData.message
          ? "Uppdateringen misslyckades"
          : `${errorData.message}`,
      })
    );
  } catch (err) {
    store.dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Uppdateringen misslyckades",
      })
    );
  }
};

const getObject = async (url) => {
  try {
    const result = await retrieve({
      url: checkTrailingUrlSlash(url),
    });
    const pdf = result.data?.data;
    if (!pdf) {
      return { data: null, unauthed: false };
    }

    return { data: pdf, unauthed: false };
  } catch (error) {
    let status = 500;
    if (error.response) {
      status = error.response.status;
    }
    if (status === 403) {
      return { data: null, unauthed: true };
    }

    return { data: null, unauthed: false };
  }
};

export const getTemplate = (id, pay) => {
  return getObject(`${constants.FASTDOC_HANDLE_URL}${id}/`);
};

export const getInstance = (id, fastdocId) => {
  if (fastdocId != null) {
    return getObject(
      `${constants.FASTDOC_INSTANCE_URL}${id}/?ensured_fastdoc_id=${fastdocId}`
    );
  } else {
    return getObject(`${constants.FASTDOC_INSTANCE_URL}${id}/`);
  }
};

export const getEditorUrl = async (id, fastdocId) => {
  if (fastdocId != null) {
    return retrieve({
      url: `${constants.FASTDOC_EDITOR_URL}${id}/?ensured_fastdoc_id=${fastdocId}`,
    });
  } else {
    return retrieve({ url: `${constants.FASTDOC_EDITOR_URL}${id}/` });
  }
};

export const unlockFieldEditing = async (id) => {
  return update({
    url: `${constants.PATCH_URL}${id}/`,
    data: { lock_auto_fastdoc_fields: false },
  });
};

export const authenticate = async (username, password) => {
  try {
    await upload({
      url: constants.AUTH_URL,
      data: { username: username, password: password },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const getHistoryProcessName = (id) => {
  return `${id}_SCRIVE_HISTORY`;
};

export const getSignupFormProcessName = () => {
  return "SCRIVE_SIGNUP_FORM";
};

export const getHasSignedUpProcessName = () => {
  return "SCRIVE_HAS_SIGNED_UP";
};

export const getSignupUrl = () => {
  return "/e-signing/sign-up";
};

export const overviewUrl = () => {
  return `/templates`;
};
export const detailUrl = ({ id }) => {
  return `/templates/detail/${id}`;
};
export const createUrl = () => {
  return `/editable-doc/create`;
};
export const editUrl = ({ id }) => {
  return `/editable-doc/edit/${id}`;
};

export const EDITABLE_DOC_CONTRACT_TYPES = {
  LEASE_CONTRACT: "lease-contract",
  OTHER_CONTRACT: "other-contract",
  PARKING_CONTRACT: "parking-contract",
  SERVICE_CONTRACT: "service-contract",
};

export const PARTY_KIND = {
  TENANT: "tenant",
  LANDLORD: "landlord",
  OTHER: "other",
  BITENANTS: "bi_tenant",
};

export const SCRIVE_BADGE_TYPE_MAP = {
  preparation: BADGE_TYPES.YELLOW,
  pending: BADGE_TYPES.DEFAULT,
  closed: BADGE_TYPES.GREEN,
  canceled: BADGE_TYPES.RED,
  timedout: BADGE_TYPES.RED,
  rejected: BADGE_TYPES.RED,
  document_error: BADGE_TYPES.RED,
};

export const SCRIVE_STATE_MAP = {
  preparation: 9,
  pending: 4,
  closed: 1,
  canceled: 3,
  timedout: 6,
  rejected: 6,
  document_error: 6,
};

export const SCRIVE_DESCRIPTION_MAP = {
  preparation: "Dokumentet förbereds för utskick till de signerande parterna",
  pending: "Dokumentet inväntar signering",
  closed: "Dokumentet är signerat",
  canceled: "Signeringsprocessen är avbruten",
  timedout: "Signeringsprocessen är avbruten då tidsgränsen passerades",
  rejected:
    "Signeringsprocessen är avbruten då signering av dokumentet nekades",
  document_error:
    "Dokumentet innehåller fel, signeringsprocessen kan därav ej fortsätta",
};

export const handleContractEditableDocUrl = ({ contractType, id }) => {
  return `/editable-doc/handle/${contractType}/${id}`;
};

export const handleContractCancelledDocUrl = ({ contractType, id }) => {
  return `/cancelled-doc/handle/${contractType}/${id}`;
};

export const matchContractAttributesToPartyFields = ({
  editableDoc,
  contract,
  apartments,
  industrialPremises,
  parkingSpots,
  tenants,
  landlordCompany,
  landlordUser,
  billingSettingLandlordCompany,
  mainTenantId,
}) => {
  const apartmentCosts = (apartments || [])?.reduce((acc, cur) => {
    return [...acc, ...cur.cost_set];
  }, []);
  const indpCosts = (industrialPremises || [])?.reduce((acc, cur) => {
    return [...acc, ...cur.cost_set];
  }, []);
  const parkingCosts = (parkingSpots || [])?.reduce((acc, cur) => {
    return [...acc, ...cur.cost_set];
  }, []);

  const combinedCosts = [
    ...(contract?.costs || []),
    ...(apartmentCosts || []),
    ...(indpCosts || []),
    ...(parkingCosts || []),
  ].filter((cost) => {
    // remove earlier costs
    if (cost.end_date) {
      const end = moment(cost.end_date);
      if (moment().isAfter(end)) return false;
    }

    return true;
  });

  const mainTenant = tenants?.find((t) => t.id === mainTenantId);
  const biTenants = tenants?.filter((t) => t.id !== mainTenantId);

  const landlordParty = editableDoc.parties?.find(
    (party) => party.category === PARTY_KIND.LANDLORD
  );

  // set all fields values to placeholders
  if (editableDoc?.parties?.length) {
    editableDoc.parties.forEach((p) => {
      if (p.fields?.length) {
        p.fields.forEach((f) => {
          f._placeholder = f.value;
          f.value = "";
        });
      }
    });
  }

  // match contract, apartment, industrialpremoses, landlord, costs and invoicing data to landlord template fields
  if (landlordParty) {
    if (contract) {
      const totalMonthlyBaseRent = combinedCosts
        ?.filter(
          (c) =>
            (c.product_category === PRODUCT_CATEGORIES.BASE_RENT ||
              c.product?.category === PRODUCT_CATEGORIES.BASE_RENT) &&
            !c?.do_not_debit
        )
        ?.reduce((acc, cur) => acc + (cur.value || 0), 0);

      landlordParty.fields.forEach((field) => {
        switch (field.kind) {
          case "contract_real_estate_key":
            field.value = contract.realestate?.str_representation || "";
            break;
          case "contract_notify_interval":
            field.value = contract.notify_interval
              ? durationParse(contract?.notify_interval)?.presentation
              : "";
            break;
          case "contract_renew_interval":
            field.value = contract.renew_interval
              ? durationParse(contract?.renew_interval)?.presentation
              : "";
            break;
          case "contract_end_date":
            field.value = contract.end_date ? contract.end_date : "Tillsvidare";
            break;
          case "contract_start_date":
            field.value = contract.start_date || "";
            break;
          case "contract_id":
            field.value = contract.id_number || "";
            break;
          case "contract_total_rent_month":
            field.value = toMoneyString(totalMonthlyBaseRent);
            break;
          case "contract_total_rent_year":
            field.value = toMoneyString((totalMonthlyBaseRent || 0) * 12);
            break;
          default:
            break;
        }

        if (landlordUser) {
          landlordParty.user = landlordUser;

          switch (field.kind) {
            case "full_name":
              field.value = `${landlordUser.first_name || ""} ${
                landlordUser.last_name || ""
              }`;
              break;
            case "company":
              field.value = landlordUser.corporate_name || "";
              break;
            case "first_name":
              field.value = landlordUser.first_name || "";
              break;
            case "last_name":
              field.value = landlordUser.last_name || "";
              break;
            case "personal_number":
              field.value = landlordUser.legal_id || "";
              break;
            case "email":
              field.value = landlordUser.email || "";
              break;
            case "phone":
              field.value = landlordUser.phone || "";
              break;

            default:
              break;
          }
        }

        if (landlordCompany) {
          switch (field.kind) {
            case "landlord_full_name": {
              field.value = landlordCompany.str_representation || "";
              break;
            }
            case "landlord_company_adress":
              field.value = landlordCompany.address?.str_representation || "";
              break;
            case "corporate_name":
              field.value = landlordCompany.name || "";
              break;
            case "company_number":
              field.value = landlordCompany.orgnr || "";
              break;
            default:
              break;
          }
        }

        if (billingSettingLandlordCompany) {
          switch (field.kind) {
            case "autogiro":
              field.value = billingSettingLandlordCompany.autogiro || "";
              break;
            case "bankgiro":
              field.value = billingSettingLandlordCompany.bankgiro || "";
              break;
            default:
              break;
          }
        }
      });
    }

    // apartment fields
    if (apartments?.length) {
      apartments.forEach((apartment, idx) => {
        landlordParty.fields.forEach((field) => {
          switch (field.kind) {
            case `area_apartment_${idx}`:
              field.value = apartment.area || "";
              break;
            case `floor_apartment_${idx}`:
              field.value =
                apartment.dynamic_floors?.length > 0
                  ? apartment.dynamic_floors
                      ?.map((f) => (f === -1 ? "KV" : f === 0 ? "BV" : f))
                      ?.join(", ")
                  : "";
              break;
            case `category_apartment_${idx}`:
              field.value = apartment.category_display || "";
              break;
            case `object_id_apartment_${idx}`:
              field.value = apartment.premises_id || "";
              break;
            case `apartment_id_apartment_${idx}`:
              field.value = apartment.apartment_id || "";
              break;
            case `address_apartment_${idx}`:
              field.value =
                apartment?.address?.str_representation?.split(",")?.[0] || "";
              break;
            case `zip_apartment_${idx}`:
              field.value = apartment?.address?.postal_code || "";
              break;
            case `city_apartment_${idx}`:
              field.value = apartment?.address?.city || "";
              break;
            case `municipality_apartment_${idx}`:
              field.value = apartment?.address?.municipality || "";
              break;

            default:
              break;
          }
        });
      });
    }

    // industrialPremises fields
    if (industrialPremises?.length) {
      industrialPremises.forEach((indp, idx) => {
        landlordParty.fields.forEach((field) => {
          switch (field.kind) {
            case `area_industrialPremises_${idx}`:
              field.value = indp.area || "";
              break;
            case `floor_industrialPremises_${idx}`:
              field.value =
                indp.dynamic_floors?.length > 0
                  ? indp.dynamic_floors
                      ?.map((f) => (f === -1 ? "KV" : f === 0 ? "BV" : f))
                      ?.join(", ")
                  : "";
              break;
            case `category_industrialPremises_${idx}`:
              field.value = indp.category_display || "";
              break;
            case `object_id_industrialPremises_${idx}`:
              field.value = indp.premises_id || "";
              break;
            case `address_industrialPremises_${idx}`:
              field.value =
                indp?.address?.str_representation?.split(",")?.[0] || "";
              break;
            case `zip_industrialPremises_${idx}`:
              field.value = indp?.address?.postal_code || "";
              break;
            case `city_industrialPremises_${idx}`:
              field.value = indp?.address?.city || "";
              break;
            case `municipality_industrialPremises_${idx}`:
              field.value = indp?.address?.municipality || "";
              break;
            default:
              break;
          }
        });
      });
    }

    // parkingspot fields
    if (parkingSpots?.length) {
      parkingSpots.forEach((spot, idx) => {
        landlordParty.fields.forEach((field) => {
          switch (field.kind) {
            case `title_parkingSpots_${idx}`:
              field.value = spot.title || "";
              break;
            case `address_parkingSpots_${idx}`:
              field.value =
                spot?.address?.str_representation?.split(",")?.[0] || "";
              break;
            case `zip_parkingSpots_${idx}`:
              field.value = spot?.address?.postal_code || "";
              break;
            case `city_parkingSpots_${idx}`:
              field.value = spot?.address?.city || "";
              break;
            case `municipality_parkingSpots_${idx}`:
              field.value = spot?.address?.municipality || "";
              break;
            case `parkinglot_parkingSpots_${idx}`:
              field.value = spot?.parking_lot?.str_representation || "";
              break;
            default:
              break;
          }
        });
      });
    }

    if (industrialPremises?.length || apartments?.length) {
      // total sum fields
      const totalPremisesArea = [
        ...(apartments || []),
        ...(industrialPremises || []),
      ].reduce((acc, cur) => {
        return acc + cur.area;
      }, 0);

      landlordParty.fields.forEach((field) => {
        switch (field.kind) {
          case `total_area`:
            field.value = totalPremisesArea || "";
            break;
          default:
            break;
        }
      });
    }
  }

  // match main tenant fields to main tenant
  if (mainTenant) {
    const matchingParty = editableDoc.parties.find((party) => {
      return party.category === PARTY_KIND.TENANT;
    });

    if (matchingParty) {
      matchingParty.user = mainTenant.user;
      const fullName = `${mainTenant.user.first_name || ""} ${
        mainTenant.user.last_name || ""
      }`;
      matchingParty.fields.forEach((field) => {
        switch (field.kind) {
          case "full_name":
            field.value = fullName || "";
            break;
          case "first_name":
            field.value = mainTenant.user.first_name || "";
            break;
          case "last_name":
            field.value = mainTenant.user.last_name || "";
            break;
          case "personal_number":
            field.value = mainTenant.user.legal_id || "";
            break;
          case "email":
            field.value = mainTenant.user.email || "";
            break;
          case "phone":
            field.value = mainTenant.user.phone || "";
            break;
          case "company":
            field.value = mainTenant.user.corporate_name || "";
            break;
          case "corporate_name":
            field.value = mainTenant.user.corporate_name || "";
            break;
          case "separate_address":
            field.value =
              mainTenant.separate_address?.str_representation?.split(
                ","
              )?.[0] || "";
            break;
          case "separate_address_postalcode":
            field.value = mainTenant.separate_address?.postal_code || "";
            break;
          case "separate_address_city":
            field.value = mainTenant.separate_address?.city || "";
            break;
          default:
            break;
        }
      });
    }
  }

  if (biTenants?.length) {
    const biTenantParties = [];

    // find all bi-tenants
    editableDoc.parties.forEach((p) => {
      if (p.category === PARTY_KIND.BITENANTS) {
        biTenantParties.push(p);
      }
    });

    // if we have bi-tenant parties, go through and match
    if (biTenantParties?.length) {
      biTenantParties.forEach((btp, idx) => {
        const biTenantWithMatchingIdx = biTenants[idx];

        if (biTenantWithMatchingIdx) {
          btp.user = biTenantWithMatchingIdx.user;
          btp.fields.forEach((field) => {
            switch (field.kind) {
              case "full_name":
                field.value = `${
                  biTenantWithMatchingIdx.user.first_name || ""
                } ${biTenantWithMatchingIdx.user.last_name || ""}`;
                break;
              case "first_name":
                field.value = biTenantWithMatchingIdx.user.first_name || "";
                break;
              case "last_name":
                field.value = biTenantWithMatchingIdx.user.last_name || "";
                break;
              case "personal_number":
                field.value = biTenantWithMatchingIdx.user.legal_id || "";
                break;
              case "email":
                field.value = biTenantWithMatchingIdx.user.email || "";
                break;
              case "phone":
                field.value = biTenantWithMatchingIdx.user.phone || "";
                break;
              case "company":
                field.value = biTenantWithMatchingIdx.user.corporate_name || "";
                break;
              case "separate_address":
                field.value =
                  biTenantWithMatchingIdx.separate_address?.str_representation?.split(
                    ","
                  )?.[0] || "";
                break;
              case "separate_address_postalcode":
                field.value =
                  biTenantWithMatchingIdx.separate_address?.postal_code || "";
                break;
              case "separate_address_city":
                field.value =
                  biTenantWithMatchingIdx.separate_address?.city || "";
                break;
              default:
                break;
            }
          });
        }
      });
    }
  }

  return editableDoc;
};

const getTenantPartyFields = (tenant) => {
  const fields = [
    {
      kind: "first_name",
      name: "Förnamn",
      value: tenant.user?.first_name || "",
    },
    {
      kind: "last_name",
      name: "Efternamn",
      value: tenant.user?.last_name || "",
    },
    {
      kind: "personal_number",
      name: "Personnummer",
      value: tenant.user?.legal_id || "",
    },
    {
      kind: "company_number",
      name: "Orgnr",
      value: tenant.user?.corporate_name ? tenant.user?.legal_id || "" : "",
    },
    {
      kind: "email",
      name: "Email",
      is_obligatory: true,
      value: tenant.user?.email || "",
    },
    {
      kind: "phone",
      name: "Telefonnummer",
      value: tenant.user?.phone || "",
    },
    {
      kind: "company",
      name: "Företagsnamn",
      value: tenant.user?.corporate_name || "",
    },
  ];

  return fields;
};

const getLandlordPartyFields = ({ landlordUser, landlordCompany }) => {
  const fields = [
    {
      kind: "first_name",
      name: "Förnamn",
      value: landlordUser?.first_name || "",
    },
    {
      kind: "last_name",
      name: "Efternamn",
      value: landlordUser?.last_name || "",
    },
    {
      kind: "personal_number",
      name: "Personnummer",
      value: landlordUser?.legal_id || "",
    },
    {
      kind: "company_number",
      name: "Organisationnummer",
      value: landlordCompany?.orgnr || "",
    },
    {
      kind: "email",
      name: "Email",
      is_obligatory: true,
      value: landlordUser?.email || "",
    },
    {
      kind: "phone",
      name: "Telefonnummer",
      value: landlordUser?.phone || "",
    },
    {
      kind: "company",
      name: "Företagsnamn",
      value: landlordCompany?.name || "",
    },
  ];

  return fields;
};

export const setInitialParties = ({
  editableDoc,
  landlordUser,
  landlordCompany,
  tenants,
  mainTenantId,
}) => {
  if (editableDoc && !editableDoc.parties) {
    editableDoc.parties = [];
  }

  if (tenants?.length) {
    tenants.forEach((tenant) => {
      const fields = getTenantPartyFields(tenant);

      editableDoc.parties.push({
        user: tenant.user,
        category:
          tenant?.id === mainTenantId
            ? PARTY_KIND.TENANT
            : PARTY_KIND.BITENANTS,
        fields,
        signatory_role: "signing_party",
        delivery_method: "email",
        confirmation_delivery_method: "email",
        authentication_method_to_sign: "standard",
        authentication_method_to_view: "standard",
      });
    });
  }

  if (landlordUser || landlordCompany) {
    const fields = getLandlordPartyFields({ landlordUser, landlordCompany });
    editableDoc.parties.push({
      user: landlordUser,
      category: PARTY_KIND.LANDLORD,
      fields,
      signatory_role: "signing_party",
      delivery_method: "email",
      confirmation_delivery_method: "email",
      authentication_method_to_sign: "standard",
      authentication_method_to_view: "standard",
    });
  }

  return editableDoc;
};
