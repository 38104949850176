import React from "react";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import { TextField } from "../../Base/Fields";
import { constants } from "../../../../store/roundingErrandSettings";

import CreatableSelect from "../CreatableSelect";
import { useDispatch } from "react-redux";
import { cloneDeep, isEqual } from "lodash";
import { useFilteredRoundingComponents } from "../../../../store/roundingComponents";
import TableSelectField from "../../Base/Fields/TableSelectField";
import BuildingTable from "src/components/Tables/Buildings/FullTable";

export default function CompForm({
  id,
  areaIndex,
  method,
  componentOptions,
  setComponentOptions,
}) {
  const storeName = constants.STORE_NAME;

  const dispatch = useDispatch();
  const [componentTitle, setComponentTitle] = React.useState(null);
  const areas = useFormInstanceField({ storeName, fieldKey: "areas" });
  const allComponentsRef = React.useRef();

  const compIndex = areas[areaIndex]?.components?.findIndex(
    (c) => (c.id || c._reduxId) === id
  );
  const realEstateIds = areas[areaIndex]?.realestates?.map((r) => r.id || r);
  const persistantQuery = {
    realestate__in: realEstateIds,
  };

  const [allComponents, allComponentsLoading] = useFilteredRoundingComponents();

  const addOption = (option) => {
    const optionsClone = cloneDeep(componentOptions);

    optionsClone.push({ title: option.title, id: option.title });
    setComponentOptions(optionsClone);
  };

  const handlePickedExistingComponent = (data) => {
    if (!data) {
      setComponentTitle(null);
      return;
    }
    setComponentTitle(data);
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`areas[${areaIndex}].components[${compIndex}].title`]: data.title,
        },
      })
    );
  };

  const getOptionLabel = (option) => {
    return option.title === null ? "Välj eller skapa ny..." : option.title;
  };
  const getOptionValue = (option) => {
    return option.id || option._internalId;
  };

  React.useEffect(() => {
    if (id && areaIndex != null) {
      const component = areas[areaIndex]?.components?.find(
        (c) => (c.id || c._reduxId) === id
      );
      if (component) {
        setComponentTitle({ id: component.title, title: component.title });
      }
    }
  }, []);

  React.useEffect(() => {
    if (componentOptions?.length) return;

    if (isEqual(allComponents, allComponentsRef?.current)) return;

    allComponentsRef.current = allComponents;

    const options = [
      ...new Set(allComponents.map((c) => ({ title: c.title, id: c.title }))),
    ];

    setComponentOptions(options);
  }, [allComponents]);

  const nextId = componentOptions?.length || "0";

  return (
    <>
      <div style={{ marginBottom: "20px", fontSize: "1.2rem" }}>
        Välj komponent och vilken byggnad den tillhör.
      </div>
      <div>Välj typ av komponent nedan, skriv för att skapa en ny typ</div>
      <CreatableSelect
        value={componentTitle}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        addOption={addOption}
        options={componentOptions}
        nextId={nextId}
        onChange={handlePickedExistingComponent}
        menuPlacement={"bottom"}
      />
      <br />
      <TextField
        storeName={storeName}
        method={method}
        title="Beskrivning"
        fieldKey={`areas[${areaIndex}].components[${compIndex}].description`}
        instructionsKey={"areas.components.description"}
      />
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={persistantQuery}
          placeholder="Välj byggnad..."
          title="Byggnad"
          method={method}
          fieldKey={`areas[${areaIndex}].components[${compIndex}].building`}
          instructionsKey="areas.components.building"
          TableComponent={BuildingTable}
        />
      </div>
    </>
  );
}
