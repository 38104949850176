import { cloneDeep } from "lodash";
import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  FilterButton,
  FilterWrapper,
} from "../../../components/Filters/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import useActiveFilters from "../../../hooks/useActiveFilters";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import SettingModalForm from "../../../components/Forms/GovErrandSetting/ChapterForm/ModalForm";

import SettingsTable from "../../../components/Tables/RoundingErrandSettings/FullTable";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

const PERSISTANT_CATEGORY_KEY_SETTINGS =
  "persistant_category_goverrandsettings";

export default function Settings() {
  const { filteredRealEstates } = useActiveFilters();

  const [createSettingOpen, setCreateSettingOpen] = React.useState(false);

  const persistantQuerySettings = {
    realestate_ids: filteredRealEstates,
    kind__isnull: false,
  };

  const canAdd = useAllPermissionCheck([
    "add_can_goverrand",
    "allow_errand_gov",
  ]);

  return (
    <>
      {canAdd && (
        <SettingModalForm
          method="POST"
          isOpen={createSettingOpen}
          onCheckout={() => setCreateSettingOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Myndighetskrav</OverviewTitle>
            {canAdd && (
              <PrimaryButton
                title="Lägg till nytt myndighetskrav"
                clicked={() => setCreateSettingOpen(true)}
              />
            )}
          </OverviewTitleWrapper>
          <SettingsTable
            title="Myndighetskrav"
            persistantQuery={persistantQuerySettings}
          />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
