import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style, design
import ModalChapter from "../../Base/Chapters/ModalChapter";
import renderChapter from "./renderChapter";

// store, state
import chapterDefs from "./chapterDefs";

import {
  useRoundingErrandSetting,
  useRoundingErrandSettingForm,
  destroyPatchForm,
  destroyPostForm,
  constants,
  detailUrl,
  create,
  update,
} from "../../../../store/roundingErrandSettings";
import { cloneDeep } from "lodash";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import { TOAST_TYPES, addToast } from "../../../../store/toasts";
import { setCustomFormError } from "../../../../store/roundingErrandSettings/store/actions";

export default ({ method, id, isOpen, onCheckout }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const [roundingErrandSetting, roundingErrandSettingLoading] =
    useRoundingErrandSetting(id);

  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useRoundingErrandSettingForm(method, id));

  const choosenKind = useFormInstanceField({ storeName, fieldKey: "kind" });

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    dispatch(updateActiveFormInstance({ storeName, data: undefined }));
    onCheckout(success);
  };

  const onSuccess = (data, returned) => {
    setLoading(false);
    checkout(true);
    if (method === "PATCH") {
      dispatch(updateActiveFormInstance({ storeName, data: returned }));
    }

    if (method === "POST") {
      push(detailUrl({ id: returned.id, isGov: true }));
    }
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);
    //set building to null if its undefined when removed during edit
    if (dataClone?.areas?.length) {
      dataClone.areas.forEach((a) => {
        if (a?.components?.length) {
          a.components.forEach((c) => {
            if (c.building === undefined) {
              c.building = null;
            }
          });
        }
      });
    }
    return dataClone;
  };

  const onSubmit = () => {
    if (choosenKind === null || choosenKind === undefined) {
      dispatch(
        setCustomFormError({
          key: "kind",
          message: "Ange en typ",
        })
      );

      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Måste sätta typ av myndighetskrav",
        })
      );

      return;
    }

    setLoading(true);

    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          preProcess,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: roundingErrandSetting,
      })
    );
  }, [roundingErrandSetting]);

  React.useEffect(() => {
    return () => {
      dispatch(updateActiveFormInstance({ storeName, data: undefined }));
    };
  }, []);

  return (
    <ModalChapter
      storeName={constants.STORE_NAME}
      chapterDefs={chapterDefs()}
      onRenderChapter={(key) => renderChapter({ key, method })}
      submitCallback={onSubmit}
      title={
        method === "POST"
          ? "Skapa myndigshetskrav"
          : "Uppdatera myndigshetskrav"
      }
      onDone={onDone}
      loading={!formLoaded || loading}
      isOpen={isOpen}
    />
  );
};
