import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import DetailInfo from "../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { LinkedObject } from "../../components/Displays";
import { TextButton } from "../../components/Forms/Base/Buttons";
import PrimaryBtn from "../../components/Forms/Base/Buttons/PrimaryBtn";
import DeleteModal from "../../components/Forms/Delete/DeleteModal";
import IndexSettingModalForm, {
  decideSettingType,
} from "../../components/Forms/IndexSetting/ModalForm/ModalForm";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailInnerWrapper } from "../../components/sharedStyles";
import { useIndexSetting, constants } from "../../store/indexsetting";
import { detailUrl as tableDetailUrl } from "../../store/indexTable";
import ConfirmModal from "../../components/Forms/Base/Modals/ConfirmModal";

export default function IndexSettingDetail() {
  const { id } = useParams();
  const { goBack } = useHistory();
  const [setting, settingLoading] = useIndexSetting(id);
  const type = decideSettingType(setting);

  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [acceptingChanges, setAcceptingChanges] = React.useState({
    open: false,
    which: "",
  });

  const doEditOrDelete = () => {
    if (acceptingChanges.which === "delete") {
      setDeleteOpen(true);
    } else {
      setEditOpen(true);
    }
  };

  const resetAcceptingChanges = () => {
    setAcceptingChanges({
      open: false,
      which: "",
    });
  };

  const openConfirmChangesModal = (which) => {
    setAcceptingChanges({
      open: true,
      which,
    });
  };

  return (
    <>
      {acceptingChanges.open && (
        <ConfirmModal
          closeFunction={() => resetAcceptingChanges()}
          onAccept={doEditOrDelete}
        >
          <p>
            Om du ändrar indexuppräkningen kommer det påverka alla kopplade
            debiteringsrader.
          </p>
          <p className="mt-6">Vill du fortsätta?</p>
        </ConfirmModal>
      )}

      {editOpen && (
        <IndexSettingModalForm
          id={id}
          instance={setting}
          method="PATCH"
          closeFunction={() => setEditOpen(false)}
        />
      )}

      <DeleteModal
        isOpen={!!deleteOpen}
        instance={setting}
        constants={constants}
        closeFunction={() => {
          setDeleteOpen(false);
        }}
        deletedCallback={() => {
          goBack();
        }}
      />

      <DetailInnerWrapper>
        <TextButton
          title="Tillbaka"
          iconType="arrow-back"
          clicked={() => goBack()}
          extraStyle={{ marginBottom: 24 }}
        />
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              {settingLoading
                ? "Laddar..."
                : setting?.title ||
                  setting?.str_representation ||
                  "Titel saknas "}
            </OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>

          <div className="fled space-x-2">
            <PrimaryBtn onClick={() => openConfirmChangesModal("edit")}>
              Redigera
            </PrimaryBtn>
            <PrimaryBtn
              secondaryError
              onClick={() => openConfirmChangesModal("delete")}
            >
              Radera
            </PrimaryBtn>
          </div>
        </OverviewTitleWrapper>

        <DetailInfo
          infoObj={{
            Inställningar: [
              {
                title: "Typ av index",
                value:
                  type === "SET"
                    ? "Fast index"
                    : type === "DYNAMIC"
                    ? "Dynamiskt enligt KPI 1980 (Oktober)"
                    : "Dynamiskt enligt vald tabell",
              },
              {
                title: "Vald tabell",
                value: (
                  <LinkedObject
                    obj={setting?.table}
                    urlMethod={tableDetailUrl}
                  />
                ),
                hidden: type !== "CUSTOM_DYNAMIC",
              },
              {
                title: "Andel som räknas upp",
                value: setting?.index_amount + "%",
              },
              {
                title: "Indexuppräkning",
                value: setting?.index_quota + "%",
                hidden: type !== "SET",
              },
              {
                title: "Minimal indexkvot",
                value: !setting?.index_min_val
                  ? "-"
                  : setting?.index_min_val + "%",
              },
              {
                title: "Maximal indexkvot",
                value: !setting?.index_max_val
                  ? "-"
                  : setting?.index_max_val + "%",
              },
              {
                title: "Minsta förändring i enheter",
                value: setting?.index_min_points || "-",
              },
              {
                title: "Antal decimaler vid beräkning",
                value: setting?.calculate_with_decimals,
              },
              {
                title: "Metod för avrundning",
                value: setting?.final_rounding_mode_display,
              },
              {
                title: "Tillåt säkning",
                value: (
                  <StatusLabel state={setting?.allow_reduction ? 0 : 6}>
                    {setting?.allow_reduction ? "Ja" : "Nej"}
                  </StatusLabel>
                ),
              },
            ],
            "Datum & Perioder": [
              {
                title: "Basdatum",
                value: setting?.index_base_date,
              },
              {
                title: "Basmånad",
                value: getMonth(
                  !setting?.index_base_month ? 10 : setting.index_base_month
                ),
              },
              {
                title: "Startmånad för indexår",
                value: getMonth(setting?.index_around_month),
              },
              {
                title: "Antal nådemånader",
                value: setting?.initial_zero_value_months || 0,
              },
            ],
            "Avisering & Bokföring": [
              {
                title: "Visa källa på avier",
                value: (
                  <StatusLabel state={setting?.include_cost_title ? 0 : 6}>
                    {setting?.include_cost_title ? "Ja" : "Nej"}
                  </StatusLabel>
                ),
              },
              {
                title: "Namn på avier",
                value: setting?.indexation_value_title,
                hidden: setting?.include_cost_title,
              },
              {
                title: "Använd produkt från debiteringsrad",
                value: (
                  <StatusLabel state={setting?.use_cost_product ? 0 : 6}>
                    {setting?.use_cost_product ? "Ja" : "Nej"}
                  </StatusLabel>
                ),
              },
              {
                title: "Produkt som används",
                value: setting?.product?.str_representation,
                hidden: setting?.use_cost_product,
              },
            ],
          }}
        />
      </DetailInnerWrapper>
    </>
  );
}

const getMonth = (month) => {
  switch (month) {
    case 1:
      return "Januari";
    case 2:
      return "Februari";
    case 3:
      return "Mars";
    case 4:
      return "April";
    case 5:
      return "Maj";
    case 6:
      return "Juni";
    case 7:
      return "Juli";
    case 8:
      return "Augusti";
    case 9:
      return "September";
    case 10:
      return "Oktober";
    case 11:
      return "November";
    case 12:
      return "December";

    default:
      return "-";
  }
};
