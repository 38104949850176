import {
  ExclamationTriangleIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../../../store/base";
import { formatError } from "./utils";

export default function M2mField({
  storeName,
  fieldKey,
  method,
  instructionsKey,
  instanceTitle,
  title,
  description,
  forceError,
  forceRequired,
  forceInstructions,
  disabled,
  children,
}) {
  const dispatch = useDispatch();

  const instructions =
    useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    }) || forceInstructions;

  const required =
    forceRequired || (instructions?._required && !instructions._allowNull);

  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey }) || forceError;

  const addInstance = () => {
    const valueClone = cloneDeep(value || []);

    valueClone.push({});
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: valueClone,
        },
      })
    );
  };

  const removeIdx = (idx) => {
    const valueClone = cloneDeep(value || []);

    valueClone.splice(idx, 1);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [fieldKey]: valueClone,
        },
      })
    );
  };

  if (!instructions || instructions?._readOnly) return null;

  return (
    <div>
      {title && (
        <label
          htmlFor={fieldKey}
          className=" text-sm flex items-center font-medium text-gray-900 "
        >
          {(error || forceError) && (
            <ExclamationTriangleIcon width={16} className="text-red-600 mr-1" />
          )}
          {title}
          {required ? "*" : ""}
        </label>
      )}
      {description && (
        <p
          id={`${fieldKey}-description`}
          className="text-xs mb-1 font-normal text-gray-500 bg-transparent"
        >
          {description}
        </p>
      )}
      {(error || forceError) && (
        <div className="text-xs mb-1 font-normal text-red-600 ">
          {formatError(forceError || error)}
        </div>
      )}
      <button
        onClick={(e) => {
          e.preventDefault();
          addInstance();
        }}
        disabled={disabled}
        className={`inline-flex w-full ${
          disabled ? "bg-slate-200 opacity-80 cursor-not-allowed" : "bg-white"
        } items-center border border-solid border-slate-300 text-gray-900  rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-50 hover:bg-sky-100  focus:outline-none text-sm p-2.5 text-center `}
      >
        Lägg till {instanceTitle}
        <PlusIcon width={16} className="ml-auto" />
      </button>

      {value?.length > 0 && (
        <div className="border border-solid rounded-sm border-gray-200 mt-2">
          {value?.map((_, idx) => {
            const parentPath = `${fieldKey}[${idx}]`;

            return (
              <div className="p-2 [&:not(:first-child)]:border-t border-solid border-slate-200 even:bg-gray-100">
                <div className="flex justify-between pb-2 items-center">
                  <div className="text-sm font-medium">
                    {instanceTitle?.charAt(0)?.toUpperCase() +
                      instanceTitle?.slice(1)}{" "}
                    {idx + 1}
                  </div>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                    onClick={() => removeIdx(idx)}
                  >
                    <XCircleIcon width={24} />
                  </button>
                </div>
                <div className="grid grid-cols-1 gap-2">
                  {children(parentPath, instructionsKey)}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
