import * as React from "react";
import FlowFormBase from "../../Base/FlowForm/FlowFormBase";

import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import {
  constants,
  updateInstance,
  updateExclusionRules,
} from "../../../../store/massConfigPremisesComponents";
import { useDispatch, useSelector } from "react-redux";
import { buildQueryString } from "../../../../store/base";
import { useHistory } from "react-router-dom";

export default function MassConfigPremisesComponentsQuery() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const { push } = useHistory();

  const instance = useSelector((state) => state[storeName].instance);
  const onSubmit = () => {
    const q = buildQueryString({
      building__in: instance?.buildings?.map((b) => b.id) || undefined,
      floors: instance?.floors?.length ? instance.floors : undefined,
      category__in: instance?.categories?.length
        ? instance?.categories
        : undefined,
      realestate__ids: instance?.realestates?.length
        ? instance.realestates.map((r) => r.id)
        : undefined,
    });

    push(
      `${constants.PREVIEW_PATH}?query=${encodeURIComponent(q)}&type=${
        instance.type
      }`
    );
  };

  return (
    <FlowFormBase
      {...{
        storeName,
        updateFunction: ({ key, value }) =>
          dispatch(updateInstance({ key, value })),
        updateExclusionsFunction: ({ key, value }) =>
          dispatch(updateExclusionRules({ key, value })),
        chapterDefs: chapterDefs(),
        chapters,
        descriptions,
        onSubmit,
        title: "Inställningar för uppsättning",
      }}
    />
  );
}
