import { cloneDeep } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import GroupList from "../../components/Account/GroupList";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../components/Details/OverviewInfo/styles";
import { InfoBox } from "../../components/Displays";
import { PrimaryButton } from "../../components/Forms/Base/Buttons";
import RenderGroup from "../../components/Forms/UserGroup/Permissions/RenderGroup";
import WhitelistPermsForm from "../../components/Forms/WhitelistPerms/WhitelistPermsForm";
import OverlaySpinner from "../../components/Loaders/OverlaySpinner";
import StandardModal from "../../components/Modals/StandardModal";
import * as SharedStyles from "../../components/sharedStyles";
import useQuery from "../../components/utils/useQuery";

import {
  buildQueryString,
  updateActiveFormInstance,
  useAllPermissionCheck,
} from "../../store/base";
import { useSupervisorCheck } from "../../store/base/hooks/permissions";
import {
  constants as groupConstants,
  useFilteredUserGroups,
  destroyPatchForm as destroyPatchGroupForm,
} from "../../store/userGroups";
import { update as updateGroup } from "../../store/userGroups/store/actions";
import appConstants from "../../store/app/constants";
import { useUser } from "../../store/users";
import PageTitle from "../Layouts/PageTitle/PageTitle";

const STEPS = {
  GROUP: "GROUP",
  PERMS: "PERMS",
  DETAIL: "DETAIL",
};

export default () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const userId = useSelector((state) => state.app.user?.id);
  const hasMfa = useSelector((state) => state.app.user?.has_mfa);
  const [user] = useUser(userId);

  const showActivateMFA = hasMfa != true;

  const canChangeGroup = useAllPermissionCheck([
    "change_can_permissions",
    "view_can_permissions",
  ]);
  const isSupervisor = useSupervisorCheck();

  const canEditUser = useAllPermissionCheck([
    "view_can_user",
    "change_can_user",
  ]);

  // only subgroups

  const groupsQuery = buildQueryString({
    users__id: user?.id,
  });
  const [groups] = useFilteredUserGroups(groupsQuery);
  const groupIds = groups?.map((g) => g.id);
  const group = groups?.find((g) => g.users.find((u) => u.id === user?.id));
  const [changeGroupLoading, setChangeGroupLoading] = React.useState(false);
  const [selectGroupOpen, setSelectGroupOpen] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(STEPS.GROUP);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [initialGroup, setInitialGroup] = React.useState(null);

  const persistantQuery = {
    group_type__isnull: true,
    "id__in!": groupIds,
  };

  const handleGroupSelected = (row) => {
    setSelectedGroup(row.original);
  };
  const checkRowHighlighted = (row) => {
    return row?.original?.id === selectedGroup?.id;
  };

  // const activityQueryString = buildQueryString({
  //   _page: "1",
  //   _page_size: "10",
  //   owner__id: user?.id,
  // });

  // USERACTIVITY
  // const [latestUserActivities] =
  //   useUserActivitiesPagination(activityQueryString);

  const goToEdit = () => {
    push(`/configcenter/profile/edit/${userId}`);
  };

  const handleNextClicked = () => {
    switch (currentStep) {
      case STEPS.GROUP:
        setCurrentStep(STEPS.PERMS);
        break;
      case STEPS.PERMS:
        setCurrentStep(STEPS.DETAIL);
        break;
      case STEPS.DETAIL:
        handleSaveGroupSwitch();
    }
  };

  const setUserInNewGroup = () => {
    if (initialGroup?.name !== selectedGroup?.name) {
      const selectedGroupClone = cloneDeep(selectedGroup);
      selectedGroupClone.users.push({ id: parseInt(userId) });
      dispatch(
        updateActiveFormInstance({
          storeName: groupConstants.STORE_NAME,
          data: selectedGroupClone,
        })
      );
      dispatch(
        updateGroup({
          id: selectedGroup.id,
          successCallback: () => {
            setSelectGroupOpen(false);
            destroyPatchGroupForm(true);
            setChangeGroupLoading(false);
          },
          errorCallback: () => {
            setSelectGroupOpen(false);
            destroyPatchGroupForm(true);
            setChangeGroupLoading(false);
          },
        })
      );
    } else {
      setSelectedGroup(false);
      destroyPatchGroupForm(true);
      setChangeGroupLoading(false);
    }
    setCurrentStep(STEPS.GROUP);
  };

  const handleSaveGroupSwitch = () => {
    setChangeGroupLoading(true);
    if (initialGroup) {
      const groupClone = cloneDeep(initialGroup);
      const newUserList = groupClone.users.filter(
        (u) => u.id !== parseInt(userId)
      );
      groupClone.users = newUserList;
      dispatch(
        updateActiveFormInstance({
          storeName: groupConstants.STORE_NAME,
          data: groupClone,
        })
      );
      dispatch(
        updateGroup({
          id: initialGroup.id,
          successCallback: () => setUserInNewGroup(),
          errorCallback: () => {},
        })
      );
    } else {
      setUserInNewGroup();
    }
  };

  React.useEffect(() => {
    if (group) {
      setSelectedGroup(group);
      setInitialGroup(group);
    }
  }, [group]);

  const breadCrumbs = [
    {
      label: "Min profil",
      url: "/configcenter/profile/user",
    },
    {
      label: "Behörigheter",
    },
  ];

  let actions = [];

  if (canEditUser)
    actions.push({
      name: "Redigera",
      onClick: () => goToEdit(),
    });

  if (showActivateMFA) {
    actions.push({
      name: "Aktivera 2-faktors autentisering",
      onClick: () => {
        dispatch({
          type: appConstants.MFA_SETTING,
          payload: {
            show: true,
            enforce: false,
          },
        });
      },
    });
  }

  return (
    <SharedStyles.BareLayoutWrapper>
      {!user && <OverlaySpinner />}
      <SharedStyles.BareLayoutTitleWrapper>
        <PageTitle title="Behörigheter" breadCrumbs={breadCrumbs} />
        {/* {(canChangeGroup || isSupervisor) && (
        )} */}
      </SharedStyles.BareLayoutTitleWrapper>
      <SharedStyles.DetailPageBox>
        {changeGroupLoading && <OverlaySpinner />}
        {group ? (
          <>
            <OverviewTitleWrapper>
              <OverviewTitle>Behörigheter grupp {group?.name}</OverviewTitle>
              {(canChangeGroup || isSupervisor) && (
                <PrimaryButton
                  title="Byt grupp"
                  clicked={() => setSelectGroupOpen(true)}
                />
              )}
            </OverviewTitleWrapper>

            <RenderGroup group={group} disabled={true} />
          </>
        ) : (
          <OverviewTitleWrapper>
            <OverviewTitle>Användaren tillhör ingen grupp</OverviewTitle>
            {(canChangeGroup || isSupervisor) && (
              <PrimaryButton
                title="Lägg till i grupp"
                clicked={() => setSelectGroupOpen(true)}
              />
            )}
          </OverviewTitleWrapper>
        )}
        <StandardModal
          isOpen={selectGroupOpen}
          closeFunction={() => {
            setCurrentStep(STEPS.GROUP);
            setSelectedGroup(initialGroup);
            setSelectGroupOpen(false);
          }}
          saveFunction={() => handleNextClicked()}
          disabled={!!selectedGroup}
          title="Byt grupp"
          withActionBar
          actionBarAcceptTitle={
            currentStep === STEPS.DETAIL ? "Genomför byte" : "Nästa"
          }
          large
        >
          {currentStep === STEPS.GROUP && (
            <GroupList
              persistantQuery={persistantQuery}
              overrideRowClick={handleGroupSelected}
              checkRowHighlighted={checkRowHighlighted}
            />
          )}
          {currentStep === STEPS.PERMS && (
            <>
              <InfoBox
                title="Information om vald grupp"
                text="Nedan specifieras vilka behörigheter användaren får när den ligger i denna grupp"
              />
              <RenderGroup group={selectedGroup} disabled={true} />
            </>
          )}
          {currentStep === STEPS.DETAIL && (
            <>
              <InfoBox
                title="Information om vald grupp"
                text="Nedan specifieras vilka extrabehörigheter användaren får tillgång till via denna grupp."
              />
              <WhitelistPermsForm
                group={selectedGroup}
                disabled={true}
                hideTitle
              />
            </>
          )}
        </StandardModal>
      </SharedStyles.DetailPageBox>
    </SharedStyles.BareLayoutWrapper>
  );
};
