export const CLEANED_INVOICE = {
  ReminderInvoiceDetails: {
    _nested: true,
    SendReminderInvoice: {
      _label: "Skicka automatisk påminnelse",
      _required: false,
      _allowNull: true,
      _internalId: "ReminderInvoiceDetails.SendReminderInvoice",
    },
    DaysDelayAfterDueDate: {
      _label: "Antal dagar efter förfallodatuom som påminnelse skickas",
      _required: true,
      _allowNull: false,
      _internalId: "ReminderInvoiceDetails.DaysDelayAfterDueDate",
    },
  },
  OurReference: {
    _required: true,
    _allowNull: false,
    _label: "Vår referens",
    _internalId: "OurReference",
  },
  YourReference: {
    _required: true,
    _allowNull: false,
    _label: "Kundens referens",
    _internalId: "YourReference",
  },
  InvoiceDate: {
    _label: "Fakturadatum",
    _internalId: "InvoiceDate",
  },
  DueDate: {
    _label: "Betalas senast",
    _internalId: "InvoiceDate",
  },
  Records: {
    ProductPublicId: {
      _label: "Produkt",
      _required: true,
      _allowNull: false,
      _internalId: "Records.ProductPublicId",
    },

    // Points to cost center with cost centers "code"
    CostCenter: {
      _label: "Kostnadsställe",
      _internalId: "Records.CostCenter",
    },

    // Points to cost center with projects "projectnumber"
    Project: {
      _label: "Project",
      _internalId: "Records.Project",
    },

    SequenceNo: {
      _label: "Artikelnummer",
      _required: true,
      _allowNull: false,
      _internalId: "Records.SequenceNo",
    },
    UnitPrice: {
      _nested: true,
      _required: true,
      _allowNull: false,
      _internalId: "Records.UnitPrice",
      _label: "Pris per enhet",

      CurrencyCode: {
        _required: true,
        _allowNull: false,
        _internalId: "Records.UnitPrice.CurrencyCode",
        _label: "Valutakod",
      },
      Value: {
        _required: true,
        _allowNull: false,
        _internalId: "Records.UnitPrice.Value",
        _label: "Pris",
      },
    },
    Units: {
      _required: true,
      _allowNull: false,
      _internalId: "Records.Units",
      _label: "Enhet",
    },
    ArticleDescription: {
      _required: true,
      _allowNull: false,
      _internalId: "Records.ArticleDescription",
      _label: "Beskrivning",
    },
    Quantity: {
      _required: true,
      _allowNull: false,
      _internalId: "Records.Quantity",
      _label: "Antal",
    },
    RecordType: {
      _required: true,
      _allowNull: false,
      _internalId: "Records.RecordType",
      _label: "Typ",
      _choices: [{ v: "Standard", d: "Standard" }],
    },
    PeriodStart: {
      _label: "Faktureringperiod start",
      _internalId: "Records.PeriodFrom",
    },
    PeriodEnd: {
      _label: "Faktureringperiod slut",
      _internalId: "Records.PeriodEnd",
    },
    VAT: {
      _label: "Moms i procent",
      _internalId: "Records.VAT",
      _choices: [
        { v: 0, d: "0%" },
        { v: 6, d: "6%" },
        { v: 12, d: "12%" },
        { v: 25, d: "25%" },
      ],
    },
    VatIsIncluded: {
      _label: "Moms inkluderat i pris",
      _internalId: "Records.VatIsIncluded",
    },
  },
  Autogiro: {
    _nested: true,
    AutogiroWithdrawalEnabled: {
      _label: "Använd autogiro på denna faktura",
      _required: false,
      _allowNull: true,
      _internalId: "Autogiro.AutogiroWithdrawalEnabled",
    },
  },

  DeliveryMethod: {
    _required: true,
    _allowNull: false,
    _label: "Faktura leveransmetod",
    _internalId: "CreditorInvoiceAddress.DeliveryMethod",
    _choices: [
      { v: "Email", d: "Email (0 kr/st)" },
      { v: "Kivra", d: "Kivra (4 kr/st)" },
      { v: "EInvoice", d: "E-faktura (2.50 kr/st exkl. bankkostnader)" },
      { v: "Mail", d: "Post (6.50 kr/st)" },
      { v: "Manually", d: "Skicka ej (0 kr/st)" },
    ],
  },
  InterestType: {
    _required: true,
    _allowNull: false,
    _label: "Dröjsmålsränta",
    _internalId: "InterestType",

    _choices: [
      { v: "Fixed", d: "Fast ränta" },
      {
        v: "AboveEffectiveReference",
        d: "Över gällande referensränta",
      },
      { v: "NoInterest", d: "Ingen dröjsmålsränta" },
    ],
  },
  InterestStartInDaysAfterDueDate: {
    _label: "Antal dagar efter betaldatum som räntepåslag påbörjas ",
    _internalId: "InterestStartInDaysAfterDueDate",
  },
  InterestPercentage: {
    _required: true,
    _allowNull: false,
    _label: "Dröjsmålsränta %",
    _internalId: "InterestPercentage",
  },
  ReasonForHigherInterest: {
    _label: "Anledning till hög ränta",
    _internalId: "ReasonForHigherInterest",
  },

  SendByMailIfEmailNotViewedInDays: {
    _internalId: "SendByMailIfEmailNotViewedInDays",
    _label: "Skicka med post om fakturan ej öppnats efter antal dagar",
  },

  InvoiceFee: {
    _nested: true,
    _label: "Administrationsavgift",

    CurrencyCode: {
      _internalId: "InvoiceFee.CurrencyCode",
      _label: "Valutakod",
      _choices: [{ v: "SEK", d: "SEK" }],
    },
    Value: {
      _internalId: "InvoiceFee.Value",
      _label: "Pris",
      _required: true,
      _allowNull: false,
    },
  },

  DebtCollectionDetails: {
    _nested: true,
    _label: "Kravhantering",

    SendToDebtCollection: {
      _label: "Skicka till kravhantering",
      _internalId: "DebtCollectionDetails.SendToDebtCollection",
    },
    StartDebtCollectionActionLevel: {
      _internalId: "DebtCollectionDetails.StartDebtCollectionActionLevel",
      _label: "Påbörja kravhantering vid nivå",
      _choices: [
        { v: "LatePaymentFee", d: "Förseningsavgift (Företag)" },
        { v: "Reminders", d: "Påminnelse" },
        { v: "DebtCollection", d: "Kravhantering" },
      ],
    },

    NumberOfReminders: {
      _internalId: "DebtCollectionDetails.NumberOfReminders",
      _label: "Antal påminnelser",
      _choices: [
        { v: 0, d: "Ingen påminnelse" },
        { v: 1, d: "1 påminnelse" },
        { v: 2, d: "2 påminnelser" },
      ],
    },
    DaysDelayAfterDueDate: {
      _internalId: "DebtCollectionDetails.DaysDelayAfterDueDate",
      _label:
        "Antal dagar efter förfallodatum som fakturan skickas till kravhantering",
    },
    PaymentTermsInDays: {
      _internalId: "DebtCollectionDetails.PaymentTermsInDays",
      _required: true,
      _allowNull: false,
      _label: "Betalningsvillkor",
    },
    Eviction: {
      _internalId: "DebtCollectionDetails.Eviction",
      _label:
        "Kravet utgör bostadsavgifter där avhysning skall ske vid betalningsföreläggande",
      type: "boolean",
    },
    InformSocialWelfare: {
      _internalId: "DebtCollectionDetails.InformSocialWelfare",
      _label:
        "Kravet utgör bostadsavgifter för privatperson där Socialstyrelsen skall informeras vid betalningsföreläggande",
      type: "boolean",
    },
  },

  Message: {
    _internalId: "Message",
    _label: "Meddelande på fakturan",
    type: "string",
  },

  Attachments: {
    _internalId: "Attachments",
    _label: "Bilagor på faktura",
    _nested: true,
    type: "array",
    File: {
      _internalId: "Attachments.File",
      content_type: {
        _internalId: "Attachments.File.content_type",
      },
      data: {
        _internalId: "Attachments.File.data",
      },
      file_name: {
        _internalId: "Attachments.File.file_name",
      },
    },
  },
};

export const MULTIPLE_CLEANED_INVOICE = {
  ...CLEANED_INVOICE,
  _periodStart: {
    _internalId: "_periodStart",
    _label: "Fakturerad period start",
  },
  _periodEnd: {
    _internalId: "_periodEnd",
    _label: "Fakturerad period slut",
  },

  invoices: {
    ...CLEANED_INVOICE,
  },
};

export const TRIPLE_CLEANED_INVOICE = {
  ...CLEANED_INVOICE,
  _periodOption: {
    _internalId: "_periodOption",
    _label: "Startmånad för 3-fakturering",
    _choices: [
      { v: 0, d: "Januari" },
      { v: 1, d: "Februari" },
      { v: 2, d: "Mars" },
      { v: 3, d: "April" },
      { v: 4, d: "Maj" },
      { v: 5, d: "Juni" },
      { v: 6, d: "Juli" },
      { v: 7, d: "Augusti" },
      { v: 8, d: "September" },
      { v: 9, d: "Oktober" },
      { v: 10, d: "November" },
      { v: 11, d: "December" },
    ],
  },
  _period1Start: {
    _internalId: "_period1Start",
    _label: "Fakturerad period 1 start",
  },
  _period1End: {
    _internalId: "_period1End",
    _label: "Fakturerad period 1 slut",
  },

  _period2Start: {
    _internalId: "_period2Start",
    _label: "Fakturerad period 2 start",
  },
  _period2End: {
    _internalId: "_period2End",
    _label: "Fakturerad period 2 slut",
  },

  _period3Start: {
    _internalId: "_period3Start",
    _label: "Fakturerad period 3 start",
  },
  _period3End: {
    _internalId: "_period3End",
    _label: "Fakturerad period 3 slut",
  },

  invoices: {
    ...CLEANED_INVOICE,
  },
};
