export default [
  {
    title: "Produkt",
    key: "PRODUCT",
    visited: true,
    hasError: false,
    fieldKeys: [
      "title",
      "category",
      "vat",
      "account_no",
      "account_no_vat",
      "account_no_non_eu",
      "account_no_purchase_vat",
      "account_no_purchase",
      "account_no_eu_vat",
      "account_no_eu",
      "vacancy_booking_product",
    ],
  },
  {
    title: "Bekräfta",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: [],
  },
];
