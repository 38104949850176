import * as React from "react";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";

import columnDefs from "./listDefs";

// store, state
import { constants, detailUrl } from "../../../store/industrialPremises";

import moment from "moment";

import { useSelector } from "react-redux";

import { BADGE_TYPES } from "../../Badge/Badge";
import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";
import { getExpandedContentForContracts } from "../../Lists/Base/FinalTable/utils";

const sDate = moment({ year: moment().year(), month: 0, day: 1 }).format(
  "YYYY-MM-DD"
);
const eDate = moment({ year: moment().year(), month: 11, day: 31 }).format(
  "YYYY-MM-DD"
);
export default function IndustrialPremisesTable({
  persistantQuery,
  withCosts = true,
  onlyWithCosts,
  tableId,
  isBare,
  ignoreLocalStorage,
  onRowClicked,
  checkRowHighlighted,
}) {
  /// TEMP
  const canViewSensitiveInfoTemp = useSelector(
    (state) =>
      state.app.user?.user_type === 1 || state.app.user?.user_type === 0
  );

  const columns = React.useMemo(
    () =>
      columnDefs({
        startDate: sDate,
        endDate: eDate,
        canViewSensitiveInfoTemp,
        withCosts,
        onlyWithCosts,
        useSqm: false,
        showMonthlyCosts: false,
        useSpecifiedPeriod: false,
      }),
    [
      sDate,
      eDate,
      persistantQuery,
      withCosts,
      onlyWithCosts,
      canViewSensitiveInfoTemp,
    ]
  );

  const filters = {
    Objektsnummer: {
      queryKey: "premises_id",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Kategori: {
      queryKey: "category",
      type: "select",
      operators: OPERATOR_TEMPLATES.SELECT,
      choices: [
        {
          v: "office",
          d: "Kontor",
        },
        {
          v: "shop",
          d: "Butik",
        },
        {
          v: "industrial",
          d: "Industri",
        },
        {
          v: "warehouse",
          d: "Lager",
        },
        {
          v: "restaurant",
          d: "Restaurang",
        },
        {
          v: "education",
          d: "Utbildning",
        },
        {
          v: "bakery",
          d: "Bageri",
        },
        {
          v: "barber",
          d: "Frisör",
        },
        {
          v: "healt_care",
          d: "Sjukvård och Hälsa",
        },
        {
          v: "meetingroom",
          d: "Mötesrum",
        },
        {
          v: "food",
          d: "Livsmedelsindustri",
        },
        {
          v: "hotel",
          d: "Hotell",
        },
        {
          v: "storage",
          d: "Förråd",
        },
        {
          v: "ground",
          d: "Mark",
        },
        {
          v: "other",
          d: "Annan",
        },
        {
          v: "gym",
          d: "Gym",
        },
        {
          v: "leisure",
          d: "Fritidsverksamhet",
        },
        {
          v: "workshop",
          d: "Verkstad",
        },
        {
          v: "veterinary",
          d: "Veterinär",
        },
        {
          v: "library",
          d: "Bibliotek",
        },
        {
          v: "atm",
          d: "Bankomat",
        },
        {
          v: "premises",
          d: "Lokal",
        },
        {
          v: "spot",
          d: "Uppställningsplats",
        },
        {
          v: "bank",
          d: "Bank",
        },
        {
          v: "cinema",
          d: "Cinema",
        },
        {
          v: "cafe",
          d: "Cafe",
        },
        {
          v: "kindergarden",
          d: "Kindergarden",
        },
        {
          v: "conference",
          d: "Conference",
        },
        {
          v: "shared_office",
          d: "Shared Office",
        },
        {
          v: "tower",
          d: "Tower/Base Station",
        },
        {
          v: "museum",
          d: "Museum",
        },
        {
          v: "gathering",
          d: "Gathering",
        },
        {
          v: "sign",
          d: "Skylt",
        },
        {
          v: "dentist",
          d: "Dentist",
        },
        {
          v: "lease",
          d: "Arrendeavtal",
        },
        {
          v: "group_living",
          d: "Group Living",
        },
        {
          v: "high_warehouse",
          d: "High Warehouse",
        },
        {
          v: "cold_storage",
          d: "Cold Storage",
        },
        {
          v: "cold_warehouse",
          d: "Cold Warehouse",
        },
        {
          v: "lss_living",
          d: "LSS Living",
        },
        {
          v: "fitness",
          d: "Fitness",
        },
        {
          v: "school",
          d: "School",
        },
        {
          v: "house",
          d: "House",
        },
        {
          v: "elderly",
          d: "Elderly",
        },
      ],
    },
    Adress: {
      queryKey: "address_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Fastighet: {
      queryKey: "realestate_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Byggnad: {
      queryKey: "building_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Area: {
      queryKey: "area",
      type: "number",
      operators: OPERATOR_TEMPLATES.NUMBER,
    },
    Hyresgäst: {
      queryKey: "tenant_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Hyresvärd: {
      queryKey: "landlord_str_representation",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
  };

  //TODO: Make select max height & scrollable select

  const badges = {
    Aktiva: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [0, 1, 2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Tidigare: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [9],
      },
    },
  };

  return (
    <BaseTable
      onRowClickedWithDetail={(obj) =>
        detailUrl({
          id: obj.id,
        })
      }
      title={"Lokaler"}
      tableId={tableId || "indp_full_table"}
      getContentForExpandedRow={(row) =>
        getExpandedContentForContracts(row, {
          startDate: sDate,
          endDate: eDate,
        })
      }
      isRowExpandable={withCosts}
      {...{
        isBare,
        ignoreLocalStorage,
        persistantQuery,
        constants,
        badges,
        filters,
        columns,
        onRowClicked,
        checkRowHighlighted,
      }}
    />
  );
}
