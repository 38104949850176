import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAllPermissionCheck, useFormField } from "../../../../store/base";

import {
  create,
  update,
  constants,
  destroyPatchForm,
  destroyPostForm,
  useInsightsGoalForm,
} from "../../../../store/insightsGoal";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import StandardModal from "../../../Modals/StandardModal";
import { TextButton } from "../../Base/Buttons";
import KPIGuide from "../WidgetPlacement/WidgetPlacementForm/KPIGuide";
import DeleteModal from "../../Delete/DeleteModal";
import {
  KPI_CONTRACT_TERM,
  KPI_RENTABLE_AREA,
  KPI_REALESTATE_COUNT,
  KPI_ECONOMIC_VACANCY,
} from "../../../Insights/Widgets/WidgetInfo";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import RealEstatePortfolioTable from "src/components/Tables/RealEstatePortfolios/FullTable";
import { cloneDeep, set, get } from "lodash";
import ScrollableModal from "../../Base/Modals/ScrollableModal";
import LocalSelectField from "../../Base/Fields/LocalSelectField";
import LocalTextInputField from "../../Base/Fields/LocalTextInputField";
import LocalTableSelectField from "../../Base/Fields/LocalTableSelectField";
import { forceUpdateGoalWidgetPlacements } from "src/store/insightsGoal/store/actions";
import Modal from "../../Base/Modals/Modal";

const inputsReducer = (state, action) => {
  if (action?.type === "all") return action.value;

  if (action?.type === "overrideMultiProps") {
    return {
      ...state,
      ...action.value,
    };
  }

  if (action.key.includes(".")) {
    let newState = cloneDeep(state);

    set(newState, action.key, action.value);

    return newState;
  }

  return {
    ...state,
    [action.key]: action.value,
  };
};

export default function InsightsGoalForm({
  method,
  insightsGoal,
  open,
  onCheckout,
  handleGoalPatched,
  kpiChoices,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;
  const [kpiInfoOpen, setKpiInfoOpen] = React.useState(false);
  const formLoaded = Boolean(useInsightsGoalForm(method, insightsGoal?.id));

  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  // error states
  const [taxValueError, setTaxValueError] = React.useState(false);
  const [noTimePeriodError, setNoTimePeriodError] = React.useState(false);
  const [noValueError, setNoValueError] = React.useState(false);

  // local error states
  const [realEstateError, setRealEstateError] = React.useState(false);
  const [companyError, setCompanyError] = React.useState(false);
  const [portfolioError, setPortfolioError] = React.useState(false);
  const [kpiError, setKpiError] = React.useState(false);


  const errorStateMapping = {
    realestate: setRealEstateError,
    company: setCompanyError,
    portfolio: setPortfolioError,
    value: setNoValueError
  };
  const [inputsState, inputsStateDispatch] = React.useReducer(
    inputsReducer,
    insightsGoal || {}
  );

  const [hasLoadedInputsState, setHasLoadedInputsState] = React.useState(false);

  React.useEffect(() => {
    if (!insightsGoal) return;

    inputsStateDispatch({
      type: "all",
      value: insightsGoal,
    });
    setHasLoadedInputsState(true);
  }, [insightsGoal]);

  const kpiSelected = React.useMemo(() => {
    return inputsState.kpi;
  }, [inputsState.kpi]);

  const showInstanceFilter = [KPI_ECONOMIC_VACANCY, KPI_CONTRACT_TERM].includes(
    kpiSelected
  );
  const showParkingFilter = [KPI_CONTRACT_TERM].includes(kpiSelected);

  React.useEffect(() => {
    if (!inputsState?.kpi) {
      inputsStateDispatch({
        key: "kpi",
        value: kpiChoices[0]["choices"][0]["v"],
      });
    }
    if (
      showInstanceFilter &&
      !inputsState?.body_parameters?.instance_filter
    ) {
      inputsStateDispatch({
        key: "body_parameters.instance_filter",
        value: "all",
      });
    }
  }, [inputsState]);


  const nonTimeKpi = [KPI_REALESTATE_COUNT, KPI_RENTABLE_AREA].includes(
    kpiSelected
  );

  const taxValueType = React.useMemo(() => {
    return inputsState?.body_parameters?.tax_value_type;
  }, [inputsState?.body_parameters]);

  const taxRecordSelected = React.useMemo(() => {
    return inputsState.kpi === "realestate_tax_record";
  }, [inputsState]);

  const noTimePeriodSelected = React.useMemo(() => {
    if (
      !inputsState?.interval_type &&
      (!inputsState?.start_date || !inputsState?.end_date)
    ) {
      return true;
    }
    return false;
  });

  React.useEffect(() => {
    if (!hasLoadedInputsState) return;
    if (taxRecordSelected && !taxValueType) {
      inputsStateDispatch({
        key: "body_parameters.tax_value_type",
        value: null,
      });
    }
  }, [taxRecordSelected]);

  const { _choices: intervalTypeFormChoices } = {
    ...useFormField({
      storeName,
      fieldKey: "interval_type",
      method,
    }),
  };

  const { _choices: conditionFormChoices } = {
    ...useFormField({
      storeName,
      fieldKey: "condition",
      method,
    }),
  };

  const removeAllErrors = () => {
    setKpiError(false);
    setTaxValueError(false);
    setNoTimePeriodError(false);
    setNoValueError(false);
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    handleGoalPatched();
    removeAllErrors();
    dispatch(
      forceUpdateGoalWidgetPlacements(returned?.kpi, returned.realestates)
    );
  };
  const customErrorCallback = (data, returnedData, error) => {
    setLoading(false);
    if (!returnedData) return;
    Object.keys(returnedData).forEach((err) => {
      if (errorStateMapping[err]) errorStateMapping[err](returnedData[err])
      errorStateMapping[err](returnedData[err]);
    });
  };

  const onFailure = (method) => {
    setLoading(false);
    if (method === "POST") {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Skapandet misslyckades",
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Uppdateringen misslyckades",
        })
      );
    }
  };

  const customValidation = () => {
    let fail = false;

    if (!inputsState?.kpi) {
      setKpiError(true);
      fail = true;
    }
    if (taxRecordSelected && !taxValueType) {
      setTaxValueError(true);
      fail = true;
    }

    if (nonTimeKpi) {
      inputsStateDispatch({
        type: "overrideMultiProps",
        value: {
          interval_type: null,
          start_date: null,
          end_date: null,
        },
      });
    } else {
      if (noTimePeriodSelected) {
        setNoTimePeriodError(true);
        fail = true;
      }
    }
    if (!inputsState?.value) {
      setNoValueError(true)
    }

    return fail;
  };

  const onSubmit = () => {
    removeAllErrors();
    setLoading(true);
    const fail = customValidation();
    if (fail) return onFailure(method);

    if (method === "POST") {
      dispatch(
        create({
          id: insightsGoal?.id,
          successCallback: onSuccess,
          errorCallback: customErrorCallback,
          forceData: inputsState,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id: insightsGoal?.id,
          successCallback: onSuccess,
          errorCallback: customErrorCallback,
          forceData: inputsState,
        })
      );
    }
  };

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    onCheckout(false);
  };
  const handleDeletedGoal = () => {
    dispatch(
      forceUpdateGoalWidgetPlacements(
        insightsGoal.kpi,
        insightsGoal.realestates
      )
    );
    checkout(false);
  };

  const hasDeletePermission = useAllPermissionCheck([
    "allow_insights",
    "delete_can_insights",
  ]);

  return (
    <>
      <ScrollableModal
        isOpen={open}
        closeFunction={() => checkout(false)}
        onAccept={onSubmit}
        title={method === "PATCH" ? "Uppdatera mål" : "Skapa mål"}
        className="!mt-0"
      >
        {(!formLoaded || (loading && open)) && <OverlaySpinner />}

        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>
              {method === "PATCH" ? "Uppdatera" : "Skapa"} mål
            </OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div style={{ marginBottom: "32px" }}>
          <div className="grid grid-cols-2 gap-6 mb-0">
            <LocalSelectField
              title="Nyckeltal"
              value={inputsState.kpi}
              choices={kpiChoices || []}
              onChange={(value) => {
                inputsStateDispatch({
                  key: "kpi",
                  value,
                });
              }}
              error={kpiError && "Obligatoriskt"}
              removePlaceholder
            />
          </div>
          <TextButton
            iconType="info"
            title="Hur fungerar nyckeltal?"
            clicked={() => setKpiInfoOpen(true)}
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTextInputField
            title="Värde"
            value={inputsState.value}
            onChange={(value) =>
              inputsStateDispatch({
                key: "value",
                value,
              })
            }
            isNumber
            step={"1"}
            error={noValueError && "Obligatoriskt"}
          />
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalSelectField
            title="Operator"
            value={inputsState.condition}
            onChange={(value) =>
              inputsStateDispatch({
                key: "condition",
                value,
              })
            }
            removePlaceholder
            choices={conditionFormChoices}
          />
        </div>

        {kpiInfoOpen && (
          <Modal
            isOpen={kpiInfoOpen}
            closeFunction={() => setKpiInfoOpen(false)}
            title="Hur fungerar nyckeltal?"
            withActionBar
            actionBarCancelTitle="Stäng"
            modalInModal
            className="!mt-0"
          >
            <KPIGuide kpiChoices={kpiChoices} />
          </Modal>
        )}

        {!nonTimeKpi && (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalSelectField
              title="Intervalltyp"
              value={inputsState.interval_type}
              choices={intervalTypeFormChoices || []}
              onChange={(value) => {
                inputsStateDispatch({
                  key: "interval_type",
                  value,
                });
              }}
              canClear
              error={
                noTimePeriodError &&
                "Detta nyckeltal kräver antingen interval eller specifika datum"
              }
            />
          </div>
        )}

        {showInstanceFilter && (
          <LocalSelectField
            title="Filtrera på"
            value={get(inputsState, "body_parameters.instance_filter")}
            onChange={(value) =>
              inputsStateDispatch({
                key: "body_parameters.instance_filter",
                value,
              })
            }
            canClear
            choices={
              showParkingFilter
                ? [
                    { v: "all", d: "Allt" },
                    { v: "apartment", d: "Lägenheter" },
                    { v: "industrialpremises", d: "Lokaler" },
                    { v: "parkingspot", d: "Fordonsplatser" },
                  ]
                : [
                    { v: "all", d: "Lägenheter & lokaler" },
                    { v: "apartment", d: "Lägenheter" },
                    { v: "industrialpremises", d: "Lokaler" },
                  ]
            }
          />
        )}
        {taxRecordSelected && (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalSelectField
              title="Typ, taxeringsvärde"
              value={get(inputsState, "body_parameters.tax_value_type")}
              onChange={(value) =>
                inputsStateDispatch({
                  key: "body_parameters.tax_value_type",
                  value,
                })
              }
              choices={[
                { v: "ground_housing_value", d: "Markvärde för bostäder" },
                { v: "build_housing_value", d: "Byggnadsvärde för bostäder" },

                { v: "ground_premises_value", d: "Markvärde för lokaler" },
                { v: "build_premises_value", d: "Byggnadsvärde för lokaler" },

                { v: "ground_industrial_value", d: "Markvärde för industri" },
                {
                  v: "build_industrial_value",
                  d: "Byggnadsvärde för industri",
                },
              ]}
              canClear
              error={taxValueError ? "Obligatorisk" : false}
            />
          </div>
        )}

        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={RealEstateTable}
            value={inputsState.realestate}
            placeholder="Välj fastighet..."
            onChange={(value) =>
              inputsStateDispatch({
                key: "realestate",
                value,
              })
            }
            title="Kopplad till fastighet"
            error={realEstateError}
          />
        </div>

        <p>Eller</p>
        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={CompanyTable}
            value={inputsState.company}
            placeholder="Välj bolag..."
            onChange={(value) =>
              inputsStateDispatch({
                key: "company",
                value,
              })
            }
            title="Kopplad till bolag"
            error={companyError}
          />
        </div>
        <p>Eller</p>
        <div className="grid grid-cols-2 gap-6 mb-6">
          <LocalTableSelectField
            TableComponent={RealEstatePortfolioTable}
            value={inputsState.portfolio}
            placeholder="Välj portfölj..."
            onChange={(value) =>
              inputsStateDispatch({
                key: "portfolio",
                value,
              })
            }
            title="Kopplad till portfölj"
            error={portfolioError}
          />
        </div>

        {method === "PATCH" && hasDeletePermission && (
          <OverviewSubtitle>
            <TextButton
              title="Radera"
              red={true}
              iconType="delete"
              iconPlacement="right"
              clicked={() => setDeleteModalOpen(true)}
            />
          </OverviewSubtitle>
        )}
        <DeleteModal
          isOpen={deleteModalOpen}
          closeFunction={() => setDeleteModalOpen(false)}
          deletedCallback={() => handleDeletedGoal()}
          constants={constants}
          instance={insightsGoal}
          modalInModal
        />
      </ScrollableModal>
    </>
  );
}
