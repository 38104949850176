import * as React from "react";
import { useDispatch } from "react-redux";

import {
  constants,
  create,
  useBrfContractForm,
  destroyPatchForm,
  destroyPostForm,
  update,
} from "../../../../store/brfContract";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../store/base";
import { addToast, TOAST_TYPES } from "../../../../store/toasts";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import Modal from "../../Base/Modals/Modal";
import { useBrfPremises } from "src/store/brfPremises";
import TableSelectField from "../../Base/Fields/TableSelectField";
import TextInputField from "../../Base/Fields/TextInputField";
import DateSelect from "../../Base/Fields/DateSelect";
import SelectField from "../../Base/Fields/SelectField";
import MediaField from "../../Base/Fields/MediaField";
import TableSelectFieldWithCreate from "../../Base/Fields/TableSelectFieldWithCreate";
import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import BrfOwnerTable from "src/components/Tables/BrfOwner/FullTable";
import TenantNestedChildren from "../../Tenant/NestedChildren";
import M2mField from "../../Base/Fields/M2mField";
import { instructions } from "src/store/base/store/services";

export default function BrfTransationModalForm({
  method,
  id,
  instance,
  onCheckout,
}) {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useBrfContractForm(method, id));

  const selectedBrfPremises = useFormInstanceField({
    storeName,
    fieldKey: "brf_premis",
  });

  const [brfPremises] = useBrfPremises(selectedBrfPremises?.id);

  const realEstate = brfPremises?.realestate?.id;

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (method === "POST") {
        dispatch(destroyPostForm(false));
      } else if (method === "PATCH") {
        dispatch(destroyPatchForm(false));
      }
    };
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    checkout(true);
    setLoading(false);
  };

  const onError = (data, returnedData) => {
    setLoading(false);

    try {
      let errorMessage = undefined;

      for (let key in returnedData) {
        if (errorMessage !== undefined) break;
        if (key === "value") continue;

        for (let val of returnedData[key]) {
          if (typeof val === "string") {
            errorMessage = val;
            break;
          }
        }
      }

      if (errorMessage) {
        dispatch(
          addToast({
            type: TOAST_TYPES.ERROR,
            title: "Ett fel har uppstått",
            description: errorMessage,
          })
        );
        return;
      }

      throw "run default error toast";
    } catch (err) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Ett fel har uppstått",
          description: "Kontrollera alla fält och försök igen.",
        })
      );
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: onError,
          preventDefaultToast: true,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: onError,
          preventDefaultToast: true,
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  const getBrfOwnerChildren = (parentPath) => {
    return (
      <>
        <div className="grid grid-cols-2 gap-6 mb-6">
          <TableSelectFieldWithCreate
            storeName={storeName}
            persistantQuery={{ is_brf: true }}
            placeholder="Välj övrig ägare..."
            title="Övrig ägare"
            method={method}
            fieldKey={`${parentPath}.tenant`}
            instructionsKey={"bi_owners.tenant"}
            TableComponent={TenantTable}
            createDisplayKey={"user.first_name"}
            tableProps={{
              isMembers: true,
            }}
            description={
              <span>
                Ange en övriga ägare för transaktionen.
                <br />
                <br />
              </span>
            }
          >
            {(parentPath, parentInstructionsPath) => (
              <TenantNestedChildren
                storeName={storeName}
                method={method}
                parentPath={parentPath}
                parentInstructionsPath={parentInstructionsPath}
                isMember
              />
            )}
          </TableSelectFieldWithCreate>

          <TextInputField
            isNumber
            step=".01"
            {...{ storeName, method }}
            fieldKey={`${parentPath}.quota`}
            instructionsKey={"bi_owners.quota"}
            title="Ägarandel i %"
            description="Ange hur stor andel av bostadsrätten som ägs av den övriga ägaren"
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      title={
        method === "POST" ? "Lägg till transaktion" : "Uppdatera transaktion"
      }
      closeFunction={() => onDone()}
      acceptTitle="Spara"
      onAccept={onSubmit}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">
        Ange detaljer om transaktion av bostadsrätt{" "}
        <strong>{brfPremises?.str_representation || ""}</strong> i föreningen{" "}
        <strong>{brfPremises?.brf_company?.str_representation}</strong>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          {...{ storeName, method }}
          fieldKey="id_number"
          title="ID för transaktionen"
          description="Internt ID för att hålla koll på transaktioner"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          isNumber
          step="0.01"
          {...{ storeName, method }}
          fieldKey="value"
          title="Köpeskilling"
          description="Köpeskilling i transkationen"
        />

        <DateSelect
          {...{ storeName, method }}
          fieldKey="transaction_date"
          title="Transaktionsdatum"
          description="Datum för köp av bostadsrätten av den nya ägaren"
        />

        <DateSelect
          {...{ storeName, method }}
          fieldKey="access_date"
          title="Tillträdelsedatum"
          description="Datum för tillträde av den nya ägaren"
        />
        <SelectField
          fieldKey={"category"}
          title="Typ av transaktion"
          method={method}
          storeName={storeName}
          description="Ange vilken typ av transaktion det gäller"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey="owner.tenant"
          method={method}
          placeholder="Välj medlem..."
          persistantQuery={{ is_brf: true }}
          title="Huvudsaklig ägare"
          description={`Om det finns två likvärdiga ägare så anges en av dem här och den andra som "Övrig ägare"`}
          TableComponent={TenantTable}
          createDisplayKey="user.first_name"
        >
          {(parentPath) => (
            <TenantNestedChildren
              storeName={storeName}
              method={method}
              parentInstructionsPath={"owner.tenant"}
              parentPath={parentPath}
              isMember
            />
          )}
        </TableSelectFieldWithCreate>

        <TextInputField
          isNumber
          step=".01"
          {...{ storeName, method }}
          fieldKey="owner.quota"
          title="Ägarandel i %"
          description="Ange hur stor andel av bostadsrätten som ägs av huvudsakliga ägaren"
        />
      </div>

      <div className="grid grid-cols-1 gap-6 mb-6">
        <M2mField
          title="Övriga ägare"
          placeholder="Lägg till övriga"
          fieldKey="bi_owners"
          storeName={storeName}
          instanceTitle="övrig ägare"
          instructionsKey={"bi_owners"}
          method={method}
        >
          {(parentPath, instructionsKey) => {
            return getBrfOwnerChildren(parentPath);
          }}
        </M2mField>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          persistantQuery={{
            realestate_ids: realEstate,
          }}
          placeholder="Välj tidigare ägare..."
          description="Lämna tomt om tidigare ägare ej finns i systemet."
          title="Säljare"
          method={method}
          fieldKey="sellers"
          TableComponent={BrfOwnerTable}
          isMany
        />
      </div>

      <MediaField
        {...{ storeName, method }}
        fieldKey="doc"
        allowedFormats={[".pdf"]}
        title="Dokument"
        description="Eventuellt dokument som tillhör transaktionen"
      />
    </Modal>
  );
}
