import * as React from "react";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";

import useActiveFilters from "../../../hooks/useActiveFilters";
import { useAllPermissionCheck } from "../../../store/base";

import ErrandsTable from "../../../components/Tables/GovErrands/FullTable";
import ModalForm from "../../../components/Forms/GovErrand/ChapterForm/ModalForm";

import { PrimaryButton } from "../../../components/Forms/Base/Buttons";

export default function Errands() {
  const { filteredRealEstates } = useActiveFilters();

  const [createErrandOpen, setCreateErrandOpen] = React.useState(false);

  const query = {
    realestate_ids: filteredRealEstates,
  };
  const canAdd = useAllPermissionCheck([
    "add_can_goverrand",
    "allow_errand_gov",
  ]);

  return (
    <>
      {canAdd && (
        <ModalForm
          method="POST"
          isOpen={createErrandOpen}
          onCheckout={() => setCreateErrandOpen(false)}
        />
      )}

      <DetailInnerWrapper>
        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitle>Ärenden</OverviewTitle>
            {canAdd && (
              <PrimaryButton
                title="Skapa manuellt ärende"
                clicked={() => setCreateErrandOpen(true)}
              />
            )}
          </OverviewTitleWrapper>
          <ErrandsTable persistantQuery={query} />
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}
