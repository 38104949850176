import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state
import { constants } from "../../../../store/parkingContractsWithCosts";

import { detailUrl } from "../../../../store/parkingContracts";
import moment from "moment";

// store, state

import { getDateRangePicker } from "src/components/Tables/LeaseContractsWithCosts/FullTableNewVersion";
import { useFrequentPermissions } from "../../../../store/base";
import { getExpandedContentForContracts } from "src/components/Lists/Base/FinalTable/utils";

import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";
import { BADGE_TYPES } from "../../../Badge/Badge";

export default function ParkingContractWithCostsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  withCosts = true,
}) {
  const history = useHistory();

  const { hasBillectaViewPermission } = useFrequentPermissions();
  const sDate = moment({ year: moment().year(), month: 0, day: 1 });
  const eDate = moment({ year: moment().year(), month: 11, day: 31 });
  const [startDate, setStartDate] = React.useState(sDate.format("YYYY-MM"));
  const [endDate, setEndDate] = React.useState(eDate.format("YYYY-MM"));

  const [useSpecifiedPeriod, setUseSpecifiedPeriod] = React.useState(false);
  const [showMonthlyCosts, setShowMonthlyCosts] = React.useState(false);

  const columns = React.useMemo(
    () =>
      columnDefs(
        startDate,
        endDate,
        withCosts,

        hasBillectaViewPermission,
        showMonthlyCosts,
        useSpecifiedPeriod
      ),
    [
      persistantQuery,
      startDate,
      endDate,
      withCosts,

      hasBillectaViewPermission,
      showMonthlyCosts,
      useSpecifiedPeriod,
    ]
  );

  const filters = {
    Avtalsnummer: {
      queryKey: "id_number",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Hyresgäst: {
      queryKey: "tenant_name",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Aviseringsstatus: {
      queryKey: "lease_invoicing_active",
      type: "boolean",
      operators: OPERATOR_TEMPLATES.BOOLEAN,
    },
    Fastighet: {
      queryKey: "realestate__key",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Startdatum: {
      queryKey: "start_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
    Slutdatum: {
      queryKey: "end_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
  };

  const badges = {
    Aktiva: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [0, 1, 2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Tidigare: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [9],
      },
    },
  };

  return (
    <>
      {withCosts &&
        getDateRangePicker({
          startDate,
          endDate,
          setStartDate,
          setEndDate,
          useSpecifiedPeriod,
          setUseSpecifiedPeriod,
          showMonthlyCosts,
          setShowMonthlyCosts,
        })}

      <BaseTable
        title={"Parkeringsavtal"}
        tableId={tableId || "parkingcontracts_full_table"}
        isRowExpandable={true}
        getContentForExpandedRow={(row) =>
          getExpandedContentForContracts(row, {
            startDate,
            endDate,
          })
        }
        onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
        }}
      />
    </>
  );
}
