import * as React from "react";
import { cloneDeep, get, isEqual, set } from "lodash";

import { useDispatch } from "react-redux";

import {
  useWidgetPlacementsForm,
  constants,
  destroyPatchForm,
  destroyPostForm,
  update,
  create,
  getAggregateOptions,
  getGranularityOptions,
  clearSpecificValues,
} from "../../../../../store/widgetPlacements";

import { useAllPermissionCheck, useFormField } from "../../../../../store/base";
import StandardModal from "../../../../Modals/StandardModal";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import {
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
  OverviewSubtitle,
  OverviewTitle,
} from "../../../../Details/OverviewInfo/styles";

import widgetsListDefs from "../../../../Lists/Widgets/listDefs";

import { PrimaryButton, TextButton } from "../../../Base/Buttons";

import {
  getInfoForWidget,
  KPI_TOP_TENANT,
  WIDGET_BARCHART,
  WIDGET_OVERVIEW_DIFF,
  WIDGET_QUICK_DETAIL,
  WIDGET_BARCHART_NEGATIVE_TRENDLINE,
  WIDGET_OVERVIEW_GENERAL,
  kpiDataMapping,
  KPI_OCCUPANCY,
  KPI_ECONOMIC_VACANCY,
  WIDGET_MULTIPLE_Y_AXIS,
  KPI_CONTRACT_VALUE,
  KPI_CONTRACT_TERM,
  KPI_RENT_PER_AREA,
  KPI_REALESTATE_TAX_RECORD,
  WIDGET_DONUTCHART,
  KPI_VACANT_OBJECTS_MARKET_RENT,
  KPI_NET_LEASTING,
} from "../../../../../components/Insights/Widgets/WidgetInfo";

import WidgetSelect from "../../../../Insights/Widgets/Fields/WidgetSelect";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";
import KPIGuide from "./KPIGuide";
import Modal from "../../../Base/Modals/Modal";
import LocalDateSelect from "../../../Base/Fields/LocalDateSelect";
import LocalSelectField from "../../../Base/Fields/LocalSelectField";
import LocalTextInputField from "../../../Base/Fields/LocalTextInputField";
import LocalSelectManyField from "../../../Base/Fields/LocalSelectManyField";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { removeObject } from "src/store/base/store/actions";

const inputsReducer = (state, action) => {
  if (action?.type === "all") return action.value;

  if (action?.type === "overrideMultiProps") {
    return {
      ...state,
      ...action.value,
    };
  }

  if (action.key.includes(".")) {
    let newState = cloneDeep(state);

    set(newState, action.key, action.value);

    return newState;
  }

  return {
    ...state,
    [action.key]: action.value,
  };
};

export default function WidgetPlacementForm({
  method,
  pageId,
  widgetPlacement,
  open,
  onCheckout,
  _gridCellClicked,
  availableGridAreas,
  bottomRightAreas,
  bottomLeftAreas,
  topLeftAreas,
  topRightAreas,
  handleDeleteModal,
  kpiChoices,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const storeName = constants.STORE_NAME;

  const formLoaded = Boolean(
    useWidgetPlacementsForm(method, widgetPlacement?.id)
  );

  const [inputsState, inputsStateDispatch] = React.useReducer(
    inputsReducer,
    widgetPlacement || {}
  );

  const [hasLoadedInputsState, setHasLoadedInputsState] = React.useState(false);

  React.useEffect(() => {
    if (method === "PATCH" && !widgetPlacement) return;
    inputsStateDispatch({
      type: "all",
      value: widgetPlacement,
    });
    setHasLoadedInputsState(true);
  }, [widgetPlacement]);

  const [gridColumn, setGridColumn] = React.useState([]);
  const [gridRow, setGridRow] = React.useState([]);

  const [widgetSelectFilter, setWidgetSelectFilter] = React.useState(null);

  const [kpiInfoOpen, setKpiInfoOpen] = React.useState(false);

  const [hideMonthGranularity, setHideMonthGranularity] = React.useState(false);

  // error states
  const [limitError, setLimitError] = React.useState(false);
  const [sortMethodError, setSortMethodError] = React.useState(false);
  const [taxValueError, setTaxValueError] = React.useState(false);
  const [noTimePeriodError, setNoTimePeriodError] = React.useState(false);
  const [hasIntervalError, setHasIntervalError] = React.useState(false);
  const [granularityError, setGranularityError] = React.useState(false);
  const [dayGranularityError, setDayGranularityError] = React.useState(false);

  const dateDisabled = React.useMemo(() => {
    let isDateDisabled = Boolean(inputsState?.interval_type);

    if (isDateDisabled && (inputsState?.start_date || inputsState?.end_date)) {
      inputsStateDispatch({
        key: "end_date",
        value: undefined,
      });
      inputsStateDispatch({
        key: "start_date",
        value: undefined,
      });
    }

    return isDateDisabled;
  }, [inputsState]);

  const [aggregateOptions, setAggregateOptions] = React.useState([]);
  const [granularityOptions, setGranularityOptions] = React.useState([]);

  const [endDateOnly, setEndDateOnly] = React.useState(false);

  const widget = React.useMemo(() => {
    return inputsState?.widget;
  }, [inputsState?.widget]);

  const widgetInfo = getInfoForWidget(widget);
  const gridCellClicked = _gridCellClicked || [
    widgetPlacement?.grid_column,
    widgetPlacement?.grid_row,
  ];

  const infoBox = {
    title:
      "Visar endast widgets som passar i den valda ytan och med det valda nyckeltalet",
  };

  const formAggregates = React.useMemo(() => {
    return inputsState?.aggregates;
  }, [inputsState?.aggregates]);

  const buildingAgeIntervals = React.useMemo(() => {
    return inputsState?.body_parameters?.building_age_intervals;
  }, [inputsState?.body_parameters]);

  const tenantAgeIntervals = React.useMemo(() => {
    return inputsState?.body_parameters?.tenant_age_intervals;
  }, [inputsState?.body_parameters]);

  const kpiSelected = React.useMemo(() => {
    return inputsState?.kpi;
  }, [inputsState?.kpi]);

  const hasKpiChanged = Boolean(method === "PATCH" && !isEqual(widgetPlacement?.kpi, kpiSelected) && kpiSelected !== undefined)
  const widgetSelected = widget;
  const hasWidgetChanged =
    !isEqual(widgetPlacement?.widget, widgetSelected) &&
    widgetSelected !== undefined;

  const noTimePeriodSelected = React.useMemo(() => {
    if (
      !inputsState?.interval_type &&
      (!inputsState?.end_date)
    ) {
      return true;
    }
    return false;
  }, [
    inputsState?.interval_type,
    inputsState?.end_date,
  ]);

  const nonTimeKpi = kpiDataMapping[inputsState?.kpi]?.disable_time;

  const aggregateBuildingAge = formAggregates?.includes("building_age");
  const aggregateTenantAge = formAggregates?.includes("tenant_age");

  const showInstanceFilter = [KPI_NET_LEASTING, KPI_VACANT_OBJECTS_MARKET_RENT, KPI_ECONOMIC_VACANCY, KPI_CONTRACT_TERM, KPI_CONTRACT_VALUE, KPI_RENT_PER_AREA, KPI_OCCUPANCY].includes(kpiSelected)
  const showParkingFilter = [KPI_CONTRACT_TERM, KPI_CONTRACT_VALUE].includes(kpiSelected)
  const showShowMonthly = kpiSelected === KPI_VACANT_OBJECTS_MARKET_RENT

  const limit = React.useMemo(() => {
    return inputsState?.body_parameters?.limit;
  }, [inputsState?.body_parameters]);

  const sortMethod = React.useMemo(() => {
    return inputsState?.body_parameters?.sort_method;
  }, [inputsState?.body_parameters]);

  const dayGranularity = React.useMemo(() => {
    return inputsState?.day_granularity;
  }, [inputsState?.day_granularity]);

  const corporateTenants = React.useMemo(() => {
    return inputsState?.body_parameters?.corporate_tenants;
  }, [inputsState?.body_parameters]);

  const taxValueType = React.useMemo(() => {
    return inputsState?.body_parameters?.tax_value_type;
  }, [inputsState?.body_parameters]);

  const widgetSelectInstructions = {
    label_accessor: "title",
    image_url_accessor: "image_url",
  };

  const topTenantSelected = React.useMemo(() => {
    return inputsState?.kpi === "top_tenant";
  }, [inputsState?.kpi]);

  const taxRecordSelected = React.useMemo(() => {
    return inputsState?.kpi === "realestate_tax_record";
  }, [inputsState?.kpi]);

  const hasDeletePermission = useAllPermissionCheck([
    "allow_insights",
    "delete_can_insights",
  ]);

  const aggregatesDisabled =
    topTenantSelected ||
    [
      WIDGET_BARCHART_NEGATIVE_TRENDLINE,
      WIDGET_DONUTCHART,
      WIDGET_OVERVIEW_DIFF,
      WIDGET_QUICK_DETAIL,
      WIDGET_OVERVIEW_GENERAL,
      WIDGET_MULTIPLE_Y_AXIS,
    ].includes(widgetSelected)

  const futureDatesDisabled = [KPI_NET_LEASTING].includes(kpiSelected)

  const deleteInterval = (intervals, accessor, idx) => {
    if (intervals?.length <= 1) return;
    const intervalsCopy = cloneDeep(intervals);
    intervalsCopy.splice(idx, 1);

    inputsStateDispatch({
      key: `body_parameters.${[accessor]}`,
      value: intervalsCopy,
    });
  };
  const addInterval = (intervals, accessor) => {
    const intervalsCopy = cloneDeep(intervals);
    const lastElem = intervalsCopy[intervalsCopy?.length - 1];

    const lastDiff = lastElem[1] - lastElem[0];
    const newInterval = [lastElem[1] + 1, lastElem[1] + 1 + lastDiff];

    intervalsCopy.push(newInterval);

    inputsStateDispatch({
      key: `body_parameters.${[accessor]}`,
      value: intervalsCopy,
    });
  };

  React.useEffect(() => {
    // console.log(1)
    if (kpiDataMapping[kpiSelected].end_date_only) {
      if (!endDateOnly) setEndDateOnly(true)

    } else if (([WIDGET_QUICK_DETAIL, WIDGET_DONUTCHART].includes(inputsState?.widget))) {
      if (!endDateOnly) setEndDateOnly(true)
      if (!hideMonthGranularity) {
        inputsStateDispatch({
          key: "month_granularity",
          value: null
        })
        setHideMonthGranularity(true)
      }
    } else {
      if (endDateOnly) setEndDateOnly(false)
      if (hideMonthGranularity) setHideMonthGranularity(false)
    }
  }, [kpiSelected, inputsState?.widget])

  React.useEffect(() => {
    // console.log(2)
    if (
      (!inputsState?.interval_type || inputsState?.interval_type == "") &&
      !inputsState?.start_date &&
      !inputsState?.end_date
    )
      return;
    const fetchData = async () => {
      const _options = await getGranularityOptions(
        inputsState?.interval_type,
        inputsState?.start_date,
        inputsState?.end_date
      );
      setGranularityOptions(_options);
    };
    fetchData().catch(console.error);
  }, [
    inputsState?.interval_type,
    inputsState?.start_date,
    inputsState?.end_date,
  ]);

  React.useEffect(() => {
    // console.log(3)
    if (aggregatesDisabled) {
      inputsStateDispatch({
        key: "aggregates",
        value: null,
      });
    }
  }, [aggregatesDisabled]);

  React.useEffect(() => {
    // console.log(4)
    if (!hasLoadedInputsState) return;
    if (!aggregateBuildingAge || aggregatesDisabled) {
      inputsStateDispatch({
        key: "body_parameters.building_age_intervals",
        value: null,
      });
    } else {
      if (
        inputsState?.body_parameters?.building_age_intervals?.length !==
        undefined
      )
        if (inputsState?.body_parameters?.building_age_intervals?.length !== 0)
          return;

      let newIntervals = [[null, null]];
      inputsStateDispatch({
        key: "body_parameters.building_age_intervals",
        value: newIntervals,
      });
    }
  }, [aggregateBuildingAge, aggregatesDisabled]);

  React.useEffect(() => {
    // console.log(5)
    if (!hasLoadedInputsState) return;
    if (!aggregateTenantAge || aggregatesDisabled) {
      inputsStateDispatch({
        key: "body_parameters.tenant_age_intervals",
        value: null,
      });
    } else {
      if (
        inputsState?.body_parameters?.tenant_age_intervals?.length !== undefined
      )
        if (inputsState?.body_parameters?.tenant_age_intervals?.length !== 0)
          return;

      let newIntervals = [[null, null]];
      inputsStateDispatch({
        key: "body_parameters.tenant_age_intervals",
        value: newIntervals,
      });
    }
  }, [aggregateTenantAge, aggregatesDisabled]);

  React.useEffect(() => {
    if (!hasLoadedInputsState) return;

    if (hasKpiChanged) {
      inputsStateDispatch({
        type: "overrideMultiProps",
        value: {
          widget: null,
          aggregates: [],
        },
      });
    }
    setWidgetSelectFilter({ areas: availableGridAreas, kpi: kpiSelected });

    if (method === "POST" && inputsState?.kpi === KPI_TOP_TENANT) {
      inputsStateDispatch({
        type: "overrideMultiProps",
        value: {
          widget: WIDGET_BARCHART,
          interval_type: "l1d",
          body_parameters: {
            sort_method: "contract_value",
            instance_filter: null
          }
        }
      })
    }

    if (
      method === "POST" &&
      showShowMonthly &&
      inputsState?.body_parameters?.show_monthly === undefined
    ) {
      inputsStateDispatch({
        key: "body_parameters.show_monthly",
        value: false,
      });
    }
    if (!kpiSelected) return;
    const fetchData = async () => {
      const _options = await getAggregateOptions(kpiSelected);
      setAggregateOptions(_options);
    };

    fetchData().catch(console.error);

  }, [hasLoadedInputsState, kpiSelected])

  React.useEffect(() => {
    if (!hasLoadedInputsState) return;
    
    if (
      showInstanceFilter &&
      (!inputsState?.body_parameters?.instance_filter || inputsState?.body_parameters?.instance_filter === "")
    ) {
      inputsStateDispatch({
        key: "body_parameters.instance_filter",
        value: "all",
      });
    }
    // Form defaults
    if (method === "POST") {
      if (kpiSelected === KPI_CONTRACT_TERM) {
        inputsStateDispatch({
          key: "interval_type",
          value: "l1d",
        });
      }
      if (kpiSelected === KPI_ECONOMIC_VACANCY) {
        inputsStateDispatch({
          type: "overrideMultiProps",
          value: {
            interval_type: "l1y",
            month_granularity: "1",
          },
        });
      }
      if (kpiSelected === KPI_CONTRACT_VALUE) {
        inputsStateDispatch({
          type: "overrideMultiProps",
          value: {
            interval_type: "l1y",
            month_granularity: "1",
          },
        });
      }
      if (kpiSelected === KPI_REALESTATE_TAX_RECORD) {
        if (inputsState?.widget === WIDGET_QUICK_DETAIL) {
          inputsStateDispatch({
            type: "overrideMultiProps",
            value: {
              interval_type: "l1d",
            },
          });
        } else {
          inputsStateDispatch({
            type: "overrideMultiProps",
            value: {
              interval_type: "l3y",
              month_granularity: "3"
            },
          });
        }
      }
      if ([KPI_CONTRACT_VALUE, KPI_RENT_PER_AREA, KPI_OCCUPANCY, KPI_VACANT_OBJECTS_MARKET_RENT, KPI_NET_LEASTING].includes(kpiSelected)) {
        if ([WIDGET_QUICK_DETAIL, WIDGET_DONUTCHART, WIDGET_OVERVIEW_GENERAL].includes(inputsState?.widget)) {
          inputsStateDispatch({
            type: "overrideMultiProps",
            value: {
              interval_type: "l1d",
            },
          });
        } else {
          inputsStateDispatch({
            type: "overrideMultiProps",
            value: {
              interval_type: "l1y",
              month_granularity: "1"
            },
          });
        }
      }
    }
  }, [kpiSelected, hasLoadedInputsState, inputsState?.widget]);

  React.useEffect(() => {
    if (!hasLoadedInputsState) return;
    if (topTenantSelected) {
      inputsStateDispatch({
        type: "overrideMultiProps",
        value: {
          body_parameters: {
            limit: null,
            sort_method: "contract_value",
            instance_filter: "all"
          }
        }
      })
      console.log(inputsState)
    }
  }, [topTenantSelected, hasLoadedInputsState]);

  React.useEffect(() => {
    // console.log(9)
    if (!hasLoadedInputsState) return;
    if (taxRecordSelected && !taxValueType) {
      inputsStateDispatch({
        key: "body_parameters.tax_value_type",
        value: null,
      });
    }
  }, [taxRecordSelected]);

  React.useEffect(() => {
    // console.log(10)
    if (!hasWidgetChanged || !hasLoadedInputsState || !widgetInfo) return;

    const gridCoordinates = getGridPlacement(widgetInfo);
    // determine which corner to draw widget from
    if (gridCoordinates[0] < gridCellClicked[0]) {
      setGridColumn([
        gridCoordinates[0],
        gridCoordinates[0] + widgetInfo.width,
      ]);
    } else {
      setGridColumn([
        gridCellClicked[0],
        gridCellClicked[0] + widgetInfo.width,
      ]);
    }
    if (gridCoordinates[1] < gridCellClicked[1]) {
      setGridRow([gridCoordinates[1], gridCoordinates[1] + widgetInfo.height]);
    } else {
      setGridRow([gridCellClicked[1], gridCellClicked[1] + widgetInfo.height]);
    }
  }, [widgetInfo]);

  React.useEffect(() => {
    // console.log(11)
    if (!hasLoadedInputsState) return;

    inputsStateDispatch({
      type: "overrideMultiProps",
      value: {
        grid_column: gridColumn[0],
        grid_column_end: gridColumn[1],
        grid_row: gridRow[0],
        grid_row_end: gridRow[1],
      },
    });
  }, [gridRow, gridColumn]);

  React.useEffect(() => {
    // console.log(12)
    if (!hasLoadedInputsState) return;
    inputsStateDispatch({
      key: "page",
      value: { id: pageId },
    });
  }, [pageId, hasLoadedInputsState]);

  React.useEffect(() => {
    // console.log(13)
    if (!hasLoadedInputsState) return;
    if (dayGranularity) {
      inputsStateDispatch({
        key: "day_granularity",
        value: 1,
      });
      inputsStateDispatch({
        key: "month_granularity",
        value: null,
      });
      setHideMonthGranularity(true);
    } else {
      inputsStateDispatch({
        key: "day_granularity",
        value: null,
      });
      setHideMonthGranularity(false);
    }
  }, [dayGranularity]);

  React.useEffect(() => {
    // console.log(14)
    if (!hasLoadedInputsState) return;
    inputsStateDispatch({
      type: "overrideMultiProps",
      value: {
        start_date: null,
        end_date: null,
      },
    });
  }, [dateDisabled]);

  // get best area to place widget, preference is bottom-right, bottom-left, top-left, top-right
  const getGridPlacement = (widget) => {
    // future optimization, can loop through all coordinates and then pick the ones closest to gridCellClicked

    let gridCoordinates = null;

    if (widget.width === 1 && widget.height === 1) {
      gridCoordinates = gridCellClicked;
      return gridCoordinates;
    }

    if (bottomRightAreas[widget.width]) {
      if (bottomRightAreas[widget.width][widget.height]) {
        gridCoordinates = bottomRightAreas[widget.width][widget.height];
        return gridCoordinates;
      } else {
        Object.keys(bottomRightAreas[widget.width]).forEach((height) => {
          if (height >= widget.height) {
            gridCoordinates = bottomRightAreas[widget.width][height];
            return;
          }
        });
      }
    } else if (!gridCoordinates) {
      Object.keys(bottomRightAreas).forEach((width) => {
        if (!gridCoordinates && width > widget.width) {
          Object.keys(bottomRightAreas[width]).forEach((height) => {
            if (height >= widget.height) {
              gridCoordinates = bottomRightAreas[width][height];
              return;
            }
          });
        }
      });

      if (!gridCoordinates && bottomLeftAreas[widget.width]) {
        if (bottomLeftAreas[widget.width][widget.height]) {
          gridCoordinates = bottomLeftAreas[widget.width][widget.height];
          return gridCoordinates;
        } else {
          Object.keys(bottomLeftAreas[widget.width]).forEach((height) => {
            if (!gridCoordinates && height >= widget.height) {
              gridCoordinates = bottomLeftAreas[widget.width][height];
              return gridCoordinates;
            }
          });
        }
      } else if (!gridCoordinates) {
        Object.keys(bottomLeftAreas).forEach((width) => {
          if (!gridCoordinates && width > widget.width) {
            Object.keys(bottomLeftAreas[width]).forEach((height) => {
              if (height >= widget.height) {
                gridCoordinates = bottomLeftAreas[width][height];
                return gridCoordinates;
              }
            });
          }
        });

        if (!gridCoordinates && topLeftAreas[widget.width]) {
          if (topLeftAreas[widget.width][widget.height]) {
            gridCoordinates = topLeftAreas[widget.width][widget.height];
            return gridCoordinates;
          } else {
            Object.keys(topLeftAreas[widget.width]).forEach((height) => {
              if (!gridCoordinates && height >= widget.height) {
                gridCoordinates = topLeftAreas[widget.width][height];
                return gridCoordinates;
              }
            });
          }
        } else if (!gridCoordinates) {
          Object.keys(topLeftAreas).forEach((width) => {
            if (!gridCoordinates && width > widget.width) {
              Object.keys(topLeftAreas[width]).forEach((height) => {
                if (height >= widget.height) {
                  gridCoordinates = topLeftAreas[width][height];
                  return gridCoordinates;
                }
              });
            }
          });

          if (!gridCoordinates && topRightAreas[widget.width]) {
            if (topRightAreas[widget.width][widget.height]) {
              gridCoordinates = topRightAreas[widget.width][widget.height];
              return gridCoordinates;
            } else {
              Object.keys(topRightAreas[widget.width]).forEach((height) => {
                if (!gridCoordinates && height >= widget.height) {
                  gridCoordinates = topRightAreas[widget.width][height];
                  return gridCoordinates;
                }
              });
            }
          } else if (!gridCoordinates) {
            Object.keys(topRightAreas).forEach((width) => {
              if (!gridCoordinates && width > widget.width) {
                Object.keys(topRightAreas[width]).forEach((height) => {
                  if (height >= widget.height) {
                    gridCoordinates = topRightAreas[width][height];
                    return gridCoordinates;
                  }
                });
              }
            });
          }
        }
      }
    }
    return gridCoordinates;
  };

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    onCheckout();
  };

  const removeAllErrors = () => {
    setLimitError(false);
    setSortMethodError(false);
    setTaxValueError(false);
    setNoTimePeriodError(false);
    setHasIntervalError(false);
    setGranularityError(false);
    setDayGranularityError(false);
  };

  const onSuccess = (_, returned) => {
    if (method === "PATCH") {
      dispatch(removeObject({ constants, objectId: widgetPlacement?.id }));
      dispatch(clearSpecificValues([widgetPlacement?.id]));
    }
    setLoading(false);
    checkout(true);
    removeAllErrors();
  };

  const onFailure = (method) => {
    setLoading(false);
    if (method === "POST") {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Skapandet misslyckades",
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Uppdateringen misslyckades",
        })
      );
    }
  };

  const customValidation = () => {
    let fail = false;
    if ((kpiSelected === KPI_TOP_TENANT && !sortMethod) || sortMethod > 10) {
      setSortMethodError(true);
      fail = true;
    }
    if (kpiSelected === KPI_TOP_TENANT && limit && limit > 10) {
      setLimitError("Begränsningen är för hög");
      fail = true;
    }
    if (kpiSelected === KPI_TOP_TENANT) {
      inputsStateDispatch({
        key: "month_granularity",
        value: null,
      });
    }
    if (taxRecordSelected && !taxValueType) {
      setTaxValueError(true);
      fail = true;
    }
    if ([WIDGET_OVERVIEW_DIFF].includes(widgetSelected)) {
      inputsStateDispatch({
        key: "double_result",
        value: true,
      });
    } else {
      inputsStateDispatch({
        key: "double_result",
        value: false,
      });
    }
    if (nonTimeKpi) {
      inputsStateDispatch({
        type: "overrideMultiProps",
        key: "double_result",
        value: {
          interval_type: null,
          start_date: null,
          end_date: null,
          month_granularity: null,
        },
      });
    } else {
      if (noTimePeriodSelected) {
        setNoTimePeriodError(true);
        fail = true;
      }
    }

    if (
      !fail &&
      inputsState?.body_parameters?.building_age_intervals &&
      inputsState?.body_parameters?.building_age_intervals?.length !== 0
    ) {
      let ints = [];
      for (let outerArray of inputsState?.body_parameters
        ?.building_age_intervals) {
        if (fail) break;
        for (let int of outerArray) {
          if (int == null || isNaN(int)) {
            fail = true;
            break;
          }
          if (ints.includes(int)) {
            fail = true;
            break;
          }
          ints.push(int);
        }
      }

      if (fail) setHasIntervalError(true);
    }

    if (
      !fail &&
      inputsState?.body_parameters?.tenant_age_intervals &&
      inputsState?.body_parameters?.tenant_age_intervals?.length !== 0
    ) {
      let ints = [];
      for (let outerArray of inputsState?.body_parameters
        ?.tenant_age_intervals) {
        if (fail) break;
        for (let int of outerArray) {
          if (int == null || isNaN(int)) {
            fail = true;
            break;
          }
          if (ints.includes(int)) {
            fail = true;
            break;
          }
          ints.push(int);
        }
      }

      if (fail) setHasIntervalError(true);
    }

    return fail;
  };

  // can't dynamically set message of month_granularity with nonconnected fields, so have a mapping in case the problem arises with other fields
  const errorStateMapping = {
    month_granularity: setGranularityError,
    day_granularity: setDayGranularityError,
  };

  const customErrorCallback = (data, returnedData, error) => {
    setLoading(false);
    if (!returnedData) return;
    Object.keys(returnedData).forEach((err) => {
      errorStateMapping[err](returnedData[err]);
    });
  };

  const customPreProcess = (data) => {
    const newData = cloneDeep(data);
    if (newData.interval_type === "") {
      newData.interval_type = null;
    }
    if (
      newData.interval_type &&
      (widgetPlacement?.start_date || widgetPlacement?.end_date)
    ) {
      newData.start_date = null;
      newData.end_date = null;
    }
    if (newData.day_granularity) {
      newData.day_granularity = 1;
    } else {
      newData.day_granularity = null;
    }
    if (newData.body_parameters?.limit) {
      newData.body_parameters.limit = parseInt(newData.body_parameters.limit);
    }
    if (newData.month_granularity === "") newData.month_granularity = null

    if (newData.widget === WIDGET_DONUTCHART && newData.month_granularity !== null) newData.month_granularity = null
    return newData;
  };

  const onSubmit = () => {
    removeAllErrors();
    setLoading(true);
    const fail = customValidation();

    if (fail) return onFailure(method);
    if (method === "POST") {
      dispatch(
        create({
          id: widgetPlacement?.id,
          successCallback: onSuccess,
          errorCallback: customErrorCallback,
          preProcess: customPreProcess,
          forceData: inputsState,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id: widgetPlacement?.id,
          successCallback: onSuccess,
          errorCallback: customErrorCallback,
          preProcess: customPreProcess,
          forceData: inputsState,
        })
      );
    }
  };

  const { _choices: intervalTypeChoices } = {
    ...useFormField({
      storeName,
      fieldKey: "interval_type",
      method,
    }),
  };
  const intervalTypeFormChoices = []
  if (intervalTypeChoices != null && typeof intervalTypeChoices[Symbol.iterator] === "function") {
  for (let choice of intervalTypeChoices)  {
    if (futureDatesDisabled && ["n1q", "n2q", "n4q", "n1m", "n3m", "n1y"].includes(choice.v)) {
      continue
    }
    intervalTypeFormChoices.push(choice)

  }

  }

  if (inputsState?.kpi == null && !widgetPlacement?.id) {
    if (!kpiChoices || kpiChoices.length === 0) return;
    inputsStateDispatch({
      key: "kpi",
      value: kpiChoices?.[0].choices?.[0].v,
    });
  }
  const buildAggregatesForRender = (aggrs) => {
    if (!aggrs || !aggregateOptions) return [];

    let choosen = aggregateOptions.map(({ v: value }) => {
      if (aggrs.includes(value)) return true;
      return false;
    });

    return choosen;
  };

  const buildAggregatesForState = (aggrs) => {
    let built = [];

    for (let i = 0; i < aggregateOptions.length; i++) {
      if (aggrs[i]) built.push(aggregateOptions[i].v);
    }

    return built;
  };

  const safeParseInt = (int) => {
    if (int === "") return int;
    return isNaN(parseInt(int)) ? 0 : parseInt(int);
  };

  if (!formLoaded) {
    return <OverlaySpinner />;
  }
  return (
    <>
      <Modal
        isOpen={open}
        closeFunction={checkout}
        onAccept={onSubmit}
        title={
          method === "PATCH"
            ? "Uppdatera widgetplacering"
            : "Skapa widgetplacering"
        }
      >
        {loading && open && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>
              {method === "PATCH" ? "Uppdatera" : "Skapa"} widgetplacering
            </OverviewTitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <div style={{ marginBottom: "32px" }}>
          <div className="grid grid-cols-2 gap-6 mb-0">
            <LocalSelectField
              title="Nyckeltal"
              value={inputsState?.kpi}
              choices={kpiChoices}
              onChange={(value) => {
                inputsStateDispatch({
                  key: "kpi",
                  value,
                });
              }}
              error={false}
              removePlaceholder
            />
          </div>
          <TextButton
            iconType="info"
            title="Hur fungerar nyckeltal?"
            clicked={() => setKpiInfoOpen(true)}
          />
        </div>
        {kpiInfoOpen && (
          <Modal
            isOpen={kpiInfoOpen}
            closeFunction={() => setKpiInfoOpen(false)}
            title="Hur fungerar nyckeltal?"
            withActionBar
            actionBarCancelTitle="Stäng"
            modalInModal
            className="!mt-0"
          >
            <KPIGuide kpiChoices={kpiChoices} />
          </Modal>
        )}

        {kpiSelected && (
          <WidgetSelect
            storeName={constants.STORE_NAME}
            instructions={widgetSelectInstructions}
            listDefs={widgetsListDefs}
            filter={widgetSelectFilter}
            title={"Välj widget..."}
            fieldTitle="Widget"
            method={method}
            fieldKey="widget"
            forceConstructStrRep={true}
            setWholeObject={true}
            infobox={infoBox}
            modalInModal
            value={inputsState?.widget}
            onChange={(value) =>
              inputsStateDispatch({
                key: "widget",
                value,
              })
            }
          />
        )}
        {!nonTimeKpi && (
          <>
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalSelectField
                title="Intervalltyp"
                value={inputsState?.interval_type}
                choices={intervalTypeFormChoices || []}
                onChange={(value) => {
                  inputsStateDispatch({
                    key: "interval_type",
                    value,
                  });
                }}
                canClear
                error={
                  noTimePeriodError &&
                  "Detta nyckeltal kräver antingen interval eller specifika datum"
                }
              />
            </div>

            <div className="grid grid-cols-2 gap-6 mb-6">
              {!endDateOnly && (
              <LocalDateSelect
                title="Startdatum"
                value={inputsState?.start_date}
                onChange={(value) =>
                  inputsStateDispatch({
                    key: "start_date",
                    value,
                  })
                }
                disabled={dateDisabled}
                inline
              />
              )}
              <LocalDateSelect
                title={endDateOnly ? "Datum" : "Slutdatum"}
                fieldKey={`end_date`}
                value={inputsState?.end_date}
                onChange={(value) =>
                  inputsStateDispatch({
                    key: "end_date",
                    value,
                  })
                }
                disabled={dateDisabled}
                inline
              />
            </div>
          </>
        )}

        {!topTenantSelected && !nonTimeKpi && !hideMonthGranularity && (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalSelectField
              title="Visa data per (månader)"
              value={inputsState?.month_granularity}
              choices={granularityOptions || []}
              onChange={(value) => {
                inputsStateDispatch({
                  key: "month_granularity",
                  value,
                });
              }}
              canClear
              error={granularityError ? granularityError : false}
            />
          </div>
        )}

        {showInstanceFilter && (
          <LocalSelectField
            title="Filtrera på"
            value={get(inputsState, "body_parameters.instance_filter")}
            onChange={(value) =>
              inputsStateDispatch({
                key: "body_parameters.instance_filter",
                value,
              })
            }
            canClear
            choices={showParkingFilter ? [
              { v: "all", d: "Allt" },
              { v: "apartment", d: "Lägenheter" },
              { v: "industrialpremises", d: "Lokaler" },
              { v: "parkingspot", d: "Fordonsplatser" },
            ] : [
              { v: "all", d: "Lägenheter & lokaler" },
              { v: "apartment", d: "Lägenheter" },
              { v: "industrialpremises", d: "Lokaler" },
            ]}
          />
        )}
        {showShowMonthly && (
          <LocalSelectField
            title="Visa belopp"
            value={get(inputsState, "body_parameters.show_monthly")}
            onChange={(value) =>
              inputsStateDispatch({
                key: "body_parameters.show_monthly",
                value,
              })
            }
            canClear
            choices={[
              { v: false, d: "Årsbelopp" },
              { v: true, d: "Månadsbelopp" },
            ]}
          />
        )}
        {topTenantSelected && (
          <>
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalSelectField
                title="Filtrera på"
                value={get(inputsState, "body_parameters.sort_method")}
                onChange={(value) =>
                  inputsStateDispatch({
                    key: "body_parameters.sort_method",
                    value,
                  })
                }
                canClear
                choices={[
                  { v: "contract_value", d: "Hyresvärde" },
                  { v: "total_area", d: "Total area" },
                  { v: "total_units", d: "Antal hyresobjekt" },
                ]}
                error={sortMethodError ? "Obligatorisk" : false}
              />
              <LocalSelectField
                title="Filtrera på hyresgästtyp"
                value={get(inputsState, "body_parameters.instance_filter")}
                onChange={(value) =>
                  inputsStateDispatch({
                    key: "body_parameters.instance_filter",
                    value,
                  })
                }
                choices={[
                  { v: "all", d: "Privata och företagshyresgäster" },
                  { v: "private", d: "Privat" },
                  { v: "corporate", d: "Företag" },
                ]}
                canClear
              />
            </div>
          </>
        )}
        {/* {dailyModeKpi && (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalCheckField
              title="Räkna ut per dag"
              value={get(inputsState, "day_granularity")}
              onChange={(value) => {
                inputsStateDispatch({
                  key: "day_granularity",
                  value: value,
                });
              }}
              error={dayGranularityError}
            />
          </div>
        )} */}

        {taxRecordSelected && (
          <div className="grid grid-cols-2 gap-6 mb-6">
            <LocalSelectField
              title="Typ, taxeringsvärde"
              value={get(inputsState, "body_parameters.tax_value_type")}
              onChange={(value) =>
                inputsStateDispatch({
                  key: "body_parameters.tax_value_type",
                  value,
                })
              }
              choices={[
                { v: "ground_housing_value", d: "Markvärde för bostäder" },
                { v: "build_housing_value", d: "Byggnadsvärde för bostäder" },

                { v: "ground_premises_value", d: "Markvärde för lokaler" },
                { v: "build_premises_value", d: "Byggnadsvärde för lokaler" },

                { v: "ground_industrial_value", d: "Markvärde för industri" },
                {
                  v: "build_industrial_value",
                  d: "Byggnadsvärde för industri",
                },
              ]}
              canClear
              error={taxValueError ? "Obligatorisk" : false}
            />
          </div>
        )}

        {!aggregatesDisabled && (
          <>
            <p className="font-medium text-gray-900 text-sm flex items-center mb-0">
              Aggregera på
            </p>
            <div className="grid grid-cols-2 gap-6 mb-6 !mt-0">
              <LocalSelectManyField
                primaryBtnClass="w-full relative !bg-white"
                choices={aggregateOptions || []}
                value={
                  buildAggregatesForRender(inputsState?.aggregates) || undefined
                }
                onChange={(val) =>
                  inputsStateDispatch({
                    key: "aggregates",
                    value: buildAggregatesForState(val),
                  })
                }
                withIcon
                gray
              />
            </div>
          </>
        )}
        {aggregateBuildingAge && (
          <p className="font-medium text-gray-900 text-sm flex items-center mb-0">
            Intervall, byggnadsålder
          </p>
        )}
        {buildingAgeIntervals?.map((item, idx) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "0px",
                marginTop: idx === 0 ? "12px" : "0px",
              }}
              key={idx}
            >
              <div className="grid grid-cols-2 gap-6 pb-5">
                <LocalTextInputField
                  isNumber
                  step={"1"}
                  title="Intervall start"
                  value={
                    inputsState?.body_parameters?.building_age_intervals?.[
                      idx
                    ]?.[0]
                  }
                  onChange={(value) =>
                    inputsStateDispatch({
                      key: `body_parameters.building_age_intervals[${idx}][0]`,
                      value: safeParseInt(value),
                    })
                  }
                />
                <LocalTextInputField
                  isNumber
                  step={"1"}
                  title="Intervall slut"
                  value={
                    inputsState?.body_parameters?.building_age_intervals?.[
                      idx
                    ]?.[1]
                  }
                  onChange={(value) =>
                    inputsStateDispatch({
                      key: `body_parameters.building_age_intervals[${idx}][1]`,
                      value: safeParseInt(value),
                    })
                  }
                />
              </div>
              {buildingAgeIntervals?.length > 1 && (
                <>
                  <button
                    className="text-red-500 ml-2"
                    onClick={() =>
                      deleteInterval(
                        buildingAgeIntervals,
                        "building_age_intervals",
                        idx
                      )
                    }
                  >
                    <XMarkIcon width={24} />
                  </button>
                </>
              )}
            </div>
          );
        })}
        {buildingAgeIntervals?.length > 0 && (
          <>
            {hasIntervalError && (
              <p className="text-red-500 text-sm !mt-0">
                Kontrollera att alla intervaller har ett värde. (Obs. Ett värde
                får aldrig upprepas)
              </p>
            )}
            <PrimaryButton
              title="Lägg till intervall"
              clicked={() =>
                addInterval(buildingAgeIntervals, "building_age_intervals")
              }
              extraStyle={{ marginTop: "5px" }}
            />
          </>
        )}

        {aggregateTenantAge && (
          <p className="font-medium text-gray-900 text-sm flex items-center mb-0">
            Intervall, hyresgästålder
          </p>
        )}
        {tenantAgeIntervals?.map((item, idx) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "0px",
                marginTop: idx === 0 ? "12px" : "0px",
              }}
              key={idx}
            >
              <div className="grid grid-cols-2 gap-6 pb-5">
                <LocalTextInputField
                  isNumber
                  step={"1"}
                  title="Intervall start"
                  value={
                    inputsState?.body_parameters?.tenant_age_intervals?.[
                      idx
                    ]?.[0]
                  }
                  onChange={(value) =>
                    inputsStateDispatch({
                      key: `body_parameters.tenant_age_intervals[${idx}][0]`,
                      value: safeParseInt(value),
                    })
                  }
                />
                <LocalTextInputField
                  isNumber
                  step={"1"}
                  title="Intervall slut"
                  value={
                    inputsState?.body_parameters?.tenant_age_intervals?.[
                      idx
                    ]?.[1]
                  }
                  onChange={(value) =>
                    inputsStateDispatch({
                      key: `body_parameters.tenant_age_intervals[${idx}][1]`,
                      value: safeParseInt(value),
                    })
                  }
                />
              </div>
              {tenantAgeIntervals?.length > 1 && (
                <>
                  <button
                    className="text-red-500 ml-2"
                    onClick={() =>
                      deleteInterval(
                        tenantAgeIntervals,
                        "tenant_age_intervals",
                        idx
                      )
                    }
                  >
                    <XMarkIcon width={24} />
                  </button>
                </>
              )}
            </div>
          );
        })}
        {tenantAgeIntervals?.length > 0 && (
          <>
            {hasIntervalError && (
              <p className="text-red-500 text-sm !mt-0">
                Kontrollera att alla intervaller har ett värde. (Obs. Ett värde
                får aldrig upprepas)
              </p>
            )}
            <PrimaryButton
              title="Lägg till intervall"
              clicked={() =>
                addInterval(tenantAgeIntervals, "tenant_age_intervals")
              }
              extraStyle={{ marginTop: "5px" }}
            />
          </>
        )}
        {method === "PATCH" && hasDeletePermission && (
          <OverviewSubtitle>
            <TextButton
              title="Radera"
              red={true}
              iconType="delete"
              iconPlacement="right"
              clicked={() => handleDeleteModal(widgetPlacement)}
            />
          </OverviewSubtitle>
        )}
      </Modal>
    </>
  );
}
