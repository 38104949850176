import * as React from "react";

import FullTable from "../Tables/Users/FullTable";

export default ({ groupId }) => {
  const persistantQuery = {
    usergroup__id: groupId,
  };

  return (
    <>
      <FullTable persistantQuery={persistantQuery} isBare ignoreLocalStorage />
    </>
  );
};
