import * as React from "react";
import { useDispatch } from "react-redux";

import {
  destroyPatchForm,
  constants,
  update,
  useParkingContractForm,
} from "../../../../store/parkingContracts";
import { updateActiveFormInstance } from "../../../../store/base";
import Modal from "../../Base/Modals/Modal";
import TableSelectField from "../../Base/Fields/TableSelectField";
import CompanyTable from "src/components/Tables/Companies/FullTable";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";

export default function UpdateParkingContractLandlordModalForm({
  id,
  onCheckout,
  instance = {},
}) {
  const method = "PATCH"; // always patch
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useParkingContractForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title="Uppdatera avtal"
      closeFunction={onDone}
      onAccept={onSubmit}
      acceptTitle="Spara"
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey="landlord"
          title={"Hyresvärd"}
          placeholder="Välj hyresvärd..."
          description="Bolaget som ska stå som hyresvärd på avtalet."
          TableComponent={CompanyTable}
        />
      </div>
    </Modal>
  );
}
