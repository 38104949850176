import { cloneDeep, isEqual, set } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";
import { useInsightsPageForm } from "src/store/insightsPage/hooks/form";
import { useFilteredRealEstates } from "src/store/realEstates";
import { addToast, TOAST_TYPES } from "src/store/toasts";
import { clearAllValues } from "src/store/widgetPlacements";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";
import { useInsightsPages, update } from "../../../store/insightsPage";
import PrimaryBtn from "../../Forms/Base/Buttons/PrimaryBtn";
import LocalSelectField from "../../Forms/Base/Fields/LocalSelectField";

import * as SC from "./styles";

const inputsReducer = (state, action) => {
  if (action?.type === "all") {
    return action.value;
  }
  if (action?.key.includes(".")) {
    let newState = cloneDeep(state);
    set(newState, action?.key, action?.value);
    return newState;
  }
  return {
    ...state,
    [action.key]: action.value,
  };
};

export default function PageTopBar({
  currentPage,
  openModal,
  setGoalOverviewOpen,
  defaultPage,
}) {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [insightsPages, insightsPagesLoading] = useInsightsPages();
  
  const realestateQuery = buildQueryString({
    id__in: currentPage?.query_parameters?.realestate_ids
  })
  const [realestates, realestatesLoading] = useFilteredRealEstates(realestateQuery)
  const [pageChoices, setPageChoices] = React.useState([]);

  useInsightsPageForm("PATCH", currentPage?.id)

  React.useEffect(() => {
    if (insightsPagesLoading) return;
    const newPageChoices = [];
    if (!defaultPage) {
      newPageChoices.push({
        d: "Dashboard",
        v: "/insights",
      });
    }

    for (let page of insightsPages) {
      if (page.id != currentPage?.id) {
        newPageChoices.push({
          d: page.title,
          v: `/insights/page/${page.id}`,
        });
      }
    }
    setPageChoices(newPageChoices);
  }, [currentPage, insightsPagesLoading]);

  const [hasCurrentPageLoaded, setHasCurrentPageLoaded] = React.useState(false);
  const [oldRealestateIds, setOldRealestateIds] = React.useState([]);

  const [inputsState, inputsStateDispatch] = React.useReducer(inputsReducer, {
    title: "",
    width: 8,
    height: 20,
    query_parameters: {},
  });

  React.useEffect(() => {
    if (!currentPage || hasCurrentPageLoaded) return;
    setHasCurrentPageLoaded(true);
    inputsStateDispatch({
      type: "all",
      value: currentPage,
    });
    setOldRealestateIds(currentPage?.query_parameters?.realestate_ids);
  }, [currentPage]);

  const [selectedRealEstates, setSelectedRealEstates] = React.useState([]);

  React.useEffect(() => {
    if (realestates.length === 0) return
    setSelectedRealEstates(realestates)
  }, [realestates])

  const hasViewPermission = useAllPermissionCheck([
    "allow_insights",
    "view_can_insights",
  ]);
  const hasCreatePermission = useAllPermissionCheck([
    "allow_insights",
    "add_can_insights",
  ]);

  const hasChangePermission = useAllPermissionCheck([
    "allow_insights",
    "change_can_insights",
  ]);
  const onCloseRealEstateSelect = () => {
    let realEstateIds = selectedRealEstates.map((elem) => elem.id);
    inputsStateDispatch({
      key: "query_parameters.realestate_ids",
      value: realEstateIds,
    });
    dispatch(clearAllValues())
  };
  const onClearAllRealEstateSelect = () => {
    const realEstateIds = []
    inputsStateDispatch({
      key: "query_parameters.realestate_ids",
      value: realEstateIds,
    });
    dispatch(clearAllValues())
  };

  const onSubmit = () => {
    dispatch(
      update({
        id: currentPage?.id,
        errorCallback: () => {
          dispatch(
            addToast({
              title: "Filtrering misslyckades",
              description: "Ladda om sidan och försök igen",
              type: TOAST_TYPES.ERROR,
            })
          );
        },
        forceData: inputsState,
        preventDefaultToast: true,
      })
    );
  };

  React.useEffect(() => {
    if (!hasCurrentPageLoaded) return;
    if (
      isEqual(oldRealestateIds, inputsState?.query_parameters?.realestate_ids)
    )
      return;

    onSubmit();
  }, [inputsState?.query_parameters?.realestate_ids]);

  return (
    <SC.TopBarContainer>
      {hasViewPermission && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex"}}>
{currentPage && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                className="mr-1"
              >
                <LocalSelectField
                  placeholder={currentPage.title}
                  choices={pageChoices}
                  onChange={(val) => push(val)}
                  value={currentPage}
                  className="w-[200px] mr-1 h-[42px]"
                  id="insights_page_selector"
                  title="Visar"
                  labelClassName="!text-xs"
                />

                {!defaultPage && hasChangePermission && (
                  <div className="mt-4 h-[42px]">
                  <PrimaryBtn secondary onClick={() => openModal("PATCH")} className="h-full">
                    Redigera
                  </PrimaryBtn>
                  </div>
                )}
              </div>
            )}
            {!defaultPage && currentPage && (
              <LocalTableSelectField
                TableComponent={RealEstateTable}
                onChange={(d) => {
                  setSelectedRealEstates(d);
                }}
                isMany
                value={selectedRealEstates}
                onDone={() => onCloseRealEstateSelect()}
                onClearAll={() => onClearAllRealEstateSelect()}
                tooltipPopOverClassName={"!left-0"}
                popOverHeightMultiplier={3}
                title="Filtrera på fastigheter"
                className="text-2 !max-h[42px] mr-1"
                labelClassName="text-xs"
              />
            )}
          </div>
          <div
            style={{
              marginRight: "25px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >

            
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem"
              }}
            >
              {hasCreatePermission && (
                <PrimaryBtn
                  primary
                  onClick={() => openModal("POST")}
                  className="mr-1 h-[42px]"
                >
                  Lägg till ny sida
                </PrimaryBtn>
              )}

              <PrimaryBtn secondary onClick={() => setGoalOverviewOpen(true)}>
                Mål
              </PrimaryBtn>
            </div>
          </div>
        </div>
      )}
    </SC.TopBarContainer>
  );
}
