import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";

import AreaList from "../../../RoundingErrandSetting/AreaList/AreaList";

export default ({ method, id }) => {
  return (
    <>
      <FormAreaTitle>Områden</FormAreaTitle>
      <FormAreaDescription>
        Ange vilka områden som inkluderas i myndighetskravet
      </FormAreaDescription>

      <AreaList method={method} id={id} />
    </>
  );
};
