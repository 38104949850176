import { axiosInstance } from "../../base/store/axios";

async function getKPIResultData(postObj, endpoint) {
  const { data } = await axiosInstance.post(endpoint, postObj);

  return data;
}

async function getAggregateOptions(kpi) {
  const postObj = {
    kpi: kpi
  }
  const { data } = await axiosInstance.post("/insights/dynamicaggregateoptions/", postObj)
  return data
}
async function getGranularityOptions(interval_type, start_date, end_date) {
  const postObj = {
    interval_type,
    start_date,
    end_date
  }
  const { data } = await axiosInstance.post("/insights/dynamicgranularityoptions/", postObj)
  return data
}

export { getKPIResultData, getAggregateOptions, getGranularityOptions };
