import * as React from "react";
import { useDispatch } from "react-redux";

// style, design
import * as SC from "../Base/Chapters/styles";
import { DoubleFieldWrapper } from "../Base/Chapters/styles";
import { TextInput } from "../Base/Fields";
import { PrimaryButton } from "../Base/Buttons";
import FullPageSpinner from "../../Loaders/FullPageSpinner";

// store, state
import {
  constants,
  destroyPatchForm,
  createNewUser,
} from "../../../store/users";
import { useCreateUserForm } from "../../../store/users/hooks/form";
import { useFormInstanceField } from "../../../store/base";
import { logout } from "../../../store/app/actions";
import NonConnectedCheckbox from "../Base/Old/NonConnected/NonConnectedCheckbox";
import moment from "moment";
import { cloneDeep } from "lodash";

const isLive = window.location.hostname === "app.pigello.se";

export default ({ method, id }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [loading, setLoading] = React.useState(false);

  const [acceptedTerms, setAcceptedTerms] = React.useState(false);

  const formLoaded = Boolean(useCreateUserForm(method, id));

  const userType = useFormInstanceField({
    storeName,
    fieldKey: "user_type",
  });

  const instance = useFormInstanceField({ storeName, fieldKey: "" });

  const acceptDisabled =
    !instance?.password ||
    !instance?.password_two ||
    !instance?.username ||
    !acceptedTerms;

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    if (userType === 3) {
      if (isLive) {
        window.location.replace("https://portal.pigello.se/");
      } else {
        window.location.replace("https://demo.portal.pigello.se/");
      }
    } else {
      dispatch(logout());
    }
  };

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      createNewUser({
        id: id,
        preProcess: (data) => {
          const dataClone = cloneDeep(data);
          dataClone.terms_accepted_date = moment().format("YYYY-MM-DD HH:mm");

          return dataClone;
        },
        successCallback: () => checkout(true),
        errorCallback: () => setLoading(false),
      })
    );
  };

  if (!formLoaded || loading) {
    return <FullPageSpinner />;
  }

  return (
    <SC.FormArea style={{ margin: "0 auto", width: "auto" }}>
      <TextInput
        title="Användarnamn"
        storeName={storeName}
        fieldKey={"username"}
        method={method}
      />

      {/* <DoubleFieldWrapper>
        <TextInput
          title="Förnamn"
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={storeName}
          fieldKey={"first_name"}
          method={method}
        />
        <TextInput
          title="Efternamn"
          extraStyles={{ minWidth: "290px" }}
          storeName={storeName}
          fieldKey={"last_name"}
          method={method}
        />
      </DoubleFieldWrapper>

      <DoubleFieldWrapper>
        <TextInput
          title="E-post"
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={storeName}
          fieldKey={"email"}
          method={method}
        />
        <TextInput
          title="Telefon"
          extraStyles={{ minWidth: "290px" }}
          storeName={storeName}
          fieldKey={"phone"}
          method={method}
        />
      </DoubleFieldWrapper> */}

      <DoubleFieldWrapper>
        <TextInput
          title="Lösenord"
          extraStyles={{ minWidth: "290px", marginRight: "20px" }}
          storeName={storeName}
          fieldKey={"password"}
          method={method}
          type="password"
        />

        <TextInput
          title="Upprepa lösenord"
          extraStyles={{ minWidth: "290px" }}
          storeName={storeName}
          fieldKey={"password_two"}
          method={method}
          type="password"
        />
      </DoubleFieldWrapper>

      {/* <TextInput
        title="Personnummer"
        storeName={storeName}
        fieldKey={"legal_id"}
        method={method}
      /> */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0 auto",
          alignItems: "center",
        }}
      >
        <NonConnectedCheckbox
          id="acceptTosCheckbox"
          title=""
          extraStyles={{ margin: 0, paddingTop: "10px" }}
          onChange={(val) => setAcceptedTerms(val)}
          value={acceptedTerms}
        />
        Bocka i för att godkänna
        <a
          href="https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/public_media/Other/Privacy_Policy.pdf"
          target="_blank"
          style={{ paddingLeft: "6px" }}
        >
          användarvillkoren
        </a>
        .
      </div>

      <PrimaryButton
        clicked={onSubmit}
        disabled={acceptDisabled}
        title={"Acceptera inbjudan"}
      />
    </SC.FormArea>
  );
};
