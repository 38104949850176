import * as React from "react";

// store, state
import { constants } from "../../../../../store/roundingErrandSettings";
import { useFormInstanceField } from "../../../../../store/base";

import { DatePicker } from "../../../Base/Fields";
import Interval, {
  INTERVAL_MAP_GOV,
} from "../../../Base/Fields/CustomFields/Interval";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  const mode = useFormInstanceField({
    storeName,
    fieldKey: "interval.mode",
  });

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Schemaläggning och intervall</OverviewTitle>
          <OverviewSubtitle>
            Schemaläggning av förknippat praktiskt utförande
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <Interval
        mode={mode}
        storeName={storeName}
        method={method}
        config={INTERVAL_MAP_GOV}
      />

      <DatePicker
        storeName={storeName}
        method={method}
        fieldKey={"interval_start"}
        title="Välj vilket datum systemet ska börja generera ronderingar"
      />
    </>
  );
};
