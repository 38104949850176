import * as React from "react";
import LocalSelectField from "../Base/Fields/LocalSelectField";
import LocalTextInputField from "../Base/Fields/LocalTextInputField";
import Modal from "../Base/Modals/Modal";

export default function InvoiceSendReminderForm({
  reminderFee,
  setReminderFee,
  closeFunction,
  handleSendManualReminderInvoice,
  reminderInvoiceDeliveryMethod,
  setReminderInvoiceDeliveryMethod,
  reminderPaymentTermsInDays,
  setReminderPaymentTermsInDays,
}) {
  return (
    <Modal
      closeFunction={closeFunction}
      title="Inställningar för påminnelse"
      onAccept={handleSendManualReminderInvoice}
      acceptTitle="Skicka påminnelse"
      denyTitle="Avbryt"
      canAccept={reminderInvoiceDeliveryMethod != null}
    >
      <LocalSelectField
        extraStyle={{ paddingBottom: 200 }}
        id="_send_reminder_method"
        value={reminderInvoiceDeliveryMethod}
        choices={[
          { v: 2, d: "Skicka ej" },
          { v: 0, d: "Email (0 kr/st)" },
          { v: 5, d: "Kivra (4 kr/st)" },
          {
            v: 4,
            d: "E-faktura (2.50 kr/st exkl. bankkostnader)",
          },
          { v: 1, d: "Post (6.50 kr/st)" },
        ]}
        onChange={(val) => setReminderInvoiceDeliveryMethod(val)}
        title="Leveransmetod"
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalTextInputField
          isNumber
          title="Påminnelseavgift (SEK)"
          description="Måste betalas för att markera faktura som betald"
          id="_send_reminder_fee"
          value={reminderFee}
          onChange={(val) => setReminderFee(val)}
        />

        <LocalTextInputField
          isNumber
          title="Betalvillkor i antal dagar"
          description="Lämna tom för att använda standardinställning"
          id="_send_reminder_terms"
          value={reminderPaymentTermsInDays}
          onChange={(val) => setReminderPaymentTermsInDays(val)}
        />
      </div>
    </Modal>
  );
}
