import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject, DateCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import NumberRangeFilter from "src/components/Lists/Base/CompleteList/Filters/NumberRangeFilter";
import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";

import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import { TextButton } from "../../Forms/Base/Buttons";
import PrimaryBtn from "src/components/Forms/Base/Buttons/PrimaryBtn";

const handleDownload = (file) => {
  const a = document.createElement("a");
  a.href = file;
  a.download = `transaction.pdf`;
  a.click();
};

export default (isAdmin, onEdit, onDelete) => {
  const cols = [
    {
      Header: "Transaktions-ID",
      accessor: "id_number",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },

    {
      Header: "Köpeskilling",
      accessor: "value",
      Cell: ({ value }) => (
        <ToolTipCell
          text={value ? `${value?.toLocaleString("sv")} SEK` : "-"}
        />
      ),
      Filter: NumberRangeFilter,
      filter: "between",
    },

    {
      Header: "Köpare",
      accessor: "owner.str_representation",
      Cell: ({ row }) => (
        <LinkedObject
          obj={{ ...row.original.owner, id: row.original.owner?.tenant?.id }}
          urlMethod={tenantDetailUrl}
          extraUrlMethodArgs={{ isMember: true }}
        />
      ),
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Säljare",
      accessor: "sellers",
      Cell: ({ row }) => {
        const txt = row.original.sellers
          .map((item) => item.str_representation)
          .join(", ");
        return <ToolTipCell text={txt ?? "-"} />;
      },
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
    },
    {
      Header: "Transaktionsdatum",
      accessor: "transaction_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Tillträdesdatum",
      accessor: "access_date",
      Cell: ({ value }) => <DateCell date={value} />,
      Filter: SimpleDateFilter,
      filter: "betweenDates",
    },
    {
      Header: "Typ av transaktion",
      accessor: "category_display",
      Cell: ({ value }) => <ToolTipCell text={value} />,
      Filter: SimpleTextFilter,
      filter: "text",
    },
    {
      Header: "Dokument",
      accessor: "doc",
      Cell: ({ row }) =>
        row.original.doc?.get ? (
          <TextButton
            title="Ladda ner"
            iconType="download"
            iconPlacement="right"
            clicked={() => handleDownload(row.original.doc.get)}
          />
        ) : (
          "-"
        ),
    },
  ];

  if (isAdmin && (onDelete || onEdit)) {
    cols.push({
      Header: "Adminfunktioner",
      id: "_admin",
      Cell: ({ row }) => (
        <>
          {onEdit && (
            <PrimaryBtn
              secondary
              className="ml-1"
              onClick={() => onEdit(row.original)}
            >
              Redigera
            </PrimaryBtn>
          )}
          {onDelete && (
            <PrimaryBtn
              secondaryError
              className="ml-1"
              onClick={() => onDelete(row.original)}
            >
              Radera
            </PrimaryBtn>
          )}
        </>
      ),
    });
  }

  return cols;
};
