import moment from "moment";
import * as React from "react";
import { buildQueryString } from "../../../store/base";
import { useBuildings } from "../../../store/buildings";
import { useFilteredRealEstates } from "../../../store/realEstates";
import {
  useFilteredRoundingAreas,
  useRoundingArea,
} from "../../../store/roundingAreas";
import { useRoundingErrandSetting } from "../../../store/roundingErrandSettings";

import BasicTable from "../../Billecta/Table/BasicTable";
import StandardModal from "../../Modals/StandardModal";

import * as SharedStyles from "../../sharedStyles";
import { OverviewTitle } from "../OverviewInfo/styles";

export default ({ roundingErrand, isGov = false }) => {
  const [actualRoundingOpen, setActualRoundingOpen] = React.useState(false);
  const [currentArea, setCurrentArea] = React.useState(null);

  const settingId = roundingErrand?.setting?.id;
  const [setting, settingLoading] = useRoundingErrandSetting(settingId);

  const areaIds = setting?.areas.map((a) => a.id);
  const areasQuery = buildQueryString({ id__in: areaIds });
  const [areas, areasLoading] = useFilteredRoundingAreas(areasQuery);

  const realestateIds =
    areas
      ?.map((a) => a.realestates.map((r) => r.id))
      .flat()
      .filter((i) => i) || [];
  const realEstateQuery = buildQueryString({ id__in: realestateIds });
  const [realestates] = useFilteredRealEstates(realEstateQuery);

  const [buildings, buildingsLoading] = useBuildings();

  const data = React.useMemo(() => {
    return areas;
  }, [areas, realestates]);

  const handleRowClicked = (row) => {
    setCurrentArea(row.original);
    setActualRoundingOpen(true);
  };

  const renderActualAreaData = (area) => {
    return (
      <>
        <BasicTable
          data={area?.components || []}
          columns={componentColumns}
          onRowClicked={() => {}}
        />
      </>
    );
  };

  const componentColumns = React.useMemo(
    () => [
      {
        Header: "Komponent",
        accessor: "title",
        Cell: (props) => {
          return <div>{props?.value}</div>;
        },
      },
      {
        Header: "Byggnad",
        accessor: "building",
        Cell: (props) => {
          const buildingName = buildings?.find(
            (b) => b.id === props?.value?.id
          );
          return (
            <div>
              {buildingName?.str_representation
                ? buildingName.str_representation
                : "-"}
            </div>
          );
        },
      },
    ],
    [buildings]
  );

  //Columns for table
  const columns = React.useMemo(
    () => [
      {
        Header: "Område",
        accessor: "title",
        Cell: (props) => {
          return <div>{props?.value}</div>;
        },
      },
      {
        Header: "Antal komponenter",
        accessor: "_components",
        Cell: (props) => {
          const numberOfComponents = props.row.original.components.length;
          return <div>{numberOfComponents} st</div>;
        },
      },
      {
        Header: "Fastigheter",
        accessor: "realestates",
        Cell: ({ value }) => {
          const realestateIds = value.map((r) => r.id);
          const matches = realestates.filter((r) =>
            realestateIds.includes(r.id)
          );

          const names = matches.map((m) => m.str_representation);

          return matches?.length ? names.join(", ") : "-";
        },
      },
    ],
    [realestates]
  );

  return (
    <SharedStyles.InnerBox>
      <OverviewTitle small>
        Områden som ingår i {isGov ? "myndighetskravet" : "ronderingen"}
      </OverviewTitle>
      <BasicTable
        data={data || []}
        columns={columns}
        onRowClicked={(row) => handleRowClicked(row)}
        tableId="componentstable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
      <StandardModal
        isOpen={actualRoundingOpen}
        closeFunction={() => setActualRoundingOpen(false)}
        title="Komponenter i området"
        withActionBar
        actionBarCancelTitle="Stäng"
      >
        {renderActualAreaData(currentArea)}
      </StandardModal>
    </SharedStyles.InnerBox>
  );
};
