import * as React from "react";

// store, state
import { constants } from "../../../../../store/roundingErrands";
import {
  useFormInstanceField,
  buildQueryString,
} from "../../../../../store/base";
import { useFilteredRoundingAreas } from "../../../../../store/roundingAreas";

// style, design
import Faults from "./Faults";
import { StatusLabel } from "../../../../Lists/Base/CompleteList/styles";
import Table from "../../../../Billecta/Table/BasicTable";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";

export default ({ areaIds }) => {
  const storeName = constants.STORE_NAME;

  const topRef = React.useRef();

  const areasQuery = buildQueryString({ id__in: areaIds });
  const [areas, areasLoading] = useFilteredRoundingAreas(areasQuery);
  const [currentArea, setCurrentArea] = React.useState(null);

  const _visited = useFormInstanceField({ storeName, fieldKey: "_visited" });
  const faults = useFormInstanceField({ storeName, fieldKey: "faults" });

  const data = React.useMemo(() => {
    return areas?.reverse() || [];
  }, [areas]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Område",
        accessor: "title",
        Cell: (props) => {
          return <div>{props?.value}</div>;
        },
      },
      {
        Header: "Antal komponenter",
        accessor: "_comps",
        Cell: (props) => {
          const numberOfComps = props.row.original.components.length;
          return <div>{numberOfComps} st</div>;
        },
      },
      {
        Header: "Hanterad",
        accessor: "_visited",
        Cell: ({ row }) => {
          const { id } = row.original;
          const isVisited = _visited?.includes(id);
          return (
            <StatusLabel state={isVisited ? "Slutbetald" : 3}>
              {isVisited ? "Ja" : "Nej"}
            </StatusLabel>
          );
        },
      },
    ],
    [_visited]
  );

  const faultsData = React.useMemo(() => {
    return faults;
  }, [faults]);

  const faultsColumns = React.useMemo(
    () => [
      {
        Header: "Komponent",
        accessor: "rounding_component",
        Cell: (props) => {
          return <div>{props?.value?.str_representation}</div>;
        },
      },
      {
        Header: "Område",
        accessor: "_compid",
        Cell: (props) => {
          const compId = props.row.original?.rounding_component?.id;

          const area = areas?.filter((a) => {
            const areaCompIds = a.components?.map((c) => c.id);
            return areaCompIds?.includes(compId);
          });

          return <div>{area?.[0]?.str_representation || "-"}</div>;
        },
      },
      {
        Header: "Avvikelse",
        accessor: "title",
        Cell: (props) => {
          return <div>{props?.value}</div>;
        },
      },
      {
        Header: "Beskrivning",
        accessor: "description",
        Cell: (props) => {
          const shortnedText = props?.value?.substring(0, 12);
          if (shortnedText) {
            return <div>{shortnedText}...</div>;
          } else {
            return <div> - </div>;
          }
        },
      },
      {
        Header: "Byggnad",
        accessor: "building",
        Cell: (props) => {
          return <div>{props?.value?.str_representation || "-"}</div>;
        },
      },
      {
        Header: "Fastighet",
        accessor: "realestate",
        Cell: (props) => {
          return <div>{props?.value?.str_representation}</div>;
        },
      },
    ],
    [faults, areas]
  );

  const clickedRow = (row) => {
    setCurrentArea(row.original);
  };

  if (areasLoading) return <OverlaySpinner />;

  if (currentArea != null) {
    return <Faults currentArea={currentArea} doneFunction={setCurrentArea} />;
  }

  return (
    <>
      <OverviewTitleWrapper style={{ scrollMargin: 30 }} ref={topRef}>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Områden att rondera</OverviewTitle>
          <OverviewSubtitle>
            Tryck på en rad för att hantera det specifika området
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <Table
        data={data}
        columns={columns}
        onRowClicked={clickedRow}
        hideSearch
        tableId="roundingerrandexecutiontable"
        withPersistantSortBy
        withPersistantGlobalFilter
      />
      <br />
      {faultsData?.length > 0 && (
        <>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Alla registrerade avvikelser</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <Table
            columns={faultsColumns}
            data={faultsData}
            hideSearch
            tableId="allfaultstable"
            withPersistantSortBy
            withPersistantGlobalFilter
          />
        </>
      )}
    </>
  );
};
