import * as React from "react";
import { useHistory, useParams } from "react-router";
import {
  BooleanLabel,
  DateCell,
  LinkedObject,
  ToolTipCell,
} from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { buildQueryString, useAllPermissionCheck } from "../../../store/base";

import BrfPaymentGroupQuotaTable from "../../../components/Tables/BrfPaymentGroupQuota/FullTable";

import { usePaymentGroup } from "../../../store/paymentGroup";
import { createUrl as brfQuotaCreateUrl } from "../../../store/brfPaymentGroupQuota";

import { TextButton } from "../../../components/Forms/Base/Buttons";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import PaymentGroupSubtable from "../../../components/Tables/PaymentGroup/SubTable";

import { detailUrl as productDetailUrl } from "../../../store/invoicingProducts";
import { toMoneyString } from "../../../components/utils/stringUtils";

export default function PaymentGroupDetail() {
  const { paymentGroupId } = useParams();
  const { push } = useHistory();

  const [paymentGroup, paymentGroupLoading] = usePaymentGroup(paymentGroupId);

  const canAddQuota = useAllPermissionCheck([
    "add_can_brf",
    "add_can_baseobject",
  ]);

  const getInfoObj = () => {
    return {
      Inställningar: [
        {
          title: "Är aktiv",
          value: (
            <BooleanLabel
              value={paymentGroup.is_active}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "Är huvudavgift",
          value: (
            <BooleanLabel
              value={paymentGroup.is_main_fee}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "Årlig avgift",
          value: (
            <ToolTipCell
              text={toMoneyString(paymentGroup.yearly_fee, true, 2)}
            />
          ),
        },
        {
          title: "Total avgift att betala",
          value: (
            <ToolTipCell
              text={toMoneyString(paymentGroup.total_fee_to_pay, true, 2)}
            />
          ),
        },
        {
          title: "Avbetalt den",
          value: <DateCell date={paymentGroup.payoff_date} />,
        },
        {
          title: "Automatiskt ränte-pålägg",
          value: (
            <BooleanLabel
              value={paymentGroup.auto_add_loan_interest_to_fee}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "För alla lägenheter",
          value: (
            <BooleanLabel
              value={paymentGroup.for_all_brf_premises}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "Startdatum",
          value: <DateCell date={paymentGroup.start_date} />,
        },
        {
          title: "Avsluta debitering för klar-betalade",
          value: (
            <BooleanLabel
              value={paymentGroup.close_for_fully_payed_premis}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "Debitering tillför till räntebetalningar",
          value: (
            <BooleanLabel
              value={paymentGroup.fee_contributes_to_interest_fees}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "Alla avgifter är kapitaltillskottsgrundande",
          value: (
            <BooleanLabel
              value={paymentGroup.all_payments_loan_capital_contribution}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "Distribuera betalning jämnt",
          value: (
            <BooleanLabel
              value={paymentGroup.distribute_fee_on_loan_proportionally}
              offLabel={"Nej"}
              onLabel={"Ja"}
            />
          ),
        },
        {
          title: "Produkt",
          value: (
            <LinkedObject
              obj={paymentGroup.product}
              urlMethod={productDetailUrl}
            />
          ),
        },
      ],
    };
  };

  return (
    <>
      <DetailInnerWrapper>
        <DetailPageBoxFlexWrapper>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "49%",
              marginRight: "24px",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Detaljer</OverviewTitle>
              </OverviewTitleWrapper>

              {paymentGroup && <DetailInfo infoObj={getInfoObj()} />}
            </DetailPageBox>
          </div>

          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "49%",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Andelar</OverviewTitle>
                {canAddQuota && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextButton
                      title="Lägg till"
                      iconType="add"
                      iconPlacement="right"
                      clicked={() => push(brfQuotaCreateUrl(paymentGroupId))}
                    />
                  </div>
                )}
              </OverviewTitleWrapper>

              <BrfPaymentGroupQuotaTable
                persistantQueryString={{
                  payment_group: paymentGroupId,
                }}
                isBare
                ignoreLocalStorage
              />
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>

        <DetailPageBoxFlexWrapper>
          <div
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxWidth: "100%",
            }}
          >
            <DetailPageBox>
              <OverviewTitleWrapper>
                <OverviewTitle>Förhandsgranskning</OverviewTitle>
              </OverviewTitleWrapper>
              {paymentGroup && (
                <PaymentGroupSubtable paymentGroup={paymentGroup} />
              )}
            </DetailPageBox>
          </div>
        </DetailPageBoxFlexWrapper>
      </DetailInnerWrapper>
    </>
  );
}
