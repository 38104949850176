import * as React from "react";

import LocalTableSelectField from "../Forms/Base/Fields/LocalTableSelectField";

import userListDefs from "../Tables/Users/listDefs";
import {
  constants as UserConstants,
  useUserPagination,
} from "../../store/users";

import Modal from "../Forms/Base/Modals/Modal";

import { buildQueryString } from "../../store/base";
import { useDispatch } from "react-redux";

import { updateMiscForReport } from "../../store/newExcelReports";
import { addToast, TOAST_TYPES } from "../../store/toasts";
import { cloneDeep } from "lodash";
import UsersTable from "../Tables/Users/FullTable";

export const useShare = ({
  setIsSharing,
  currentSharingReport,
  setCurrentSharingReport,
  setIsLoading,
  shouldDispatchUpdate = true,
  setSharingState,
  onShareSettingsUpdate,
}) => {
  const dispatch = useDispatch();
  const [shareState, setShareState] = React.useState([]);
  const [initialShareState, setInitialShareState] = React.useState([]);

  React.useEffect(() => {
    if (!currentSharingReport) return;

    setShareState(currentSharingReport.shared_with);
    setInitialShareState(currentSharingReport.shared_with);
  }, [currentSharingReport]);

  const onShareSuccess = (res, postData) => {
    setIsLoading(false);
    resetStates();
    dispatch(
      addToast({
        type: TOAST_TYPES.SUCCESS,
        title: `Rapporten har delats med ${shareState.length} användare`,
      })
    );
    onShareSettingsUpdate && onShareSettingsUpdate(res.data);
  };

  const onShareError = () => {
    setIsLoading(false);
    resetStates();
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Kunde inte dela rapporten",
        description: "Ladda om sidan och försök igen",
      })
    );
  };

  const resetStates = () => {
    setShareState([]);
    setInitialShareState([]);
    setCurrentSharingReport(undefined);
  };

  const shareClose = () => {
    resetStates();
    setIsSharing(false);
  };

  const shareDone = () => {
    const shareSorted = shareState.sort((a, b) => a.id - b.id);
    const initialSorted = initialShareState.sort((a, b) => a.id - b.id);

    setIsSharing(false);

    let same = true;

    if (shareSorted.length !== initialSorted.length) same = false;

    if (same) {
      for (let i = 0; i < shareSorted.length; i++) {
        if (shareSorted[i].id !== initialSorted[i].id) {
          same = false;
          break;
        }
      }
    }

    if (!same) {
      if (!shouldDispatchUpdate) {
        setSharingState(cloneDeep(shareState));
        resetStates();
        return;
      }

      //update data
      setIsLoading(true);

      dispatch(
        updateMiscForReport({
          reportId: currentSharingReport.id,
          postData: {
            shared_with: shareState,
          },
          onSuccess: onShareSuccess,
          onError: onShareError,
        })
      );

      return;
    }

    resetStates();
  };

  const updateShareState = (val) => {
    setShareState(val);
  };

  const ShareElement = React.useMemo(() => {
    return (
      <Modal
        title={"Dela rapport"}
        closeFunction={() => shareClose()}
        onAccept={() => shareDone()}
      >
        <LocalTableSelectField
          value={shareState}
          TableComponent={UsersTable}
          onChange={(val) => updateShareState(val)}
          placeholder={"Välj användaren att dela med..."}
          isMany
          persistantQueryString={{
            user_type__in: [0, 1],
          }}
          modalClassName="!mt-0"
        />
      </Modal>
    );
  });

  return { ShareElement };
};
