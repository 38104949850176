export default {
  SET_INSTANCE: "SET_INSTANCE_MASS_CONFIG_ERRANDS",
  SET_EXCLUSION_RULES: "SET_EXCLUSION_RULES_MASS_CONFIG_ERRANDS",
  SET_DATA: "SET_DATA_MASS_CONFIG_ERRANDS",
  PATH: "/errand-settings/mass-config/setup",
  PREVIEW_PATH: "/errand-settings/mass-config/preview",
  SUMMARY_PATH: "/errand-settings/mass-config/summary",
  CONFIG_PATH: "/errand-settings/mass-config/room-component-config",
  APARTMENT_CREATE_URL: "/errands/components/bulk/apartment/",
  INDP_CREATE_URL: "/errands/components/bulk/industrialpremises/",
  COMMON_AREA_CREATE_URL: "/errands/components/bulk/commonarea/",
  BRF_CREATE_URL: "/errands/components/bulk/brfpremis/",
  PARKING_CREATE_URL: "/errands/components/bulk/parkingspot/",

  STORE_NAME: "massConfigPremisesComponents",
};
