import React from "react";
import { useDispatch } from "react-redux";

import {
  useAllPermissionCheck,
} from "../../../../store/base";
import {
  constants,
  destroyPatchForm,
  destroyPostForm,
  create,
  update,
  clearAllValues,
} from "../../../../store/insightsPage";
import { useInsightsPageForm } from "../../../../store/insightsPage/hooks/form";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../Details/OverviewInfo/styles";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";

import insightsPageDefaults from "../../../Insights/defaults";
import { useHistory } from "react-router-dom";
import { DoubleFieldWrapper } from "../../Base/Chapters/styles";
import { isEqual, cloneDeep, set } from "lodash";

import { TextButton } from "../../Base/Buttons";
import Modal from "../../Base/Modals/Modal";
import LocalTextInputField from "../../Base/Fields/LocalTextInputField";
import { addToast, TOAST_TYPES } from "src/store/toasts";
import { removeObject } from "src/store/base/store/actions";
import { PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

const inputsReducer = (state, action) => {
  if (action?.type === "all") {
    return action.value;
  }
  if (action?.key.includes(".")) {
    let newState = cloneDeep(state);
    set(newState, action?.key, action?.value);
    return newState;
  }
  return {
    ...state,
    [action.key]: action.value,
  };
};

const INSIGHTS_PAGE_DEFAULTS = [
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "dashboard",
    description:
      "Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer. Standardsida visar en väldigt cool dashboard med fina grafer",
    label: "Dashboard",
    imageUrl:
      "https://atlas-sol-public-storage.s3.eu-north-1.amazonaws.com/insights_images/dashboard_beta.png",
    width: 6,
    height: 20,
  },
  {
    id: "__create_empty_page",
    description: "Skapa en cool tom sida",
    label: "Tom sida",
  },
];

export default function InsightsPageForm({
  method,
  insightsPage,
  open,
  onCheckout,
  handleDeleteModal,
  widgetPlacements,
}) {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);

  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false);

  const formLoaded = Boolean(useInsightsPageForm(method, insightsPage?.id));

  // error states
  const [widthError, setWidthError] = React.useState(null);
  const [heightError, setHeightError] = React.useState(null);
  const [titleError, setTitleError] = React.useState(null);
  const [templateError, setTemplateError] = React.useState(null);

  const hasDeletePermission = useAllPermissionCheck([
    "allow_insights",
    "delete_can_insights",
  ]);

  const [inputsState, inputsStateDispatch] = React.useReducer(inputsReducer, {
    title: "",
    width: 6,
    height: 20,
    query_parameters: {},
  });

  const hasChanged = React.useMemo(() => {
    return !isEqual(insightsPage, inputsState);
  }, [inputsState]);

  const queryParameters = React.useMemo(() => {
    return inputsState?.query_parameters;
  }, [inputsState?.query_parameters]);

  const title = React.useMemo(() => {
    return inputsState?.title;
  }, [inputsState]);

  React.useEffect(() => {
    if (!insightsPage) return;
    if (method !== "PATCH") return;
    inputsStateDispatch({
      type: "all",
      value: insightsPage,
    });
  }, [insightsPage]);

  const checkout = (success, pageId) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }
    onCheckout();
  };

  const onSuccess = (_, returnedObject) => {
    setLoading(false);
    checkout(true);
    if (method === "POST") {
      dispatch(clearAllValues());
    } else if (
      inputsState?.width !== insightsPage?.width ||
      inputsState?.height !== insightsPage?.height
    ) {
      dispatch(
        removeObject({
          objectId: returnedObject.id,
          constants,
        })
      );
    }
    push(`/insights/page/${returnedObject.id}`);
  };

  const preProcessInputs = (_data) => {
    const data = cloneDeep(_data);
    if (data.width === "") delete data.width;
    if (data.height === "") delete data.height;

    return data;
  };
  const onFailure = (method) => {
    setLoading(false);
    if (method === "POST") {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Skapandet misslyckades",
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Uppdateringen misslyckades",
        })
      );
    }
  };

  const customValidation = () => {
    let fail = false;
    if (inputsState?.width || inputsState?.height) {
      let widestCol = 0;
      let highestRow = 0;
      widgetPlacements?.map((elem) => {
        widestCol = Math.max(widestCol, elem.grid_column_end - 1);
        highestRow = Math.max(highestRow, elem.grid_row_end - 1);
      });
      if (inputsState?.width && inputsState.width < widestCol) {
        setWidthError("En widgetplacering blockerar detta");
        fail = true;
      }
      if (inputsState?.height && inputsState.height < highestRow) {
        setHeightError("En widgetplacering blockerar detta");
        fail = true;
      }
      
    }

    if (inputsState.title.length === 0) {
      fail = true;
      setTitleError("Ange en titel");
    }
     if (method === "POST" && !selectedTemplate) {
      fail = true
      setTemplateError("Välj en mall")
     }

    return fail;
  };

  const clearAllErrors = () => {
    setHeightError(null)
    setWidthError(null)
    setTemplateError(null)
    setTitleError(null)
  }

  const onSubmit = async () => {
    clearAllErrors()
    const fail = customValidation();
    if (fail) return onFailure(method);

    setLoading(true);

    if (method === "POST" && selectedTemplate?.id !== "__create_empty_page") {
      const returnedObject = await insightsPageDefaults(
        selectedTemplate,
        title,
        queryParameters
      );

      onSuccess(null, returnedObject);
      setLoading(false);

      return;
    }

    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          forceData: inputsState,
          preProcess: preProcessInputs,
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id: insightsPage?.id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
          forceData: inputsState,
          preProcess: preProcessInputs,
        })
      );
    }
  };

  if (!formLoaded) {
    return <OverlaySpinner />;
  }

  const safeParseInt = (int) => {
    if (int === "") return int;
    return isNaN(parseInt(int)) ? 0 : parseInt(int);
  };

  return (
    <Modal
      isOpen={open}
      closeFunction={() => checkout(false)}
      onAccept={onSubmit}
      title={
        method === "PATCH" ? "Uppdatera Insightssida" : "Skapa Insightssida"
      }
      canAccept={hasChanged}
    >
      {loading && open && <OverlaySpinner />}
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle small>
            {method === "PATCH" ? "Uppdatera" : "Skapa"} insightssida
          </OverviewTitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <LocalTextInputField
          value={inputsState.title}
          onChange={(value) =>
            inputsStateDispatch({
              key: "title",
              value,
            })
          }
          title="Titel"
          error={titleError && titleError}
        />
      </div>

      { method === "POST" && (
      <>
      <div className="flex flex-col !mb-[-1rem]">
        <p className="text-lg font-bold">Mallar</p>
        {templateError && (
          <p className="text-red-600 text-sm">{templateError}</p>
        )}
      </div>
      <div className="flex flex-wrap w-full">
        {INSIGHTS_PAGE_DEFAULTS.map((def) => {
          return (
            <div
              className={classNames(
                "w-96 cursor-pointer border border-gray-100 shadow rounded p-2 mt-3 ml-3",
                selectedTemplate?.id === def.id && "bg-blue-100"
              )}
              onClick={() => setSelectedTemplate(def)}
            >
              <p className="text-sm font-medium mb-2">{def.label}</p>
              {def.id === "__create_empty_page" ? (
                <div
                  className={classNames(
                    "h-[130px] w-[250px] text-gray-200 m-auto",
                    selectedTemplate?.id === def.id && "!text-gray-400"
                  )}
                >
                  <PlusIcon width={250} height={130} />
                </div>
              ) : (
                <div
                  style={{
                    height: "130px",
                    width: "250px",
                    backgroundImage: `url(${def.imageUrl})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                  }}
                ></div>
              )}
              <p className="text-xs mt-2">{def.description}</p>
            </div>
          );
        })}
      </div>
      </>

      )}
    
      {(method === "PATCH" || selectedTemplate?.id === "__create_empty_page") && (
        <>
          <TextButton
            title={
              showAdvancedSettings
                ? "Dölj avancerade inställningar"
                : "Visa avancerade inställningar"
            }
            iconPlacement="right"
            clicked={
              showAdvancedSettings
                ? () => setShowAdvancedSettings(false)
                : () => setShowAdvancedSettings(true)
            }
          />
          {showAdvancedSettings && (
            <DoubleFieldWrapper justifyContent={"space-between"} width={"65"}>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <LocalTextInputField
                  isNumber
                  step={"1"}
                  value={inputsState.width}
                  onChange={(value) =>
                    inputsStateDispatch({
                      key: "width",
                      value: safeParseInt(value),
                    })
                  }
                  title="Bredd (rutnät)"
                  error={widthError}
                />
                <LocalTextInputField
                  isNumber
                  step={"1"}
                  value={inputsState.height}
                  onChange={(value) =>
                    inputsStateDispatch({
                      key: "height",
                      value: safeParseInt(value),
                    })
                  }
                  title="Höjd (rutnät)"
                  error={heightError}
                />
              </div>
            </DoubleFieldWrapper>
          )}
        </>
      )}

      {method === "PATCH" && (
        <>
          {hasDeletePermission && (
            <OverviewSubtitle>
              <TextButton
                title="Radera"
                red={true}
                iconType="delete"
                iconPlacement="right"
                clicked={() => handleDeleteModal(insightsPage)}
              />
            </OverviewSubtitle>
          )}
        </>
      )}
    </Modal>
  );
}
