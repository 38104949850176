import * as React from "react";
import { constants } from "../../../../../store/roundingErrands";
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";
import { TextInput, TimePicker } from "../../../Base/Fields";
import ReportedBySelection from "../../../ErrandBase/ReportedBySelection";

export default ({ method, apartmentId, indpId }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <FormAreaTitle>Detaljer kring myndighetskrav</FormAreaTitle>

      <FormAreaDescription>
        Fyll i titel, datum då den manuella begäran gjordes samt vem som
        initierat myndighetskravet.
      </FormAreaDescription>

      <TextInput
        storeName={storeName}
        fieldKey={"title"}
        method={method}
        title="Titel"
      />

      <TimePicker
        storeName={storeName}
        method={method}
        fieldKey={"created_at"}
        defaultNow={true}
        title="Initierat den"
      />

      <ReportedBySelection
        method={method}
        storeName={storeName}
        apartmentId={apartmentId}
        indpId={indpId}
        hideTenantOption
        title="Initierat av"
      />
    </>
  );
};
