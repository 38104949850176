const defs = [
  {
    title: "Dokument",
    key: "DOCUMENT",
    visited: true,
    hasError: false,
    fieldKeys: ["completion_files", "title"],
  },
  {
    title: "Utfall",
    key: "FAULTS",
    visited: false,
    hasError: false,
    fieldKeys: ["faults"],
  },
  {
    title: "Granska och slutför",
    key: "CONFIRM",
    visited: false,
    hasError: false,
    fieldKeys: ["status"],
  },
];

export default () => {
  return defs;
};
