import * as React from "react";

// style, design
import {
  FormAreaDescription,
  FormAreaTitle,
} from "../../../Base/Chapters/styles";

import { constants } from "../../../../../store/roundingErrandSettings";

import { Select, TextInput } from "../../../Base/Fields";
import { NonNestedSelect } from "../../../Base/Fields";
import { useFilteredUsers } from "../../../../../store/users";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import DelegationOrder from "../../../RoundingErrandSetting/DelegationOrder/DelegationOrder";
import { useDispatch } from "react-redux";

const TYPE_CHOICES = [
  {
    title: "OVK",
    id: 0,
  },
  {
    title: "SBA",
    id: 1,
  },
  {
    title: "Lekplats",
    id: 2,
  },
  {
    title: "Elektricitet",
    id: 3,
  },
  {
    title: "Hiss",
    id: 4,
  },
  {
    title: "Tryckkärl",
    id: 5,
  },
  {
    title: "AC",
    id: 6,
  },
  {
    title: "Skyddsrum",
    id: 7,
  },
  {
    title: "Underhåll",
    id: 8,
  },
  {
    title: "Markarbete",
    id: 9,
  },
  {
    title: "Egen SBA",
    id: 10,
  },
];

export default ({ method, id }) => {
  const storeName = constants.STORE_NAME;
  const dispatch = useDispatch();
  const trusteeKey = "responsible_trustee";

  const filteredUsersQuery = buildQueryString({
    user_type__in: [0, 1, 2],
  });

  const title = useFormInstanceField({ storeName, fieldKey: "title" });

  const kind = useFormInstanceField({ storeName, fieldKey: "kind" });

  const [initialKind] = React.useState(kind);

  React.useEffect(() => {
    if (((kind === 0 || kind) && !title) || initialKind !== kind) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            title: TYPE_CHOICES?.[kind]?.title,
          },
        })
      );
    }
  }, [kind]);

  return (
    <>
      <FormAreaTitle>Generell information kring myndighetskrav</FormAreaTitle>
      <FormAreaDescription>
        Ange ett namn för myndighetskravet och ange vem som är ansvarig att
        administrera myndigshetsprotokollet.
      </FormAreaDescription>

      <Select
        storeName={storeName}
        fieldKey="kind"
        method={method}
        title="Välj typ"
      />

      <TextInput
        title="Titel"
        method={method}
        storeName={storeName}
        fieldKey={"title"}
        alwaysShowDescription
      />

      <NonNestedSelect
        storeName={storeName}
        method={method}
        fieldKey={trusteeKey}
        fetchMethod={useFilteredUsers}
        fetchQueryString={filteredUsersQuery}
        title="Ansvarig"
      />

      <DelegationOrder
        storeName={storeName}
        method={method}
        goToRoleButton
        isGov
        modalInModal
      />
    </>
  );
};
