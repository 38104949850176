import * as React from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";

//store, state
import {
  useRoundingErrand,
  constants,
  acceptRoundingErrand,
  declineRoundingErrand,
} from "../../../store/roundingErrands";
import { detailUrl as roundingSettingDetailUrl } from "../../../store/roundingErrandSettings";
import {
  useFilteredRoundingErrandFaults,
  constants as faultConstants,
  update as updateFault,
} from "../../../store/roundingErrandFaults";
import { useFilteredRoundingComponents } from "../../../store/roundingComponents";
import {
  create as createReportErrand,
  detailUrl as reportErrandDetailUrl,
  useFilteredReportErrands,
} from "../../../store/reportErrands";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { useFilteredBuildings } from "../../../store/buildings";
import { useFilteredNotes } from "../../../store/notes";
import { useUser, detailUrl as userDetailUrl } from "../../../store/users";
import { detailUrl as tenantDetailUrl } from "../../../store/tenants";
import {
  buildQueryString,
  setActiveFormInstance,
  updateActiveFormInstance,
} from "../../../store/base";

//components & styles
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";
import { DateCell, LinkedObject, TimeCell } from "../../../components/Displays";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import {
  ErrandStatusLabel,
  StatusLabel,
} from "../../../components/Lists/Base/CompleteList/styles";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  getChosenPerformer,
  getErrandStatus,
} from "../../../components/utils/errandUtils";
import Components from "../../../components/Details/RoundingErrand/Components";
import BasicTable from "../../../components/Billecta/Table/BasicTable";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/Forms/Base/Buttons";
import StandardModal from "../../../components/Modals/StandardModal";
import FaultReportErrandForm from "../../../components/Forms/GovErrand/FaultReportErrand/FaultReportErrandForm";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";

function GovErrandOverview() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { push } = useHistory();
  const { govErrandId } = useParams();
  const currentUser = useSelector((state) => state.app.user);

  const [govErrand, govErrandLoading] = useRoundingErrand(govErrandId);
  const [reportErrands] = useFilteredReportErrands();
  const faultIds = govErrand?.faults?.map((f) => f.id) || [];
  const faultsQuery = buildQueryString({ id__in: faultIds });
  const [faults] = useFilteredRoundingErrandFaults(faultsQuery);
  const componentIds = faults?.map((f) => f.rounding_component.id);
  const componentQuery = buildQueryString({ id__in: componentIds });
  const [components] = useFilteredRoundingComponents(componentQuery);
  const [realEstates] = useFilteredRealEstates();
  const [buildings] = useFilteredBuildings();

  const [reportedBy] = useUser(govErrand?.reported_by?.id);

  const [loading, setLoading] = React.useState(false);
  const [faultOpen, setFaultOpen] = React.useState(false);
  const [createFaultReportOpen, setCreateFaultReportOpen] =
    React.useState(false);

  const notesQ = buildQueryString({
    id__in: govErrand?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const data = React.useMemo(() => {
    return faults;
  }, [faults, components]);

  const getRealestateName = (id) => {
    if (!id) return "-";
    const match = realEstates?.find((r) => r.id === id);
    return match?.str_representation;
  };

  const getBuildingName = (id) => {
    if (!id) return "-";
    const match = buildings?.find((r) => r.id === id);
    return match?.str_representation;
  };

  const getComponentName = (id) => {
    if (!id) return "-";
    const match = components.find((c) => c.id === id);
    return match?.title;
  };

  const handleAcceptErrand = () => {
    dispatch(acceptRoundingErrand({ id: govErrand?.id }));
  };

  const onDeclineSuccess = () => {
    push("/gov-errands");
  };

  const handleDeclineErrand = () => {
    dispatch(
      declineRoundingErrand({
        id: govErrand?.id,
        successCallback: onDeclineSuccess,
      })
    );
  };

  const handleCloseFault = (forceClose = false) => {
    if (forceClose) {
      setFaultOpen(false);
      setCreateFaultReportOpen(false);
    }
    if (createFaultReportOpen) {
      setCreateFaultReportOpen(false);
    } else {
      setFaultOpen(false);
    }
  };

  const handleConfirmFault = () => {
    if (createFaultReportOpen) {
      handleCreateReportErrand();
    } else {
      setCreateFaultReportOpen(true);
    }
  };

  const onSuccess = (data, returnedData) => {
    const faultMatch = faults.find((f) => f.id === faultOpen?.id);
    const faultClone = cloneDeep(faultMatch);

    faultClone.fault_report = { id: returnedData?.id };
    dispatch(
      setActiveFormInstance({
        storeName: faultConstants.STORE_NAME,
        data: faultClone,
      })
    );
    dispatch(
      updateFault({
        id: faultClone.id,
        successCallback: () => {
          handleCloseFault(true);
          setLoading(false);
        },
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onError = () => {
    setLoading(false);
  };

  const handleCreateReportErrand = () => {
    setLoading(true);
    dispatch(
      createReportErrand({ successCallback: onSuccess, errorCallback: onError })
    );
  };

  const faultColumnsWithoutReportErrand = React.useMemo(
    () => [
      {
        Header: "Komponent",
        accessor: "rounding_component",
        Cell: ({ value }) => {
          const compId = value?.id;
          return <div>{getComponentName(compId)}</div>;
        },
      },
      {
        Header: "Avvikelse",
        accessor: "title",
        Cell: (props) => {
          return <div>{props?.value}</div>;
        },
      },
      {
        Header: "Beskrivning",
        accessor: "description",
        Cell: (props) => {
          const shortnedText = props?.value?.substring(0, 12);
          if (shortnedText) {
            return <div>{shortnedText}...</div>;
          } else {
            return <div> - </div>;
          }
        },
      },
      {
        Header: "Felanmäld",
        accessor: "fault_report",
        Cell: (props) => {
          if (props?.value) {
            return <StatusLabel state={0}>Ja</StatusLabel>;
          } else {
            return <StatusLabel state={3}>Nej</StatusLabel>;
          }
        },
      },
      {
        Header: "Byggnad",
        accessor: "building",
        Cell: (props) => {
          return <div>{getBuildingName(props?.value?.id)}</div>;
        },
      },
      {
        Header: "Fastighet",
        accessor: "realestate",
        Cell: (props) => {
          return <div>{getRealestateName(props?.value?.id)}</div>;
        },
      },
    ],
    [faults, components]
  );

  const { status, display } = getErrandStatus({
    status: govErrand?.status,
    plannedStart: govErrand?.planned_start,
    plannedEnd: govErrand?.planned_end,
    actualStart: govErrand?.execute_start,
    actualEnd: govErrand?.execute_end,
  });

  const infoObjProps = {
    faults,
    components,
    status,
    display,
    reportedBy,
  };

  const faultObjProps = {
    getRealestateName,
    getBuildingName,
    getComponentName,
    reportErrands,
  };

  React.useEffect(() => {
    dispatch(updateActiveFormInstance({ storeName, data: govErrand }));
  }, [govErrand]);

  return (
    <>
      <DetailInnerWrapper>
        {(govErrand?.performer?.user?.id === currentUser?.id ||
          govErrand?.requested_performer?.user?.id === currentUser?.id) && (
          <DetailPageBox
            style={{
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
            error
          >
            {govErrand?.performer?.user?.id === currentUser?.id && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ maxWidth: "70%" }}>
                  Du har accepterat ärendet, klicka på "Neka" för att avsäga dig
                  ärendet.
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PrimaryButton
                    title="Neka"
                    clicked={() => handleDeclineErrand()}
                  />
                </div>
              </div>
            )}
            {govErrand?.requested_performer?.user?.id === currentUser?.id &&
              !govErrand?.performer && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ maxWidth: "70%" }}>
                    Du har blivit tillfrågad att utföra ärendet, klicka på
                    "Acceptera" för att anta dig ärendet eller "Neka" för att
                    avböja begäran.{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PrimaryButton
                      title="Acceptera"
                      clicked={() => handleAcceptErrand()}
                      extraStyle={{ marginRight: 12 }}
                    />
                    <SecondaryButton
                      title="Neka"
                      clicked={() => handleDeclineErrand()}
                    />
                  </div>
                </div>
              )}
          </DetailPageBox>
        )}
        <StandardModal
          isOpen={faultOpen}
          closeFunction={handleCloseFault}
          forceCloseFunction={() => handleCloseFault(true)}
          saveFunction={handleConfirmFault}
          withActionBar
          actionBarAcceptTitle={
            createFaultReportOpen
              ? "Registrera felanmälan"
              : "Upprätta felanmälan"
          }
          actionBarCancelTitle={
            createFaultReportOpen ? "Avbryt felanmälan" : "Stäng"
          }
          title={`Avvikelse ${faultOpen.title}`}
          canAccept={!faultOpen?.fault_report}
        >
          {loading && <OverlaySpinner />}
          {createFaultReportOpen ? (
            <FaultReportErrandForm
              fault={faultOpen}
              realestates={realEstates}
              buildings={buildings}
            />
          ) : (
            <DetailInfo infoObj={getFaultInfoObj(faultOpen, faultObjProps)} />
          )}
        </StandardModal>
        <DetailPageBox style={{ flex: 1 }}>
          <OverviewTitleWrapper>
            <OverviewTitle>Ärende</OverviewTitle>
          </OverviewTitleWrapper>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1, maxWidth: "50%", marginRight: 24 }}>
              <DetailInfo
                infoObj={getInfoObj(govErrand, infoObjProps)}
                extraChapterStyles={{ marginRight: 0 }}
              />
              <InnerBox>
                <Notes
                  notes={notes}
                  contentType={"errands.roundingerrand"}
                  objectId={govErrand?.id}
                  title="Anteckningar"
                />
              </InnerBox>
            </div>
            <div style={{ flex: 1, maxWidth: "50%", flexDirection: "column" }}>
              <Components roundingErrand={govErrand} isGov />
              {faults?.length > 0 && (
                <InnerBox style={{ marginTop: 36 }}>
                  <OverviewTitle small>Avvikelser</OverviewTitle>
                  <BasicTable
                    data={data || []}
                    columns={faultColumnsWithoutReportErrand}
                    onRowClicked={(row) => setFaultOpen(row.original)}
                    tableId="infotable"
                    withPersistantSortBy
                    withPersistantGlobalFilter
                  />
                </InnerBox>
              )}
            </div>
          </div>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const getTime = (timeString) => {
  return timeString ? moment(timeString).format("YYYY-MM-DD HH:mm:ss") : "-";
};

const isDelayed = (timeString, forText) => {
  const planned = moment(timeString);
  const today = moment();
  const delayed = planned.isBefore(today);
  if (delayed) {
    if (forText) {
      return true;
    }
    return 3;
  } else {
    if (forText) {
      return false;
    }
    return 1;
  }
};

function getInfoObj(govErrand, infoObjProps) {
  const getNumberOfFaults = () => {
    if (govErrand?.status !== 3 && govErrand?.status !== 4) {
      return "Inte avklarat";
    }
    return (
      <StatusLabel state={govErrand.faults.length > 0 ? 7 : 0}>
        {`${govErrand.faults.length ? `${govErrand.faults.length} st` : "Nej"}`}
      </StatusLabel>
    );
  };

  const getReportedBy = () => {
    const user = infoObjProps?.reportedBy;

    if (user?.tenant) {
      const obj = {
        id: user.tenant?.id,
        str_representation: user?.str_representation,
      };
      return <LinkedObject obj={obj} urlMethod={tenantDetailUrl} />;
    }
    return <LinkedObject obj={user} urlMethod={userDetailUrl} />;
  };

  const getPerformer = (errand) => {
    const foundPerformer = getChosenPerformer(errand);
    if (foundPerformer) {
      return <LinkedObject obj={foundPerformer} urlMethod={userDetailUrl} />;
    }
    return <div> Ingen utförare delegerad </div>;
  };

  let data = [
    {
      title: "Status",
      value: (
        <ErrandStatusLabel state={infoObjProps?.status}>
          {infoObjProps?.display}
        </ErrandStatusLabel>
      ),
    },
    {
      title: "Genererad av",
      value: (
        <LinkedObject
          obj={govErrand?.setting}
          urlMethod={roundingSettingDetailUrl}
        />
      ),
    },
    {
      title: "Antal fel",
      value: getNumberOfFaults(),
    },
    {
      title: "Skapat",
      value: <DateCell date={govErrand?.created_at} />,
    },
    {
      title: "Rapporterat av",
      value: getReportedBy(),
    },
    {
      title: "Utförare",
      value: getPerformer(govErrand),
    },
    {
      title: `${
        isDelayed(govErrand?.planned_start, true)
          ? "Planerad start(Försenad)"
          : "Planerad start"
      }`,
      value: (
        <StatusLabel
          state={
            govErrand?.planned_start ? isDelayed(govErrand?.planned_start) : 10
          }
        >
          {getTime(govErrand?.planned_start)}
        </StatusLabel>
      ),
    },
    {
      title: `${
        isDelayed(govErrand?.planned_end, true)
          ? "Planerat slut(Försenad)"
          : "Planerat slut"
      }`,
      value: (
        <StatusLabel
          state={
            govErrand?.planned_end ? isDelayed(govErrand?.planned_end) : 10
          }
        >
          {getTime(govErrand?.planned_end)}
        </StatusLabel>
      ),
    },
  ];

  const infoObj = {
    Ärende: data,
  };

  return infoObj;
}

const getFaultInfoObj = (fault, faultObjProps) => {
  const displayReportErrand = () => {
    if (fault?.fault_report) {
      const reportErrand = faultObjProps?.reportErrands?.find(
        (errand) => errand.id === fault?.fault_report?.id
      );
      return (
        <LinkedObject obj={reportErrand} urlMethod={reportErrandDetailUrl} />
      );
    } else {
      return <div>Ingen felanmälan upprättad</div>;
    }
  };

  let data = [
    {
      title: "Beskrivning",
      value: fault?.description || "-",
    },
    {
      title: "Komponent",
      value:
        faultObjProps.getComponentName(fault?.rounding_component?.id) || "-",
    },
    {
      title: "Byggnad",
      value: faultObjProps.getBuildingName(fault?.building?.id) || "-",
    },
    {
      title: "Fastighet",
      value: faultObjProps.getRealestateName(fault?.realestate?.id) || "-",
    },
    {
      title: "Avvikelse registrerad",
      value: <TimeCell date={fault?.created_at} /> || "-",
    },
    {
      title: "Felanmälan",
      value: displayReportErrand(),
    },
  ];

  const faultObj = {
    [`${fault?.title}`]: data,
  };
  return faultObj;
};

export default GovErrandOverview;
