export default () => {
  let chapters = [
    {
      title: "Ronderingstyp",
      key: "TYPE",
      visited: true,
      hasError: false,
      fieldKeys: ["setting"],
    },
    {
      title: "Detaljer",
      key: "DESCRIPTION",
      visited: false,
      hasError: false,
      fieldKeys: ["title", "created_at", "reported_by", "priority"],
    },

    {
      title: "Planering",
      key: "PLANNING",
      visited: false,
      hasError: false,
      fieldKeys: [
        "expected_duration",
        "planned_start",
        "planned_end",
        "tenant_sets_time",
        "remind_executor_duration",
      ],
    },
    {
      title: "Bekräfta",
      key: "CONFIRM",
      visited: false,
      hasError: false,
      fieldKeys: ["status", "send_automaticly"],
    },
  ];

  return chapters;
};
