import * as React from "react";

import {
  TextInput,
  Select,
  AddressField,
  NumberInput,
  Checkbox,
  TextField,
  RadioGroup,
  DatePicker,
} from "../../../Base/Fields";

// store, state
import { constants } from "../../../../../store/paymentGroup";

export default ({ method }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <DatePicker
      title={"Gäller f.o.m"}
      fieldKey={"start_date"}
      method={method}
      storeName={storeName}
      description={"Debitering och beräkningar för denna avgiftsgrupp görs f.o.m detta datum"}
      />
      <NumberInput
      title={"Årlig avgift"}
      fieldKey={"yearly_fee"}
      storeName={storeName}
      method={method}
      />
      <NumberInput
      title={"Total avgift som ska betalas in"}
      fieldKey={"total_fee_to_pay"}
      storeName={storeName}
      method={method}
      description={"Ange den totala avgiften som över tid slutligen ska betalas in"}
      />
      <RadioGroup
        title="Automatiskt ränte-pålägg från lån"
        description="Detta indikerar på att avgifter från underliggade låns räntor automatiskt läggs på vid varje debitering"
        storeName={storeName}
        method={method}
        options={[
          { label: "Ja", value: true },
          { label: "Nej", value: false },
        ]}
        fieldKey={"auto_add_loan_interest_to_fee"}
        defaultValue={false}
        />
    </>
  );
};
