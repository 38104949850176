import * as React from "react";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  useRoundingErrandForm,
  destroyPatchForm,
  constants,
  detailUrl,
  update,
  useRoundingErrand,
} from "../../../../store/roundingErrands";
import {
  buildQueryString,
  setActiveFormInstance,
} from "../../../../store/base";
import { useFilteredRoundingErrandSettings } from "../../../../store/roundingErrandSettings";

//components & styles
import FlowFormBase from "../../Base/FlowForm/FlowFormBase";
import chapterDefs from "./chapterDefs";
import descriptions from "./Descriptions";
import chapters from "./Chapters";
import FullPageSpinner from "../../../Loaders/FullPageSpinner";

export default ({ method = "PATCH" }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const { errandId } = useParams();
  const { replace } = useHistory();
  const formLoaded = Boolean(useRoundingErrandForm(method, errandId));
  const [roundingErrand] = useRoundingErrand(errandId);

  const settingsQuery = buildQueryString({
    id__in: roundingErrand?.setting?.id,
  });
  const [settings] = useFilteredRoundingErrandSettings(settingsQuery);
  const areaIds = settings?.[0]?.areas?.map((a) => a.id) || [];

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
    replace(detailUrl({ id: returned.id, isGov: false }));
  };

  const onError = () => {
    setLoading(false);
  };

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        id: errandId,
        successCallback: onSuccess,
        errorCallback: onError,
        preProcess: (data) => preProcess({ data }),
      })
    );
  };

  React.useEffect(() => {
    dispatch(setActiveFormInstance({ storeName, data: roundingErrand }));
  }, [roundingErrand]);

  React.useEffect(() => {
    return () => {
      dispatch(destroyPatchForm(false));
    };
  }, []);

  return (
    <>
      {(loading || !formLoaded) && <FullPageSpinner />}

      <FlowFormBase
        {...{
          storeName,
          chapterDefs: chapterDefs(),
          chapters,
          descriptions,
          areaIds,
          method,
          onSubmit,
          displayDocumentFieldKey: "completion_files[0].fileData_",
          title: "Genomgång av rondering",
        }}
      />
    </>
  );
};

const preProcess = ({ data }) => {
  const copy = cloneDeep(data);

  copy.status = 3;

  return copy;
};
