import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

import {
  buildQueryString,
  updateActiveFormInstance,
} from "../../../store/base";
import {
  useRoundingErrandSetting,
  constants,
  update,
} from "../../../store/roundingErrandSettings";
import { detailUrl as userDetailUrl } from "../../../store/users";
import { useFilteredRealEstates } from "../../../store/realEstates";
import { useFilteredNotes } from "../../../store/notes";

import NextErrandInfo from "../../../components/Details/RoundingErrandSetting/NextErrandInfo";
import Areas from "../../../components/Details/RoundingErrandSetting/Areas";
import Notes from "../../../components/Details/OverviewInfo/Notes/Notes";

//styling
import { LinkedObject } from "../../../components/Displays";
import BasicTable from "../../../components/Billecta/Table/BasicTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import StandardModal from "../../../components/Modals/StandardModal";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import {
  BodyText,
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  OverviewTitle,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { useFilteredBuildings } from "../../../store/buildings";
import { getIntervalStr } from "../../../components/Displays/InvoicingHelpers";

function RoundingErrandSettingOverview() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { settingId } = useParams();

  const [roundingErrandSetting, roundingErrandSettingLoading] =
    useRoundingErrandSetting(settingId);
  const [realEstates, realEstatesLoading] = useFilteredRealEstates();
  const [buildings, buildingsLoading] = useFilteredBuildings();

  const [autoGenOn, setAutoGenOn] = React.useState(false);
  const [pausedLoading, setPausedLoading] = React.useState(false);
  const [areaModalOpen, setAreaModalOpen] = React.useState(false);
  const [activeAreaInfo, setActiveAreaInfo] = React.useState(null);

  const notesQ = buildQueryString({
    id__in: roundingErrandSetting?.notes?.map((n) => n.id) || [],
  });
  const [notes] = useFilteredNotes(notesQ);

  const data = React.useMemo(() => {
    return activeAreaInfo?.realestates;
  }, [activeAreaInfo, realEstates]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Namn",
        accessor: "id",
        Cell: ({ value }) => {
          const match = realEstates?.find((r) => r.id === value);
          return <div>{match?.str_representation || "-"}</div>;
        },
      },
      {
        Header: "Adress",
        accessor: "_id",
        Cell: ({ row }) => {
          const id = row?.original?.id;
          const match = realEstates?.find((r) => r.id === id);
          return <div>{match?.addresses[0]?.base || "-"}</div>;
        },
      },
    ],
    [realEstates]
  );

  const componentData = React.useMemo(() => {
    return activeAreaInfo?.components;
  }, [activeAreaInfo, realEstates, buildings]);

  const componentColumns = React.useMemo(
    () => [
      {
        Header: "Namn",
        accessor: "str_representation",
        Cell: ({ value }) => {
          return <div>{value || "-"}</div>;
        },
      },
      {
        Header: "Beskrivning",
        accessor: "description",
        Cell: ({ value }) => {
          const shortnedText = value?.substring(0, 12);
          if (shortnedText) {
            return <div>{shortnedText}...</div>;
          } else {
            return <div> - </div>;
          }
        },
      },
      {
        Header: "Byggnad",
        accessor: "building",
        Cell: ({ value }) => {
          return <div>{value?.str_representation || "-"}</div>;
        },
      },
      {
        Header: "Fastighet",
        accessor: "realestates",
        Cell: ({ value }) => {
          const matches = realEstates
            ?.filter((r) => value.includes(r.id))
            ?.map((rr) => rr.str_representation)
            ?.join(", ");
          return <div>{matches || "-"}</div>;
        },
      },
    ],
    [realEstates]
  );

  const preProcess = () => {
    const clone = cloneDeep(roundingErrandSetting);
    if (clone.paused) {
      clone.paused = false;
    } else {
      clone.paused = true;
    }
    return clone;
  };

  const onSuccess = (data, returnedData) => {
    setPausedLoading(false);
    setAutoGenOn(returnedData?.paused);
  };

  const handleTogglePaused = () => {
    setPausedLoading(true);
    dispatch(
      update({
        id: roundingErrandSetting?.id,
        preProcess: preProcess,
        successCallback: onSuccess,
        errorCallback: () => setPausedLoading(false),
      })
    );
  };

  const handleOpenAreaModal = (row) => {
    setActiveAreaInfo(row.original);
    setAreaModalOpen(true);
  };

  React.useEffect(() => {
    dispatch(
      updateActiveFormInstance({ storeName, data: roundingErrandSetting })
    );
    if (roundingErrandSetting) {
      setAutoGenOn(roundingErrandSetting?.paused);
    }
  }, [roundingErrandSetting]);

  return (
    <>
      {pausedLoading && <OverlaySpinner />}

      <StandardModal
        isOpen={areaModalOpen}
        closeFunction={() => setAreaModalOpen(false)}
        withActionBar
        title="Områdesöversikt"
        actionBarCancelTitle="Stäng"
      >
        <OverviewTitleWrapper
          style={{
            marginBottom: "40px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <OverviewTitle small>
            Det ingår {activeAreaInfo?.realestates?.length} fastigheter i
            området
          </OverviewTitle>
          <BasicTable
            data={data}
            columns={columns}
            onRowClicked={() => {}}
            tableId="arearealestatetable"
            hideSearch
          />
        </OverviewTitleWrapper>
        <OverviewTitle small>
          Det ingår {activeAreaInfo?.components?.length} komponenter i området
        </OverviewTitle>

        <BasicTable
          data={componentData}
          columns={componentColumns}
          onRowClicked={() => {}}
          tableId="comptable"
          hideSearch
        />
      </StandardModal>

      <DetailInnerWrapper>
        <DetailPageBox style={{ flex: 1 }}>
          <OverviewTitleWrapper>
            <OverviewTitle>Ronderingstyp</OverviewTitle>
          </OverviewTitleWrapper>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1, marginRight: 24 }}>
              <DetailInfo
                infoObj={getInfoObj(roundingErrandSetting)}
                extraChapterStyles={{ marginRight: 0 }}
              />
              <InnerBox>
                <Notes
                  notes={notes}
                  contentType={"errands.roundingerrandsetting"}
                  objectId={roundingErrandSetting?.id}
                  title="Anteckningar"
                />
              </InnerBox>
            </div>
            <div style={{ flex: 1, flexDirection: "column" }}>
              <NextErrandInfo
                setting={roundingErrandSetting}
                autoGenOn={autoGenOn}
                onToggle={handleTogglePaused}
              />
              <Areas
                roundingSetting={roundingErrandSetting}
                openAreaModal={handleOpenAreaModal}
                realestates={realEstates}
              />
            </div>
          </div>
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

const getResponsibleTrustee = (trustee) => {
  if (trustee) {
    return <LinkedObject obj={trustee} urlMethod={userDetailUrl} />;
  }
  return <div>Ingen ansvarig hittades</div>;
};

function getInfoObj(roundingErrandSetting, infoObjProps) {
  let data = [
    {
      title: "Titel",
      value: roundingErrandSetting?.str_representation,
    },
    {
      title: "Typ av interval",
      value: roundingErrandSetting?.interval?.mode_display,
    },
    {
      title: "Interval",
      value: getIntervalStr(roundingErrandSetting?.interval),
    },
    {
      title: "Interval påbörjades",
      value: roundingErrandSetting?.interval_start,
    },
    {
      title: "Ansvarig",
      value: getResponsibleTrustee(roundingErrandSetting?.responsible_trustee),
    },
    {
      title: "Prioriterad roll",
      value: roundingErrandSetting?.performers?.[0]?.str_representation || "-",
    },
  ];

  const infoObj = {
    Information: data,
  };

  return infoObj;
}

export default RoundingErrandSettingOverview;
