import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  create,
  destroyPatchForm,
  destroyPostForm,
  constants,
  update,
  detailUrl,
  useBrfCompanyForm,
} from "../../../../store/brfCompanies";
import { updateActiveFormInstance } from "../../../../store/base";
import { AddressField } from "../../Base/Fields";
import TableSelectField from "../../Base/Fields/TableSelectField";
import TextInputField from "../../Base/Fields/TextInputField";
import MediaField from "../../Base/Fields/MediaField";
import InvoicingSettingTable from "src/components/Tables/InvoicingSettings/FullTable";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";
import UserTable from "src/components/Tables/Users/FullTable";
import SelectField from "../../Base/Fields/SelectField";
import InvoicingProductTable from "src/components/Tables/InvoicingProducts/FullTable";

export default function BRFModalForm({ method, id, onCheckout, instance }) {
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const { push } = useHistory();
  const formLoaded = Boolean(useBrfCompanyForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    if (method === "POST") {
      dispatch(destroyPostForm(success));
    } else if (method === "PATCH") {
      dispatch(destroyPatchForm(success));
    }

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);

    push(detailUrl({ id: returned.id }));
  };

  const onSubmit = () => {
    setLoading(true);
    if (method === "POST") {
      dispatch(
        create({
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    } else if (method === "PATCH") {
      dispatch(
        update({
          id,
          successCallback: onSuccess,
          errorCallback: () => setLoading(false),
        })
      );
    }
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={method === "POST" ? "Skapa förening" : "Uppdatera förening"}
      onAccept={onSubmit}
      acceptTitle="Spara"
      closeFunction={onDone}
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Föreningens uppgifter</div>

      <div class="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          title="Namn på BRF"
          storeName={storeName}
          fieldKey="company.name"
          method={method}
        />
        <TextInputField
          title="Orgnr"
          storeName={storeName}
          fieldKey="company.orgnr"
          method={method}
        />

        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"company.owner"}
          persistantQuery={{ "user_type__in!": [3, 5] }}
          placeholder="Välj ansvarig användare..."
          title="Ansvarig användare (signeringspart)"
          description={
            "Välj vilken användare som föreslås som signerande part på digitala dokument under denna BRF"
          }
          TableComponent={UserTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 ">
        <AddressField
          title="Föreningens adress"
          storeName={storeName}
          fieldKey="company.address"
          method={method}
        />
      </div>

      <MediaField
        storeName={storeName}
        method={method}
        fieldKey={`company.image`}
        allowedFormats={[".png", ".jpg", ".jpeg"]}
        title="Bolagets logga"
        fileKey="image"
      />

      <hr />

      <div className="mb-6 text-base font-medium">Avisering</div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"company.invoicing_config"}
          placeholder="Välj aviseringsinställning..."
          title="Föreslagen aviseringsinställning"
          description={
            "Välj vilken basinställning för avisering som ska föreslås för avtal som skapas under bolaget"
          }
          TableComponent={InvoicingSettingTable}
        />
      </div>

      <hr />

      <div className="mb-6 text-base font-medium">Avgifter & debitering</div>

      <div className="grid grid-cols-2 gap-6 mb-6 ">
        <TextInputField
          storeName={storeName}
          fieldKey={`pledge_fee`}
          title="Pantsättningsavgift"
          description="Avgift vid pantsättning i föreningen. "
          method={method}
        />
        <SelectField
          storeName={storeName}
          fieldKey={"pledge_fee_paid_by"}
          title="Pantsättningsavgift betalas av"
          method={method}
          description={
            "Lämnas tom om ingen automatisk debitering ska göras av systemet."
          }
        />

        <TableSelectField
          storeName={storeName}
          placeholder="Välj produkt för pantsättningsavgift..."
          title="Produkt För Pantsättningsavgift"
          method={method}
          fieldKey="pledge_fee_product"
          TableComponent={InvoicingProductTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TextInputField
          storeName={storeName}
          fieldKey={`transfer_fee`}
          title="Överlåtelseavgift"
          description="Avgift vid överlåtelse av bostadsrätt i föreningen."
          method={method}
        />
        <SelectField
          storeName={storeName}
          fieldKey={"transfer_fee_paid_by"}
          title="Överlåtelseavgift betalas av"
          method={method}
          description={
            "Lämnas tom om ingen automatisk debitering ska göras av systemet"
          }
        />
        <TableSelectField
          storeName={storeName}
          placeholder="Välj produkt för överlåtelseavgift..."
          title="Produkt för överlåtelseavgift"
          method={method}
          fieldKey="transfer_fee_product"
          TableComponent={InvoicingProductTable}
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <SelectField
          storeName={storeName}
          fieldKey={"placement_fee_paid_by"}
          title="Upplåtelseavgift betalas av"
          method={method}
          description={
            "Upplåtelseavgiften styrs på varje lägenhet. Lämna tom för att indikera på att ingen automatisk debitering ska göras av systemet"
          }
        />
      </div>
    </Modal>
  );
}
