import React from "react";
import { useDispatch, useSelector } from "react-redux";

//store, state
import {
  buildQueryString,
  setActiveFormInstance,
} from "../../../../store/base";
import {
  useReportErrandForm,
  constants,
} from "../../../../store/reportErrands";
import {
  constants as settingConstants,
  useReportErrandSettingsPagination,
} from "../../../../store/reportErrandSettings";
import listDefs from "../../../Tables/ReportErrandSettings/listDefs";

//components & styles
import { Component } from "../../ReportErrand/ChapterForm/Chapters";
import OverlaySpinner from "../../../Loaders/OverlaySpinner";
import { ImageM2MContent, TableSelect, TextInput } from "../../Base/Fields";
import { FormAreaDescription, FormAreaTitle } from "../../Base/Chapters/styles";
import TableSelectField from "../../Base/Fields/TableSelectField";
import ReportErrandSettingsTable from "src/components/Tables/ReportErrandSettings/FullTable";

export default function FaultReportErrandForm({
  fault,
  realestates,
  buildings,
}) {
  const storeName = constants.STORE_NAME;
  const method = "POST";
  const dispatch = useDispatch();

  const user = useSelector((state) => state.app.user);

  const formLoaded = Boolean(useReportErrandForm(method));
  const settingsQuery = buildQueryString({ kind__isnull: true });
  const settingsFilterMethod = (obj) => {
    return obj;
  };

  React.useEffect(() => {
    if (fault) {
      const realestateMatch = realestates?.find(
        (r) => r.id === fault?.realestate?.id
      );
      const buildingMatch = buildings?.find(
        (r) => r.id === fault?.building?.id
      );

      const newFault = {
        title: fault.title,
        report_comments: [{ content: fault.description }],
        _tempRealestate: realestateMatch,
        _tempBuilding: buildingMatch || undefined,
        reported_by: { id: user?.id },
      };
      dispatch(
        setActiveFormInstance({
          storeName,
          data: newFault,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(setActiveFormInstance({ storeName, data: {} }));
    };
  }, []);

  if (!formLoaded) return <OverlaySpinner />;
  return (
    <>
      <FormAreaTitle>Fyll i nödvändig information</FormAreaTitle>
      <FormAreaDescription style={{ marginBottom: 20 }}>
        Den information som finns på avvikelsen autofylls nedan
      </FormAreaDescription>

      <TextInput
        storeName={storeName}
        fieldKey={"title"}
        method={method}
        title="Titel"
      />

      <TextInput
        storeName={storeName}
        fieldKey={`report_comments[0].content`}
        instructionsKey={"report_comments.content"}
        title="Beskrivning"
        method={method}
      />

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectField
          storeName={storeName}
          method={method}
          fieldKey={"setting"}
          placeholder="Välj felanmälningstyp..."
          title="Typ av felanmälan"
          description="Välj vilken typ av felanmälan som ska registreras."
          persistantQuery={{ kind__isnull: true }}
          TableComponent={ReportErrandSettingsTable}
        />
      </div>

      <Component method={method} />

      <FormAreaDescription style={{ marginBottom: 12, marginTop: 24 }}>
        Ladda upp bilder som underlättar för utföraren att avhjälpa felet.
      </FormAreaDescription>

      <ImageM2MContent
        storeName={storeName}
        fieldKey={"report_files"}
        method={method}
        title="Bilder på felet"
        fileKey={"file"}
        allowedFileFormats={[".png", ".jpg", ".pdf"]}
        contentKey={"description"}
      />
    </>
  );
}
