import * as React from "react";

import { constants } from "../../../../../store/roundingErrandSettings";

import { TextInput } from "../../../Base/Fields";
import { NonNestedSelect } from "../../../Base/Fields";
import { useFilteredUsers } from "../../../../../store/users";
import { buildQueryString } from "../../../../../store/base";
import DelegationOrder from "../../../RoundingErrandSetting/DelegationOrder/DelegationOrder";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

export default ({ method, id }) => {
  const storeName = constants.STORE_NAME;
  const trusteeKey = "responsible_trustee";

  const filteredUsersQuery = buildQueryString({
    user_type__in: [0, 1, 2],
  });
  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Generell ronderingsinformation</OverviewTitle>
          <OverviewSubtitle>
            Ange ett namn för ronderingstypen och ange vem som är ansvarig att
            utföra ronderingen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        title="Typ av rondering"
        method={method}
        storeName={storeName}
        fieldKey={"title"}
        alwaysShowDescription
      />

      <NonNestedSelect
        storeName={storeName}
        method={method}
        fieldKey={trusteeKey}
        fetchMethod={useFilteredUsers}
        fetchQueryString={filteredUsersQuery}
        title="Ansvarig förvaltare"
      />

      <DelegationOrder
        storeName={storeName}
        method={method}
        modalInModal
        goToRoleButton
      />
    </>
  );
};
