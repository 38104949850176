import baseReducer, { INITIAL_STATE } from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const newState = baseReducer(state, action, constants);
  const { type, payload } = action;

  switch (type) {
    case constants.CLEAR_INSIGHTSPAGE_VALUES: {
      return { ...INITIAL_STATE, resultData: {} };
    }

    default:
      return newState;
  }

}