import * as React from "react";
import { useDispatch } from "react-redux";

import {
  destroyPatchForm,
  constants,
  update,
  useLeaseContractForm,
} from "../../../../store/leaseContracts";
import { updateActiveFormInstance } from "../../../../store/base";
import { cloneDeep } from "lodash";
import Modal from "../../Base/Modals/Modal";
import OverlaySpinner from "src/components/Loaders/OverlaySpinner";

import TenantTable from "src/components/Tables/Tenants/FullTableNewVersion";
import TenantNestedChildren from "../../Tenant/NestedChildren";
import TableSelectFieldWithCreate from "../../Base/Fields/TableSelectFieldWithCreate";

export default function UpdateLeaseContractTenantsModalForm({
  id,
  onCheckout,
  instance = {},
}) {
  const method = "PATCH"; // always patch
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const [loading, setLoading] = React.useState(false);

  const formLoaded = Boolean(useLeaseContractForm(method, id));

  React.useEffect(() => {
    if (instance) {
      dispatch(
        updateActiveFormInstance({
          storeName: storeName,
          data: instance,
        })
      );
    }
  }, []);

  const checkout = (success) => {
    dispatch(destroyPatchForm(success));

    onCheckout();
  };

  const onSuccess = (_, returned) => {
    setLoading(false);
    checkout(true);
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    if (!dataClone?.tenant) {
      dataClone.tenant = null;
    }

    if (dataClone.bi_tenants?.length < 1) {
      dataClone.bi_tenants = [];
    }

    return dataClone;
  };

  const onSubmit = () => {
    setLoading(true);

    dispatch(
      update({
        preProcess,
        id,
        successCallback: onSuccess,
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onDone = () => {
    checkout(false);
  };

  return (
    <Modal
      title={"Uppdatera hyresgäster på avtal"}
      closeFunction={onDone}
      onAccept={onSubmit}
      acceptTitle="Spara"
    >
      {(loading || !formLoaded) && <OverlaySpinner />}
      <div className="mb-6 text-base font-medium">Hyresgäster på avtal</div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey="tenant"
          method={method}
          placeholder="Välj hyresgäst..."
          title="Hyresgäst"
          description="Välj primär hyresgäst på avtalet. Denna hyresgäst är även mottagare av avier för avtalet om avisering är aktiverat."
          TableComponent={TenantTable}
          createDisplayKey="user.first_name"
        >
          {(parentPath) => (
            <TenantNestedChildren
              storeName={storeName}
              method={method}
              parentInstructionsPath={"tenant"}
              parentPath={parentPath}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6 relative">
        <TableSelectFieldWithCreate
          storeName={storeName}
          fieldKey="bi_tenants"
          isMany
          method={method}
          placeholder="Välj hyresgäster..."
          title="Ytterligare hyresgäster"
          description="Välj ytterligare hyresgäst på avtalet."
          TableComponent={TenantTable}
          createDisplayKey="user.first_name"
        >
          {(parentPath) => (
            <TenantNestedChildren
              storeName={storeName}
              method={method}
              parentInstructionsPath={"tenant"}
              parentPath={parentPath}
            />
          )}
        </TableSelectFieldWithCreate>
      </div>
    </Modal>
  );
}
