import * as React from "react";
import { useHistory } from "react-router-dom";

// style, design
import BaseTable from "src/components/Lists/Base/FinalTable/BaseTable";
import columnDefs from "./listDefs";

// store, state

import { constants, detailUrl } from "../../../store/otherContracts";
import { useFrequentPermissions } from "../../../store/base";

import { BADGE_TYPES } from "../../Badge/Badge";

import { OPERATOR_TEMPLATES } from "src/components/Lists/Base/FinalTable/utils";

export default function OtherContractsTable({
  persistantQuery,
  isBare,
  ignoreLocalStorage,
  tableId,
  onRowClicked,
  checkRowHighlighted,
  onRowSelected,
  disableBadgeFilterCounts,
}) {
  const { hasBillectaViewPermission } = useFrequentPermissions();

  //TODO: Fix export for this table
  // const exportParser = {
  //   state: (data) => data.state_display,
  //   category: (data) => data.category_display,
  //   notify_interval: (data) => durationParse(data.notify_interval, true),
  //   renew_interval: (data) => durationParse(data.renew_interval, true),
  // };

  // const exportExclusions = ["main_contract", "content_type"];

  const columns = React.useMemo(
    () => columnDefs(hasBillectaViewPermission),
    [persistantQuery, hasBillectaViewPermission]
  );

  const filters = {
    Avtalsnummer: {
      queryKey: "id_number",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Motpart: {
      //Not implemented on backend
      queryKey: "tenant_name",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Aviseringsstatus: {
      queryKey: "lease_invoicing_active",
      type: "boolean",
      operators: OPERATOR_TEMPLATES.BOOLEAN,
    },
    Fastighet: {
      queryKey: "realestate__key",
      type: "text",
      operators: OPERATOR_TEMPLATES.SEARCH,
    },
    Startdatum: {
      queryKey: "start_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
    Slutdatum: {
      queryKey: "end_date",
      type: "date",
      operators: OPERATOR_TEMPLATES.DATE,
    },
  };

  const badges = {
    Aktiva: {
      color: BADGE_TYPES.GREEN,
      querySet: {
        state__in: [0, 1, 2],
      },
    },
    Utgående: {
      color: BADGE_TYPES.YELLOW,
      querySet: {
        state__in: [3],
      },
    },
    Kommande: {
      color: BADGE_TYPES.INDIGO,
      querySet: {
        state__in: [4],
      },
    },
    "Ej signerade": {
      color: BADGE_TYPES.PURPLE,
      querySet: {
        state__in: [5],
      },
    },
    Uppsagda: {
      color: BADGE_TYPES.RED,
      querySet: {
        state__in: [6, 7],
      },
    },
    Tidigare: {
      color: BADGE_TYPES.GRAY,
      querySet: {
        state__in: [9],
      },
    },
  };

  return (
    <>
      <BaseTable
        tableId={tableId || "othercontracts_full_table"}
        title="Övriga avtal"
        onRowClickedWithDetail={(obj) => detailUrl({ id: obj.id })}
        {...{
          isBare,
          ignoreLocalStorage,
          onRowClicked,
          columns,
          persistantQuery,
          badges,
          filters,
          constants,
          checkRowHighlighted,
          onRowSelected,
          disableBadgeFilterCounts,
        }}
      />
    </>
  );
}
