import { cloneDeep } from "lodash";
import moment from "moment";
import {
  post,
  put,
  destroyForm,
  addToProcess,
  setActiveFormInstance,
} from "../../base";

import { getFormInstanceStateType } from "../../base/utils";

import { cleanPutInvoice, invoiceToCreditInvoice } from "../../billecta/utils";

import constants from "./constants";
import { store } from "../../store";
import * as services from "./services";
import { removeFromProgress } from "../../base/store/actions";
import { addToast, TOAST_TYPES } from "../../toasts";
import {
  convertInvoiceToBillecta,
  convertInvoiceToPigello,
  prepareInvoiceClone,
} from "../utils";
import { axiosInstance } from "../../base/store/axios";

export const getAllInvoices = (creditorId) => {
  return async (dispatch) => {
    const state = store.getState();
    const closedFromDate = state[constants.STORE_NAME].invoicesClosedFromDate;
    const invoices = await services.getAllInvoices(creditorId, closedFromDate);

    invoices.sort((a, b) => {
      const aIsBefore = moment(a.Created).isBefore(moment(b.Created));

      return aIsBefore ? 1 : -1;
    });

    invoices.forEach((i) => {
      if (i.Stage === "Manual" && !!i.ReminderInvoiceActionPublicId) {
        i.Stage = "ReminderInvoiceSent";
      }
    });

    const vacancyBookingInvoices = invoices.filter(
      (i) => i.ActionType === "VerificationInvoiceAction"
    );
    const otherInvoices = invoices.filter(
      (i) => i.ActionType !== "VerificationInvoiceAction"
    );

    dispatch({
      type: constants.SET_ALL_INVOICES,
      payload: {
        invoices: otherInvoices,
        vacancyBookingInvoices,
      },
    });
  };
};

export const setInvoicesClosedFromDate = ({ date, creditorId }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CLOSED_FROM_DATE,
      payload: {
        date,
      },
    });

    dispatch(getAllInvoices(creditorId));
  };
};

export const setShowConnectedPaymentFromDate = ({ date, creditorId }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CONNECTED_PAYMENT_DATE_FROM,
      payload: {
        date,
      },
    });

    dispatch(getAllPayments(creditorId));
  };
};

export const getAllPayments = (creditorId) => {
  return async (dispatch) => {
    const state = store.getState();

    const calls = [
      services.getAllUnconnectedPayments(creditorId),
      services.getAllConnectedPayments({
        creditorId,
        connectedFromDate: state[constants.STORE_NAME].connectedPaymentFromDate,
        connectedToDate: state[constants.STORE_NAME].connectedPaymentToDate,
      }),
    ];

    const [unconnectedPayments, connectedPayments] = await Promise.all(calls);

    dispatch({
      type: constants.SET_PAYMENTS,
      payload: {
        payments: [...unconnectedPayments, ...connectedPayments],
      },
    });
  };
};

export const getManualInvoiceProposal = ({
  creditorId,
  errorCallback,
  successCallback,
  selectedDebtorConfig,
  companyInvoiceConfig,
}) => {
  return async (dispatch) => {
    try {
      const name = "manual_invoice";
      const inProgress = store
        .getState()
        [constants.STORE_NAME].inProgress.includes(name);

      if (inProgress) {
        return undefined;
      }

      addToProcess(dispatch, constants, name);

      const InvoiceDate = moment().format("YYYY-MM-DD");
      const DueDate = moment().add({ months: 1 }).format("YYYY-MM-DD");

      dispatch({
        type: getFormInstanceStateType(constants.STORE_NAME),
        payload: {
          result: {
            InvoiceDate,
            DueDate,
            Message: selectedDebtorConfig?.default_invoice_message,
            DeliveryMethod: companyInvoiceConfig?.delivery_method,
            OurReference: companyInvoiceConfig?.our_reference,
            YourReference: selectedDebtorConfig?.your_reference,
            DebtorPublicId: selectedDebtorConfig?.id,
            CreditorPublicId: creditorId,
            InvoiceFee: {
              CurrencyCode: "SEK",
              Value: companyInvoiceConfig?.admin_fee || 0,
            },
            ReminderInvoiceDetails: {
              DaysDelayAfterDueDate: 3,
            },
          },
        },
      });

      removeFromProgress(dispatch, constants, name);

      successCallback && successCallback();
    } catch (e) {
      const message = e?.response?.Message;

      errorCallback && errorCallback(message);
    }
  };
};

export const preparePutInvoice = ({
  invoiceId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const invoice = await services.getSingleInvoice(invoiceId);

      const formatted = await convertInvoiceToPigello({ invoice });

      dispatch({
        type: getFormInstanceStateType(constants.STORE_NAME),
        payload: { result: formatted, clean: true },
      });

      successCallback();
    } catch (e) {
      errorCallback(e);
    }
  };
};

export const prepareCreditInvoice = ({
  invoiceId,
  newInvoiceDate = moment().format("YYYY-MM-DD"),
  connectToOriginal,
  successCallback,
  errorCallback,
  skipSetForm = false,
}) => {
  return async (dispatch) => {
    try {
      const invoice = await services.getSingleInvoice(invoiceId);

      const formatted = await convertInvoiceToPigello({ invoice });

      const inverted = invoiceToCreditInvoice({
        formattedInvoice: formatted,
        newInvoiceDate,
      });

      // set crediting invoice if we're conneting it to the original invoice
      if (connectToOriginal) {
        inverted.CreditingInvoicePublicId = invoiceId;
      }

      if (inverted?.Records) {
        inverted.Records.push({
          ArticleDescription: `Krediterar fakura ${invoice?.InvoiceNumber}`,
          RecordType: "Message",
        });
      }

      if (!skipSetForm) {
        dispatch(
          setActiveFormInstance({
            storeName: constants.STORE_NAME,
            data: inverted,
          })
        );
      }

      successCallback(inverted);
    } catch (e) {
      errorCallback(e);
    }
  };
};

export const adjustPayment = ({
  companyId,
  originalInvoiceId,
  originalPaidAmount,
  invoiceDateCredit,
  attestCredit,
  registerCreditPayment,
  creditPaymentCode,
  creditPaymentDate,
  creditPaymentRef,
  matchPaymentToInvoice,
  debitPaymentCode,
  debitPaymentDate,
  debitPaymentRef,
  debitPaymentInvoiceId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      // 1. create credit invoice based on original
      const invoice = await services.getSingleInvoice(originalInvoiceId);

      const formatted = await convertInvoiceToPigello({ invoice });

      const inverted = invoiceToCreditInvoice({
        formattedInvoice: formatted,
        newInvoiceDate: invoiceDateCredit,
      });

      inverted.CreditingInvoicePublicId = originalInvoiceId;
      if (inverted?.Records) {
        inverted.Records.push({
          ArticleDescription: `Krediterar fakura ${invoice?.InvoiceNumber}`,
          RecordType: "Message",
        });
      }
      const readyForCreate = await convertInvoiceToBillecta({
        invoiceData: inverted,
        companyId,
        isCredit: true,
      });

      const { PublicId: creditInvoiceActionId } =
        await services.createCreditInvoice(readyForCreate);

      // 2. if attest credit, attest it
      if (attestCredit) {
        await services.attestInvoice(creditInvoiceActionId);
      }

      // 3. Register credit as paid if options
      if (attestCredit && registerCreditPayment) {
        const postObj = {
          ActionPublicId: creditInvoiceActionId,
          Amount: {
            CurrencyCode: "SEK",
            Value: originalPaidAmount * 100 * -1, // flip to negative for credit invoice
          },
          Date: creditPaymentDate,
          PaymentMeanCode: creditPaymentCode,
          PaymentReferenceText: creditPaymentRef,
        };

        await services.registerInvoicePayment({
          postObj,
          invoiceActionId: creditInvoiceActionId,
        });
      }
      // 4. Register original payment on correct invoice
      if (matchPaymentToInvoice) {
        const postObj = {
          ActionPublicId: debitPaymentInvoiceId,
          Amount: {
            CurrencyCode: "SEK",
            Value: originalPaidAmount * 100,
          },
          Date: debitPaymentDate,
          PaymentMeanCode: debitPaymentCode,
          PaymentReferenceText: debitPaymentRef,
        };

        await services.registerInvoicePayment({
          postObj,
          invoiceActionId: debitPaymentInvoiceId,
        });
      }
      // 5. callback to go to copy and create new invoice
      successCallback();
    } catch (e) {
      errorCallback();
    }
  };
};

export const connectCreditToDebit = ({
  connectToId,
  creditorId,
  companyId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      // step 1 - get the credit invoice from store
      const creditInvoice = cleanPutInvoice(
        cloneDeep(state[constants.STORE_NAME].formInstance)
      );

      // step 2 - fix values for billecta
      const formatted = await convertInvoiceToBillecta({
        invoiceData: creditInvoice,
        companyId,
        isUpdate: true,
        isCredit: true,
      });

      // step 3 - set crediting invoice property
      formatted.CreditingInvoicePublicId = connectToId;

      // step 4 - save invoice
      const url = `/accounting/gateways/invoice/${creditInvoice.ActionPublicId}/`;
      await axiosInstance.put(url, formatted);

      // step 5, refetch invoice
      dispatch(
        getHandleInvoice({
          invoiceActionId: creditInvoice?.ActionPublicId,
          creditorId,
        })
      );

      successCallback();
    } catch (e) {
      errorCallback(e?.response?.data?.Message);
    }
  };
};

export const getHandleInvoice = ({ invoiceActionId, notFoundCallback }) => {
  return async (dispatch) => {
    try {
      addToProcess(dispatch, constants, invoiceActionId);

      const invoice = await services.getSingleInvoice(invoiceActionId);

      // convert billecta ids to pigello
      const convertedInvoice = await convertInvoiceToPigello({
        invoice,
        isDisplay: true,
      });

      // special case for manual reminder invoices
      if (
        convertedInvoice.ReminderInvoiceActionPublicId &&
        convertedInvoice?.State?.Stage === "Manual"
      ) {
        convertedInvoice.State.Stage = "ReminderInvoiceSent";
      }

      // set current invoice for overview
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: convertedInvoice,
        },
      });

      dispatch({
        type: getFormInstanceStateType(constants.STORE_NAME),
        payload: { result: convertedInvoice, clean: true },
      });

      removeFromProgress(dispatch, constants, invoiceActionId);
    } catch (e) {
      const notFound =
        e?.response?.data?.Message === "Fakturan kunde inte hittas"; // bad error message, string comparison may be better

      notFoundCallback && notFound && notFoundCallback();
    }
  };
};

export const getCopyInvoice = ({
  invoiceActionId,
  newInvoiceDate,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const invoice = await services.getSingleInvoice(invoiceActionId);

      // convert billecta ids to pigello
      const convertedInvoice = await convertInvoiceToPigello({
        invoice,
      });

      // get debtor to set tenant in create form
      const { debtor, tenant } = await services.convertBillectaDebtorToPigello({
        DebtorPublicId: convertedInvoice.DebtorPublicId,
      });

      const formReady = prepareInvoiceClone({
        formattedInvoice: convertedInvoice,
        newInvoiceDate: newInvoiceDate,
      });

      dispatch({
        type: getFormInstanceStateType(constants.STORE_NAME),
        payload: { result: formReady, clean: true },
      });

      successCallback({ debtor, tenant });
    } catch (e) {
      errorCallback();
    }
  };
};

export const getReminderInvoice = ({ reminderInvoiceId, invoiceId }) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, `reminder_${reminderInvoiceId}`);

    const reminderInvoice = await services.getReminderInvoice(invoiceId);

    // set current invoice for overview
    dispatch({
      type: constants.SET_CURRENT_REMINDER_INVOICE,
      payload: {
        reminderInvoice: reminderInvoice,
      },
    });

    removeFromProgress(dispatch, constants, `reminder_${reminderInvoiceId}`);
  };
};

export const getDebtCollectionInfo = ({ debtCollectionId }) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, `debtInfo_${debtCollectionId}`);

    const debtCollectionInfo = await services.getDebtCollectionInfo(
      debtCollectionId
    );

    // set current invoice for overview
    dispatch({
      type: constants.SET_CURRENT_DEBT_INVOICE_INFO,
      payload: {
        debtInvoiceInfo: debtCollectionInfo,
      },
    });

    removeFromProgress(dispatch, constants, `debtInfo_${debtCollectionId}`);
  };
};

export const downloadBillectaFile = ({ filePublicId, fileName }) => {
  return async (dispatch) => {
    try {
      const preview = await services.downloadBillectaFile(filePublicId);

      // trigger pdf download
      const a = document.createElement("a");
      a.href = "data:application/pdf;base64, " + preview;
      a.download = `${fileName || filePublicId}.pdf`;
      a.click();

      dispatch(
        addToast({
          type: TOAST_TYPES.SUCCESS,
          title: "Filen hämtades",
        })
      );
    } catch (e) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej hämta fil",
        })
      );
    }
  };
};

export const createManualInvoice = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  companyId,
}) => {
  const url = `/accounting/gateways/invoice/`;

  return post({
    url: url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    asyncPreProcess: async (data) =>
      await convertInvoiceToBillecta({ invoiceData: data, companyId }),
  });
};

export const updateInvoice = ({
  successCallback,
  errorCallback,
  id,
  companyId,
}) => {
  const url = `/accounting/gateways/invoice/${id}/`;

  return put({
    url,
    constants,
    successCallback,
    errorCallback,
    asyncPreProcess: async (data) =>
      await convertInvoiceToBillecta({
        invoiceData: data,
        companyId,
        isUpdate: true,
      }),
  });
};

export const creditInvoice = ({
  successCallback,
  errorCallback,
  companyId,
  attestDirectly,
}) => {
  return async (dispatch) => {
    try {
      const state = store.getState();

      // step 1 - get the credit invoice from store
      const creditInvoice = cloneDeep(state[constants.STORE_NAME].formInstance);

      // step 2 - fix values for billecta
      const formatted = await convertInvoiceToBillecta({
        invoiceData: creditInvoice,
        companyId,
        isCredit: true,
      });

      // step 3 - save credit invoice
      const { PublicId: creditInvoiceActionId } =
        await services.createCreditInvoice(formatted);

      // step 4 - (OPTIONAL) - attest credit invoice
      if (attestDirectly) {
        await services.attestInvoice(creditInvoiceActionId);
      }

      successCallback && successCallback(creditInvoiceActionId);
    } catch (e) {
      const message = e.response?.data?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const getInvoicePreview = ({
  companyId,
  skipDownload,
  method,
  isCredit,
  successCallback,
  errorCallback,
}) => {
  return async () => {
    try {
      const formInstance = store.getState()[constants.STORE_NAME].formInstance;

      const formattedData = await convertInvoiceToBillecta({
        invoiceData: formInstance,
        companyId,
        isUpdate: method !== "POST",
        isCredit,
      });

      const preview = await services.generateInvoicePreview({
        postObj: formattedData,
      });

      successCallback &&
        successCallback(`data:application/pdf;base64, ${preview}`);

      if (skipDownload) {
        return;
      } else {
        // trigger pdf download
        const a = document.createElement("a");
        a.href = "data:application/pdf;base64, " + preview;
        a.download = "preview.pdf";
        a.click();
      }
    } catch (e) {
      console.log(e);
      errorCallback && errorCallback();
    }
  };
};

export const clearInvoiceIdPreview = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.CLEAR_CURRENT_INVOICE_PREVIEW_PDF,
    });
  };
};

export const getInvoiceIdPreview = ({ invoiceId, download = false }) => {
  return async (dispatch) => {
    addToProcess(dispatch, constants, `preview_${invoiceId}`);

    const preview = await services.generateInvoiceIdPreview(invoiceId);

    if (download) {
      const a = document.createElement("a");
      a.href = "data:application/pdf;base64, " + preview;
      a.download = "preview.pdf";
      a.click();
    }

    dispatch({
      type: constants.SET_CURRENT_INVOICE_PREVIEW_PDF,
      payload: {
        id: invoiceId,
        pdf: `data:application/pdf;base64, ${preview}`,
      },
    });

    removeFromProgress(dispatch, constants, `preview_${invoiceId}`);
  };
};

export const mergeInvoices = ({
  invoices,
  creditorId,
  invoiceDate,
  dueDate,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const merges = {};
      let calls = [];
      let couldNotMergeCount = 0;
      let couldMergeCount = 0;

      invoices.forEach((invoice) => {
        const debtorId = invoice.DebtorPublicId;

        if (merges[debtorId]) {
          merges[debtorId].push(invoice?.ActionPublicId);
        } else {
          merges[debtorId] = [invoice?.ActionPublicId];
        }
      });

      Object.keys(merges || {}).forEach((debtorId) => {
        const current = merges[debtorId];

        if (current.length === 1) {
          couldNotMergeCount += 1;
          delete merges[debtorId];
        } else {
          couldMergeCount += current.length;
          calls.push(
            services.mergeInvoices({
              invoiceIds: current,
              invoiceDate,
              dueDate,
            })
          );
        }
      });

      const results = await Promise.all(calls.map((p) => p.catch((e) => e)));
      const validResults = results.filter(
        (result) => !(result instanceof Error)
      );
      const errorResults = results.filter((result) => result instanceof Error);

      dispatch(getAllInvoices(creditorId));

      successCallback &&
        successCallback({
          couldNotMergeCount,
          couldMergeCount,
          errorResults,
          validResults,
        });
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const attestAllInvoicesForCreditor = ({
  creditorId,
  errorCallback,
  successCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.attestCreditor(creditorId);
      successCallback();
    } catch (e) {
      errorCallback(e);
    }
  };
};

export const attestMultipleInvoices = ({
  invoiceIds,
  creditorId,
  successCallback,
  errorCallback,
  onProgress,
  onFailed,
}) => {
  return async (dispatch) => {
    try {
      const promises = invoiceIds.map((id) => {
        return services.attestInvoice(id);
      });

      await Promise.progress(promises, onProgress, onFailed);

      dispatch(getAllInvoices(creditorId));

      successCallback();
    } catch (e) {
      errorCallback(e);
    }
  };
};

export const attestInvoice = ({
  invoiceId,
  creditorId,
  successCallback,
  errorCallback,
  skipGetInvoice = false,
}) => {
  return async (dispatch) => {
    try {
      await services.attestInvoice(invoiceId);

      if (!skipGetInvoice) {
        dispatch(
          getHandleInvoice({
            invoiceActionId: invoiceId,
            creditorId,
          })
        );
      }

      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e.response?.data?.Message);
    }
  };
};

export const deleteInvoice = ({
  invoiceId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const deleteCall = async () => await services.deleteInvoice(invoiceId);

      dispatch({
        type: constants.SET_DELETE_INVOICES_LOADING,
        payload: {
          loading: true,
        },
      });

      const resp = await deleteCall();

      // clear current invoice
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: null,
        },
      });

      // reload all invoices
      dispatch(getAllInvoices(creditorId));

      dispatch({
        type: constants.SET_DELETE_INVOICES_LOADING,
        payload: {
          loading: false,
        },
      });

      successCallback && successCallback();
    } catch (e) {
      dispatch({
        type: constants.SET_DELETE_INVOICES_LOADING,
        payload: {
          loading: false,
        },
      });
      console.log(e);
      errorCallback && errorCallback();
    }
  };
};

export const sendInvoice = ({
  invoiceActionId,
  creditorId,
  method,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.sendInvoice(invoiceActionId, method);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const disputeInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.disputeInvoice(invoiceActionId);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const undisputeInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.undisputeInvoice(invoiceActionId);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const cancelRemindersForInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.cancelReminders(invoiceActionId);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const pauseInvoice = ({ id, creditorId, successCallback }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_INVOICE,
      payload: {
        invoice: null,
      },
    });

    await services.pauseInvoice(id);

    // get all invoices again after update
    dispatch(getHandleInvoice({ invoiceActionId: id, creditorId }));
    dispatch(getAllInvoices(creditorId));

    successCallback && successCallback();
  };
};

export const resumeInvoice = ({ id, creditorId, successCallback }) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_CURRENT_INVOICE,
      payload: {
        invoice: null,
      },
    });

    await services.resumeInvoice(id);

    // get all invoices again after update
    dispatch(getHandleInvoice({ invoiceActionId: id, creditorId }));
    dispatch(getAllInvoices(creditorId));

    successCallback && successCallback();
  };
};

export const matchCreditToDebit = ({
  creditorId,
  debitInvoiceActionId,
  creditInvoiceActionId,
  successCallback,
  errorCallback,
  updateSingular,
}) => {
  return async (dispatch) => {
    try {
      await services.creditInvoice({
        debitInvoiceActionId,
        creditInvoiceActionId,
      });

      if (updateSingular) {
        dispatch(getHandleInvoice({ invoiceActionId: creditInvoiceActionId }));
      }

      dispatch(getAllInvoices(creditorId));

      successCallback && successCallback();
    } catch (e) {
      const message = e.response?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const sendManualReminderInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
  reminderInvoiceDeliveryMethod,
  reminderFee,
  reminderPaymentTermsInDays,
}) => {
  return async (dispatch) => {
    try {
      // clear current invoice data
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: null,
        },
      });

      const body = {
        DeliveryMethod: reminderInvoiceDeliveryMethod,
        ReminderFee: { Value: (reminderFee || 0) * 100, CurrencyCode: "SEK" },
        PaymentTermsInDays: reminderPaymentTermsInDays
          ? parseInt(reminderPaymentTermsInDays)
          : null,
        InvoiceActionPublicId: invoiceActionId,
      };

      await services.sendManualReminderInvoice({ body });

      // update data
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      dispatch(getAllInvoices(creditorId));

      successCallback && successCallback();
    } catch (e) {
      const message = e?.response?.data?.Message;

      errorCallback && errorCallback(message);
    }
  };
};

export const sendDebtCollectionInvoice = ({
  creditorId,
  invoiceActionId,
  payload,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      // clear current invoice data
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: null,
        },
      });

      await services.sendDebtCollectionInvoice({ body: payload });

      // update data
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      dispatch(getAllInvoices(creditorId));

      successCallback && successCallback();
    } catch (e) {
      const message = e?.response?.data?.Message;

      errorCallback && errorCallback(message);
    }
  };
};

export const cancelDebtCollection = ({
  invoiceActionId,
  creditorId,
  debtCollectionActionId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.cancelDebtCollection(debtCollectionActionId);

      // since we cancel - we don't have to remove the debtcoll - the reference to it will be removed on invoice
      // so we simply recollect the invoice
      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const postponeDebtCollection = ({
  debtCollectionActionId,
  toDate,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.postponeDebtCollection(debtCollectionActionId, toDate);

      // since it's not cancelled - we don't retrieve back the entire invoice, only the debth info
      dispatch(
        getDebtCollectionInfo({ debtCollectionId: debtCollectionActionId })
      );
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const registerInvoicePayment = ({
  invoiceActionId,
  creditorId,
  paymentData,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const postObj = {
        ActionPublicId: invoiceActionId,
        Amount: {
          CurrencyCode: paymentData.currency,
          // ören to sek
          Value: paymentData.value * 100,
        },
        // WriteOff: null,
        // Comment: "Paid 1 SEK",
        Date: paymentData.paidDate,
        // WriteOffVat: 0,
        PaymentMeanCode: paymentData.paymentMeansCode,
        OvershootingAmountHandling: paymentData.overpaymentHandle,
        PaymentReferenceText: paymentData.reference,
      };

      // cleat currenct invoice
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: null,
        },
      });

      await services.registerInvoicePayment({ postObj, invoiceActionId });

      successCallback && successCallback();

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      dispatch(getAllInvoices(creditorId));
    } catch (e) {
      const message = e.response?.data?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const registerDebtHandlingPaymnet = ({
  debtCollectionActionId,
  invoiceActionId,
  creditorId,
  paymentData,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const postObj = {
        ActionPublicId: debtCollectionActionId,
        Value: {
          CurrencyCode: paymentData.currency,
          // ören to sek
          Value: paymentData.value * 100,
        },
        PaymentDate: paymentData.paidDate,
        PaymentMeanCode: paymentData.paymentMeansCode,
        Comment: paymentData.reference,
        IsPaymentCreditation: paymentData.isPaymentCreditation,
        InvoiceInterest: paymentData.invoiceInterest,
      };

      // cleat currenct invoice
      dispatch({
        type: constants.SET_CURRENT_INVOICE,
        payload: {
          invoice: null,
        },
      });

      // also clear the current debthandling action
      dispatch({
        type: constants.SET_CURRENT_DEBT_INVOICE_INFO,
        payload: {
          currentDebtInvoiceInfo: null,
        },
      });

      await services.registerDebtHandlingPaymnet({ postObj, invoiceActionId });

      successCallback && successCallback();

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      dispatch(getAllInvoices(creditorId));
    } catch (e) {
      const message = e.response?.data?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const matchPaymentsToInvoice = ({
  paymentIds,
  invoiceId,
  paymentDate,
  successCallback,
  errorCallback,
  alternativeErrorCallback,
  creditorId,
}) => {
  return async (dispatch) => {
    try {
      const res = await services.matchPaymentsToInvoice({
        paymentIds,
        invoiceId,
        creditorId,
        paymentDate,
      });

      if (res.every((r) => r.Successfull)) {
        successCallback();
      } else {
        alternativeErrorCallback &&
          alternativeErrorCallback(
            "En eller flera betalningar kunde ej matchas. Detta kan bero på t.ex. att negativa belopp ej kan matchas mot fakturor."
          );
      }

      // get all invoices & payments again after update
      dispatch(getAllInvoices(creditorId));
      dispatch(getAllPayments(creditorId));
    } catch (e) {
      const message = e.response?.data?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const deletePayment = ({
  paymentId,
  bookKeepingAccount,
  transactionDate,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const res = await services.deletePayment({
        paymentId,
        bookKeepingAccount,
        transactionDate,
      });

      dispatch(getAllInvoices(creditorId));
      dispatch(getAllPayments(creditorId));

      successCallback && successCallback();
    } catch (e) {
      dispatch(getAllInvoices(creditorId));
      dispatch(getAllPayments(creditorId));

      const message = e.response?.data?.Message;
      errorCallback && errorCallback(message);
    }
  };
};

export const activateAutogiroForInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.activateAutogiroForInvoice(invoiceActionId);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};

export const inactivateAutogiroForInvoice = ({
  invoiceActionId,
  creditorId,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      await services.inactivateAutogiroForInvoice(invoiceActionId);

      // get all invoices again after update
      dispatch(getHandleInvoice({ invoiceActionId, creditorId }));
      successCallback && successCallback();
    } catch (e) {
      errorCallback && errorCallback(e?.response?.data?.Message);
    }
  };
};
