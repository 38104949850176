import * as React from "react";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import { addToast, TOAST_TYPES } from "../../../../../store/toasts";

//store, state
import { constants } from "../../../../../store/roundingErrands";
import {
  buildQueryString,
  setActiveFormInstance,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import {
  useRealEstatePagination,
  performFilter as realestatePerformFilter,
  constants as realEstateConstants,
  useFilteredRealEstates,
} from "../../../../../store/realEstates";
import realestateListDefs from "../../../../Tables/RealEstate/listDefs";

import {
  useBuildingPagination,
  useFilteredBuildings,
  constants as buildingConstants,
  performFilter as buildingPerformFilter,
  useBuildingPaginationCount,
} from "../../../../../store/buildings";
import buildingListDefs from "../../../../Tables/Buildings/listDefs";

import {
  constants as roundingAreaConstants,
  update as updateArea,
} from "../../../../../store/roundingAreas";

import {
  constants as roundingComponentConstants,
  useRoundingComponentsPagination,
  performFilter as componentsPerformFilter,
} from "../../../../../store/roundingComponents";

//components & styling
import { TableSelect, TextField, TextInput } from "../../../Base/Fields";
import Table from "../../../../Billecta/Table/BasicTable";
import { PrimaryButton, TextButton } from "../../../Base/Buttons";
import { InnerBox } from "../../../../sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import SimpleTextFilter from "../../../../Lists/Base/CompleteList/Filters/TextFilter";
import { InfoBox, ToolTipCell } from "../../../../Displays";
import StandardModal from "../../../../Modals/StandardModal";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import BuildingTable from "src/components/Tables/Buildings/FullTable";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import RoundingComponentsTable from "src/components/Tables/RoundingComponents/FullTable";
import RealEstateTable from "src/components/Tables/RealEstate/FullTable";

const componentsListDefs = () => [
  {
    Header: "Komponent",
    accessor: "str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
];

export default function Faults({ currentArea, doneFunction }) {
  const dispatch = useDispatch();
  const method = "PATCH";
  const storeName = constants.STORE_NAME;

  //The selected area from the areatable
  const [pickedArea, setPickedArea] = React.useState(null);
  const _visited = useFormInstanceField({ storeName, fieldKey: "_visited" });

  React.useEffect(() => {
    setPickedArea(currentArea);
  }, [currentArea]);

  React.useEffect(() => {
    const visitedClone = cloneDeep(_visited || []);
    if (!visitedClone?.includes(currentArea?.id)) {
      visitedClone.push(currentArea?.id);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { _visited: visitedClone },
        })
      );
    }
  }, [currentArea]);
  //For when creating a new component
  const compFieldKey = `_newComp`;
  const compInstance = useFormInstanceField({
    storeName,
    fieldKey: compFieldKey,
  });
  const [updateAreaLoading, setUpdateAreaLoading] = React.useState(false);

  const [handleFaultIndex, setHandleFaultIndex] = React.useState(null);
  const [createNewCompOpen, setCreateNewCompOpen] = React.useState(false);

  const fault = useFormInstanceField({
    storeName,
    fieldKey: `faults[${handleFaultIndex}]`,
  });

  let componentsQuery = {};
  let compIds = [];
  if (pickedArea) {
    compIds = pickedArea?.components?.map((c) => c.id);
    componentsQuery = { id__id: compIds };
  }

  const topRef = React.useRef();

  const faults =
    useFormInstanceField({
      storeName,
      fieldKey: "faults",
    }) || [];

  const goToTop = () => {
    setTimeout(() => {
      topRef.current &&
        topRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 150);
  };

  let realEstateQuery = {};
  let realEstateIds = [];
  if (pickedArea) {
    realEstateIds = pickedArea?.realestates?.map((c) => c.id);
    realEstateQuery = { id__in: realEstateIds };
  }

  const persistantFilterMethodRealestate = (obj) => {
    return realEstateIds?.includes(obj?.id);
  };
  const [realEstates] = useFilteredRealEstates(
    buildQueryString(realEstateQuery)
  );

  const buildingQuery = {
    realestate__ids: realEstateIds,
  };
  const [buildingCount] = useBuildingPaginationCount(
    buildQueryString(buildingQuery)
  );
  const [buildings] = useFilteredBuildings(buildQueryString(buildingQuery));

  const tableFaults = React.useMemo(() => {
    const compIds = currentArea?.components?.map((c) => c.id);
    return (
      faults.filter((f) => compIds.includes(f.rounding_component?.id)) || []
    );
  }, [faults, currentArea]);

  const tableColumns = React.useMemo(
    () => [
      {
        Header: "Komponent",
        accessor: "rounding_component",
        Cell: (props) => {
          return <div>{props?.value?.str_representation}</div>;
        },
      },
      {
        Header: "Avvikelse",
        accessor: "title",
        Cell: (props) => {
          return <div>{props?.value}</div>;
        },
      },
      {
        Header: "Beskrivning",
        accessor: "description",
        Cell: (props) => {
          const shortnedText = props?.value?.substring(0, 12);
          if (shortnedText) {
            return <div>{shortnedText}...</div>;
          } else {
            return <div> - </div>;
          }
        },
      },
      {
        Header: "Byggnad",
        accessor: "building",
        Cell: (props) => {
          return <div>{props?.value?.str_representation || "-"}</div>;
        },
      },
      {
        Header: "Fastighet",
        accessor: "realestate",
        Cell: (props) => {
          return <div>{props?.value?.str_representation}</div>;
        },
      },
    ],
    []
  );

  const handleComponentPicked = (obj) => {
    if (obj?.building) {
      const match = buildings.find((b) => b.id === obj?.building?.id);
      if (match) {
        dispatch(
          updateActiveFormInstance({
            storeName,
            data: {
              [`faults[${handleFaultIndex}].building`]: match,
              [`faults[${handleFaultIndex}].realestate`]: match?.realestate,
            },
          })
        );
      }
    }
  };

  const handleAddFaults = () => {
    const faultsCopy = [...(faults || [])];
    const newIndex = faultsCopy.length;
    faultsCopy.push({});
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          faults: faultsCopy,
        },
      })
    );

    setHandleFaultIndex(newIndex);
  };

  const handleEditFault = (row) => {
    setHandleFaultIndex(row.index);
  };

  const handleRemoveFault = (index) => {
    const faultsCopy = cloneDeep(faults);
    faultsCopy.splice(index, 1);

    setHandleFaultIndex(null);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          faults: faultsCopy,
        },
      })
    );

    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden togs bort",
      })
    );

    goToTop();
  };

  const handleEditFaultDone = () => {
    dispatch(
      addToast({
        type: TOAST_TYPES.INFO,
        title: "Raden uppdaterades",
      })
    );
    setHandleFaultIndex(null);

    goToTop();
  };

  const onSuccessArea = (data, _returnedData) => {
    const createdComp = _returnedData?.components?.find(
      (c) => c.title === compInstance.title
    );
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`faults[${handleFaultIndex}].rounding_component`]: createdComp,
        },
      })
    );
    dispatch(
      setActiveFormInstance({
        storeName: roundingAreaConstants.STORE_NAME,
        data: undefined,
      })
    );
    handleComponentPicked(createdComp);
    setUpdateAreaLoading(false);
    setCreateNewCompOpen(false);
  };

  const onErrorArea = (data, returnedData) => {
    setUpdateAreaLoading(false);
  };

  const handleCreateNewComp = () => {
    const compsClone = cloneDeep(pickedArea?.components);
    if (!compInstance?.id) compsClone.push(compInstance);
    dispatch(
      setActiveFormInstance({
        storeName: roundingAreaConstants.STORE_NAME,
        data: {
          id: pickedArea.id,
          components: compsClone,
        },
      })
    );
    dispatch(
      updateArea({
        id: pickedArea?.id,
        successCallback: onSuccessArea,
        errorCallback: onErrorArea,
      })
    );
    setUpdateAreaLoading(true);
  };

  const handleCancelNewComp = () => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          _newComp: undefined,
        },
      })
    );
    setCreateNewCompOpen(false);
  };

  if (handleFaultIndex != null) {
    return (
      <>
        <StandardModal
          isOpen={createNewCompOpen}
          closeFunction={() => handleCancelNewComp()}
          saveFunction={() => handleCreateNewComp()}
          withActionBar
          actionBarAcceptTitle="Skapa komponent"
          actionBarCancelTitle="Avbryt"
        >
          {updateAreaLoading && <OverlaySpinner />}
          <InfoBox
            text={`När du skapar en ny komponent blir den tillgänglig i hela systemet likt de redan existerande komponenterna, om du skapar och går bakåt så finns den du precis skapade tillgänglig i listan över befintliga komponenter. `}
          />
          <TextInput
            title="Namn på komponent"
            storeName={storeName}
            method={method}
            fieldKey={`[${compFieldKey}].title`}
            instructionsKey={`faults.title`}
          />

          <TextField
            title="Beskrivning av komponent"
            storeName={storeName}
            method={method}
            fieldKey={`[${compFieldKey}].description`}
            instructionsKey={`faults.description`}
          />
          {buildingCount > 0 ? (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectField
                storeName={storeName}
                persistantQuery={buildingQuery}
                placeholder="Välj byggnad..."
                title="Byggnad där komponenten finns"
                method={method}
                forceRender
                fieldKey={`[${compFieldKey}].building`}
                TableComponent={BuildingTable}
              />
            </div>
          ) : (
            <InfoBox
              title="Obs"
              text="Områdets fastighet/er saknar byggnader att välja, därav väljs fastighet efter skapandet av komponent."
            />
          )}
        </StandardModal>
        <OverviewTitleWrapper>
          <OverviewTitle small>
            Hantera rad {faults[handleFaultIndex]?.title || ""}
          </OverviewTitle>
        </OverviewTitleWrapper>

        <TextInput
          title="Rubrik för avvikelse"
          storeName={storeName}
          method={method}
          fieldKey={`faults.[${handleFaultIndex}].title`}
          instructionsKey={`faults.title`}
        />

        <TextField
          title="Beskrivning av avvikelse"
          storeName={storeName}
          method={method}
          fieldKey={`faults.[${handleFaultIndex}].description`}
          instructionsKey={`faults.description`}
        />

        <div style={{ display: "flex" }}>
          {pickedArea?.components?.length > 0 && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectField
                storeName={storeName}
                persistantQuery={componentsQuery}
                placeholder="Välj komponent..."
                title="Komponent"
                method={method}
                fieldKey={`faults.[${handleFaultIndex}].rounding_component`}
                instructionsKey={"faults.rounding_component"}
                changeCallback={(obj) => handleComponentPicked(obj)}
                TableComponent={RoundingComponentsTable}
              />
            </div>
          )}

          {pickedArea && !fault?.rounding_component && (
            <TextButton
              title="Skapa ny komponent"
              extraStyle={{ marginLeft: 32 }}
              iconType={"add"}
              iconPlacement="right"
              clicked={() => setCreateNewCompOpen(true)}
            />
          )}
        </div>
        {fault?.rounding_component && (
          <>
            {pickedArea && fault?.rounding_component && !fault?.realestate ? (
              <div className="grid grid-cols-2 gap-6 mb-6">
                <TableSelectField
                  storeName={storeName}
                  persistantQuery={realEstateQuery}
                  title="Välj fastighet..."
                  fieldTitle="Fastighet"
                  method={method}
                  fieldKey={`faults.[${handleFaultIndex}].realestate`}
                  instructionsKey="faults.realestate"
                  TableComponent={RealEstateTable}
                />
              </div>
            ) : (
              <div>
                {fault?.building ? (
                  <>Fastighet: {fault?.realestate?.str_representation}</>
                ) : (
                  <div className="grid grid-cols-2 gap-6 mb-6">
                    <TableSelectField
                      storeName={storeName}
                      persistantQuery={realEstateQuery}
                      placeholder="Välj fastighet..."
                      title="Fastighet"
                      method={method}
                      fieldKey={`faults.[${handleFaultIndex}].realestate`}
                      instructionsKey="faults.realestate"
                      TableComponent={RealEstateTable}
                    />
                  </div>
                )}
                <br />
                Byggnad:{" "}
                {fault?.building?.str_representation ||
                  "Komponenten är inte associerad med en byggnad"}
              </div>
            )}
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextButton
            title="Ta bort rad"
            red
            clicked={() => handleRemoveFault(handleFaultIndex)}
          />
          <PrimaryButton title="Klar" clicked={handleEditFaultDone} />
        </div>
      </>
    );
  }

  return (
    <>
      <TextButton
        title="Tillbaka till områden"
        iconType="arrow-back"
        extraStyle={{ marginBottom: 12 }}
        clicked={() => doneFunction(null)}
      />
      <OverviewTitleWrapper style={{ scrollMargin: 30 }} ref={topRef}>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>
            Avvikelser på område {currentArea?.str_representation}
          </OverviewTitle>
          <OverviewSubtitle>
            Tryck på en rad för att antingen redigera eller ta bort den.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
        <PrimaryButton title="Lägg till rad" clicked={handleAddFaults} />
      </OverviewTitleWrapper>
      {tableFaults?.length > 0 ? (
        <>
          <Table
            onRowClicked={handleEditFault}
            columns={tableColumns}
            data={tableFaults}
            hideSearch
            tableId="faultstable"
            withPersistantSortBy
            withPersistantGlobalFilter
          />
        </>
      ) : (
        <InnerBox
          style={{
            minHeight: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Inga avvikelser är tillagda än
          <div style={{ marginTop: 24 }}>
            <PrimaryButton title="Lägg till rad" clicked={handleAddFaults} />
          </div>
        </InnerBox>
      )}
    </>
  );
}
