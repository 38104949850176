import { cloneDeep } from "lodash";
import { buildQueryString } from "../base";
import { axiosInstance } from "../base/store/axios";
import constants from "./store/constants";

import { constants as productConstants } from "../invoicingProducts";
import { constants as costCenterConstants } from "../invoicingCostCenters";
import { constants as projectConstants } from "../invoicingProjects";
import { cleanPutInvoice } from "../billecta/utils";
import moment from "moment";

export const overviewUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}`;

export const createUrl = ({ creditorId, query }) =>
  `/rental-notifications/company/${creditorId}/invoice/create${
    query ? `?${query}` : ""
  }`;

export const editUrl = ({ creditorId, invoiceId }) =>
  `/rental-notifications/company/${creditorId}/invoice/${invoiceId}/edit`;

export const creditUrl = ({ creditorId, invoiceId, query }) =>
  `/rental-notifications/company/${creditorId}/invoice/${invoiceId}/credit${
    query ? `?${query}` : ""
  }`;

export const creditorDetailUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}`;

export const creditorInvoiceDetailUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/invoices`;

export const creditorPaymentsDetailUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/payments`;

export const createManyInvoiceUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/create-multiple-invoices`;

export const createTripleInvoiceUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/create-triple-invoices`;

export const createManyContractInvoiceUrl = ({ creditorId }) =>
  `/rental-notifications/company/${creditorId}/create-multiple-contract-invoices`;

export const invoiceDetailUrl = ({ invoiceId, creditorId }) =>
  `/rental-notifications/company/${creditorId}/invoice/${invoiceId}`;

export const billectaStateMap = {
  None: "Okänd",
  Created: "Ej attesterad",
  ReminderInvoiceSent: "Påminnelse",
  Manual: "Förfallen (Ej hanterad)",
  Completed: "Slutbetald",
  Cancelled: "Avbruten",
  InvoiceSent: "Skickad",
  Attested: "Attesterad",
  CreditInvoice: "Kreditfaktura",
  SentToDebtCollection: "Kravhantering",
  SalesRequested: "SalesRequested",
  SaleRequestAccepted: "SaleRequestAccepted",
  SalesRequestedCancelled: "SalesRequestedCancelled",
  SalesRequestedDenied: "SalesRequestedDenied",
};

export const BILLECTA_ACTIONS = {
  ACTION_UPDATED: 0,
  ACTION_DEBIT_INTERVAL: 1,
  ACTION_CREATED: 2,
  ACTION_SIGNED: 3,
  ACTION_CANCELLED: 4,
};

export const INTERVAL_MAPPER = {
  Monthly: "Månadsvis",
  Yearly: "Årsvis",
  Quarterly: "Kvartalsvis",
};

export const MONTH_MAPPER = {
  1: "Januari",
  2: "Februari",
  3: "Mars",
  4: "April",
  5: "Maj",
  6: "Juni",
  7: "Juli",
  8: "Augusti",
  9: "September",
  10: "Oktober",
  11: "November",
  12: "December",
};

export const DELIVERY_METHOD_MAPPER = {
  Email: "Email (0 kr/st)",
  Kivra: "Kivra (4 kr/st)",
  EInvoice: "E-faktura (2.50 kr/st exkl. bankkostnader)",
  Mail: "Post (6.50 kr/st)",
  Manually: "Skicka ej",
};

export const DELIVERY_METHOD_MAPPER_EXCL_PRICES = {
  Email: "Email",
  Kivra: "Kivra",
  EInvoice: "E-faktura",
  Mail: "Post",
  Manually: "Skicka ej",
};

export const INVOICE_TYPES = {
  LEASE: "LEASE",
  PARKING: "PARKING",
  OTHER: "OTHER",
};

// expects an invoice with the pigello format, achieved by calling convertInvoiceToPigello
export const prepareInvoiceClone = ({ formattedInvoice, newInvoiceDate }) => {
  const clone = cloneDeep(formattedInvoice);

  const initialDaysDiff = moment(formattedInvoice.DueDate).diff(
    moment(formattedInvoice.InvoiceDate)
  );

  const newDueDate = moment(newInvoiceDate)
    .add(initialDaysDiff)
    .format("YYYY-MM-DD");

  clone.InvoiceDate = newInvoiceDate;
  clone.DueDate = newDueDate;

  delete clone.DebtorPublicId;
  delete clone.YourReference;
  delete clone.InvoicePDF;
  delete clone.InvoiceSendDateOverride;
  delete clone.PaymentOverride;
  delete clone.ReminderInvoiceDetails;
  delete clone.DeliveryAddressOverride;
  delete clone.InvoiceNumber;
  delete clone.CommunicationLanguage;
  delete clone.DeliveryDate;
  delete clone.IsLocked;
  delete clone.CreditCard;

  return clone;
};

// replace products, costcenters and projects to pigello internals
export const convertInvoiceToPigello = async ({ invoice, isDisplay }) => {
  let clone;
  if (!isDisplay) {
    clone = cleanPutInvoice(invoice);
  } else {
    clone = cloneDeep(invoice);
  }

  if (clone?.Records?.length > 0) {
    const productIdsToEnsure = [];
    const costCenterIdsToEnsure = [];
    const projectIdsToEnsure = [];

    let ensuredProductIds = [];
    let ensuredCostCenterIds = [];
    let ensuredProjectIds = [];

    for (let i = 0; i < clone.Records.length; i++) {
      const cur = clone.Records[i];

      if (
        cur.ProductPublicId &&
        !productIdsToEnsure.includes(cur.ProductPublicId)
      ) {
        productIdsToEnsure.push(cur.ProductPublicId);
      }

      if (cur.CostCenter && !costCenterIdsToEnsure.includes(cur.CostCenter)) {
        costCenterIdsToEnsure.push(cur.CostCenter);
      }

      if (cur.Project && !projectIdsToEnsure.includes(cur.Project)) {
        projectIdsToEnsure.push(cur.Project);
      }
    }

    // fetch ensured id
    if (productIdsToEnsure.length > 0) {
      const productQ = buildQueryString({
        billecta_object_ids: productIdsToEnsure,
      });

      const { data } = await axiosInstance.get(
        `${productConstants.LIST_URL}?${productQ}`
      );

      ensuredProductIds = data;
    }

    if (costCenterIdsToEnsure.length > 0) {
      const costCenterQ = buildQueryString({
        billecta_object_ids: costCenterIdsToEnsure,
      });
      const { data } = await axiosInstance.get(
        `${costCenterConstants.LIST_URL}?${costCenterQ}`
      );

      ensuredCostCenterIds = data;
    }

    if (projectIdsToEnsure.length > 0) {
      const projectQ = buildQueryString({
        billecta_object_ids: projectIdsToEnsure,
      });

      const { data } = await axiosInstance.get(
        `${projectConstants.LIST_URL}?${projectQ}`
      );

      ensuredProjectIds = data;
    }

    for (let i = 0; i < clone.Records.length; i++) {
      const cur = clone.Records[i];

      if (cur.ProductPublicId) {
        const product = ensuredProductIds.find((p) => {
          if (
            p.billecta_ids.find((bid) => bid.object_id === cur.ProductPublicId)
          ) {
            return true;
          }
          return false;
        });

        cur.ProductPublicId = product;
      }

      if (cur.CostCenter) {
        const costCenter = ensuredCostCenterIds.find((p) => {
          if (p.billecta_ids.find((bid) => bid.object_id === cur.CostCenter)) {
            return true;
          }
          return false;
        });

        cur.CostCenter = costCenter;
      }

      if (cur.Project) {
        const project = ensuredProjectIds.find((p) => {
          if (p.billecta_ids.find((bid) => bid.object_id === cur.Project)) {
            return true;
          }
          return false;
        });

        cur.Project = project;
      }
    }
  }

  return clone;
};

// replace internal products, costcenters and projects with billecta ids.
// ensured ids forces backend to create the objects in the correct billecta
// company to avoid errors
export const convertInvoiceToBillecta = async ({
  invoiceData,
  companyId,
  isUpdate,
  isCredit,
}) => {
  const clone = cloneDeep(invoiceData);

  // fix value for InvoiceFee
  if (clone?.InvoiceFee?.Value) {
    clone.InvoiceFee.Value = clone.InvoiceFee.Value * 100;
  }

  // ensure debtor id
  if (!isUpdate && !isCredit) {
    const { data } = await axiosInstance.patch(
      `${constants.ENSURE_DEBTOR_IDS_URL}${companyId}/`,
      {
        ids: [clone.DebtorPublicId],
      }
    );
    clone.DebtorPublicId = data[clone.DebtorPublicId];
  }

  // fix records, values and billecta ids
  if (clone?.Records?.length > 0) {
    const productIdsToEnsure = [];
    const costCenterIdsToEnsure = [];
    const projectIdsToEnsure = [];

    let ensuredProductIds = [];
    let ensuredCostCenterIds = [];
    let ensuredProjectIds = [];

    // gather ids to ensure
    for (let i = 0; i < clone.Records.length; i++) {
      const cur = clone.Records[i];

      if (cur.RecordType != "Message") {
        if (
          cur.ProductPublicId?.id &&
          !productIdsToEnsure.includes(cur.ProductPublicId.id)
        ) {
          productIdsToEnsure.push(cur.ProductPublicId.id);
        }

        if (
          cur.CostCenter?.id &&
          !costCenterIdsToEnsure.includes(cur.CostCenter.id)
        ) {
          costCenterIdsToEnsure.push(cur.CostCenter.id);
        }

        if (cur.Project?.id && !projectIdsToEnsure.includes(cur.Project.id)) {
          projectIdsToEnsure.push(cur.Project.id);
        }
      }
    }

    // fetch ensured id
    if (productIdsToEnsure.length > 0) {
      const { data } = await axiosInstance.patch(
        `${constants.ENSURE_PRODUCT_IDS_URL}${companyId}/`,
        {
          ids: productIdsToEnsure,
        }
      );

      ensuredProductIds = data;
    }
    if (costCenterIdsToEnsure.length > 0) {
      const { data } = await axiosInstance.patch(
        `${constants.ENSURE_COSTCENTER_IDS_URL}${companyId}/`,
        {
          ids: costCenterIdsToEnsure,
        }
      );

      ensuredCostCenterIds = data;
    }
    if (projectIdsToEnsure.length > 0) {
      const { data } = await axiosInstance.patch(
        `${constants.ENSURE_PROJECT_IDS_URL}${companyId}/`,
        {
          ids: projectIdsToEnsure,
        }
      );

      ensuredProjectIds = data;
    }

    // fix values and insert correct ids
    for (let i = 0; i < clone.Records.length; i++) {
      const cur = clone.Records[i];

      // set order
      cur.SequenceNo = i;

      // if is message, nothing needs to be done
      if (cur.RecordType != "Message") {
        cur.UnitPrice.Value = cur.UnitPrice.Value * 100;

        if (
          cur.ProductPublicId?.id &&
          ensuredProductIds[cur.ProductPublicId.id]
        ) {
          cur.ProductPublicId = ensuredProductIds[cur.ProductPublicId.id];
        }

        if (cur.CostCenter?.id && ensuredCostCenterIds[cur.CostCenter.id]) {
          cur.CostCenter = ensuredCostCenterIds[cur.CostCenter.id];
        } else {
          delete cur.CostCenter;
        }

        if (cur.Project?.id && ensuredProjectIds[cur.Project.id]) {
          cur.Project = ensuredProjectIds[cur.Project.id];
        } else {
          delete cur.Project;
        }
      }
    }
  }

  // clear debt collection if not activated just in case
  if (!clone.DebtCollectionDetails?.SendToDebtCollection) {
    delete clone.DebtCollectionDetails;
  }

  // clear interest in not activated
  if (!clone.InterestStartInDaysAfterDueDate) {
    delete clone.InterestPercentage;
    delete clone.InterestStartInDaysAfterDueDate;
  }

  // handle attachments
  if (clone.Attachments) {
    clone.Attachments = clone.Attachments.map((a) => {
      // existing billecta attachment
      if (a.File && !a._tempData) return a;

      // new attachment
      return {
        File: {
          content_type: a._tempData.data.split(",")[0],
          data: a._tempData.data.split(",")[1],
          file_name: a._tempData.file_name,
        },
      };
    });
  }

  return clone;
};
