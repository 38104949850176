import * as React from "react";
import { RadioGroup } from "../../../../Forms/Base/Fields";
import StandardModal from "../../../../Modals/StandardModal";
import { toMoneyString } from "../../../../utils/stringUtils";
import DetailInfo from "../../DetailInfo/DetailInfo";

import { TextButton } from "../../../../Forms/Base/Buttons";
import { updateActiveFormInstance } from "../../../../../store/base";
import { useDispatch } from "react-redux";
import TableSelectField from "src/components/Forms/Base/Fields/TableSelectField";
import ErrandComponentPlacementTable from "src/components/Tables/ErrandComponents/FullTable";
import ErrandArticlesTable from "src/components/Tables/ErrandArticles/FullTable";
import TextInputField from "src/components/Forms/Base/Fields/TextInputField";
import SelectField from "src/components/Forms/Base/Fields/SelectField";

export default function HandleErrandCost({
  costs,
  handleCostIndex,
  componentIds, // select component for cost for inspection errand
  closeFunction,
  method,
  storeName,
  onUpdate,
  onRemove,
  modalInModal,
}) {
  const dispatch = useDispatch();
  const currentCost = costs?.[handleCostIndex];

  const [editActive, setEditActive] = React.useState(false);

  // Set new costs to edit
  React.useEffect(() => {
    if (
      handleCostIndex != null &&
      currentCost &&
      (currentCost?.unit_cost == null || !currentCost?.title)
    ) {
      setEditActive(true);
    }
  }, [handleCostIndex, currentCost]);

  const infoObj = {
    Detaljer: [
      {
        title: "Beskrivning",
        value: currentCost?.title,
      },
      {
        title: "Artikel",
        value:
          currentCost?.article?.str_representation ||
          currentCost?.article?.title ||
          "-",
      },
      {
        title: "Gäller komponent",
        value: currentCost?.component_placement?.str_representation || "-",
      },
      {
        title: "Antal",
        value: currentCost?.unit_amount,
      },
      {
        title: "Kostnad/enhet",
        value: toMoneyString(currentCost?.unit_cost),
      },
    ],
  };

  const handleArticleSelected = (article) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          [`costs[${handleCostIndex}].title`]: article.title,
          [`costs[${handleCostIndex}].unit_amount`]:
            article.standard_unit_amount,
          [`costs[${handleCostIndex}].unit_cost`]: article.cost_per_unit,
          [`costs[${handleCostIndex}].vat`]: 25,
        },
      })
    );
  };

  return (
    <StandardModal
      isOpen={handleCostIndex !== null}
      closeFunction={editActive ? () => setEditActive(false) : closeFunction}
      title={`${editActive ? "Redigera" : "Hantera"} kostnad`}
      withActionBar
      actionBarAcceptTitle={editActive ? "Klar" : "Redigera"}
      actionBarCancelTitle={editActive ? "Tillbaka" : "Stäng"}
      saveFunction={editActive ? onUpdate : () => setEditActive(true)}
      modalInModal={modalInModal}
    >
      {!editActive ? (
        <>
          <DetailInfo infoObj={infoObj} />

          <TextButton
            title="Radera rad"
            clicked={onRemove}
            red
            iconType="close"
            iconPlacement="right"
            extraStyle={{ marginTop: 24 }}
          />
        </>
      ) : (
        <>
          {componentIds?.length > 0 && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <TableSelectField
                storeName={storeName}
                persistantQuery={{
                  id__in: componentIds || [],
                }}
                placeholder="Välj komponent..."
                title="Gäller komponent"
                method={method}
                fieldKey={`costs[${handleCostIndex}].component_placement`}
                instructionsKey={"costs.component_placement"}
                TableComponent={ErrandComponentPlacementTable}
              />
            </div>
          )}

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TableSelectField
              storeName={storeName}
              placeholder="Välj artikel..."
              title="Artikel"
              method={method}
              fieldKey={`costs[${handleCostIndex}].article`}
              instructionsKey={"costs.article"}
              changeCallback={handleArticleSelected}
              TableComponent={ErrandArticlesTable}
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              title="Beskrivning"
              storeName={storeName}
              method={method}
              fieldKey={`costs[${handleCostIndex}].title`}
              instructionsKey="costs.title"
            />

            <TextInputField
              isNumber
              {...{ method, storeName }}
              fieldKey={`costs[${handleCostIndex}].unit_amount`}
              instructionsKey="costs.unit_amount"
              title="Antal enheter"
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            <TextInputField
              isNumber
              step=".01"
              {...{ method, storeName }}
              fieldKey={`costs[${handleCostIndex}].unit_cost`}
              instructionsKey="costs.unit_cost"
              title="Kostnad/enhet (SEK)"
              description="Värde på kostnaden per enhet i SEK."
            />

            <SelectField
              title="Momssats"
              description="Momssats som ska appliceras på kostnaden."
              storeName={storeName}
              method={method}
              fieldKey={`costs[${handleCostIndex}].vat`}
              instructionsKey="costs.vat"
            />
          </div>

          <RadioGroup
            title="Ansvarig för kostnad"
            description="Ange vilken part som ska betala kostnaden"
            storeName={storeName}
            method={method}
            options={[
              { label: "Hyresgäst", value: 0 },
              { label: "Hyresvärd", value: 1 },
              { label: "Annan", value: 2 },
            ]}
            fieldKey={`costs[${handleCostIndex}].liable`}
            instructionsKey="costs.liable"
          />
        </>
      )}
    </StandardModal>
  );
}
