import * as React from "react";
import { ToolTipCell } from "../../Displays";

export default () => [
  {
    Header: "Område",
    accessor: "str_representation",
    Cell: ({ value }) => <ToolTipCell text={value} />,
  },
];
