import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { cloneDeep } from "lodash";

import { useRoundingErrandSetting } from "../../../store/roundingErrandSettings";
import {
  buildQueryString,
  updateActiveFormInstance,
} from "../../../store/base";
import {
  constants,
  create,
  useRoundingErrandsPaginationCount,
  detailUrl,
} from "../../../store/roundingErrands";
import useActiveFilters from "../../../hooks/useActiveFilters";

//components & styles
import ErrandsTable from "../../../components/Tables/RoundingErrands/FullTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import Primary from "../../../components/Forms/Base/Buttons/Primary";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";

function RoundingErrand() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { settingId } = useParams();
  const { filteredRealEstates } = useActiveFilters();

  const [roundingErrandSetting, roundingErrandSettingLoading] =
    useRoundingErrandSetting(settingId);

  const { id } = useSelector((state) => state.app?.user);
  const { push } = useHistory();

  const [createErrandLoading, setCreateErrandLoading] = React.useState(false);

  const generateNextErrand = () => {
    const currentTime = moment().format("YYYY-MM-DD HH:mm");
    const plannedStart = `${roundingErrandSetting?.next_recurrence} 00:00:00`;
    const newErrand = {
      setting: { id: roundingErrandSetting?.id },
      title: roundingErrandSetting?.str_representation,
      created_at: currentTime,
      reported_by: { id: id },
      planned_start: plannedStart,
    };

    dispatch(updateActiveFormInstance({ storeName, data: newErrand }));
    onSubmit();
  };

  const checkout = () => {
    dispatch(updateActiveFormInstance({ storeName, data: undefined }));
  };

  const onSuccess = (data, returned) => {
    setCreateErrandLoading(false);

    if (returned?.id) {
      push(detailUrl({ id: returned.id, isGov: false }));
    }
    checkout();
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setCreateErrandLoading(true);

    dispatch(
      create({
        successCallback: onSuccess,
        errorCallback: () => setCreateErrandLoading(false),
        preProcess,
      })
    );
  };

  const errandQuery = {
    setting: settingId,
  };

  const initialOrderBy = ["-planned_start"];

  return (
    <DetailInnerWrapper>
      {createErrandLoading && <OverlaySpinner />}
      <DetailPageBox>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 12,
          }}
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle style={{ marginBottom: 8 }}>Ärenden</OverviewTitle>

              {/*If autogen is off, we hide info regarding next errand*/}
              {!roundingErrandSetting?.paused && (
                <OverviewSubtitle>
                  Nästa autogenererade ärende skapas{" "}
                  <StatusLabel state={1}>
                    {roundingErrandSetting?.next_recurrence}
                  </StatusLabel>
                </OverviewSubtitle>
              )}
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          {/*If autogen is off, we do not give option to generate next */}
          {!roundingErrandSetting?.paused && (
            <Primary
              title="Generera nästkommande nu"
              clicked={() => {
                generateNextErrand();
              }}
            />
          )}
        </div>
        <ErrandsTable
          initialOrderBy={initialOrderBy}
          persistantQuery={errandQuery}
        />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default RoundingErrand;
