import * as React from "react";
import { useApartment } from "../../../../../store/apartments";
import { useBrfPremises } from "../../../../../store/brfPremises";
import { useCommonArea } from "../../../../../store/commonAreas";
import { useErrandComponent } from "../../../../../store/errandComponents";
import { useIndustrialPremises } from "../../../../../store/industrialPremises";
import { useParkingSpot } from "../../../../../store/parkingSpots";
import { getPremisesDetailUrlFromRoom } from "../../../../../views/ErrandSettings/ComponentDetail";
import DetailInfo from "../../../../Details/OverviewInfo/DetailInfo/DetailInfo";
import { DateCell, LinkedObject } from "../../../../Displays";
import { InnerBox } from "../../../../sharedStyles";
import { getCurrentProductTypeUsage } from "../../../../TechnicalManagement/utils";
import { detailUrl as componentTypeDetailUrl } from "../../../../../store/componentTypes";
import { detailUrl as productTypeDetailUrl } from "../../../../../store/productTypes";

export default function SelectedComponent({ componentId }) {
  const [component] = useErrandComponent(componentId);
  const room = component?.room;
  const currentProductUsage = getCurrentProductTypeUsage(component);
  const productType = currentProductUsage?.product_type;
  const componentType = productType?.component_type;
  const premisesDetailUrl = getPremisesDetailUrlFromRoom(
    room,
    component?.parking_spot
  );

  const [apartment] = useApartment(room?.apartment?.id);
  const [indp] = useIndustrialPremises(room?.industrial_premises?.id);
  const [commonArea] = useCommonArea(room?.common_area?.id);
  const [brfPremis] = useBrfPremises(room?.brf_premis?.id);
  const [parkingSpot] = useParkingSpot(component?.parking_spot?.id);

  const premises = apartment || indp || commonArea || brfPremis || parkingSpot;

  return (
    <InnerBox>
      <DetailInfo
        infoObj={getInfoObj({
          component,
          componentType,
          currentProductUsage,
          productType,
          premisesDetailUrl,
          componentType,
          premises,
        })}
      />
    </InnerBox>
  );
}

function getInfoObj({
  componentType,
  productType,
  component,
  premisesDetailUrl,
  currentProductUsage,
  premises,
}) {
  return {
    Komponent: [
      {
        title: "Komponenttyp",
        value: (
          <LinkedObject
            obj={componentType}
            urlMethod={componentTypeDetailUrl}
          />
        ),
      },
      {
        title: "Komponentmodell",
        value: (
          <LinkedObject obj={productType} urlMethod={productTypeDetailUrl} />
        ),
      },
      {
        title: "Placerad i",
        value: (
          <LinkedObject
            obj={{
              str_representation: `${
                component?.room?.room_id
                  ? component.room.room_id
                  : component?.parking_spot
                  ? "Fordonsplats"
                  : "-"
              } ${component?.room ? "i" : ""} ${
                premises?.str_representation || "-"
              }`,
              id: "null",
            }}
            urlMethod={() => premisesDetailUrl}
          />
        ),
      },
      {
        title: "Installerades",
        value: <DateCell date={currentProductUsage?.from_date} />,
      },
    ],
  };
}
