import * as React from "react";
import { constants } from "../../../../../store/roundingErrands";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";

import { TextInput, TimePicker } from "../../../Base/Fields";
import ReportedBySelection from "../../../ErrandBase/ReportedBySelection";

export default ({ method, apartmentId, indpId }) => {
  const storeName = constants.STORE_NAME;

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Detaljer kring ronderingen</OverviewTitle>
          <OverviewSubtitle>
            Fyll i titel, datum då den manuella begäran gjordes samt vem som
            initierat ronderingen
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      <hr />

      <TextInput
        storeName={storeName}
        fieldKey={"title"}
        method={method}
        title="Titel"
      />

      <TimePicker
        storeName={storeName}
        method={method}
        fieldKey={"created_at"}
        defaultNow={true}
        title="Initierat den"
      />

      <ReportedBySelection
        method={method}
        storeName={storeName}
        apartmentId={apartmentId}
        indpId={indpId}
        hideTenantOption
        title="Initierat av"
      />
    </>
  );
};
