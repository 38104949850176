import * as React from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { cloneDeep } from "lodash";

import { useRoundingErrandSetting } from "../../../store/roundingErrandSettings";
import { updateActiveFormInstance } from "../../../store/base";
import {
  constants,
  create,
  useRoundingErrandsPaginationCount,
  detailUrl,
} from "../../../store/roundingErrands";
import useActiveFilters from "../../../hooks/useActiveFilters";

//components & styles
import ErrandsTable from "../../../components/Tables/GovErrands/FullTable";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import Primary from "../../../components/Forms/Base/Buttons/Primary";

import {
  DetailInnerWrapper,
  DetailPageBox,
} from "../../../components/sharedStyles";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";

const PERSISTANT_CATEGORY_KEY = "persistant_category_goverrands";

function GovErrand() {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { settingId } = useParams();
  const { filteredRealEstates } = useActiveFilters();

  const [govErrandSetting, govErrandSettingLoading] =
    useRoundingErrandSetting(settingId);

  const { id } = useSelector((state) => state.app?.user);
  const { push } = useHistory();

  const [createErrandLoading, setCreateErrandLoading] = React.useState(false);

  const generateNextErrand = () => {
    const currentTime = moment().format("YYYY-MM-DD HH:mm");
    const plannedStart = `${govErrandSetting?.next_recurrence} 00:00:00`;
    const newErrand = {
      setting: { id: govErrandSetting?.id },
      title: govErrandSetting?.str_representation,
      created_at: currentTime,
      reported_by: { id: id },
      planned_start: plannedStart,
    };

    dispatch(updateActiveFormInstance({ storeName, data: newErrand }));
    onSubmit();
  };

  const checkout = () => {
    dispatch(updateActiveFormInstance({ storeName, data: undefined }));
  };

  const onSuccess = (data, returned) => {
    setCreateErrandLoading(false);

    if (returned?.id) {
      push(detailUrl({ id: returned.id, isGov: true }));
    }
    checkout();
  };

  const preProcess = (data) => {
    const dataClone = cloneDeep(data);

    return dataClone;
  };

  const onSubmit = () => {
    setCreateErrandLoading(true);

    dispatch(
      create({
        successCallback: onSuccess,
        errorCallback: () => setCreateErrandLoading(false),
        preProcess,
      })
    );
  };

  const errandQuery = {
    setting: settingId,
    order_by: ["planned_start", "status"],
  };

  // const getExternalFilters = () => {
  //   return (
  //     <FilterWrapper>
  //       <FilterButton
  //         style={{
  //           color: "#9258f0",
  //           borderColor: "#9258f0",
  //           backgroundColor: "#f2ebff",
  //         }}
  //         selected={selectedFilters.includes(0)}
  //         onClick={() => toggleSelected([0])}
  //       >
  //         Ej påbörjade ({nonStartedCount})
  //       </FilterButton>

  //       <FilterButton
  //         style={{
  //           color: "#499dff",
  //           borderColor: "#499dff",
  //           backgroundColor: "#e4f1ff",
  //         }}
  //         selected={selectedFilters.includes(1)}
  //         onClick={() => toggleSelected([1])}
  //       >
  //         Påbörjade ({startedCount})
  //       </FilterButton>

  //       <FilterButton
  //         style={{
  //           color: "#fdb738",
  //           borderColor: "#fdb738",
  //           backgroundColor: "#fff4de",
  //         }}
  //         selected={selectedFilters.includes(2)}
  //         onClick={() => toggleSelected([2])}
  //       >
  //         Pausade ({pausedCount})
  //       </FilterButton>

  //       <FilterButton
  //         style={{
  //           color: "#2ec177",
  //           borderColor: "#2ec177",
  //           backgroundColor: "#e4fbef",
  //         }}
  //         selected={selectedFilters.includes(3)}
  //         onClick={() => toggleSelected([3])}
  //       >
  //         Avklarade ({doneCount})
  //       </FilterButton>
  //     </FilterWrapper>
  //   );
  // };

  return (
    <DetailInnerWrapper>
      {createErrandLoading && <OverlaySpinner />}
      <DetailPageBox>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 12,
          }}
        >
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle style={{ marginBottom: 8 }}>Ärenden</OverviewTitle>

              {/*If autogen is off, we hide info regarding next errand*/}
              {!govErrandSetting?.paused && (
                <OverviewSubtitle>
                  Nästa autogenererade ärende skapas{" "}
                  <StatusLabel state={1}>
                    {govErrandSetting?.next_recurrence}
                  </StatusLabel>
                </OverviewSubtitle>
              )}
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          {/*If autogen is off, we do not give option to generate next */}
          {!govErrandSetting?.paused && (
            <Primary
              title="Generera nästkommande nu"
              clicked={() => {
                generateNextErrand();
              }}
            />
          )}
        </div>
        <ErrandsTable persistantQuery={errandQuery} isBare ignoreLocalStorage />
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}

export default GovErrand;
