import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { useDispatch } from "react-redux";

//store, state
import usePermissionRedirect from "../../hooks/usePermissionRedirect";
import useActiveFilters from "../../hooks/useActiveFilters";
import {
  updateActiveFormInstance,
  useAllPermissionCheck,
} from "../../store/base";
import {
  constants,
  useRoundingErrandsPaginationCount,
} from "../../store/roundingErrands";

//components & styles
import Settings from "./overview/Settings";
import Errands from "./overview/Errands";
import { StatusLabel } from "../../components/Lists/Base/CompleteList/styles";
import { DetailLayoutWrapper } from "../../components/sharedStyles";
import DetailPageHeaderMenu from "../Layouts/DetailPageHeaderMenu/DetailPageHeaderMenu";

export default function MainGovErrandOverview() {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { filteredRealEstates } = useActiveFilters();

  usePermissionRedirect(["allow_errand_gov", "view_can_goverrand"]);

  const [activeCount] = useRoundingErrandsPaginationCount({
    realEstates: filteredRealEstates,
    kindIsNull: false,
    isGov: true,
  });

  const canAdd = useAllPermissionCheck([
    "add_can_goverrand",
    "allow_errand_gov",
  ]);

  const SUB_PAGES = [
    {
      path: "/settings",
      label: "Myndighetskrav",
      component: Settings,
    },
    {
      path: "/errands",
      label: "Ärenden",
      component: Errands,
    },
  ];

  const subPageLinks = SUB_PAGES.map((sp) => ({
    url: `${url}${sp.path}`,
    label: sp.label,
    hasError: sp.hasError,
  }));

  const renderInfoPills = () => {
    return (
      <>
        <StatusLabel state={0} style={{ marginRight: 12 }}>
          Antal ärenden: {activeCount == null ? "Laddar..." : activeCount}
        </StatusLabel>
      </>
    );
  };

  React.useEffect(() => {
    dispatch(updateActiveFormInstance({ storeName, data: undefined }));
  }, []);

  return (
    <>
      <DetailLayoutWrapper>
        <DetailPageHeaderMenu
          title={`Myndighetskrav`}
          {...{ subPages: subPageLinks, renderInfoPills }}
        />

        <Switch>
          {SUB_PAGES.map((sp) => (
            <Route
              key={sp.path}
              path={`${path}${sp.path}`}
              component={sp.component}
            />
          ))}

          {/* Default sub page  */}
          <Redirect exact to={`${url}${SUB_PAGES[0].path}`} path={`${path}`} />
        </Switch>
      </DetailLayoutWrapper>
    </>
  );
}
