import * as React from "react";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";

import { constants } from "../../../../../store/reportErrands";

import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import { InnerBox } from "../../../../sharedStyles";
import { PrimaryButton, TextButton } from "../../../Base/Buttons";

import { useFilteredErrandComponents } from "../../../../../store/errandComponents";
import RoomOverview from "../../../../TechnicalManagement/Component/RoomOverview";
import { useDispatch } from "react-redux";
import SelectedComponent from "./SelectedComponent";
import { getComponentsByRooms } from "../../../../TechnicalManagement/utils";
import { InfoBox } from "../../../../Displays";
import StandardModal from "../../../../Modals/StandardModal";
import ApartmentTable from "src/components/Tables/Apartments/FullTableNewVersion";
import LocalTableSelectField from "src/components/Forms/Base/Fields/LocalTableSelectField";
import IndustrialPremisesTable from "src/components/Tables/IndustrialPremises/FullTableNewVersion";
import CommonAreaTable from "src/components/Tables/CommonArea/FullTable";
import BrfPremisesTable from "src/components/Tables/BrfPremises/FullTable";
import ParkingSpotsTable from "src/components/Tables/Parking/ParkingSpots/FullTable";

const TYPES = {
  APARTMENT: 1,
  INDUSTRIAL_PREMISES: 2,
  COMMON_AREA: 3,
  BRF_PREMIS: 4,
  PARKING_SPOT: 5,
};

export const getComponentQueryKeyFromType = (type) => {
  if (type === TYPES.APARTMENT) return "apartments";
  if (type === TYPES.INDUSTRIAL_PREMISES) return "industrial_premises_list";
  if (type === TYPES.COMMON_AREA) return "common_areas";
  if (type === TYPES.BRF_PREMIS) return "brf_premises";
  if (type === TYPES.PARKING_SPOT) return "parking_spot";
};

export default () => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;

  const [selectedType, setSelectedType] = React.useState(null);
  const [selectedPremises, setSelectedPremises] = React.useState(null);

  const _filterObjectsByTenantId = useFormInstanceField({
    storeName,
    fieldKey: "_filterObjectsByTenantId",
  });

  const componentQueryKey = getComponentQueryKeyFromType(selectedType);

  const selectedSetting = useFormInstanceField({
    storeName,
    fieldKey: "setting",
  });
  const selectedComponent = useFormInstanceField({
    storeName,
    fieldKey: "component_placement",
  });
  const status = useFormInstanceField({
    storeName,
    fieldKey: "status",
  });

  const componentQ = buildQueryString({
    [componentQueryKey || "id__in"]: selectedPremises?.id || "-1",
  });
  const [components, componentsLoading] =
    useFilteredErrandComponents(componentQ);

  const componentsByRoom = React.useMemo(() => {
    return getComponentsByRooms({ components });
  }, [components]);

  const handleSelectComponent = (component) => {
    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          component_placement: component,
        },
      })
    );
  };

  React.useEffect(() => {
    if (selectedSetting && selectedSetting?.category != null) {
      switch (selectedSetting.category) {
        case 0: {
          setSelectedType(TYPES.APARTMENT);
          return;
        }
        case 1: {
          setSelectedType(TYPES.INDUSTRIAL_PREMISES);
          return;
        }
        case 4: {
          setSelectedType(TYPES.COMMON_AREA);
          return;
        }
        case 5: {
          setSelectedType(TYPES.PARKING_SPOT);
          return;
        }
        case 6: {
          setSelectedType(TYPES.BRF_PREMIS);
          return;
        }
        default:
          return;
      }
    }
  }, []);

  const disabled = status != null && status !== 0;

  if (!selectedSetting)
    return (
      <InnerBox>
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Komponent</OverviewTitle>
            <OverviewSubtitle>
              Välj vilken komponent ärendet gäller. Välj objekt för att filtrera
              fram rätt komponent.
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <hr />

        <InfoBox
          title="Felanmälningstyp saknas"
          text="Välj typ av felanmälan först för att välja komponent"
        />
      </InnerBox>
    );

  if (selectedComponent) {
    return (
      <InnerBox>
        {disabled && (
          <InfoBox
            title={
              status === 3 || status === 4
                ? "Ärendet slutfört"
                : "Ärendet påbörjat"
            }
            text={`Eftersom ärendet är ${
              status === 3 || status === 4 ? "slutfört" : "påbörjat"
            } kan du inte redigera komponent.`}
          />
        )}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle small>Vald komponent</OverviewTitle>
            <OverviewSubtitle>
              {!disabled && (
                <TextButton
                  title="Byt komponent"
                  clicked={() => {
                    dispatch(
                      updateActiveFormInstance({
                        storeName,
                        data: {
                          component_placement: null,
                        },
                      })
                    );
                  }}
                  iconType="close"
                />
              )}
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>
        </OverviewTitleWrapper>

        <SelectedComponent componentId={selectedComponent?.id} />
      </InnerBox>
    );
  }

  return (
    <>
      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Komponent</OverviewTitle>
          <OverviewSubtitle>
            Välj vilken komponent ärendet gäller. Välj objekt för att filtrera
            fram rätt komponent.
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>
      <hr />

      {!selectedType && (
        <InnerBox>
          <OverviewTitleWrapper>
            <OverviewTitle small>Välj typ av objekt</OverviewTitle>
          </OverviewTitleWrapper>
          {(selectedSetting?.category === 0 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Lägenhet"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setSelectedType(TYPES.APARTMENT)}
            />
          )}

          {(selectedSetting?.category === 1 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Lokal"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setSelectedType(TYPES.INDUSTRIAL_PREMISES)}
            />
          )}

          {(selectedSetting?.category === 4 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Gemensamt utrymme"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setSelectedType(TYPES.COMMON_AREA)}
            />
          )}

          {(selectedSetting?.category === 5 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Parkeringsplats"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setSelectedType(TYPES.PARKING_SPOT)}
            />
          )}

          {(selectedSetting?.category === 6 ||
            selectedSetting?.category == null) && (
            <TextButton
              extraStyle={{ marginBottom: 12 }}
              title="Bostadsrätt"
              iconType="arrow"
              iconPlacement="right"
              clicked={() => setSelectedType(TYPES.BRF_PREMIS)}
            />
          )}
        </InnerBox>
      )}

      {selectedType && !selectedPremises && (
        <InnerBox>
          {selectedSetting?.category == null && (
            <TextButton
              title="Byt typ av objekt"
              iconType="arrow-back"
              extraStyle={{ marginBottom: 12 }}
              clicked={() => {
                setSelectedPremises(null);
                setSelectedType(null);
              }}
            />
          )}
          <OverviewTitleWrapper>
            <OverviewTitle small>Välj objekt</OverviewTitle>
          </OverviewTitleWrapper>

          {selectedType === TYPES.APARTMENT && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                TableComponent={ApartmentTable}
                value={selectedPremises}
                title="Lägenhet"
                persistantQuery={{
                  broad_tenant: _filterObjectsByTenantId,
                }}
                placeholder="Välj lägenhet..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => setSelectedPremises(val)}
              />
            </div>
          )}

          {selectedType === TYPES.INDUSTRIAL_PREMISES && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                TableComponent={IndustrialPremisesTable}
                value={selectedPremises}
                title="Lokal"
                persistantQuery={{
                  broad_tenant: _filterObjectsByTenantId,
                }}
                placeholder="Välj lokal..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => setSelectedPremises(val)}
              />
            </div>
          )}

          {selectedType === TYPES.COMMON_AREA && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                TableComponent={CommonAreaTable}
                value={selectedPremises}
                title="Gemensamt utrymme"
                persistantQuery={{
                  broad_tenant: _filterObjectsByTenantId,
                }}
                placeholder="Välj utrymme..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => setSelectedPremises(val)}
              />
            </div>
          )}

          {selectedType === TYPES.BRF_PREMIS && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                TableComponent={BrfPremisesTable}
                value={selectedPremises}
                title="Bostadsrätt"
                persistantQuery={{
                  broad_tenant: _filterObjectsByTenantId,
                }}
                placeholder="Välj bostadsrätt..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => setSelectedPremises(val)}
              />
            </div>
          )}

          {selectedType === TYPES.PARKING_SPOT && (
            <div className="grid grid-cols-2 gap-6 mb-6">
              <LocalTableSelectField
                TableComponent={ParkingSpotsTable}
                value={selectedPremises}
                title="Fordonsplats"
                persistantQuery={{
                  broad_tenant: _filterObjectsByTenantId,
                }}
                placeholder="Välj fordonsplats..."
                description="Välj objekt för att visa komponenter"
                onChange={(val) => setSelectedPremises(val)}
              />
            </div>
          )}
        </InnerBox>
      )}

      {selectedPremises && (
        <InnerBox>
          <StandardModal
            modalInModal
            title="Välj komponent"
            isOpen
            closeFunction={() => {
              setSelectedPremises(null);
            }}
          >
            {Object.keys(componentsByRoom)?.length > 0 ? (
              <RoomOverview
                rooms={componentsByRoom}
                selectOnly
                onSelect={(component) => handleSelectComponent(component)}
                isParkingSpot={selectedType === TYPES.PARKING_SPOT}
              />
            ) : (
              <InnerBox>
                <div>
                  {componentsLoading
                    ? "Laddar..."
                    : "Detta objekt innehåller inga komponenter."}
                </div>

                {!componentsLoading && (
                  <div style={{ marginTop: 12 }}>
                    <PrimaryButton
                      title="Tillbaka till objekt"
                      clicked={() => setSelectedPremises(null)}
                    />
                  </div>
                )}
              </InnerBox>
            )}
          </StandardModal>
        </InnerBox>
      )}
    </>
  );
};
