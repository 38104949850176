import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { DateCell, InfoBox, LinkedObject } from "../../../components/Displays";
import {
  DetailInnerWrapper,
  DetailPageBox,
  DetailPageBoxFlexWrapper,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  getCurrentProductTypeUsage,
  getSortedComponentHistory,
} from "../../../components/TechnicalManagement/utils";
import {
  useErrandComponent,
  update,
  constants,
} from "../../../store/errandComponents";
import { getPremisesDetailUrlFromRoom } from "../ComponentDetail";
import { detailUrl as componentTypeDetailUrl } from "../../../store/componentTypes";
import { detailUrl as productTypeDetailUrl } from "../../../store/productTypes";
import DetailInfo from "../../../components/Details/OverviewInfo/DetailInfo/DetailInfo";
import { useApartment } from "../../../store/apartments";
import { useIndustrialPremises } from "../../../store/industrialPremises";
import { useCommonArea } from "../../../store/commonAreas";
import { useBrfPremises } from "../../../store/brfPremises";
import { useParkingSpot } from "../../../store/parkingSpots";
import { buildQueryString } from "../../../store/base";
import {
  useFilteredReportErrands,
  detailUrl as errandDetailUrl,
} from "../../../store/reportErrands";
import ReportErrandBox from "../../../components/TechnicalManagement/ErrandComponents/ReportErrandBox";
import {
  useFilteredInspectionErrands,
  detailUrl as inspectionDetailUrl,
} from "../../../store/inspectionErrands";
import { useFilteredComponentRatings } from "../../../store/componentRatings";
import theme from "../../../theme";
import { StatusLabel } from "../../../components/Lists/Base/CompleteList/styles";
import {
  PrimaryButton,
  TextButton,
} from "../../../components/Forms/Base/Buttons";
import ComponentForm from "../../../components/Forms/Component/ChapterForm/ModalForm";
import ReportErrandForm from "../../../components/Forms/ReportErrand/ChapterForm/ModalForm";
import { useDispatch } from "react-redux";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import {
  Image,
  ImageWrapper,
} from "../../../components/Details/ReportErrand/styles";
import UpdateCurrentComponentForm from "../../../components/Forms/Component/UpdateCurrentForm/ModalForm";
import ConfirmModal from "../../../components/Forms/Base/Modals/ConfirmModal";
import DeleteModal from "../../../components/Forms/Delete/DeleteModal";

const getContentKeyFromRoom = (room) => {
  if (room?.apartment) return "apartment";
  if (room?.industrial_premises) return "industrial_premises";
  if (room?.common_area) return "common_area";
  if (room?.brf_premis) return "brf_premis";
  if (room?.parking_spot) return "parking_spot";
};
const getPremisesIdFromRoom = (room) => {
  if (room?.apartment) return room.apartment.id;
  if (room?.industrial_premises) return room.industrial_premises.id;
  if (room?.common_area) return room.common_area.id;
  if (room?.brf_premis) return room.brf_premis.id;
  if (room?.parking_spot) return room.parking_spot.id;
};

export default function ComponentOverviewDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [component] = useErrandComponent(id);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editCurrentOpen, setEditCurrentOpen] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [exampleOpen, setExampleOpen] = React.useState(false);
  const [createReportErrandOpen, setCreateReportErrandOpen] =
    React.useState(false);
  const [confirmDeleteComponentOpen, setConfirmDeleteComponentOpen] =
    React.useState(false);
  const [deleteComponentOpen, setDeleteComponentOpen] = React.useState(false);
  const { push, goBack } = useHistory();
  const room = component?.room;
  const currentProductUsage = getCurrentProductTypeUsage(component);
  const productType = currentProductUsage?.product_type;
  const componentType = productType?.component_type;
  const premisesDetailUrl = getPremisesDetailUrlFromRoom(
    room,
    component?.parking_spot
  );
  const contentKey = getContentKeyFromRoom(room);
  const premisesId = getPremisesIdFromRoom(room);

  const ratingFilter = buildQueryString({
    component_placement: id,
  });
  const [componentRatings, componentRatingsLoading] =
    useFilteredComponentRatings(ratingFilter);

  const sortedHistory = getSortedComponentHistory({
    component,
    ratings: componentRatings,
  });
  const ratingsWithRating = React.useMemo(() => {
    return componentRatings?.filter((c) => !!c.rating);
  }, [componentRatings]);

  const chartData = React.useMemo(() => {
    if (!ratingsWithRating || ratingsWithRating?.length === 0) return [];

    return ratingsWithRating.map((r) => ({ x: r.created_at, y: r.rating }));
  }, [ratingsWithRating]);

  const activeReportErrandQ = buildQueryString({
    component_placement: id,
    status__in: [0, 1, 2],
  });
  const activeInspectionsQ = buildQueryString({
    [contentKey]: premisesId,
    status__in: [0, 1, 2],
  });
  const [activeReportErrands] = useFilteredReportErrands(activeReportErrandQ);
  const [activeInspectionErrands] =
    useFilteredInspectionErrands(activeInspectionsQ);

  const [apartment] = useApartment(room?.apartment?.id);
  const [indp] = useIndustrialPremises(room?.industrial_premises?.id);
  const [commonArea] = useCommonArea(room?.common_area?.id);
  const [brfPremis] = useBrfPremises(room?.brf_premis?.id);
  const [parkingSpot] = useParkingSpot(component?.parking_spot?.id);

  const setArchived = () => {
    setLoading(true);

    dispatch(
      update({
        id,
        forceData: {
          archived: !component?.archived,
        },
        successCallback: () => setLoading(false),
        errorCallback: () => setLoading(false),
      })
    );
  };

  const onUpdateInstallation = () => {
    const currentProductTypeUsageIndex = component.product_types.findIndex(
      (cpt) => cpt.id === currentProductUsage?.id
    );
    setEditCurrentOpen(currentProductTypeUsageIndex);
  };

  const premises = apartment || indp || commonArea || brfPremis || parkingSpot;

  const chartOptions = {
    chart: {
      type: "area",
      fontFamily: "Inter",
    },
    stroke: {
      curve: "straight",
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => val,
      },
      min: 1,
      max: 5,
    },
    xaxis: {
      type: "category",
      labels: {
        formatter: (val) => moment(val).format("YYYY-MM-DD"),
        show: true,
      },
      position: "bottom",
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },

    tooltip: {
      theme: "dark",
    },

    markers: {
      size: 5,
      colors: [theme.colors.primaryBlue],

      strokeWidth: 0,
    },

    colors: [theme.colors.primaryBlue],
  };

  const openImage = (src) => {
    window.open(src, "_blank").focus();
  };

  return (
    <>
      <ReportErrandForm
        method="POST"
        instance={{
          component_placement: {
            _dontClear: true,
            id,
            str_representation: component?.str_representation,
          },
        }}
        isOpen={createReportErrandOpen}
        onCheckout={() => setCreateReportErrandOpen(false)}
      />

      {confirmDeleteComponentOpen && (
        <ConfirmModal
          closeFunction={() => setConfirmDeleteComponentOpen(false)}
          onAccept={() => setDeleteComponentOpen(true)}
        >
          <h3 className="mb-2 text-base font-normal text-slate-700 ">
            Observera att ärenden kopplade till denna komponent, såväl aktiva
            som historiska, kommer att raderas om denna komponent raderas. Vill
            du forstätta ändå?
          </h3>
        </ConfirmModal>
      )}

      <DeleteModal
        isOpen={deleteComponentOpen}
        closeFunction={() => setDeleteComponentOpen(false)}
        instance={component}
        constants={constants}
        deletedCallback={() => goBack()}
      />

      <ComponentForm
        method="PATCH"
        id={id}
        instance={component}
        isOpen={editOpen}
        patchId={id}
        onCheckout={() => setEditOpen(false)}
      />

      <UpdateCurrentComponentForm
        id={id}
        instance={component}
        isOpen={editCurrentOpen != null}
        patchIndex={editCurrentOpen}
        onCheckout={() => setEditCurrentOpen(null)}
      />

      <DetailInnerWrapper>
        {component?.archived && (
          <DetailPageBox>
            <InfoBox
              title="Denna komponent är arkiverad"
              text='Denna komponent är arkiverad och ej längre i bruk. Detta innebär att den inte kommer att dyka upp för hyresgäster och utförare i ärenden. För att aktivera komponenten igen, tryck på "Aktivera denna komponent" i översikten.'
              boxTheme="warning"
            />
          </DetailPageBox>
        )}

        <DetailPageBoxFlexWrapper>
          <DetailPageBox
            style={{ flex: 2, maxWidth: "66%", alignSelf: "flex-start" }}
          >
            {loading && <OverlaySpinner />}
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle>Översikt</OverviewTitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            <InnerBox
              style={{
                marginBottom: 12,
                backgroundColor: theme.colors.primaryBlue,
              }}
            >
              <div
                style={{
                  fontSize: theme.fontSizes.headerXSmall,
                  color: "#fff",
                }}
              >
                <strong style={{ color: "#fff" }}>
                  Uppdatera installation av komponent
                </strong>
              </div>

              <div
                style={{
                  fontSize: theme.fontSizes.headerXSmall,
                  color: "#fff",
                }}
              >
                Uppdatering av komponenter sker genom att installera en ny
                komponentmodell som ersätter den gamla. Detta för att kunna
                hålla koll på historiken för en viss komponent. <br />
                {exampleOpen ? (
                  `Exempel: En spis av typen Bosch 123 installeras 2020-01-01.
                Efter 3 år byts spisen ut till en Siemens 321. Komponenten är
                fortfarande samma, men har bytt underliggande komponentmodell
                för att kunna visa på händelser och behålla sin historik.`
                ) : (
                  <TextButton
                    inverted
                    title="Visa exempel..."
                    clicked={() => setExampleOpen(true)}
                  />
                )}
              </div>

              <div>
                <PrimaryButton
                  extraStyle={{
                    backgroundColor: "#fff",
                    color: theme.colors.primaryBlue,
                    marginTop: 12,
                  }}
                  title="Lägg till ny installation"
                  clicked={() => setEditOpen(true)}
                />
              </div>
            </InnerBox>

            <DetailInfo
              infoObj={getInfoObj({
                productType,
                component,
                componentType,
                premisesDetailUrl,
                currentProductUsage,
                premises,
                onUpdateInstallation,
              })}
            />

            {component?.archived ? (
              <InnerBox>
                <div style={{ fontSize: theme.fontSizes.headerXSmall }}>
                  <strong>Aktivera komponent</strong>
                </div>

                <div style={{ fontSize: theme.fontSizes.headerXSmall }}>
                  Denna komponent är arkiverad. Detta innebär att den ej längre
                  går att felanmäla/besikta samt döljs för utförare. Komponenten
                  kan aktiveras igen och fungerar då som vanligt.
                </div>

                <TextButton
                  extraStyle={{ marginTop: 12 }}
                  title="Aktivera denna komponent"
                  iconType="sync"
                  clicked={setArchived}
                />

                <TextButton
                  extraStyle={{ marginTop: 12 }}
                  title="Radera denna komponent"
                  red
                  iconType="close"
                  clicked={() => setConfirmDeleteComponentOpen(true)}
                />
              </InnerBox>
            ) : (
              <InnerBox>
                <div style={{ fontSize: theme.fontSizes.headerXSmall }}>
                  <strong>Arkivera komponent</strong>
                </div>

                <div style={{ fontSize: theme.fontSizes.headerXSmall }}>
                  Komponenter bör inte raderas från systemet då kopplingen till
                  ärenden samt statistik då går förlorade. För att dölja en
                  komponent för hyresgäster och utförare så kan de arkiveras
                  istället. En arkiverad komponent kan enkelt aktiveras igen
                  efter att ha arkiverats. För att ändå radera en komponent
                  krävs att den först arkiveras.
                </div>

                <TextButton
                  extraStyle={{ marginTop: 12 }}
                  title="Arkivera denna komponent"
                  red
                  iconType="lock"
                  clicked={setArchived}
                />
              </InnerBox>
            )}
          </DetailPageBox>
          <DetailPageBox
            style={{ flex: 1, maxWidth: "33%", alignSelf: "flex-start" }}
          >
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>Aktiva ärenden</OverviewTitle>
                <OverviewSubtitle>
                  <TextButton
                    title="Felanmäl komponent"
                    iconType="add"
                    iconPlacement="right"
                    clicked={() => setCreateReportErrandOpen(true)}
                  />{" "}
                </OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>
            {activeReportErrands?.map((errand) => {
              return (
                <ReportErrandBox
                  key={errand.id}
                  onClick={() => push(errandDetailUrl({ id: errand.id }))}
                  errand={errand}
                />
              );
            })}

            {activeInspectionErrands?.map((errand) => {
              return (
                <ReportErrandBox
                  onClick={() => push(inspectionDetailUrl({ id: errand.id }))}
                  errand={errand}
                />
              );
            })}

            {activeInspectionErrands?.length === 0 &&
              activeReportErrands?.length === 0 && (
                <InnerBox
                  style={{
                    minHeight: 120,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Detta objekt har inga aktiva ärenden
                </InnerBox>
              )}
          </DetailPageBox>
        </DetailPageBoxFlexWrapper>

        <DetailPageBox>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Historik</OverviewTitle>
              <OverviewSubtitle>
                Visar händelser och betyg för komponenten
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>

          <InnerBox style={{ marginBottom: 24 }}>
            <div
              style={{
                fontSize: theme.fontSizes.headerXSmall,
                fontWeight: theme.fontWeights.headerSmall,
                marginBottom: 12,
              }}
            >
              Historiskt skick
            </div>
            <InnerBox>
              <ReactApexChart
                series={[
                  {
                    data: chartData,
                  },
                ]}
                options={{ ...chartOptions }}
                height={280}
                type="area"
              />
            </InnerBox>
          </InnerBox>

          {sortedHistory?.length === 0 ? (
            <InnerBox
              style={{
                minHeight: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Historik saknas på denna komponent
            </InnerBox>
          ) : (
            <>
              {sortedHistory.map((record, idx) => {
                if (record.type === "RATING") {
                  return (
                    <div
                      key={record.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flex: 1,
                        padding: "6px 0 6px 0",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: 40,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: 14,
                            width: 14,
                            borderRadius: 28,
                            borderWidth: 2,
                            backgroundColor: theme.colors.blueLight,
                            borderColor: theme.colors.blue,
                          }}
                        />
                      </div>

                      <InnerBox style={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 8,
                          }}
                        >
                          <DateCell date={record?.created_at} />

                          <StatusLabel
                            style={{ marginLeft: 8 }}
                            state={
                              record?.inspection_errand
                                ? "Attesterad"
                                : record?.report_errand
                                ? 4
                                : 10
                            }
                          >
                            {record?.inspection_errand
                              ? "Besiktning"
                              : record?.report_errand
                              ? "Felanmälan"
                              : "Logg"}
                          </StatusLabel>

                          <StatusLabel
                            style={{ marginLeft: 8 }}
                            state={record?.is_ok ? 0 : 6}
                          >
                            {record?.is_ok
                              ? record?.inspection_errand
                                ? "Godkänd"
                                : "Avhjälpt"
                              : record?.inspection_errand
                              ? "Nekad"
                              : "Ej avhjälpt"}
                          </StatusLabel>
                        </div>

                        <InnerBox>
                          {record?.inspection_errand ? (
                            <>
                              <div style={{ fontSize: 14 }}>
                                Kommentar:{" "}
                                {record?.note || "Ingen kommentar lämnades"}
                              </div>

                              {record?.file && (
                                <ImageWrapper style={{ margin: "12px 0" }}>
                                  <Image
                                    onClick={() => openImage(record?.file?.get)}
                                    src={record?.file?.get}
                                  />
                                </ImageWrapper>
                              )}

                              <TextButton
                                iconType="arrow"
                                iconPlacement="right"
                                title="Gå till besiktning"
                                clicked={() =>
                                  push(
                                    inspectionDetailUrl({
                                      id: record?.inspection_errand?.id,
                                    })
                                  )
                                }
                              />
                            </>
                          ) : record?.report_errand ? (
                            <>
                              <div style={{ fontSize: 14 }}>
                                Kommentar:{" "}
                                {record?.note || "Ingen kommentar lämnades"}
                              </div>

                              {record?.file && (
                                <ImageWrapper style={{ margin: "12px 0" }}>
                                  <Image
                                    onClick={() => openImage(record?.file?.get)}
                                    src={record?.file?.get}
                                  />
                                </ImageWrapper>
                              )}

                              <TextButton
                                iconType="arrow"
                                iconPlacement="right"
                                title="Gå till ärende"
                                clicked={() =>
                                  push(
                                    errandDetailUrl({
                                      id: record?.report_errand?.id,
                                    })
                                  )
                                }
                              />
                            </>
                          ) : (
                            <>
                              <div style={{ fontSize: 14 }}>
                                Kommentar:{" "}
                                {record?.note || "Ingen kommentar lämnades"}
                              </div>
                            </>
                          )}
                        </InnerBox>
                      </InnerBox>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={record.id + "installation"}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingVertical: 6,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: 40,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            height: 14,
                            width: 14,
                            borderRadius: 28,
                            borderWidth: 2,
                            backgroundColor: theme.colors.blueLight,
                            borderColor: theme.colors.blue,
                          }}
                        />
                      </div>
                      <InnerBox containerStyle={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 8,
                          }}
                        >
                          <DateCell date={record?.from_date} />

                          <StatusLabel style={{ marginLeft: 8 }} state={9}>
                            Installation
                          </StatusLabel>
                        </div>

                        <InnerBox>
                          <div style={{ fontSize: 14 }}>
                            {record?.product_type?.name}{" "}
                            {record?.product_type?.code
                              ? `med artikelnr. ${record?.product_type?.code}`
                              : ""}{" "}
                            installerades
                          </div>

                          <TextButton
                            iconType="arrow"
                            iconPlacement="right"
                            title="Gå till komponentmodell"
                            clicked={() =>
                              push(
                                productTypeDetailUrl({
                                  id: record?.product_type?.id,
                                })
                              )
                            }
                          />
                        </InnerBox>
                      </InnerBox>
                    </div>
                  );
                }
              })}
            </>
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    </>
  );
}

function getInfoObj({
  componentType,
  productType,
  component,
  premisesDetailUrl,
  currentProductUsage,
  premises,
  onUpdateInstallation,
}) {
  return {
    Komponent: [
      {
        title: "Komponenttyp",
        value: (
          <LinkedObject
            obj={componentType}
            urlMethod={componentTypeDetailUrl}
          />
        ),
      },
      {
        title: "Komponentmodell",
        value: (
          <LinkedObject obj={productType} urlMethod={productTypeDetailUrl} />
        ),
      },
      {
        title: "Placerad i",
        value: (
          <LinkedObject
            obj={{
              str_representation: `${
                component?.room?.room_id
                  ? component.room.room_id
                  : component?.parking_spot
                  ? "Fordonsplats"
                  : "-"
              } ${component?.room ? "i" : ""} ${
                premises?.str_representation || "-"
              }`,
              id: "null",
            }}
            urlMethod={() => premisesDetailUrl}
          />
        ),
      },
      {
        title: "Installerades",
        value: <DateCell date={currentProductUsage?.from_date} />,
      },
      {
        title: "Serienummer",
        value: currentProductUsage?.code || "-",
      },
      {
        title: "",
        value: (
          <TextButton
            extraStyle={{ marginTop: 12 }}
            title="Uppdatera nuvarande installation"
            iconType="edit"
            iconPlacement="right"
            clicked={onUpdateInstallation}
          />
        ),
      },
    ],
  };
}
