import * as React from "react";

// style, design
import { ToolTipCell, LinkedObject, TimeCell } from "../../Displays";

import SimpleTextFilter from "src/components/Lists/Base/CompleteList/Filters/TextFilter";

import SimpleDateFilter from "src/components/Lists/Base/CompleteList/Filters/DateFilter";
import StateFilter from "./Filters/StateFilter";

import {
  ErrandStatusLabel,
  StatusLabel,
} from "src/components/Lists/Base/CompleteList/styles";
import { getErrandStatus } from "../../utils/errandUtils";

import { detailUrl as userDetailUrl } from "../../../store/users";

export default (settings, realEstates) => [
  {
    Header: "Status",
    // accessor: "status",
    id: "errand_status",
    Cell: ({ row }) => {
      const { status, display } = getErrandStatus({
        status: row.original?.status,
        plannedStart: row.original?.planned_start,
        plannedEnd: row.original?.planned_end,
        actualStart: row.original?.execute_start,
        actualEnd: row.original?.execute_end,
      });
      return <ErrandStatusLabel state={status}>{display}</ErrandStatusLabel>;
    },
    Filter: StateFilter,
    filter: "textExact",
  },
  {
    Header: "Ärendenummer",
    accessor: "errand_id",
    Cell: ({ value }) => <ToolTipCell text={value} />,
    Filter: SimpleTextFilter,
    filter: "text",
  },
  {
    Header: "Ärendetyp",
    accessor: "setting",
    Cell: ({ value }) => {
      const id = value?.id;
      let setting = settings?.find((s) => s.id === id);
      return <ToolTipCell text={setting?.str_representation} />;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Avvikelser",
    accessor: "faults",
    Cell: ({ row }) => {
      const faults = row.original.faults;
      if (row.original.status === 3 || row.original.status === 4) {
        if (faults.length > 0) {
          return <StatusLabel state={8}>{faults.length} st</StatusLabel>;
        } else {
          return <StatusLabel state={0}>Nej</StatusLabel>;
        }
      }
      return <StatusLabel state={5}> - </StatusLabel>;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Skapat",
    accessor: "created_at",
    Cell: ({ value }) => <TimeCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
  {
    Header: "Rapporterad av",
    accessor: "reported_by",
    Cell: ({ value }) => {
      return <LinkedObject obj={value} urlMethod={userDetailUrl} />;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Planerat utförandedatum",
    accessor: "planned_start",
    Cell: ({ value }) => <TimeCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
  {
    Header: "Utförandedatum",
    accessor: "execute_end",
    Cell: ({ value }) => <TimeCell date={value} />,
    Filter: SimpleDateFilter,
    filter: "betweenDates",
  },
  {
    Header: "Fastigheter",
    accessor: "realestates",
    Cell: ({ value }) => {
      const settingRealEstateIds = value;
      const matches = realEstates?.filter((r) =>
        settingRealEstateIds.includes(r.id)
      );

      const estates = matches?.map((m) => m.str_representation);
      return <div>{estates?.length ? estates.join(", ") : "-"}</div>;
    },
    disableSortBy: true,
    disableFilters: true,
    disableGlobalFilter: true,
  },
];
