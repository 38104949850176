import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import { store } from "../../store";
import { createUploadHandler } from "./uploadHandler";
import { cloneDeep } from "lodash";
import { handleUpdateConnectedStateOnCreate } from "./connectedStateHandlers";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  // the main file
  if (cloned?.editabledoc?.docData_) {
    // file has been edited, editabledoc.doc has been set
    // so delete docData (on the clone, we have the reference on formInstance)
    delete cloned?.editabledoc?.docData_;
  } else {
    // file has not been edited, don't send anything
    delete cloned?.editabledoc?.doc;
  }

  // the cancel file
  if (cloned?.cancelled_doc?.docData_) {
    // file has been edited, editabledoc.doc has been set
    // so delete docData (on the clone, we have the reference on formInstance)
    delete cloned?.cancelled_doc?.docData_;
  } else {
    // file has not been edited, don't send anything
    delete cloned?.cancelled_doc?.doc;
  }

  // the attachments
  (cloned?.editabledoc?.attachments || []).forEach((a) => {
    if (a) {
      // will never be sent to backend
      delete a._tempData;
      delete a._referenceId;

      if (a.id) {
        // file has not been edited, so we can just use the id
        delete a?.pdf;
      }
    }
  });

  (cloned?.cancelled_doc?.attachments || []).forEach((a) => {
    if (a) {
      // will never be sent to backend
      delete a._tempData;
      delete a._referenceId;

      if (a.id) {
        // file has not been edited, so we can just use the id
        delete a?.pdf;
      }
    }
  });

  if (cloned.editabledoc && !cloned.editabledoc?.title) {
    cloned.editabledoc.title = "doc";
  }

  return cloned;
};

const uploadCallback = async (responseData, dispatch) => {
  const state = store.getState();
  const formInstance = state[constants.STORE_NAME].formInstance;

  await createUploadHandler({ formInstance, responseData, dispatch });
  return;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    uploadCallback,
    updateStateCallback: handleUpdateConnectedStateOnCreate,
    preProcess: fullPreProcess,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return patch({
    url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    uploadCallback,
    updateStateCallback: handleUpdateConnectedStateOnCreate,
    preProcess: fullPreProcess,
  });
};
