import * as React from "react";
import * as SC from "../../../../Details/Apartment/styles";

import { kpiDataMapping } from "../../../../Insights/Widgets/WidgetInfo";

export default ({ kpiChoices }) => {
  return (
    <div>
      <div style={{ fontSize: 16, fontWeight: 600 }}>
        Hur fungerar nyckeltal?
      </div>
      <SC.Paragraph>
        I Pigello kan du granska och undersöka olika komponenter i systemet med
        nyckeltal. Nedan finner du beskrivningar för varje nyckeltal
      </SC.Paragraph>{" "}
      {kpiChoices?.map((category) => (
        category.choices.map((kpiChoice) => (
          <SC.Paragraph>
            <strong style={{ marginBottom: 8 }}>
              {kpiChoice.d}
            </strong>
            <p>{kpiDataMapping[kpiChoice.v].info_text}</p>
          </SC.Paragraph>
        ))
      )
        
        )


    }

    </div>
  );
};
