import styled, { css } from "styled-components";
import deleteIcon from "../../../../assets/svg/close-circle-red.svg";
import editIcon from "../../../../assets/svg/edit.svg";

export const ListWrapper = styled.div`
  background-color: white;
  border: thin solid rgba(0, 0, 0, 0.2);
  width: 100%;
  box-shadow: ${(p) => p.theme.boxShadows.default};
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const ListHeader = styled.div`
  border-bottom: thin solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const ComponentArea = styled.div`
  flex: 3;
  padding: 12px;
  padding-right: 24px;
  border-right: thin solid rgba(0, 0, 0, 0.2);
`;
export const ComponentName = styled.div`
  flex: 2;
  padding: 12px;
`;

export const ListItem = styled.div`
  padding: 12px;
  border-bottom: ${(p) => p.theme.borders.standard};
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  font-weight: ${(p) => p.theme.fontWeights.body};
  font-size: ${(p) => p.theme.fontSizes.body};
  position: relative;
  &:nth-of-type(even) {
    background-color: ${(p) => p.theme.colors.gray1};
  }

  ${(p) =>
    p.active &&
    css`
      background-color: ${(p) => p.theme.colors.greenLight};
      &:nth-of-type(even) {
        background-color: ${(p) => p.theme.colors.greenLight}};
      }
    `}
`;

export const ListContent = styled.div`
  width: 100%;
  min-height: 200px;
  max-height: 380px;
  overflow-y: scroll;
`;

export const ListBottom = styled.div`
  padding: 12px;
  border-top: thin solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EditButton = styled.div`
  margin-left: 12px;
  margin-right: 56px;
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${editIcon});
`;

export const AbsoluteRemoveButton = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${deleteIcon});
`;

export const RemoveButton = styled.div`
  margin-left: 12px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${deleteIcon});
`;

export const HeaderSpacer = styled.div`
  width: 36px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  border-top: thin solid rgba(0, 0, 0, 0.2);
`;

export const AreaActionsWrapper = styled.div`
  flex: 3;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 18px;
  border-right: thin solid rgba(0, 0, 0, 0.2);
`;

export const SelectWrapper = styled.div`
  width: 90%;
  max-height: 38px;
`;

export const ComponentActionsWrapper = styled.div`
  flex: 2;
  padding: 12px;
  display: flex;
  align-items: center;
`;
