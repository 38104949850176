import { cloneDeep } from "lodash";
import * as React from "react";
import {
  FiThumbsDown,
  FiThumbsUp,
  FiImage,
  FiPlay,
  FiFile,
  FiX,
} from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useApartment } from "../../../../../store/apartments";
import {
  buildQueryString,
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../../../../store/base";
import { useBrfPremises } from "../../../../../store/brfPremises";
import { useFilteredChecklistRows } from "../../../../../store/checklistRows";
import { useFilteredChecklists } from "../../../../../store/checklists";
import { useCommonArea } from "../../../../../store/commonAreas";
import { useFilteredErrandComponents } from "../../../../../store/errandComponents";
import { useIndustrialPremises } from "../../../../../store/industrialPremises";
import {
  constants,
  useInspectionErrand,
} from "../../../../../store/inspectionErrands";
import { useParkingSpot } from "../../../../../store/parkingSpots";
import theme from "../../../../../theme";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../../Details/OverviewInfo/styles";
import OverlaySpinner from "../../../../Loaders/OverlaySpinner";
import StandardModal from "../../../../Modals/StandardModal";
import { InnerBox } from "../../../../sharedStyles";
import RoomOverview from "../../../../TechnicalManagement/Component/RoomOverview";
import {
  ExecuteIconContainer,
  FileContainer,
  FileName,
  FilesContainer,
  FileType,
  FileTypeDisplay,
} from "../../../../TechnicalManagement/Component/styles";
import {
  getComponentsByRooms,
  getInspectionErrandChecklistIdForComponent,
} from "../../../../TechnicalManagement/utils";
import { TextButton } from "../../../Base/Buttons";
import { RadioGroup, TextField } from "../../../Base/Fields";
import MediaField from "../../../Base/Fields/MediaField";

const FILE_TYPE_MAPPER = {
  jpg: "Bild",
  mp4: "Video",
  pdf: "Dokument",
};

const ICON_MAPPER = {
  jpg: (
    <FiImage style={{ marginRight: 12 }} color={theme.colors.red} size={24} />
  ),
  mp4: (
    <FiPlay style={{ marginRight: 12 }} color={theme.colors.green} size={24} />
  ),
  pdf: (
    <FiFile style={{ marginRight: 12 }} color={theme.colors.blue} size={24} />
  ),
};

export default ({ method }) => {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const { inspectionErrandId } = useParams();

  const [handleExecutionRatingIndex, setHandleExecutionRatingIndex] =
    React.useState(null);
  const [handleExecutionChecklist, setHandleExecutionChecklist] =
    React.useState(null);

  const [currentOkChecklistRows, setCurrentOkChecklistRows] = React.useState(
    []
  );
  const [currentNotOkChecklistRows, setCurrentNotOkChecklistRows] =
    React.useState([]);

  const executionRatings = useFormInstanceField({
    storeName,
    fieldKey: "execution_ratings",
  });

  const [inspectionErrand, inspectionErrandLoading] =
    useInspectionErrand(inspectionErrandId);

  const [apartment, apartmentLoading] = useApartment(
    inspectionErrand?.apartment?.id
  );
  const [industrialPremises, industrialPremisesLoading] = useIndustrialPremises(
    inspectionErrand?.industrial_premises?.id
  );
  const [commonArea, commonAreaLoading] = useCommonArea(
    inspectionErrand?.common_area?.id
  );
  const [brfPremises, brfPremisesLoading] = useBrfPremises(
    inspectionErrand?.brf_premis?.id
  );
  const [parkingSpot, parkingSpotLoading] = useParkingSpot(
    inspectionErrand?.parking_spot?.id
  );

  const currentPremises =
    apartment || industrialPremises || commonArea || brfPremises || parkingSpot;

  const key = inspectionErrand?.apartment
    ? "apartments"
    : inspectionErrand?.industrial_premises
    ? "industrial_premises_list"
    : inspectionErrand?.common_area
    ? "common_areas"
    : inspectionErrand?.brf_premis
    ? "brf_premises"
    : "parking_spot";

  const componentQ = buildQueryString({
    [key]: currentPremises?.id || "-1",
  });
  const [components, componentsLoading] =
    useFilteredErrandComponents(componentQ);

  const checklistIds = React.useMemo(() => {
    // product type checklist has precedence over component type
    const ids = components?.reduce((acc, cur) => {
      const checklistId = getInspectionErrandChecklistIdForComponent(cur);

      if (checklistId) {
        return [...acc, checklistId];
      } else {
        return acc;
      }
    }, []);

    return ids;
  }, [components]);

  const checklistQ = buildQueryString({
    id__in: checklistIds || [],
  });

  const [checklists, checklistsLoading] = useFilteredChecklists(checklistQ);

  const checklistRowQ = buildQueryString({
    check_list: handleExecutionChecklist?.checklistId || -1,
    hide_for_non_tenants: false,
  });

  const [checklistRows, checklistRowsLoading] =
    useFilteredChecklistRows(checklistRowQ);

  const componentsByRoom = React.useMemo(() => {
    return getComponentsByRooms({ components });
  }, [components]);

  const handleOnRate = ({ component, isOk }) => {
    const compId = component.id;

    const connected = executionRatings?.findIndex(
      (e) => e.component_placement?.id == compId
    );

    if (connected == null || connected < 0) {
      const executionRatingsClone = cloneDeep(executionRatings || []);
      executionRatingsClone.push({
        component_placement: {
          id: compId,
        },
        is_ok: isOk,
      });

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            execution_ratings: executionRatingsClone,
          },
        })
      );

      if (!isOk) {
        setHandleExecutionRatingIndex(executionRatingsClone.length - 1);
      }
    } else {
      setHandleExecutionRatingIndex(connected);
    }
  };

  const handleOnChecklistRate = ({ component, checklistId }) => {
    setHandleExecutionChecklist({ component, checklistId });
  };

  const markAllAsOk = () => {
    if (!components?.length) return;

    const executionRatingsClone = cloneDeep(executionRatings || []);

    components.forEach((comp) => {
      const existing = executionRatingsClone?.find(
        (e) => e.component_placement?.id === comp.id
      );

      if (existing) {
        existing.is_ok = true;
      } else {
        executionRatingsClone.push({
          component_placement: comp,
          is_ok: true,
        });
      }
    });

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { execution_ratings: executionRatingsClone },
      })
    );
  };

  const handleNotOkChecklistRow = (row) => {
    const id = row.id;

    let okClone = cloneDeep(currentOkChecklistRows || []);
    let notOkClone = cloneDeep(currentNotOkChecklistRows || []);

    if (okClone.includes(id)) {
      okClone = okClone.filter((n) => n !== id);
    }

    if (!notOkClone.includes(id)) {
      notOkClone.push(id);
    }

    setCurrentNotOkChecklistRows(notOkClone);
    setCurrentOkChecklistRows(okClone);
  };
  const handleOkChecklistRow = (row) => {
    const id = row.id;

    let okClone = cloneDeep(currentOkChecklistRows || []);
    let notOkClone = cloneDeep(currentNotOkChecklistRows || []);

    if (notOkClone.includes(id)) {
      notOkClone = notOkClone.filter((n) => n !== id);
    }

    if (!okClone.includes(id)) {
      okClone.push(id);
    }

    setCurrentNotOkChecklistRows(notOkClone);
    setCurrentOkChecklistRows(okClone);
  };

  const handleClearChecklistRow = (row) => {
    const id = row.id;

    let okClone = cloneDeep(currentOkChecklistRows || []);
    let notOkClone = cloneDeep(currentNotOkChecklistRows || []);

    notOkClone = notOkClone.filter((n) => n !== id);
    okClone = okClone.filter((n) => n !== id);

    setCurrentNotOkChecklistRows(notOkClone);
    setCurrentOkChecklistRows(okClone);
  };

  const handleChecklistFinished = () => {
    const executionRatingsClone = cloneDeep(executionRatings || []);

    // check if component already rated
    const currentComponent = handleExecutionChecklist.component;
    const connectedIndex = executionRatings?.findIndex(
      (e) => e.component_placement?.id == currentComponent.id
    );

    const connectedRating = executionRatingsClone?.[connectedIndex];

    if (connectedRating?.id) {
      connectedRating.ok_check_list_rows = currentOkChecklistRows?.map((c) => ({
        id: c,
      }));
      connectedRating.not_ok_check_list_rows = currentNotOkChecklistRows?.map(
        (c) => ({
          id: c,
        })
      );
      connectedRating.is_ok =
        currentNotOkChecklistRows?.length > 0 ? false : true;

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            execution_ratings: executionRatingsClone,
          },
        })
      );

      setHandleExecutionRatingIndex(connectedIndex);

      setCurrentNotOkChecklistRows([]);
      setCurrentOkChecklistRows([]);
      setHandleExecutionChecklist(null);
      return;
    } else {
      const newRating = {
        not_ok_check_list_rows: currentNotOkChecklistRows?.map((c) => ({
          id: c,
        })),
        ok_check_list_rows: currentOkChecklistRows?.map((c) => ({ id: c })),
        is_ok: currentNotOkChecklistRows?.length > 0 ? false : true,
        component_placement: handleExecutionChecklist.component,
      };

      executionRatingsClone.push(newRating);

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: {
            execution_ratings: executionRatingsClone,
          },
        })
      );

      setCurrentNotOkChecklistRows([]);
      setCurrentOkChecklistRows([]);
      setHandleExecutionChecklist(null);
      setHandleExecutionRatingIndex(executionRatingsClone.length - 1);
    }
  };

  const clearRating = ({ component }) => {
    const executionRatingsClone = cloneDeep(executionRatings || []);

    const connectedIndex = executionRatings?.findIndex(
      (e) => e.component_placement?.id == component.id
    );

    const connectedRating = executionRatingsClone?.[connectedIndex];

    // never delete saved rating, only edit
    if (connectedRating?.id) {
      // open checklist if checklist
      const checkl = getInspectionErrandChecklistIdForComponent(component);
      if (checkl) {
        handleOnChecklistRate({ component, checklistId: checkl });
      } else {
        setHandleExecutionRatingIndex(connectedIndex);
      }
      return;
    } else {
      executionRatingsClone.splice(connectedIndex, 1);
    }

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: {
          execution_ratings: executionRatingsClone,
        },
      })
    );
  };

  return (
    <>
      <StandardModal
        isOpen={handleExecutionRatingIndex != null}
        title="Besikta komponent"
        closeFunction={() => setHandleExecutionRatingIndex(null)}
        withActionBar
        actionBarCancelTitle="Klar"
      >
        <RadioGroup
          storeName={storeName}
          method={method}
          defaultValue={null}
          options={[
            { label: "Godkänd", value: true },
            { label: "Ej godkänd", value: false },
          ]}
          title="Resultat av besiktning"
          fieldKey={`execution_ratings[${handleExecutionRatingIndex}].is_ok`}
          instructionsKey={`execution_ratings.is_ok`}
        />

        <TextField
          storeName={storeName}
          fieldKey={`execution_ratings[${handleExecutionRatingIndex}].note`}
          instructionsKey={`execution_ratings.note`}
          method={method}
          title={"Kommentar"}
        />

        <MediaField
          storeName={storeName}
          method={method}
          fieldKey={`execution_ratings[${handleExecutionRatingIndex}].file`}
          instructionsKey={`execution_ratings.file`}
          allowedFormats={[".png", ".jpg", ".jpeg"]}
          title="Bild"
          fileKey="file"
        />
      </StandardModal>

      <StandardModal
        title="Gå igenom checklista för komponent"
        withActionBar
        actionBarAcceptTitle="Klar"
        isOpen={handleExecutionChecklist != null}
        closeFunction={() => setHandleExecutionChecklist(null)}
        saveFunction={handleChecklistFinished}
        canAccept={
          (currentOkChecklistRows?.length || 0) +
            (currentNotOkChecklistRows?.length || 0) ===
          (checklistRows?.length || 0)
        }
      >
        {checklistRowsLoading && <OverlaySpinner />}
        {checklistRows?.map((cr) => {
          return (
            <InnerBox key={cr.id} style={{ marginBottom: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ maxWidth: "80%" }}>
                  <div style={{ fontWeight: 500 }}>{cr.title}</div>
                  <div
                    style={{
                      fontSize: theme.fontSizes.headerXSmall,
                      fontSize: theme.fontSizes.headerXSmall,
                    }}
                  >
                    {cr.description}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {!currentNotOkChecklistRows.includes(cr.id) && (
                    <ExecuteIconContainer
                      onClick={() => handleOkChecklistRow(cr)}
                      style={{
                        backgroundColor: currentOkChecklistRows.includes(cr.id)
                          ? theme.colors.greenLight
                          : "#fff",
                      }}
                    >
                      <FiThumbsUp
                        color={theme.colors.greenDark}
                        size={theme.fontSizes.headerSmall}
                      />
                    </ExecuteIconContainer>
                  )}

                  {!currentOkChecklistRows.includes(cr.id) && (
                    <ExecuteIconContainer
                      onClick={() => handleNotOkChecklistRow(cr)}
                      style={{
                        marginLeft: 6,
                        backgroundColor: currentNotOkChecklistRows.includes(
                          cr.id
                        )
                          ? theme.colors.redLight
                          : "#fff",
                      }}
                    >
                      <FiThumbsDown
                        color={theme.colors.red}
                        size={theme.fontSizes.headerSmall}
                      />
                    </ExecuteIconContainer>
                  )}

                  {(currentNotOkChecklistRows.includes(cr.id) ||
                    currentOkChecklistRows.includes(cr.id)) && (
                    <ExecuteIconContainer
                      onClick={() => handleClearChecklistRow(cr)}
                      style={{
                        marginLeft: 6,
                        height: 32,
                        width: 32,
                        backgroundColor: "#fff",
                      }}
                    >
                      <FiX
                        color={theme.colors.graphite}
                        size={theme.fontSizes.headerXSmall}
                      />
                    </ExecuteIconContainer>
                  )}
                </div>
              </div>

              {cr?.files?.length > 0 && (
                <>
                  <FilesContainer>
                    <div
                      style={{
                        fontSize: theme.fontSizes.headerSmall,
                        marginBottom: 0,
                      }}
                    >
                      Filer
                    </div>
                    <div
                      style={{
                        marginBottom: 12,
                        fontSize: theme.fontSizes.headerXSmall,
                      }}
                    >
                      Klicka på en fil för att öppna den
                    </div>

                    {cr.files?.map((file) => {
                      const fileType = file.str_representation?.split(".")[1];
                      const fileName = file.str_representation?.split(".")[0];

                      return (
                        <FileContainer
                          key={file.id}
                          onClick={() =>
                            window.open(file?.file?.get, "_blank").focus()
                          }
                        >
                          {ICON_MAPPER[fileType]}
                          <div>
                            <FileType>{FILE_TYPE_MAPPER[fileType]}</FileType>
                            <FileName>{fileName}</FileName>
                          </div>

                          <FileTypeDisplay>{fileType}</FileTypeDisplay>
                        </FileContainer>
                      );
                    })}
                  </FilesContainer>
                </>
              )}
            </InnerBox>
          );
        })}
      </StandardModal>

      <OverviewTitleWrapper>
        <OverviewTitleWithSubtitleWrapper>
          <OverviewTitle>Besiktning av komponenter</OverviewTitle>
          <OverviewSubtitle>
            Klicka på en komponent för att ge den ett omdöme
          </OverviewSubtitle>

          <OverviewSubtitle>
            <TextButton
              title="Eller tryck här för att markera alla komponenter som godkända"
              iconType="done"
              iconPlacement="right"
              clicked={() => markAllAsOk()}
            />
          </OverviewSubtitle>
        </OverviewTitleWithSubtitleWrapper>
      </OverviewTitleWrapper>

      {Object.keys(componentsByRoom)?.length > 0 && (
        <RoomOverview
          isParkingSpot={!!parkingSpot}
          rooms={componentsByRoom}
          handleEditRoom={() => {}}
          ratings={executionRatings}
          showRatings
          isExecute
          onRate={handleOnRate}
          onChecklistRate={handleOnChecklistRate}
          checklists={checklists}
          clearRating={clearRating}
        />
      )}
    </>
  );
};
