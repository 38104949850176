import * as React from "react";

export default () => {
  return (
    <div>
      Registrering av avvikelser.
      <br />
      <br />
      Fyll i alla eventuella avvikelser som uppstår under ronderingen.
    </div>
  );
};
