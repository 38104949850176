import * as React from "react";

import columnDefs from "./listDefs";

import { constants } from "../../../store/roundingComponents";
import BaseTable from "../../Lists/Base/FinalTable/BaseTable";

export default function RoundingComponentsTable({
  persistantQuery,
  tableId,
  ignoreLocalStorage,
  isBare,
  checkRowHighlighted,
  onRowClicked,
}) {
  const columns = React.useMemo(() => columnDefs(), [persistantQuery]);

  const filters = {};

  return (
    <BaseTable
      tableId={tableId || "rounding_components_full_table"}
      title="Komponenter"
      {...{
        checkRowHighlighted,
        onRowClicked,
        ignoreLocalStorage,
        isBare,
        filters,
        constants,
        columns,
        persistantQuery,
      }}
    />
  );
}
