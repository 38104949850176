import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../../components/Details/OverviewInfo/styles";
import { exportEditableDoc } from "../../../components/DigitalDoc/utils";
import { PrimaryButton } from "../../../components/Forms/Base/Buttons";
import OverlaySpinner from "../../../components/Loaders/OverlaySpinner";
import Pdf from "../../../components/Pdf/Pdf";
import {
  DetailInnerWrapper,
  DetailPageBox,
  InnerBox,
} from "../../../components/sharedStyles";
import {
  setActiveFormInstance,
  useFormInstanceField,
} from "../../../store/base";
import {
  EDITABLE_DOC_CONTRACT_TYPES,
  handleContractEditableDocUrl,
  matchContractAttributesToPartyFields,
} from "../../../store/editabledocs";
import {
  constants,
  setDocumentApprovalIndex,
  skipDocument,
} from "../../../store/pipes";
import { constants as tenantConstants } from "../../../store/tenants";
import { constants as apartmentConstants } from "../../../store/apartments";
import { constants as industrialPremisesConstants } from "../../../store/industrialPremises";
import { constants as parkingSpotConstants } from "../../../store/parkingSpots";
import { constants as companyConstants } from "../../../store/companies";
import { constants as companyInvoicingConstants } from "../../../store/invoicingCompany";
import { constants as userConstants } from "../../../store/users";
import {
  constants as leaseContractConstants,
  overviewUrl as leaseOverviewUrl,
  detailUrl as leaseContractDetailUrl,
  update as leaseContractUpdate,
} from "../../../store/leaseContracts";
import {
  constants as parkingContractConstants,
  overviewUrl as parkingOverviewurl,
  detailUrl as parkingContractDetailUrl,
  update as parkingContractUpdate,
} from "../../../store/parkingContracts";
import { addToast, TOAST_TYPES } from "../../../store/toasts";
import { axiosInstance } from "../../../store/base/store/axios";
import { useHistory, useParams } from "react-router-dom";
import { InfoBox } from "../../../components/Displays";
import Table from "../../../components/Billecta/Table/BasicTable";
import { activateScriveSigning } from "../../../store/editabledocs/store/actions";
import StackedButtons from "../../../components/Forms/Base/Buttons/StackedButtons";
export default function HandleLeadEditableDoc() {
  const dispatch = useDispatch();
  const { type } = useParams();
  const { push } = useHistory();
  const storeName = constants.STORE_NAME;
  const index = useSelector((state) => state[storeName].docIndex);
  const createdContracts = useSelector(
    (state) => state[storeName].createdContracts
  );
  const skippedContracts = useSelector(
    (state) => state[storeName].deniedDocContracts
  );
  const editableDocBase = useFormInstanceField({
    storeName,
    fieldKey: "base_doc",
  });

  const approvedDocuments = createdContracts?.filter(
    (c) => !skippedContracts?.find((sc) => sc.id == c.id)
  );
  const totalApproved = approvedDocuments?.length;

  const [currentEditableDocProposalData, setCurrentEditableDocProposalData] =
    React.useState(null);
  const [currentEditableDocProposal, setCurrentEditableDocProposal] =
    React.useState(null);

  const [landlordCompanies, setLandlordCompanies] = React.useState([]); // cached landlords, will probably be the same for many docs
  const [landlordCompanyUsers, setLandlordCompanyUsers] = React.useState([]); // cached landlord users, will probably be the same for many docs
  const [landlordCompanyInvoicings, setLandlordCompanyInvoicings] =
    React.useState([]); // cached landlord invoicings, will probably be the same for many docs
  const [loading, setLoading] = React.useState(false);

  const idxOutOfRange = index > createdContracts?.length - 1;

  const currentContract = createdContracts?.[index];

  React.useEffect(() => {
    if (currentContract) {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: `Granska förslag för ${currentContract?.id_number}`,
          description: `Tryck på "Spara och gå vidare" för att spara. Tryck på "Neka och gå vidare" för att hantera dokumentet senare`,
        })
      );

      buildEditableDocProposal();
    }
  }, [currentContract]);

  /**
   * Fetches all necessary data to build document and sets as current proposal
   */
  const buildEditableDocProposal = async () => {
    setLoading(true);

    // get latest contract version
    const { data: contract } = await axiosInstance.get(
      `${
        type === "leasecontracts"
          ? leaseContractConstants.GET_URL
          : parkingContractConstants.GET_URL
      }${currentContract.id}/`
    );

    // tenant fetch
    const { data: tenant } = await axiosInstance.get(
      `${tenantConstants.GET_URL}${contract?.tenant?.id}/`
    );

    // premises fetch
    const { data: obj } = await axiosInstance.get(
      `${
        contract?.apartments?.length
          ? apartmentConstants.GET_URL
          : contract?.industrial_premises_list?.length
          ? industrialPremisesConstants.GET_URL
          : parkingSpotConstants.GET_URL
      }${
        contract?.apartments?.length
          ? contract?.apartments?.[0].id
          : contract?.industrial_premises_list?.[0].id
          ? contract?.industrial_premises_list?.[0].id
          : contract?.parking_spots?.[0].id
      }/`
    );

    // landlord company fetch
    let landlordCompany;
    const landlordCompanyId = contract?.landlord?.id;

    // first check in "cache"
    if (landlordCompanies?.find((l) => l.id == landlordCompanyId)) {
      landlordCompany = landlordCompanies?.find(
        (l) => l.id == landlordCompanyId
      );
    } else if (landlordCompanyId) {
      const { data: fetchedLandlord } = await axiosInstance.get(
        `${companyConstants.GET_URL}${landlordCompanyId}/`
      );

      landlordCompany = fetchedLandlord;

      // update "cache"
      setLandlordCompanies([...landlordCompanies, fetchedLandlord]);
    }

    // landlord user fetch
    let landlordUser;
    const landlordUserId = landlordCompany?.owner?.id;

    // first check in "cache"
    if (landlordCompanyUsers?.find((l) => l.id == landlordUserId)) {
      landlordUser = landlordCompanyUsers?.find((l) => l.id == landlordUserId);
    } else if (landlordUserId) {
      const { data: fetchedLandlordUser } = await axiosInstance.get(
        `${userConstants.GET_URL}${landlordUserId}/`
      );

      landlordUser = fetchedLandlordUser;

      // update "cache"
      setLandlordCompanyUsers([...landlordCompanyUsers, fetchedLandlordUser]);
    }

    // landlord user fetch
    let billingSettingLandlordCompany;
    const billingSettingId = landlordCompany?.invoicing_details?.id;

    // first check in "cache"
    if (landlordCompanyInvoicings?.find((l) => l.id == billingSettingId)) {
      billingSettingLandlordCompany = landlordCompanyInvoicings?.find(
        (l) => l.id == billingSettingId
      );
    } else if (billingSettingId) {
      const { data: fetchedBillingSetting } = await axiosInstance.get(
        `${companyInvoicingConstants.GET_URL}${billingSettingId}/`
      );

      billingSettingLandlordCompany = fetchedBillingSetting;

      // update "cache"
      setLandlordCompanyInvoicings([
        ...landlordCompanyInvoicings,
        billingSettingLandlordCompany,
      ]);
    }

    const proposalData = matchContractAttributesToPartyFields({
      editableDoc: editableDocBase,
      contract,
      apartments: contract?.apartments?.length ? [obj] : null,
      parkingSpots: contract?.parking_spots?.length ? [obj] : null,
      industrialPremises: contract?.industrial_premises_list?.length
        ? [obj]
        : null,
      tenants: [tenant],
      landlordCompany,
      landlordUser,
      billingSettingLandlordCompany,
      mainTenantId: contract?.tenant?.id,
    });

    // replace editabledoc base with built editable doc
    setCurrentEditableDocProposalData(proposalData);

    const proposal = await exportEditableDoc({
      editableDoc: proposalData,
      forShow: true,
    });

    setCurrentEditableDocProposal(proposal);
    setLoading(false);
  };

  const skipDoc = () => {
    setLoading(true);
    // create empty editabledoc to allow for editing in later stage
    const updateMethod =
      type === "leasecontracts" ? leaseContractUpdate : parkingContractUpdate;
    const contractConstants =
      type === "leasecontracts"
        ? leaseContractConstants
        : parkingContractConstants;

    //Set form instance to make sure POST and upload gets correct data
    dispatch(
      setActiveFormInstance({
        storeName: contractConstants.STORE_NAME,
        data: {
          id: currentContract.id,
          editabledoc: {
            title: currentContract?.id_number || "doc",
          },
        },
      })
    );

    dispatch(
      updateMethod({
        preventDefaultToast: true,
        id: currentContract.id,
        successCallback: () => {
          // go to next doc
          dispatch(skipDocument());

          // reset state
          setCurrentEditableDocProposal(null);
          setCurrentEditableDocProposalData(null);
          setLoading(false);
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Förfrågan misslyckades",
              description: "Kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const saveDocument = (sendForSigning) => {
    setLoading(true);

    const updateMethod =
      type === "leasecontracts" ? leaseContractUpdate : parkingContractUpdate;
    const contractConstants =
      type === "leasecontracts"
        ? leaseContractConstants
        : parkingContractConstants;

    //Set form instance to make sure POST and upload gets correct data
    dispatch(
      setActiveFormInstance({
        storeName: contractConstants.STORE_NAME,
        data: {
          id: currentContract.id,
          editabledoc: currentEditableDocProposalData,
        },
      })
    );

    dispatch(
      updateMethod({
        preventDefaultToast: true,
        id: currentContract.id,
        successCallback: (_, responseData) => {
          const editableDocId = responseData.editabledoc.id;

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Skapandet lyckades",
            })
          );

          if (sendForSigning) {
            dispatch(
              activateScriveSigning({
                id: editableDocId,
                successCallback: (sign) => {
                  // Signing process started
                  // go to next doc
                  dispatch(
                    setDocumentApprovalIndex({
                      index: index + 1,
                    })
                  );

                  dispatch(
                    addToast({
                      type: TOAST_TYPES.SUCCESS,
                      title: "Signeringsprocess påbörjades",
                      description:
                        "Gå till avtalets detaljsida för att se status och händelser",
                    })
                  );

                  // reset state
                  setCurrentEditableDocProposal(null);
                  setCurrentEditableDocProposalData(null);
                  setLoading(false);
                },
                errorCallback: (e, data) => {
                  dispatch(
                    addToast({
                      type: TOAST_TYPES.ERROR,
                      title: "Kunde ej starta signeringsprocess",
                      description: "Kontakta Pigello om problemet kvarstår",
                    })
                  );

                  // go to next doc
                  dispatch(
                    setDocumentApprovalIndex({
                      index: index + 1,
                    })
                  );

                  // reset state
                  setCurrentEditableDocProposal(null);
                  setCurrentEditableDocProposalData(null);
                  setLoading(false);
                },
              })
            );
          } else {
            // go to next doc
            dispatch(
              setDocumentApprovalIndex({
                index: index + 1,
              })
            );

            // reset state
            setCurrentEditableDocProposal(null);
            setCurrentEditableDocProposalData(null);
            setLoading(false);
          }
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Skapande av signeringsdokument misslyckades",
              description: "Kontakta Pigello om problemet kvarstår",
            })
          );
        },
      })
    );
  };

  const openEditableDocHandler = (contractId) => {
    const url = handleContractEditableDocUrl({
      id: contractId,
      contractType:
        type === "leasecontracts"
          ? EDITABLE_DOC_CONTRACT_TYPES.LEASE_CONTRACT
          : EDITABLE_DOC_CONTRACT_TYPES.PARKING_CONTRACT,
    });
    window.open(url, "_blank").focus();
  };

  const openContractHandler = (contractId) => {
    const urlMethod =
      type === "leasecontracts"
        ? leaseContractDetailUrl
        : parkingContractDetailUrl;
    const url = urlMethod({ id: contractId });
    window.open(url, "_blank").focus();
  };

  const createdData = React.useMemo(() => {
    return approvedDocuments;
  }, [approvedDocuments]);

  const skippedData = React.useMemo(() => {
    return skippedContracts;
  }, [skippedContracts]);

  const skippedColumns = React.useMemo(
    () => [
      {
        Header: "Avtals-ID",
        accessor: "id_number",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Hyresgäst",
        accessor: "tenant",
        Cell: ({ value }) => {
          return <div>{value?.str_representation}</div>;
        },
      },
      {
        Header: "Objekt",
        accessor: (row) =>
          row.apartments?.[0]?.str_representation ||
          row.industrial_premises_list?.[0]?.str_representation ||
          row.parking_spots?.[0]?.str_representation,
        Cell: ({ row }) => {
          const obj =
            row.original.apartments?.[0] ||
            row.original.industrial_premises_list?.[0] ||
            row.original.parking_spots?.[0];

          return <div>{obj?.str_representation}</div>;
        },
      },

      {
        Header: "Hyresvärd",
        accessor: "landlord",
        Cell: ({ value }) => {
          return <div>{value?.str_representation}</div>;
        },
      },

      {
        Header: "Fastighet",
        accessor: "realestate",
        Cell: ({ value }) => {
          return <div>{value?.str_representation}</div>;
        },
      },
    ],
    []
  );

  // done with all contracts
  if (idxOutOfRange) {
    return (
      <DetailInnerWrapper>
        <DetailPageBox>
          {loading && <OverlaySpinner />}

          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle>Alla dokument har gåtts igenom</OverviewTitle>
            </OverviewTitleWithSubtitleWrapper>

            <div style={{ display: "flex", alignItems: "center" }}>
              <PrimaryButton
                extraStyle={{ marginRight: 12 }}
                title="Gå till avtalsöversikt"
                clicked={() =>
                  push(
                    type === "leasecontracts"
                      ? leaseOverviewUrl()
                      : parkingOverviewurl()
                  )
                }
              />
            </div>
          </OverviewTitleWrapper>

          {skippedData?.length > 0 ? (
            <InnerBox style={{ marginTop: 24 }}>
              {skippedContracts?.length > 0 && (
                <InfoBox
                  boxTheme="warning"
                  title={`${skippedContracts.length} dokument hoppades över`}
                  text="Dessa avtal listas nedan. Tryck på ett avtal för att hantera dess dokument separat. Listan kan exporteras nedan för att hanteras senare."
                />
              )}

              <Table
                withExport
                onRowClicked={(row) => openEditableDocHandler(row.original.id)}
                data={skippedData}
                columns={skippedColumns}
              />
            </InnerBox>
          ) : (
            <></>
          )}

          {createdData?.length > 0 && (
            <InnerBox style={{ marginTop: 24 }}>
              {totalApproved > 0 && (
                <InfoBox
                  boxTheme="ok"
                  title={`${totalApproved} dokument skapades`}
                  text="Dessa avtal är redo att starta signering på. Gå till avtalsöversikten för att starta signeringsprocesserna om de inte startades direkt i detta flöde."
                />
              )}

              <Table
                withExport
                onRowClicked={(row) => openContractHandler(row.original.id)}
                data={createdData}
                columns={skippedColumns}
              />
            </InnerBox>
          )}
        </DetailPageBox>
      </DetailInnerWrapper>
    );
  }

  return (
    <DetailInnerWrapper>
      <DetailPageBox>
        {loading && <OverlaySpinner />}
        <OverviewTitleWrapper>
          <OverviewTitleWithSubtitleWrapper>
            <OverviewTitle>
              Granska signeringsdokument {(index || 0) + 1} av{" "}
              {createdContracts?.length}
            </OverviewTitle>

            <OverviewSubtitle>
              Avtalsnummer: {currentContract?.id_number}
            </OverviewSubtitle>
            <OverviewSubtitle>
              Hyresobjekt:{" "}
              {currentContract?.apartments?.[0]?.str_representation ||
                currentContract?.industrial_premises?.[0]?.str_representation ||
                currentContract?.parking_spots?.[0]?.str_representation}
            </OverviewSubtitle>
            <OverviewSubtitle>
              Hyresgäst: {currentContract?.tenant?.str_representation}
            </OverviewSubtitle>
          </OverviewTitleWithSubtitleWrapper>

          <StackedButtons
            options={[
              {
                title: "Spara dokument",

                onClick: saveDocument,
              },
              {
                title: "Spara dokument och starta signeringsprocess",
                onClick: () => saveDocument(true),
              },
              {
                title: "Hoppa över detta dokument",
                type: "error",
                onClick: skipDoc,
              },
            ]}
          />
        </OverviewTitleWrapper>

        {!loading && currentEditableDocProposal && !idxOutOfRange && (
          <InnerBox>
            <Pdf uint8={currentEditableDocProposal} />
          </InnerBox>
        )}
      </DetailPageBox>
    </DetailInnerWrapper>
  );
}
