import * as React from "react";
import { useParams } from "react-router";
import { DetailInnerWrapper } from "../../../components/sharedStyles";

import { useRoundingErrand } from "../../../store/roundingErrands";
import Document from "../../../components/Details/RoundingErrand/Document";

function ErrandReport() {
  const { govErrandId } = useParams();
  const [govErrand, govErrandLoading] = useRoundingErrand(govErrandId);

  return (
    <DetailInnerWrapper>
      <Document roundingErrand={govErrand} />
    </DetailInnerWrapper>
  );
}

export default ErrandReport;
