import React, { useLayoutEffect } from "react";
import {
  getInfoForWidget,
  kpiDataMapping,
  KPI_OCCUPANCY,
  prepGoalsForWidget,
  WIDGET_DONUTCHART,
} from "../../components/Insights/Widgets/WidgetInfo";
import {
  useInsightsWidgetData,
  constants,
  destroyPatchForm,
} from "../../store/widgetPlacements/index";

import ReactApexChart from "react-apexcharts";
import * as DetailPageStyles from "../../views/Layouts/DetailPageHeaderMenu/styles";
import * as SC from "../../components/Layout/Insights/Widgets/styles";
import * as buttonStyles from "../../components/Forms/Base/Buttons/styles";
import PopupHandle from "../../components/Insights/PopupHandle";
import { useConfigCenter } from "../../store/configcenter";
import { useDispatch, useSelector } from "react-redux";
import { buildQueryString, useAllPermissionCheck, useInProgress } from "../../store/base";
import { useInsightsGoals } from "src/store/insightsGoal";
import { removeFromProgress } from "src/store/base/store/actions";

import classNames from "classnames";

import trendingUpIcon from "../../assets/svg/material/trending_up.svg";
import trendingDownIcon from "../../assets/svg/material/trending_down.svg";

export default function WidgetPlacement({
  _widgetPlacement,
  _insightsPage,
  gridRef,
  setFormOpen,
  handleDeleteModal,
  jsonWidgetPlacement,
  setQuickEditPopupOpen,
  hasEditPermission,
  realestateIds,
  needRealEstateIds
}) {
  const deleteQueue = useSelector((state) => {
    return state[constants.STORE_NAME].deleteQueue;
  });

  const dispatch = useDispatch();

  let id = _widgetPlacement?.id;
  if (jsonWidgetPlacement || (deleteQueue && deleteQueue[id])) {
    id = null;
  }
  let widgetPlacement = null;
  if (jsonWidgetPlacement) {
    widgetPlacement = jsonWidgetPlacement;
  } else {
    widgetPlacement = _widgetPlacement;
  }
  let insightsPage = null;
  if (jsonWidgetPlacement) {
    insightsPage = { width: 6, height: 10 };
  } else {
    insightsPage = _insightsPage;
  }

  const hasLoadedWidgetPlacementData = useSelector((state) => {
    return Boolean(state[constants.STORE_NAME]?.resultData?.[widgetPlacement?.id])
  })

  const [widgetPlacementData, widgetPlacementDataLoading] = useInsightsWidgetData(insightsPage, widgetPlacement, jsonWidgetPlacement, realestateIds, needRealEstateIds);

  const filterRealEstateIds = insightsPage?.query_parameters?.realestate_ids || []
  const goalFilter = buildQueryString({
    kpi: widgetPlacement?.kpi,
    exact_realestate_ids: filterRealEstateIds
  });
  const [insightsGoals, insightsGoalsLoading] = useInsightsGoals(goalFilter)
  const [configCenter, configCenterLoading] = useConfigCenter();
  const noData =
    widgetPlacementData?.no_data ||
    widgetPlacementData?.datapoints?.length === 0;

  let labels = widgetPlacementData?.labels;
  let chartSeries = widgetPlacementData?.datapoints;
  const widgetInfo = getInfoForWidget(widgetPlacement?.widget);
  const InternalComponent = widgetInfo?.internal_component;

  const [quickEditOpen, setQuickEditOpen] = React.useState(false);

  const [containerWidth, setContainerWidth] = React.useState(0);
  const [containerHeight, setContainerHeight] = React.useState(0);

  const [aveGoalDiff, setAveGoalDiff] = React.useState(null);
  const [goalMetric, setGoalMetric] = React.useState(null);
  const [goalMetricGoals, setGoalMetricGoals] = React.useState([]);
  const [goalsPrepped, setGoalsPrepped] = React.useState(false);

  const [chartColors, setChartColors] = React.useState([]);

  const hasChangePermission = useAllPermissionCheck([
    "allow_insights",
    "change_can_insights",
  ]);

  const popupRef = React.useRef();
  const inProgress = useInProgress({
    storeName: constants.STORE_NAME,
    name: `value-${widgetPlacement?.id}`,
  });  
  const [popupPlacement, setPopupPlacement] = React.useState(["right", "down"]);

  const nonTimeKpi = kpiDataMapping[widgetPlacement?.kpi]?.disable_time;

  let chartOptions = {};
  let cleanValueDict = {};

  useLayoutEffect(() => {
    if (!gridRef.current) return;
    if (jsonWidgetPlacement || (widgetPlacement && insightsPage)) {
      setContainerWidth(
        (gridRef.current?.offsetWidth / insightsPage?.width) *
          getInfoForWidget(widgetPlacement?.widget).width
      );
      setContainerHeight(
        (gridRef.current?.offsetHeight / insightsPage?.height) * getInfoForWidget(widgetPlacement?.widget).height
      );
    }
  }, [
    widgetPlacement,
    widgetPlacementData,
    insightsPage,
    gridRef.current?.offsetWidth,
    gridRef.current?.offsetHeight,
    localStorage.getItem("sidebar__open"),
  ]); 


  React.useEffect(() => {
  if (!goalsPrepped && chartSeries && insightsGoalsLoading === false) {
      if (!widgetPlacementDataLoading && chartSeries && chartSeries.length === 0) {
        setGoalsPrepped(true)
        return
      }
      const [totalValue, _goalMetricGoals, goalMetric] = prepGoalsForWidget(widgetPlacement.widget, insightsGoals, chartSeries)
      setAveGoalDiff(Math.round(totalValue / _goalMetricGoals.length * 100)) 
      setGoalMetricGoals(_goalMetricGoals) 
      setGoalMetric(goalMetric)
      setGoalsPrepped(true)
  }
  }, [insightsGoalsLoading, chartSeries, goalsPrepped])

  React.useEffect(() => {
    if (goalsPrepped) setGoalsPrepped(false)
  }, [widgetPlacementData])


  React.useEffect(() => {
  const newChartColors = []
  if (!configCenter) return
  if (!hasEditPermission) {
    ["#5165FB", "#A2ADFC", "#7686FC", "#2A42F8", "#0F29F0"].forEach((elem) =>
      newChartColors.push(elem)
    );
  } else {
    configCenter.insights_color_scheme.forEach((elem) => {
      newChartColors.push(`#${elem}`);
    });
  }
  setChartColors(newChartColors)
  }, [hasEditPermission, configCenter])

  if (!widgetPlacementData || widgetPlacementDataLoading || !hasLoadedWidgetPlacementData || (!widgetPlacementData?.no_data && (!configCenter || !goalsPrepped || chartColors.length === 0))) {
    return;
  } else {
    if (inProgress) {
      removeFromProgress(dispatch, constants, `value-${widgetPlacement.id}`)
    }
  }

  const additionalData = widgetPlacementData.additionalData;

  if (InternalComponent && !noData) {
    (additionalData["containerHeight"] = containerHeight),
      (additionalData["containerWidth"] = containerWidth),
      (chartOptions = widgetInfo?.chart_options(
        widgetPlacement,
        chartColors,
        cleanValueDict,
        chartSeries,
        labels,
        additionalData,
        insightsGoals?.length
      ));
  } else if (!noData) {
    chartOptions = widgetInfo?.chart_options(
      labels,
      widgetPlacement,
      chartColors,
      chartSeries,
      insightsGoals?.length
    );
  }

  const handlePopupClick = (e) => {
    if (nonTimeKpi) {
      setFormOpen(widgetPlacement);
    } else if (!quickEditOpen) {
      const newPopupPlacement = ["right", "down"];
      if (widgetPlacement.grid_column_end >= insightsPage.width) {
        newPopupPlacement[0] = "left";
      }
      if (widgetPlacement.grid_row_end >= insightsPage.height) {
        newPopupPlacement[1] = "up";
      }
      setPopupPlacement(newPopupPlacement);
      setQuickEditOpen(true);
      setQuickEditPopupOpen(true);
    } else {
      setQuickEditPopupOpen(false);
      setQuickEditOpen(false);
    }
  };

  const chartType = widgetInfo?.apex_chart_type;

  if (widgetPlacement?.kpi === KPI_OCCUPANCY && widgetPlacement?.widget === WIDGET_DONUTCHART) {
    chartSeries = [...chartSeries].concat([100 - chartSeries.reduce((partialSum, a) => partialSum + a, 0)])
    chartOptions = {...chartOptions}
    if (chartOptions["labels"].length > 0 && chartOptions["labels"][-1] !== "Vakans") chartOptions["labels"].push("Vakans")
  }

  const getRenderComponent = () => {
    if (noData) {
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <p style={{ fontSize: "0.9rem" }}>
            Det verkar inte finnas någon data, bekräfta dina behörigheter
          </p>
        </div>
      );
    }
    if (!chartSeries || !containerWidth || !containerHeight) return;
    if (InternalComponent) {
      return (
        <InternalComponent
          height={containerHeight - 100}
          width={containerWidth * 0.85}
          valueDict={cleanValueDict}
          chartOptions={chartOptions}
          widgetPlacement={widgetPlacement}
        />
      );
    } else if (chartType) {
      return (
        <ReactApexChart
          options={{ ...chartOptions }}
          series={chartSeries}
          height={containerHeight - 100}
          width={containerWidth * 0.95}
          type={chartType}
        />
      );
    } else {
      return (
        <ReactApexChart
          options={{ ...chartOptions }}
          series={chartSeries}
          height={containerHeight - 100}
          width={containerWidth * 0.95}
        />
      );
    }
  };

  const handleQuickEditClose = (skipDestroy) => {
    if (!skipDestroy) {
      dispatch(destroyPatchForm(false));
    }
    setQuickEditPopupOpen(false);
    setQuickEditOpen(false);
  };

  return (
    <div>
      <SC.InsightsWidgetContainer
        containerWidth={containerWidth - 10}
        containerHeight={containerHeight - 10}
        minWidth={
          (520 * getInfoForWidget(widgetPlacement?.widget).width) /
          insightsPage?.width
        }
      >
        <SC.InsightsSettingsContainer>
          <DetailPageStyles.HandleAction
            ref={popupRef}
            noPointer={true}
            justifyContent={"space-between"}
            padding={"1px 0"}
          >
            <div
              style={{ marginLeft: "15px", paddingTop: "5px", maxWidth: "80%" }}
            >
              <p
                style={{
                  color: "#696969",
                  cursor: "auto",
                  fontSize: "10px",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {widgetPlacement?.kpi_display}
              </p>
              <p
                style={{
                  color: "#a6a4a4",
                  cursor: "auto",
                  fontSize: "10px",
                }}
              >
                {widgetPlacement?.interval_type ? "Intervall: " : ""}{" "}
                {widgetPlacement?.interval_type_display}
                {widgetPlacement?.end_date
                  ? `${widgetPlacement?.start_date || ""} ${widgetPlacement?.end_date}`
                  : ""}
              </p>
            </div>
            {!jsonWidgetPlacement && (
              <>
                {hasChangePermission && (
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={handlePopupClick}
                  >
                    <buttonStyles.MenuButton
                      style={{ display: "flex", alignItems: "center" }}
                    />
                  </div>
                )}

                <PopupHandle
                  {...{
                    open: quickEditOpen,
                    closeFunction: (skipDestroy) =>
                      handleQuickEditClose(skipDestroy),
                    widgetPlacement,
                    openModalForm: setFormOpen,
                    handleDeleteModal: handleDeleteModal,
                    popupPlacement: popupPlacement,
                  }}
                />
              </>
            )}
          </DetailPageStyles.HandleAction>
        </SC.InsightsSettingsContainer>
        <SC.InsightsChartWrapper>
          {getRenderComponent()}
        </SC.InsightsChartWrapper>
        {Boolean(aveGoalDiff) && (
        <div className="flex flex-row justify-end relative">
          <div className={classNames(goalMetric ? "bg-[rgb(112,213,139)]" : "bg-[rgb(255,133,133)]", "rounded pl-1 flex flex-row")}>
            <div className="flex flex-row py-0.5">
              <p className="text-[0.5rem] text-white font-bold mr-1 leading-[16px]">Mål | {aveGoalDiff}%</p>
                {goalMetricGoals.length > 1 && (
              <div className={classNames(goalMetric ? "bg-[rgba(202,255,202,0.3)]": "bg-[rgba(255,202,202,0.3)]", "rounded-full min-w-[16px] mr-1 px-1")}>
                <p className="text-[0.5rem] text-white font-bold text-center leading-[16px]">+{goalMetricGoals.length -1}</p>
            </div>
                )}
              </div>
            <div className={classNames(goalMetric ? "bg-[rgb(42,197,83)]":"bg-[rgb(252,81,81)]", "py-0.5 pr-1 rounded")}>
                  <div style={{ backgroundImage: `url(${goalMetric ? trendingUpIcon : trendingDownIcon})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", height: "16px", width: "16px", marginLeft: "2px"}}></div>
            </div>
          </div>
        </div>

        )}
      </SC.InsightsWidgetContainer>
    </div>
  );
}
